import { LinearProgress } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAUserRider } from "../api/usersRiders.api";
import BackButton from "./BackButton";
import UsersRidersSingleInformation from "./UsersRidersSingleInformation";
export default function UsersRidersSingle() {
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    ["getAUserRider", parseInt(id)],
    () => getAUserRider(parseInt(id))
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <BackButton></BackButton>
      {data && (
        <>
          <h2 style={{ margin: "20px 0px 0px 0px" }}>
            {data.users_riders[0].first_name
              ? data.users_riders[0].first_name
              : ""}{" "}
            {data.users_riders[0].last_name
              ? data.users_riders[0].last_name
              : ""}{" "}
          </h2>
          <div style={{ margin: "0px 0px 20px 0px" }}>
            {data.users_riders[0].email}
          </div>
        </>
      )}

      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Information"></Tab>
      </Tabs>

      {value == 0 && (
        <UsersRidersSingleInformation></UsersRidersSingleInformation>
      )}
    </div>
  );
}
