import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { FormattedCreateMerchantPayload } from "../components/MerchantCreate";
export interface Merchant {
  id: number;
  name?: string;
  subtitle?: string;
  image?: string;
  logo?: string;
  hash?: string;
  description?: string;
  address_id?: number;
  google_place_id?: string | null;
  hours?: string;
  phone_number?: string;
  priority?: number;
  website_url?: string;
  rating?: string;
  status?: number;
  creation_date?: number;
  update_date?: number;
}

export interface MerchantXMerchantCategory {
  id: number;
  name?: string;
  subtitle?: string;
  image?: string;
  logo?: string;
  hash?: string;
  description?: string;
  address_id?: number;
  google_place_id?: string | null;
  hours?: string;
  phone_number?: string;
  priority?: number;
  website_url?: string;
  rating?: string;
  status?: number;
  creation_date?: number;
  update_date?: number;
  address_name?: string;
  address_latitude?: string;
  address_longitude?: string;
  category_names?: string;
  category_ids?: string;
}

interface MerchantResponse {
  success: boolean;
  merchants: Merchant[];
}
interface MerchantXMerchantCategoryResponse {
  success: boolean;
  merchants: MerchantXMerchantCategory[];
}
export interface MerchantUpdatePayload {
  merchant: {
    id?: number;
    name?: string;
    subtitle?: string;
    image?: string;
    logo?: string;
    hash?: string;
    description?: string;
    address_id?: number;
    google_place_id?: string | null;
    hours?: string;
    phone_number?: string;
    priority?: number;
    website_url?: string;
    rating?: string;
    status?: number;
    creation_date?: number;
    update_date?: number;
  };
  address: {
    name?: string;
    latitude?: number;
    longitude?: number;
  };
  merchant_categories: number[];
}

interface MerchantGeosuggestFixtures {
  address_name: string;
  address_latitude: number;
  address_longitude: number;
}
interface GetMerchantGeosuggestFixturesResponse {
  success: boolean;
  fixtures: MerchantGeosuggestFixtures[];
}
export async function getMerchants(): Promise<MerchantXMerchantCategoryResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<MerchantXMerchantCategoryResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/merchants`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getMerchantFixtures(): Promise<GetMerchantGeosuggestFixturesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetMerchantGeosuggestFixturesResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/merchants/geosuggest-fixtures`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getAMerchant(
  id: number
): Promise<MerchantXMerchantCategoryResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<MerchantXMerchantCategoryResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/merchants/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function updateAMerchant(
  merchantPayload: MerchantUpdatePayload
): Promise<MerchantXMerchantCategoryResponse> {
  const token = useAuthStore.getState().token;

  const {
    merchant: { id, ...merchantWithoutId },
    ...rest
  } = merchantPayload;
  const newMerchantPayload = { ...rest, merchant: merchantWithoutId };

  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/merchants/${id}`,
    newMerchantPayload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

interface CreateAMerchantPayload {
  name?: string;
  subtitle?: string;
  description?: string;
  phone_number?: string;
  website_url?: string;
  priority?: number;
  status?: number;
  address_string?: string;
}

export async function createAMerchant(
  merchant: FormattedCreateMerchantPayload
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/merchants`,
    merchant,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function searchMerchants(
  searchParameters: string
): Promise<MerchantResponse> {
  const token = useAuthStore.getState().token;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/merchants/search`,
    {
      search: searchParameters,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
