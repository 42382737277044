import AndroidIcon from "@mui/icons-material/Android";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
export default function RidesCreatedFromOptions(props: {
  created_from: number;
}) {
  const buttonStyle = {
    minWidth: "auto",
    padding: "2px 1px",
    cursor: "default",
    color: "gray",
  };

  return (
    <>
      {props.created_from === 0 && (
        <Tooltip title={`Created from Dashboard`}>
          <Button style={{ ...buttonStyle }}>
            <DashboardIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.created_from === 1 && (
        <Tooltip title={`Created from iOS Rider App`}>
          <Button style={{ ...buttonStyle }}>
            <PhoneIphoneIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.created_from === 2 && (
        <Tooltip title={`Created from Driver App`}>
          <Button style={{ ...buttonStyle }}>
            <DirectionsCarIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.created_from === 3 && (
        <Tooltip title={`Created from Android Rider App`}>
          <Button style={{ ...buttonStyle }}>
            <AndroidIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
