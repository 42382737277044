import { Alert, LinearProgress, MenuItem, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DrawingManagerF, GoogleMap } from "@react-google-maps/api";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { getZoneCoordinatesAndZoomFromSystemParam } from "../api/sys_params.api";
import {
  ZoneDeviated,
  createAZoneDeviated,
  getStopsWithoutDeviatedZone,
} from "../api/zonesDeviated.api";
import { polygonStringValidation } from "../utility/polygonStringValidation";
import BackButton from "./BackButton";
import StaticMapPreview from "./StaticMapPreview";
import * as turf from "@turf/turf";
import { Polygon } from "geojson";
import {
  coordinatesToPolygonString,
  isValidPolygonString,
  kmlStringToGeoJson,
} from "../utility/geoUtils";

const containerStyle = {
  width: "600px",
  height: "400px",
};
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function ZoneDeviatedCreate() {
  const navigate = useNavigate();
  const {
    data: zoneCoordinatesAndZoomFromSystemParamData,
    status,
    isLoading,
    isRefetching,
  } = useQuery(
    "getZoneCoordinatesAndZoomFromSystemParam",
    getZoneCoordinatesAndZoomFromSystemParam,
    {
      refetchOnMount: true,
    }
  );
  // ZONE MUTATION SETUP
  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAZoneDeviated, {
    onSuccess: () => {
      queryClient.invalidateQueries("getZones");
      enqueueSnackbar("Zone deviated was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/zones-deviated");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this zone Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  // CLEAR ZONE STATE AND SET INITIAL PARAMETERS
  useEffect(() => {
    setSelectedRadio("kml");
  }, []);

  const {
    data: stopsData,
    status: stopsStatus,
    isLoading: stopsIsLoading,
    isRefetching: stopsIsRefetching,
  } = useQuery("getStopsWithoutDeviatedZone", getStopsWithoutDeviatedZone, {
    refetchOnMount: true,
  });

  //POLYGON GOOGLE MAPS HANDLER
  const onPolygonComplete = async (polygon: google.maps.Polygon) => {
    let coordinates = await polygon.getPath();
    let coordData = coordinates.getArray();
    let coordinatesFormatted = [];
    let i = 0;
    for (i; i < coordData.length; i++) {
      let formattedLat = coordData[i].lat().toFixed(6);
      let formattedLng = coordData[i].lng().toFixed(6);
      let formattedCoordinatePair = [formattedLat, formattedLng];
      coordinatesFormatted.push(formattedCoordinatePair);
    }
    let formattedString = "";
    coordinatesFormatted.forEach((coordArray) => {
      formattedString += coordArray.toString() + " ";
    });
    formattedString += coordinatesFormatted[0].toString() + " ";
    let t = polygonStringValidation(formattedString);
    if (t) {
      setIsPolyStringValid(true);
    }
    setZonePayload({
      ...createZonePayload,
      polygon: formattedString,
    });
    polygon.setOptions({ editable: true, draggable: false });
  };

  const [createZonePayload, setZonePayload] = useState<ZoneDeviated>({
    status: 0,
    stop_id: 0,
    priority: 200,
    polygon: null,
  });
  const [selectedRadio, setSelectedRadio] = useState<string>("kml");
  const kmlInput = useRef<HTMLInputElement>();
  const [kmlUpdateError, setKmlUpdateError] = useState<boolean>(false);
  const [isPolyStringValid, setIsPolyStringValid] = useState<boolean>(false);

  function radioSelectHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setIsPolyStringValid(false);
    setZonePayload({
      ...createZonePayload,
      polygon: null,
    });
    setSelectedRadio(e.target.value);
  }

  async function kmlInputHandler() {
    try {
      //Check File Exists
      setKmlUpdateError(false);
      setIsPolyStringValid(false);
      if (!kmlInput.current?.files) {
        throw `No File attached.`;
      }
      //Check File name contains .kml
      let fileName = kmlInput.current?.files![0];
      if (fileName.name.match(/.kml/) == null) {
        setKmlUpdateError(true);
        throw `Incorrect file type.`;
      }
      let file = kmlInput.current?.files![0] as Blob;

      if (file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              if (reader.result) {
                const kmlGeoJson = kmlStringToGeoJson(reader.result as string);

                let firstPolygon: Polygon | null = null;
                turf.flattenEach(kmlGeoJson, (feature) => {
                  if (
                    feature.geometry.type === "Polygon" &&
                    feature.geometry.coordinates.length === 1 // Make sure it is a simple polygon (no holes)
                  ) {
                    firstPolygon = feature.geometry;
                    return false; // break out of flattenEach
                  }
                });

                if (!firstPolygon) {
                  throw new Error("Object must contain a Simple Polygon");
                }

                let coordinateString = coordinatesToPolygonString(
                  (firstPolygon as Polygon).coordinates[0]
                );

                if (!isValidPolygonString(coordinateString)) {
                  throw new Error("Invalid Polygon String.");
                }

                setZonePayload({
                  ...createZonePayload,
                  polygon: coordinateString,
                });
                setIsPolyStringValid(true);
              }
            } catch (error) {
              setKmlUpdateError(true);
            }
          },
          false
        );
        reader.readAsText(file);
      }
    } catch (error) {
      setKmlUpdateError(true);
    }
  }

  // useEffect(() => {
  //   setZonePayload({
  //     ...createZonePayload,
  //     stop_id: stopsData?.stops[0].id,
  //   });
  // }, [stopsData]);

  useEffect(() => {
    if (createZonePayload.stop_id != 0) {
      let stop = stopsData?.stops.filter((stop) => {
        return stop.id == createZonePayload.stop_id;
      })[0].name;
      setZonePayload({
        ...createZonePayload,
        name: stop,
      });
    }
  }, [createZonePayload.stop_id]);

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (createZonePayload.polygon) {
      setKmlUpdateError(false);
    }
    if (!kmlUpdateError) {
      await mutateAsync(createZonePayload);
    }
  }

  if (isMutationLoading || stopsIsLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Deviated Zone</h2>
      {zoneCoordinatesAndZoomFromSystemParamData && stopsData && (
        <div className="create-asset-form" style={{ width: "400px" }}>
          <form onSubmit={submitHandler}>
            <TextField
              required
              select
              inputProps={{ min: 1 }}
              style={{ display: "block", margin: "20px 0px 20px 0px" }}
              size="small"
              fullWidth
              placeholder="Select a stop"
              label="Stop"
              value={createZonePayload.stop_id}
              onChange={(e) => {
                setZonePayload({
                  ...createZonePayload,
                  stop_id: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0} disabled>
                Select a Stop
              </MenuItem>
              {stopsData?.stops.map((stop) => {
                return (
                  <MenuItem key={stop.id} value={stop.id}>
                    {stop.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              required
              inputProps={{ maxLength: 64, autoComplete: "off" }}
              size="small"
              fullWidth
              sx={inputBlock}
              label="Name"
              value={createZonePayload.name || ""}
              onChange={(e) => {
                setZonePayload({
                  ...createZonePayload,
                  name: e.target.value,
                });
              }}
            ></TextField>

            <TextField
              required
              inputProps={{ min: 0, max: 3 }}
              fullWidth
              sx={inputBlock}
              size="small"
              label="Status"
              select
              value={createZonePayload.status}
              onChange={(e) => {
                setZonePayload({
                  ...createZonePayload,
                  status: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>Enabled</MenuItem>
              <MenuItem value={1}>Disabled</MenuItem>
            </TextField>
            <TextField
              inputProps={{ min: 0, max: 255 }}
              required
              size="small"
              type={"number"}
              fullWidth
              sx={inputBlock}
              label="Priority"
              value={createZonePayload.priority}
              onChange={(e) => {
                setZonePayload({
                  ...createZonePayload,
                  priority: parseInt(e.target.value),
                });
              }}
            ></TextField>
            <FormLabel id="zone-definition-radio-buttons-group">
              Zone Definition
            </FormLabel>
            <RadioGroup
              aria-labelledby="zone-definition-buttons-group-label"
              // defaultValue="kml"
              value={selectedRadio}
              name="radio-buttons-group"
              onChange={radioSelectHandler}
            >
              <FormControlLabel
                value="kml"
                control={<Radio />}
                label="KML Upload"
              />
              <FormControlLabel
                value="zone"
                control={<Radio />}
                label="Draw a Zone"
              />
              <FormControlLabel
                value="coordinateString"
                control={<Radio />}
                label="Coordinate String"
              />
            </RadioGroup>
            {selectedRadio == "coordinateString" && (
              <div>
                <div className="display-flex-center">
                  <TextField
                    type="text"
                    inputProps={{ min: 0 }}
                    sx={inputBlock}
                    error={!isPolyStringValid}
                    label="Polygon Coordinate String"
                    onChange={(e) => {
                      let t = polygonStringValidation(e.target.value);
                      if (!t) {
                        setIsPolyStringValid(false);
                      }
                      if (t) {
                        setIsPolyStringValid(true);
                        setZonePayload({
                          ...createZonePayload,
                          polygon: e.target.value,
                        });
                      }
                    }}
                  ></TextField>
                  {isPolyStringValid && (
                    <StaticMapPreview
                      polygonString={createZonePayload.polygon!}
                    ></StaticMapPreview>
                  )}
                </div>

                {!isPolyStringValid && (
                  <Alert severity="error">
                    Invalid Polygon string formatting.
                  </Alert>
                )}
              </div>
            )}
            {selectedRadio == "kml" && (
              <div>
                <div className="display-flex-center">
                  <TextField
                    type="file"
                    fullWidth
                    sx={inputBlock}
                    error={!isPolyStringValid}
                    onChange={(e) => {
                      kmlInputHandler();
                    }}
                    inputRef={kmlInput}
                  ></TextField>
                  {isPolyStringValid && (
                    <StaticMapPreview
                      polygonString={createZonePayload.polygon!}
                    ></StaticMapPreview>
                  )}
                </div>
                {kmlUpdateError && (
                  <Alert severity="error">
                    There was a problem reading this file.
                  </Alert>
                )}
                {createZonePayload.polygon && !kmlUpdateError && (
                  <Alert severity="success">Succesfully uploaded file.</Alert>
                )}
              </div>
            )}
            {selectedRadio == "zone" && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                options={{
                  streetViewControl: false,
                }}
                center={{
                  lat: parseFloat(
                    zoneCoordinatesAndZoomFromSystemParamData.lat
                  ),
                  lng: parseFloat(
                    zoneCoordinatesAndZoomFromSystemParamData.lng
                  ),
                }}
                zoom={parseFloat(
                  zoneCoordinatesAndZoomFromSystemParamData.zoom
                )}
                id="drawing-manager-example"
              >
                <DrawingManagerF
                  options={{
                    drawingControl: true,
                    drawingControlOptions: {
                      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                    polygonOptions: {
                      editable: true,
                      draggable: false,
                    },
                  }}
                  drawingMode={google.maps.drawing.OverlayType.POLYGON}
                  onPolygonComplete={onPolygonComplete}
                />
              </GoogleMap>
            )}
            <button
              className="button-primary"
              style={{ margin: "20px 0px" }}
              type="submit"
              disabled={
                kmlUpdateError ||
                !createZonePayload.polygon ||
                !isPolyStringValid
              }
            >
              Create Zone
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
