import { LinearProgress } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getADriver } from "../api/drivers.api";
import BackButton from "./BackButton";
import DriversSingleInformation from "./DriversSingleInformation";
import DriversSingleShifts from "./DriversSingleShifts";
import DriversSingleRatings from "./DriversSingleRatings";
import DriversSingleRides from "./DriversSingleRides";
export default function DriversSingle() {
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    ["getADriver", parseInt(id)],
    () => getADriver(parseInt(id))
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  if (isLoading) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2 style={{ margin: "20px 0px" }}>
        {data?.drivers[0].first_name} {data?.drivers[0].last_name}
      </h2>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Information"></Tab>
        <Tab label="Shifts"></Tab>
        <Tab label="Ratings"></Tab>
        <Tab label="Rides"></Tab>
      </Tabs>

      {value == 0 && <DriversSingleInformation></DriversSingleInformation>}
      {value == 1 && <DriversSingleShifts></DriversSingleShifts>}
      {value == 2 && <DriversSingleRatings></DriversSingleRatings>}
      {value == 3 && <DriversSingleRides></DriversSingleRides>}
    </div>
  );
}
