import axios, { AxiosError } from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface Vehicle {
  id?: number;
  vehicle_type_id?: number;
  rider_display_name?: string | null;
  internal_number?: number;
  default_job_id?: number | null;
  sort_priority?: number;
  status?: number;
  vin_number?: string;
  tag_number?: string;
  nickname?: string;
}
interface VehicleXVehicleType {
  id?: number;
  vehicle_type_id?: number;
  nickname?: string;
  rider_display_name?: string;
  vin_number?: string;
  tag_number?: string;
  internal_number?: number;
  default_job_id?: number;
  sort_priority?: number;
  status?: number;
  name?: string;
  max_passengers?: number;
  max_passengers_hops?: number;
  default_color?: string;
}
interface GetAVehicleResponse {
  success: boolean;
  vehicle: VehicleXVehicleType[];
}
export const getVehicles = async function () {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/vehicles`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAVehicle = async function (
  vehicleId: number | undefined
): Promise<GetAVehicleResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/vehicles/${vehicleId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAVehicle = async function (vehicleFieldsToUpdate: Vehicle) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/vehicles`,
    vehicleFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAVehicle = async function (vehicle: Vehicle) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/vehicles`,
    vehicle,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
