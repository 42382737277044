import RectangleIcon from "@mui/icons-material/Rectangle";
import { Alert, LinearProgress, MenuItem, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { BlockPicker } from "react-color";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { Route, getARoute, getRouteKml, updateARoute } from "../api/routes.api";
import StaticMapPreview from "./StaticMapPreview";
import AutohideOptions from "./utility/AutohideOptions";
import JobTypeOptions from "./utility/JobTypeOptions";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";
import * as turf from "@turf/turf";
import { LineString } from "geojson";
import {
  coordinatesToPolygonString,
  isValidPolygonString,
  kmlStringToGeoJson,
} from "../utility/geoUtils";

export default function RoutesSingleInformation() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getARoute",
    () => getARoute(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync: updateRoute, isLoading: isMutationLoading } =
    useMutation(updateARoute, {
      onSuccess: () => {
        queryClient.invalidateQueries("getRoutes");
        queryClient.invalidateQueries("getARoute");
        enqueueSnackbar("Route was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this route. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    });
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedRoute, setUpdatedRoute] = useState<Route>({
    id: parseInt(id),
  });

  const kmlInput = useRef<HTMLInputElement>();
  const [kmlUpdateError, setKmlUpdateError] = useState<boolean>(false);

  // EXPORT ROUTE KML
  const {
    data: kmlExportData,
    isLoading: isKmlExportLoading,
    isRefetching: isKmlExportRefetching,
    refetch: refetchKmlExport,
  } = useQuery("getRouteKml", () => getRouteKml(parseInt(id)), {
    enabled: false,
    onSuccess: (d) => {
      const url = window.URL.createObjectURL(d);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${data?.routes[0].name}.kml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      enqueueSnackbar("Route KML exported succesfully.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem exporting this route KML. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  console.log(updatedRoute);
  async function kmlInputHandler() {
    try {
      setKmlUpdateError(false);
      let tmp = { ...updatedRoute };
      delete tmp.polygon;
      setUpdatedRoute(tmp);
      //Check File Exists
      if (!kmlInput.current?.files) {
        throw `No File attached.`;
      }
      //Check File name contains .kml
      let fileName = kmlInput.current?.files![0];
      if (fileName.name.match(/.kml/) == null) {
        setKmlUpdateError(true);
        throw `Incorrect file type.`;
      }
      let file = kmlInput.current?.files![0] as Blob;

      if (file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              if (reader.result) {
                const kmlGeoJson = kmlStringToGeoJson(reader.result as string);

                let firstLineString: LineString | null = null;
                turf.flattenEach(kmlGeoJson, (feature) => {
                  if (feature.geometry.type === "LineString") {
                    firstLineString = feature.geometry;
                    return false; // break out of flattenEach
                  }
                });

                if (!firstLineString) {
                  throw new Error("Object must contain a LineString");
                }

                let isSufficientlySmallCoordinateString = false;
                let outputString = "";
                let tolerance = 0.000005;

                while (isSufficientlySmallCoordinateString === false) {
                  let simplified = turf.simplify(
                    firstLineString as LineString,
                    {
                      tolerance: tolerance,
                      highQuality: false,
                    }
                  );

                  let simplifiedCoordinateString = coordinatesToPolygonString(
                    simplified.coordinates
                  );

                  if (simplifiedCoordinateString.length < 65535) {
                    isSufficientlySmallCoordinateString = true;
                    outputString = simplifiedCoordinateString;
                  } else {
                    tolerance *= 2;
                    if (tolerance > 1) {
                      throw new Error(
                        "Escaping while loop, try a different polygon string."
                      );
                    }
                  }
                }

                if (!isValidPolygonString(outputString)) {
                  throw new Error("Invalid Polygon String.");
                }

                setUpdatedRoute({
                  ...updatedRoute,
                  polygon: outputString,
                });
              }
            } catch (error) {
              console.log(error);
              setKmlUpdateError(true);
            }
          },
          false
        );
        reader.readAsText(file);
      }
    } catch (error) {
      setKmlUpdateError(true);
    }
  }

  async function updateRouteHandler() {
    await updateRoute(updatedRoute);
    setEditEnabled(false);
  }

  useEffect(() => {
    setUpdatedRoute({ id: parseInt(id) });
    setKmlUpdateError(false);
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <h2>
        Route Information{" "}
        <span style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={(e) => {
              setEditEnabled(!editEnabled);
            }}
            className="button-primary-sm"
          >
            Edit
          </button>
          <button
            style={{ marginLeft: "10px" }}
            onClick={(e) => {
              refetchKmlExport();
            }}
            className="button-primary-sm"
          >
            Export KML
          </button>
        </span>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        {data?.routes[0] && (
          <form onSubmit={updateRouteHandler}>
            <div className="field-block">
              <span className="label">Name</span>
              {editEnabled ? (
                <TextField
                  required
                  inputProps={{
                    maxLength: 255,
                    autoComplete: "off",
                    required: true,
                  }}
                  size="small"
                  label="Name"
                  defaultValue={data?.routes[0].name}
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      name: e.target.value,
                    });
                  }}
                ></TextField>
              ) : (
                data?.routes[0].name
              )}
            </div>
            <div className="field-block">
              <span className="label">Description</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ maxLength: 1024, autoComplete: "off" }}
                  size="small"
                  label="Description"
                  defaultValue={data?.routes[0].description}
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      description: e.target.value,
                    });
                  }}
                ></TextField>
              ) : data?.routes[0].description ? (
                data?.routes[0].description
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Redirect Description</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ maxLength: 255, autoComplete: "off" }}
                  size="small"
                  label="Redirect Description"
                  defaultValue={data?.routes[0].redirect_description}
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      redirect_description: e.target.value,
                    });
                  }}
                ></TextField>
              ) : data?.routes[0].redirect_description ? (
                data?.routes[0].redirect_description
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">
                {editEnabled ? "Polygon KML Upload" : "Polygon"}
              </span>
              {editEnabled ? (
                <div>
                  <div className="display-flex-center">
                    <TextField
                      type="file"
                      error={kmlUpdateError}
                      onChange={(e) => {
                        kmlInputHandler();
                      }}
                      inputRef={kmlInput}
                    ></TextField>
                    {updatedRoute.polygon && (
                      <StaticMapPreview
                        polygonString={updatedRoute.polygon!}
                      ></StaticMapPreview>
                    )}
                  </div>
                  {kmlUpdateError && (
                    <Alert severity="error">
                      There was a problem reading this file.
                    </Alert>
                  )}
                  {updatedRoute.polygon && (
                    <Alert severity="success">Succesfully uploaded file.</Alert>
                  )}
                </div>
              ) : (
                <StaticMapPreview
                  polygonString={data?.routes[0].polygon!}
                  noMargin
                ></StaticMapPreview>
              )}
            </div>
            <div className="field-block">
              <span className="label">Color</span>
              {editEnabled ? (
                <BlockPicker
                  color={updatedRoute.color || data?.routes[0].color}
                  triangle="hide"
                  colors={[
                    "#E74C3C",
                    "#3498DB",
                    "#2ECC71",
                    "#F1C40F",
                    "#E67E22",
                    "#9B59B6",
                    "#8E664D",
                    "#E84393",
                    "#2C3E50",
                    "#FFFFFF",
                  ]}
                  onChangeComplete={(c, e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      color: c.hex,
                    });
                  }}
                ></BlockPicker>
              ) : (
                <RectangleIcon
                  sx={{
                    color: data?.routes[0].color,
                    border: "1px solid black",
                  }}
                ></RectangleIcon>
              )}
            </div>
            <div className="field-block">
              <span className="label">Text Color</span>
              {editEnabled ? (
                <BlockPicker
                  color={updatedRoute.text_color || data?.routes[0].text_color}
                  triangle="hide"
                  colors={[
                    "#E74C3C",
                    "#3498DB",
                    "#2ECC71",
                    "#F1C40F",
                    "#E67E22",
                    "#9B59B6",
                    "#8E664D",
                    "#E84393",
                    "#2C3E50",
                    "#FFFFFF",
                  ]}
                  onChangeComplete={(c, e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      text_color: c.hex,
                    });
                  }}
                ></BlockPicker>
              ) : (
                <RectangleIcon
                  sx={{
                    color:
                      data?.routes[0].text_color || updatedRoute.text_color,
                    border: "1px solid black",
                  }}
                ></RectangleIcon>
              )}
            </div>
            <div className="field-block">
              <span className="label">Prediction Depth</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ autoComplete: "off", required: true }}
                  size="small"
                  type="number"
                  required
                  defaultValue={data?.routes[0].prediction_depth}
                  label="Prediction Depth"
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      prediction_depth: parseInt(e.target.value),
                    });
                  }}
                ></TextField>
              ) : (
                data?.routes[0].prediction_depth
              )}
            </div>
            <div className="field-block">
              <span className="label">Type</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ autoComplete: "off", required: true }}
                  size="small"
                  required
                  select
                  defaultValue={data?.routes[0].type}
                  label="Type"
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      type: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>Fixed</MenuItem>
                  <MenuItem value={1}>Deviated</MenuItem>
                </TextField>
              ) : (
                <JobTypeOptions type={data?.routes[0].type!}></JobTypeOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Autohide</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ autoComplete: "off", required: true }}
                  size="small"
                  required
                  select
                  defaultValue={data?.routes[0].auto_hide}
                  label="Auto Hide"
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      auto_hide: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </TextField>
              ) : (
                <AutohideOptions
                  autohide={data?.routes[0].auto_hide!}
                ></AutohideOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Priority</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ autoComplete: "off", required: true }}
                  size="small"
                  type="number"
                  required
                  defaultValue={data?.routes[0].priority}
                  label="Priority"
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      priority: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                ></TextField>
              ) : (
                data?.routes[0].priority
              )}
            </div>
            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ autoComplete: "off", required: true }}
                  size="small"
                  required
                  select
                  defaultValue={data?.routes[0].status}
                  label="Status"
                  onChange={(e) => {
                    setUpdatedRoute({
                      ...updatedRoute,
                      status: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                  <MenuItem value={2}>Hidden</MenuItem>
                </TextField>
              ) : (
                <StatusOptions status={data?.routes[0].status!}></StatusOptions>
              )}
            </div>

            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={Object.keys(updatedRoute).length <= 1}
                  className="button-primary btn"
                >
                  Save
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
