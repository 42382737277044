import React from "react";

interface SystemParameterTypeProps {
  type: number;
}

export default function SystemParameterTypes(props: SystemParameterTypeProps) {
  return (
    <div>
      {props.type == 0 && <>char</>}
      {props.type == 1 && <>short</>}
      {props.type == 2 && <>int</>}
      {props.type == 3 && <>long int</>}
      {props.type == 4 && <>bool </>}
      {props.type == 5 && <>float </>}
      {props.type == 6 && <>double </>}
      {props.type == 7 && <>string </>}
      {props.type == 8 && <>2D point </>}
      {props.type == 9 && <>3D point </>}
    </div>
  );
}
