import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface DriverRecapEntry {
  id?: number;
  first_name?: string;
  last_name?: string;
  total_shift_seconds?: number;
  avg_rating?: number;
  rating_count?: number;
  rating_count_negative?: number;
}

interface DriverRecapResponse {
  success: boolean;
  drivers: DriverRecapEntry[];
}

interface DriverRecapParameters {
  startDate?: string;
  endDate?: string;
}

export const getDriverRecap = async function (
  driverRecap: DriverRecapParameters
): Promise<DriverRecapResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/driver-recap`,
    driverRecap,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
