export const createSafeFilename = function (
  dbName: string,
  reportName: string,
  dateStart: string,
  dateEnd: string,
  extension: string,
  jobName: string = "",
  rideMode: string = ""
) {
  // Function to replace spaces and special characters with underscore
  const makeSafe = (str: string) =>
    str.toLowerCase().replace(/[^a-z0-9]+/g, "_");

  let s = `${makeSafe(dbName)}_${makeSafe(reportName)}_${makeSafe(
    dateStart
  )}-${makeSafe(dateEnd)}`;
  if (jobName) {
    s += `_${makeSafe(jobName)}`;
  }
  if (rideMode) {
    s += `_${makeSafe(rideMode)}`;
  }
  s += `.${makeSafe(extension)}`;
  return s;
};
