import { LinearProgress, Switch, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../App";
import {
  RiderHomeAnnouncement,
  createUpdateRiderhomeAnnouncement,
  getRiderHomeAnnouncement,
} from "../api/sys_params.api";
import BackButton from "./BackButton";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import AnnouncementPreview from "./riderHomeAnnouncement/AnnouncementPreview";

export default function RiderHomeAnnouncementSingle() {
  const { data, status, isLoading, isRefetching } = useQuery(
    "getRiderHomeAnnouncement",
    () => getRiderHomeAnnouncement(),
    { refetchOnMount: true }
  );
  const {
    mutateAsync: updateRiderHomeAnnouncement,
    isLoading: isMutationLoading,
  } = useMutation(createUpdateRiderhomeAnnouncement, {
    onSuccess: () => {
      queryClient.invalidateQueries("getRiderHomeAnnouncement");
      enqueueSnackbar("Rider home announcement was succesfully updated.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem making changes to this announcement. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedAnnouncement, setUpdatedAnnouncement] =
    useState<RiderHomeAnnouncement>();
  useEffect(() => {
    setUpdatedAnnouncement({ ...updatedAnnouncement, ...data?.announcement });
  }, [data]);

  async function updateAnnouncementHandler(
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    if (updatedAnnouncement) {
      await updateRiderHomeAnnouncement(updatedAnnouncement);
    }
    setEditEnabled(false);
  }

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Rider Home Announcement{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      {updatedAnnouncement?.rider_home_announcement_1_enabled == 1 && (
        <AnnouncementPreview
          announcement={updatedAnnouncement}
        ></AnnouncementPreview>
      )}
      {data?.announcement && (
        <div
          className={
            editEnabled ? "asset-container editmode" : "asset-container"
          }
        >
          <form onSubmit={updateAnnouncementHandler}>
            {editEnabled && (
              <div className="field-block">
                <span className="label">Enable Announcement</span>
                <Switch
                  checked={
                    updatedAnnouncement?.rider_home_announcement_1_enabled
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setUpdatedAnnouncement({
                        ...updatedAnnouncement,
                        rider_home_announcement_1_enabled: 1,
                      });
                    }
                    if (!e.target.checked) {
                      setUpdatedAnnouncement({
                        ...updatedAnnouncement,
                        rider_home_announcement_1_enabled: 0,
                        rider_home_announcement_1_cta_enabled: 0,
                      });
                    }
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            )}

            <div className="field-block">
              <span className="label">Title</span>
              {editEnabled ? (
                <TextField
                  required
                  fullWidth
                  inputProps={{ autoComplete: "off", maxlength: 255 }}
                  disabled={
                    updatedAnnouncement?.rider_home_announcement_1_enabled
                      ? false
                      : true
                  }
                  size="small"
                  label="Title"
                  value={updatedAnnouncement?.rider_home_announcement_1_title}
                  onChange={(e) => {
                    setUpdatedAnnouncement({
                      ...updatedAnnouncement,
                      rider_home_announcement_1_title: e.target.value,
                    });
                  }}
                ></TextField>
              ) : updatedAnnouncement?.rider_home_announcement_1_title ? (
                updatedAnnouncement?.rider_home_announcement_1_title
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Subtitle</span>
              {editEnabled ? (
                <TextField
                  required
                  fullWidth
                  disabled={
                    updatedAnnouncement?.rider_home_announcement_1_enabled
                      ? false
                      : true
                  }
                  inputProps={{ autoComplete: "off", maxlength: 255 }}
                  size="small"
                  label="Subtitle"
                  value={
                    updatedAnnouncement?.rider_home_announcement_1_subtitle
                  }
                  onChange={(e) => {
                    setUpdatedAnnouncement({
                      ...updatedAnnouncement,
                      rider_home_announcement_1_subtitle: e.target.value,
                    });
                  }}
                ></TextField>
              ) : updatedAnnouncement?.rider_home_announcement_1_subtitle ? (
                updatedAnnouncement?.rider_home_announcement_1_subtitle
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            {editEnabled && (
              <div className="field-block">
                <span className="label">Enable CTA</span>
                <Switch
                  checked={
                    updatedAnnouncement?.rider_home_announcement_1_cta_enabled
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setUpdatedAnnouncement({
                        ...updatedAnnouncement,
                        rider_home_announcement_1_cta_enabled: 1,
                      });
                    }
                    if (!e.target.checked) {
                      setUpdatedAnnouncement({
                        ...updatedAnnouncement,
                        rider_home_announcement_1_cta_enabled: 0,
                      });
                    }
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            )}

            <div className="field-block">
              <span className="label">CTA</span>
              {editEnabled ? (
                <TextField
                  required={
                    updatedAnnouncement?.rider_home_announcement_1_cta_enabled
                      ? true
                      : false
                  }
                  inputProps={{ autoComplete: "off", maxlength: 255 }}
                  fullWidth
                  size="small"
                  label="CTA"
                  disabled={
                    updatedAnnouncement?.rider_home_announcement_1_cta_enabled
                      ? false
                      : true
                  }
                  value={updatedAnnouncement?.rider_home_announcement_1_cta}
                  onChange={(e) => {
                    setUpdatedAnnouncement({
                      ...updatedAnnouncement,
                      rider_home_announcement_1_cta: e.target.value,
                    });
                  }}
                ></TextField>
              ) : updatedAnnouncement?.rider_home_announcement_1_cta ? (
                updatedAnnouncement?.rider_home_announcement_1_cta
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">URL</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ autoComplete: "off", maxlength: 255 }}
                  required={
                    updatedAnnouncement?.rider_home_announcement_1_cta_enabled
                      ? true
                      : false
                  }
                  fullWidth
                  size="small"
                  label="URL"
                  value={updatedAnnouncement?.rider_home_announcement_1_url}
                  disabled={
                    updatedAnnouncement?.rider_home_announcement_1_cta_enabled
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    setUpdatedAnnouncement({
                      ...updatedAnnouncement,
                      rider_home_announcement_1_url: e.target.value,
                    });
                  }}
                ></TextField>
              ) : updatedAnnouncement?.rider_home_announcement_1_url ? (
                updatedAnnouncement?.rider_home_announcement_1_url
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>

            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button className="button-primary btn">Save</button>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
}
