import { LinearProgress } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getAShift } from "../api/shifts.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import dayjs from "dayjs";
import BackButton from "./BackButton";
export default function ShiftSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAShift",
    () => getAShift(parseInt(id)),
    { refetchOnMount: true }
  );

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>Shift Information</h2>
      {data && (
        <div className={"asset-container"}>
          <div className="field-block">
            <span className="label">Shift Id</span>
            {data?.shifts[0].id}
          </div>
          <div className="field-block">
            <span className="label">Driver Id</span>
            {data?.shifts[0].driver_id}
          </div>
          <div className="field-block">
            <span className="label">Vehicle Id</span>
            {data?.shifts[0].vehicle_id}
          </div>
          <div className="field-block">
            <span className="label">Vehicle Nickname</span>
            {data?.shifts[0].vehicle_nickname}
          </div>
          <div className="field-block">
            <span className="label">Vehicle Type Name</span>
            {data?.shifts[0].vehicle_type_name}
          </div>
          <div className="field-block">
            <span className="label">Job Id</span>
            {data?.shifts[0].job_id}
          </div>
          <div className="field-block">
            <span className="label">Job Name</span>
            {data?.shifts[0].job_name}
          </div>
          <div className="field-block">
            <span className="label">Scheduled Shift Id</span>
            {data?.shifts[0].scheduled_shift_id}
          </div>
          <div className="field-block">
            <span className="label">Start Timestamp</span>
            {dayjs(data?.shifts[0].start_timestamp * 1000)
              .tz(
                useSysParamsStore.getState().getParamByName("global_timezone")
                  ?.value
              )
              .format("ddd, MMM D, YYYY, hh:mm a")}
          </div>
          <div className="field-block">
            <span className="label">End Timestamp</span>
            {dayjs(data?.shifts[0].end_timestamp * 1000)
              .tz(
                useSysParamsStore.getState().getParamByName("global_timezone")
                  ?.value
              )
              .format("ddd, MMM D, YYYY, hh:mm a")}
          </div>
          <div className="field-block">
            <span className="label">Started Timestamp</span>
            {dayjs(data?.shifts[0].started_timestamp * 1000)
              .tz(
                useSysParamsStore.getState().getParamByName("global_timezone")
                  ?.value
              )
              .format("ddd, MMM D, YYYY, hh:mm a")}
          </div>
          <div className="field-block">
            <span className="label">Ended Timestamp</span>
            {dayjs(data?.shifts[0].ended_timestamp * 1000)
              .tz(
                useSysParamsStore.getState().getParamByName("global_timezone")
                  ?.value
              )
              .format("ddd, MMM D, YYYY, hh:mm a")}
          </div>
          <div className="field-block">
            <span className="label">Start Mileage</span>
            {data?.shifts[0].start_mileage}
          </div>
          <div className="field-block">
            <span className="label">End Mileage</span>
            {data?.shifts[0].end_mileage}
          </div>
          <div className="field-block">
            <span className="label">Start Latitude</span>
            {data?.shifts[0].start_latitude}
          </div>
          <div className="field-block">
            <span className="label">Start Longitude</span>
            {data?.shifts[0].start_longitude}
          </div>
          <div className="field-block">
            <span className="label">Start Lead Time</span>
            {data?.shifts[0].start_lead_time}
          </div>
        </div>
      )}
    </div>
  );
}
