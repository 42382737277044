import { CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import { getSystemParamsConfig } from "../api/sys_params.api";
import { queryClient } from "../App";
import DashboardSideNavigation from "../components/DashboardSideNavigation";
import DashboardTopNavigation from "../components/DashboardTopNavigation";
import { useAuthStore } from "../stores/auth.store.zus";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
export default function DashboardLayout() {
  const {
    data: config,
    isLoading,
    isError,
  } = useQuery("systemParamConfig", getSystemParamsConfig);

  if (isError) {
    useAuthStore.getState().setToken(null);
    useAuthStore.getState().setLogin(false);
    useAuthStore.getState().setDb("");
    sessionStorage.setItem("token", "");
    queryClient.clear();
    window.location.href = "/";
  }
  if (isLoading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (config) {
    useSysParamsStore.getState().setSysParams(config.sys_params);
  }

  return (
    <div className="dashboard-layout">
      <div className="content-container">
        <div className="content-container-left">
          <DashboardSideNavigation></DashboardSideNavigation>
        </div>
        <div className="content-container-right">
          <DashboardTopNavigation></DashboardTopNavigation>
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
}
