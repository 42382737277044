import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Chip, Stack } from "@mui/material";
interface TypeOptions {
  type: number;
}

export default function TypeOptions(props: TypeOptions) {
  return (
    <>
      {props.type == 0 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<AccountCircleIcon />}
            label="Normal"
          />
        </Stack>
      )}
      {props.type == 1 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            icon={<AdminPanelSettingsIcon />}
            color="warning"
            label="Admin"
          />
        </Stack>
      )}
    </>
  );
}
