import { create } from "zustand";

interface IAuthState {
  token: string | null;
  isLoggedIn: boolean;
  db: string | null;
  serviceName: string | null;
  accessType: number;
  setToken: (token: string | null) => void;
  setAccessType: (type: number) => void;
  setLogin: (loginValue: boolean) => void;
  setDb: (db: string) => void;
  setServiceName: (serviceName: string) => void;
}

export const useAuthStore = create<IAuthState>((set) => ({
  isLoggedIn: false,
  token: null,
  db: null,
  createZoneString: null,
  serviceName: null,
  accessType: 0,
  setToken: (token) => set((state) => ({ token: token })),
  setLogin: (loginValue) => set((state) => ({ isLoggedIn: loginValue })),
  setDb: (db) => set((state) => ({ db: db })),
  setServiceName: (sn) => set((state) => ({ serviceName: sn })),
  setAccessType: (type) => set((state) => ({ accessType: type })),
}));
