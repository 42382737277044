import {
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { JobWithRiderMessaging, createAJob } from "../api/jobs.api";
import { getJobCategories } from "../api/jobs_categories.api";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

const defaults: JobWithRiderMessaging = {
  short_name: "",
  name: "",
  rider_display_name: null,
  driver_default_access_code: null,
  description: "",
  color: "#000000",
  text_color: "#FFFFFF",
  route_id: null,
  category_id: 1,
  status: 1,
  shift_mode: 0,
  hours_mode: 0,
  eta_priority: 100,
  eta_max: null,
  eta_url: null,
  grandfather_access_account_id: null,
  default_job_zone_rules: 0,
  rider_messaging_only: true,
};

export default function JobCreateMessaging() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutateAsync: isMutationSaving,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAJob, {
    onSuccess: () => {
      queryClient.invalidateQueries("getJobs");
      queryClient.invalidateQueries("getAJob");
      queryClient.invalidateQueries("getJobCategories");
      queryClient.invalidateQueries("getAJobCategory");

      enqueueSnackbar("Job was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/jobs");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this job. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  const { data: jobCategoriesData, isLoading } = useQuery(
    "getJobCategories",
    getJobCategories,
    { refetchOnMount: true }
  );
  // useEffect(() => {
  //   //Set Defaults
  //   setCreateJobPayload({
  //     ...createJobPayload,
  //     ...defaults,
  //   });
  // }, []);
  const [createJobPayload, setCreateJobPayload] =
    useState<JobWithRiderMessaging>(defaults);

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (createJobPayload.rider_display_name?.trim().length === 0) {
      createJobPayload.rider_display_name = null;
    }
    if (createJobPayload.description?.trim().length === 0) {
      createJobPayload.description = "";
    }
    await isMutationSaving(createJobPayload);
  }
  if (isLoading) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <div className="create-asset-container">
      <h2>Messaging Job</h2>
      {createJobPayload &&
        jobCategoriesData &&
        jobCategoriesData.jobs_categories.length > 0 && (
          <div className="create-asset-form">
            <form onSubmit={submitHandler}>
              <TextField
                inputProps={{
                  maxlength: 64,
                  required: true,
                  autoComplete: "off",
                }}
                size="small"
                sx={inputBlock}
                label="Name"
                value={createJobPayload.name}
                helperText="For example, Crosstown Messaging"
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    name: e.target.value,
                  });
                }}
              ></TextField>
              <TextField
                inputProps={{
                  maxlength: 16,
                  required: true,
                  autoComplete: "off",
                }}
                size="small"
                sx={inputBlock}
                label="Short Name"
                helperText="For example, CROSSTOWN-MSG"
                value={createJobPayload.short_name}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    short_name: e.target.value,
                  });
                }}
              ></TextField>
              {/* CATEGORY ID FETCH FROM job_categories */}
              <TextField
                inputProps={{ required: true, min: 0 }}
                size="small"
                style={{ width: "300px" }}
                label="Category"
                select
                value={createJobPayload.category_id}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    category_id: parseInt(e.target.value),
                  });
                }}
              >
                {jobCategoriesData?.jobs_categories.map((jc) => {
                  return (
                    <MenuItem key={jc.id} value={jc.id}>
                      {jc.name}
                    </MenuItem>
                  );
                })}
              </TextField>
              {/* STATUS SELECT */}
              <TextField
                inputProps={{ required: true, min: 0 }}
                select
                size="small"
                sx={inputBlock}
                label="Status"
                value={createJobPayload.status}
                helperText={"All jobs started as disabled"}
                disabled
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    status: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Enabled</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
              </TextField>
              <TextField
                inputProps={{ required: true, min: 0, max: 255 }}
                size="small"
                sx={inputBlock}
                label="Priority"
                type="number"
                value={createJobPayload.eta_priority}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    eta_priority: parseInt(e.target.value),
                  });
                }}
              ></TextField>

              <TextField
                inputProps={{ required: true }}
                size="small"
                style={{ width: "300px", margin: "20px 0px" }}
                label="Inital Zone Rules"
                select
                value={createJobPayload.default_job_zone_rules}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    default_job_zone_rules: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Deny ANY {"<>"} ANY</MenuItem>
                <MenuItem value={1}>Allow ANY {"<>"} ANY</MenuItem>
              </TextField>
              <TextField
                inputProps={{ maxlength: 255 }}
                size="small"
                sx={inputBlock}
                fullWidth
                label="Job Message"
                helperText="Message shown to riders inside of rider app.  For example, 'Sorry, rides to this area are temporarily unavailable.'"
                value={createJobPayload.cancel_message}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    cancel_message: e.target.value,
                  });
                }}
              ></TextField>
              <FormGroup style={{ margin: "20px 0px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={createJobPayload.rider_messaging_only}
                      onChange={(e) => {
                        setCreateJobPayload({
                          ...createJobPayload,
                          rider_messaging_only:
                            !createJobPayload.rider_messaging_only,
                        });
                      }}
                    />
                  }
                  label="Only show messaging to riders"
                />
              </FormGroup>
              <button className="button-primary" type="submit">
                Create Job
              </button>
            </form>
          </div>
        )}
    </div>
  );
}
