import { MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import BackButton from "./BackButton";
import JobCreateDeviated from "./JobCreateDeviated";
import JobCreateFixed from "./JobCreateFixed";
import JobCreateMessaging from "./JobCreateMessaging";
import JobCreateOnDemand from "./JobCreateOnDemand";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function JobCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedJobType, setSelectedJobType] = useState<number>(0);

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a new job</h2>
      <div className="create-asset-form">
        <form onSubmit={submitHandler}>
          <TextField
            inputProps={{ required: true, min: 0 }}
            select
            size="small"
            sx={inputBlock}
            label="Job Type"
            defaultValue={0}
            value={selectedJobType}
            onChange={(e) => {
              setSelectedJobType(parseInt(e.target.value));
            }}
          >
            <MenuItem value={0}>On-Demand</MenuItem>
            <MenuItem value={2}>Fixed Route</MenuItem>
            <MenuItem value={1}>Deviated Route</MenuItem>
            <MenuItem value={3}>Messaging</MenuItem>
          </TextField>
        </form>
      </div>
      {selectedJobType == 0 && <JobCreateOnDemand></JobCreateOnDemand>}
      {selectedJobType == 1 && <JobCreateDeviated></JobCreateDeviated>}
      {selectedJobType == 2 && <JobCreateFixed></JobCreateFixed>}
      {selectedJobType == 3 && <JobCreateMessaging></JobCreateMessaging>}
    </div>
  );
}
