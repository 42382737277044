import {
  Alert,
  CircularProgress,
  FormGroup,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DrawingManagerF, GoogleMap } from "@react-google-maps/api";
import * as turf from "@turf/turf";
import { Polygon } from "geojson";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { searchMerchants } from "../api/merchants.api";
import { getZoneCoordinatesAndZoomFromSystemParam } from "../api/sys_params.api";
import { Zone, createAZone } from "../api/zones.api";
import {
  coordinatesToPolygonString,
  isValidPolygonString,
  kmlStringToGeoJson,
} from "../utility/geoUtils";
import { polygonStringValidation } from "../utility/polygonStringValidation";
import BackButton from "./BackButton";
import StaticMapPreview from "./StaticMapPreview";

const containerStyle = {
  width: "600px",
  height: "400px",
};
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function ZoneCreate() {
  const navigate = useNavigate();
  const {
    data: zoneCoordinatesAndZoomFromSystemParamData,
    status,
    isLoading,
    isRefetching,
  } = useQuery(
    "getZoneCoordinatesAndZoomFromSystemParam",
    getZoneCoordinatesAndZoomFromSystemParam,
    {
      refetchOnMount: true,
    }
  );
  // ZONE MUTATION SETUP
  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAZone, {
    onSuccess: () => {
      queryClient.invalidateQueries("getZones");
      enqueueSnackbar("Zone was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/zones");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this zone Error: ${
          (error as any).response.data.error || error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  // CLEAR ZONE STATE AND SET INITIAL PARAMETERS
  useEffect(() => {
    setSelectedRadio("kml");
  }, []);

  // REDIRECT MERCHANT SETUP

  const {
    mutateAsync: searchMerchant,
    data: merchantData,
    isError: isMerchantSearchError,
    isLoading: isMerchantSearchLoading,
  } = useMutation(searchMerchants, {
    onSuccess: () => {
      queryClient.invalidateQueries("getMerchants");
    },
  });

  async function searchHandler(searchValue: string) {
    await searchMerchant(searchValue);
  }

  //POLYGON GOOGLE MAPS HANDLER

  const onPolygonComplete = async (polygon: google.maps.Polygon) => {
    let coordinates = await polygon.getPath();
    let coordData = coordinates.getArray();
    let coordinatesFormatted = [];
    let i = 0;
    for (i; i < coordData.length; i++) {
      let formattedLat = coordData[i].lat().toFixed(6);
      let formattedLng = coordData[i].lng().toFixed(6);
      let formattedCoordinatePair = [formattedLat, formattedLng];
      coordinatesFormatted.push(formattedCoordinatePair);
    }
    let formattedString = "";
    coordinatesFormatted.forEach((coordArray) => {
      formattedString += coordArray.toString() + " ";
    });
    formattedString += coordinatesFormatted[0].toString() + " ";
    let t = polygonStringValidation(formattedString);
    if (t) {
      setIsPolyStringValid(true);
    }
    setZonePayload({
      ...createZonePayload,
      polygon: formattedString,
    });
    polygon.setOptions({ editable: true, draggable: false });
  };

  const [createZonePayload, setZonePayload] = useState<Zone>({
    status: 2,
    redirect_merchant_id: 0,
    priority: 200,
    polygon: null,
  });
  const [selectedRadio, setSelectedRadio] = useState<string>("kml");
  const kmlInput = useRef<HTMLInputElement>();
  const [kmlUpdateError, setKmlUpdateError] = useState<boolean>(false);
  const [isPolyStringValid, setIsPolyStringValid] = useState<boolean>(false);
  const [useCustomPriority, setCustomPriority] = useState<boolean>(false);

  function radioSelectHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setIsPolyStringValid(false);
    setZonePayload({
      ...createZonePayload,
      polygon: null,
    });
    setSelectedRadio(e.target.value);
  }

  async function kmlInputHandler() {
    try {
      //Check File Exists
      setKmlUpdateError(false);
      setIsPolyStringValid(false);
      if (!kmlInput.current?.files) {
        throw `No File attached.`;
      }
      //Check File name contains .kml
      let fileName = kmlInput.current?.files![0];
      if (fileName.name.match(/.kml/) == null) {
        setKmlUpdateError(true);
        throw `Incorrect file type.`;
      }
      let file = kmlInput.current?.files![0] as Blob;

      if (file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              if (reader.result) {
                const kmlGeoJson = kmlStringToGeoJson(reader.result as string);

                let firstPolygon: Polygon | null = null;
                turf.flattenEach(kmlGeoJson, (feature) => {
                  if (
                    feature.geometry.type === "Polygon" &&
                    feature.geometry.coordinates.length === 1 // Make sure it is a simple polygon (no holes)
                  ) {
                    firstPolygon = feature.geometry;
                    return false; // break out of flattenEach
                  }
                });

                if (!firstPolygon) {
                  throw new Error("Object must contain a Simple Polygon");
                }

                let coordinateString = coordinatesToPolygonString(
                  (firstPolygon as Polygon).coordinates[0]
                );

                if (!isValidPolygonString(coordinateString)) {
                  throw new Error("Invalid Polygon String.");
                }

                setZonePayload({
                  ...createZonePayload,
                  polygon: coordinateString,
                });
                setIsPolyStringValid(true);
              }
            } catch (error) {
              console.log(error);
              setKmlUpdateError(true);
            }
          },
          false
        );
        reader.readAsText(file);
      }
    } catch (error) {
      setKmlUpdateError(true);
    }
  }

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (createZonePayload.polygon) {
      setKmlUpdateError(false);
    }
    if (!kmlUpdateError) {
      console.log(createZonePayload);
      await mutateAsync(createZonePayload);
    }
  }

  if (isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Zone</h2>
      {zoneCoordinatesAndZoomFromSystemParamData && (
        <div className="create-asset-form">
          <form onSubmit={submitHandler}>
            <TextField
              required
              inputProps={{ maxLength: 64, autoComplete: "off" }}
              size="small"
              sx={inputBlock}
              label="Name"
              value={createZonePayload.name}
              onChange={(e) => {
                let nameValue = e.target.value;
                let s = nameValue.split(" ").join("-");
                setZonePayload({
                  ...createZonePayload,
                  name: s,
                });
              }}
            ></TextField>
            <TextField
              required
              inputProps={{ min: 0, max: 3 }}
              sx={inputBlock}
              size="small"
              label="Status"
              select
              value={createZonePayload.status}
              onChange={(e) => {
                setZonePayload({
                  ...createZonePayload,
                  status: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>Enabled</MenuItem>
              <MenuItem value={1}>Disabled</MenuItem>
              <MenuItem value={2}>Hidden</MenuItem>
            </TextField>
            <div className="display-flex-center">
              {!useCustomPriority && (
                <TextField
                  inputProps={{ required: true }}
                  required
                  size="small"
                  sx={inputBlock}
                  label="Priority"
                  select
                  value={createZonePayload.priority}
                  onChange={(e) => {
                    setZonePayload({
                      ...createZonePayload,
                      priority: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={100}>100 - Walking Mall</MenuItem>
                  <MenuItem value={200}>200 - Service Area</MenuItem>
                  <MenuItem value={300}>300 - Job Rule</MenuItem>
                  <MenuItem value={400}>400 - Vehicle Rule</MenuItem>
                </TextField>
              )}

              <FormGroup
                style={!useCustomPriority ? { marginLeft: "20px" } : {}}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={useCustomPriority}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked == false) {
                          setZonePayload({
                            ...createZonePayload,
                            priority: 200,
                          });
                        }
                        setCustomPriority(event.target.checked);
                      }}
                    ></Switch>
                  }
                  label="Custom Priority"
                />
              </FormGroup>
            </div>

            {useCustomPriority == true && (
              <TextField
                required
                type="numbers"
                inputProps={{ min: 1 }}
                size="small"
                sx={inputBlock}
                label="Priority"
                onChange={(e) => {
                  setZonePayload({
                    ...createZonePayload,
                    priority: parseInt(e.target.value),
                  });
                }}
              ></TextField>
            )}
            <Autocomplete
              size="small"
              id="asynchronous-demo"
              sx={{ width: 300, margin: "20px 0px" }}
              filterOptions={(x) => x} // disable the built-in filtering, https://mui.com/material-ui/react-autocomplete/#search-as-you-type
              options={
                merchantData?.merchants
                  ? merchantData?.merchants.map((m) => {
                      return { value: m.id, label: m.name };
                    })
                  : []
              }
              onChange={(e, val, reason) => {
                if (reason == "clear") {
                  setZonePayload({
                    ...createZonePayload,
                    redirect_merchant_id: 0,
                  });
                }
                if (reason == "selectOption") {
                  setZonePayload({
                    ...createZonePayload,
                    redirect_merchant_id: val?.value,
                  });
                }
              }}
              onInputChange={debounce((e, newvalue) => {
                searchHandler(newvalue);
              }, 500)}
              loading={isMerchantSearchLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Redirect Merchant"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isMerchantSearchLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <FormLabel id="zone-definition-radio-buttons-group">
              Zone Definition
            </FormLabel>
            <RadioGroup
              aria-labelledby="zone-definition-buttons-group-label"
              // defaultValue="kml"
              value={selectedRadio}
              name="radio-buttons-group"
              onChange={radioSelectHandler}
            >
              <FormControlLabel
                value="kml"
                control={<Radio />}
                label="KML Upload"
              />
              <FormControlLabel
                value="zone"
                control={<Radio />}
                label="Draw a Zone"
              />
              <FormControlLabel
                value="coordinateString"
                control={<Radio />}
                label="Coordinate String"
              />
            </RadioGroup>
            {selectedRadio == "coordinateString" && (
              <div>
                <div className="display-flex-center">
                  <TextField
                    type="text"
                    inputProps={{ min: 0 }}
                    sx={inputBlock}
                    error={!isPolyStringValid}
                    label="Polygon Coordinate String"
                    onChange={(e) => {
                      let t = polygonStringValidation(e.target.value);
                      if (!t) {
                        setIsPolyStringValid(false);
                      }
                      if (t) {
                        setIsPolyStringValid(true);
                        setZonePayload({
                          ...createZonePayload,
                          polygon: e.target.value,
                        });
                      }
                    }}
                  ></TextField>
                  {isPolyStringValid && (
                    <StaticMapPreview
                      polygonString={createZonePayload.polygon!}
                    ></StaticMapPreview>
                  )}
                </div>

                {!isPolyStringValid && (
                  <Alert severity="error">
                    Invalid Polygon string formatting.
                  </Alert>
                )}
              </div>
            )}
            {selectedRadio == "kml" && (
              <div>
                <div className="display-flex-center">
                  <TextField
                    type="file"
                    sx={inputBlock}
                    error={!isPolyStringValid}
                    onChange={(e) => {
                      kmlInputHandler();
                    }}
                    inputRef={kmlInput}
                  ></TextField>
                  {isPolyStringValid && (
                    <StaticMapPreview
                      polygonString={createZonePayload.polygon!}
                    ></StaticMapPreview>
                  )}
                </div>
                {kmlUpdateError && (
                  <Alert severity="error">
                    There was a problem reading this file.
                  </Alert>
                )}
                {createZonePayload.polygon && !kmlUpdateError && (
                  <Alert severity="success">Succesfully uploaded file.</Alert>
                )}
              </div>
            )}
            {selectedRadio == "zone" && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                options={{
                  streetViewControl: false,
                }}
                center={{
                  lat: parseFloat(
                    zoneCoordinatesAndZoomFromSystemParamData.lat
                  ),
                  lng: parseFloat(
                    zoneCoordinatesAndZoomFromSystemParamData.lng
                  ),
                }}
                zoom={parseFloat(
                  zoneCoordinatesAndZoomFromSystemParamData.zoom
                )}
                id="drawing-manager-example"
              >
                <DrawingManagerF
                  options={{
                    drawingControl: true,
                    drawingControlOptions: {
                      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                    polygonOptions: {
                      editable: true,
                      draggable: false,
                    },
                  }}
                  drawingMode={google.maps.drawing.OverlayType.POLYGON}
                  onPolygonComplete={onPolygonComplete}
                />
              </GoogleMap>
            )}
            <button
              className="button-primary"
              style={{ margin: "20px 0px" }}
              type="submit"
              disabled={
                kmlUpdateError ||
                !createZonePayload.polygon ||
                !isPolyStringValid
              }
            >
              Create Zone
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
