export default function NotAuthorized() {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "500px",
      }}
    >
      <h1>Authorization required to view this page.</h1>
    </div>
  );
}
