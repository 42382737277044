import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface Driver {
  id?: number;
  stripe_user_id?: string | null;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  thumb?: string | null;
  creation_date?: number;
  update_date?: number;
  delete_date?: number;
  delete_reason?: string | null;
  type?: number;
  status?: number;
  log_id?: number | null;
  apn_uuid?: string | null;
  mode?: number;
}

interface DriversResponse {
  success: boolean;
  drivers: Driver[];
}

interface DriverShift {
  id: number;
  driver_id: number;
  vehicle_id: number;
  job_id: number;
  scheduled_shift_id: null;
  start_timestamp: number;
  end_timestamp: number;
  started_timestamp: number;
  ended_timestamp: number;
  start_mileage: number;
  end_mileage: number;
  start_latitude: number;
  start_longitude: number;
  start_lead_time: number | null;
  job_name: string;
  vehicle_nickname: string;
  vehicle_type_name: string;
}

interface DriversShiftResponse {
  success: boolean;
  shifts: DriverShift[];
}
interface DriverRating {
  ride_id: number;
  rating: number;
  feedback_comment: string | null;
  pick_up_date: number;
}
interface DriversRatingResponse {
  success: boolean;
  ratings: DriverRating[];
}
interface DriverRatingKpi {
  avg_rating: number | null;
  rating_count: number | null;
  avg_rating_30_days: number | null;
  rating_count_30_days: number | null;
}
interface DriversRatingKpiResponse {
  success: boolean;
  ratingsKpi: DriverRatingKpi[];
}

export const getDrivers = async function (): Promise<DriversResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/drivers`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const getADriver = async function (
  driverId: number
): Promise<DriversResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/drivers/${driverId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getADriversShifts = async function (
  driverId: number
): Promise<DriversShiftResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/drivers/${driverId}/shifts`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getADriversRatings = async function (
  driverId: number
): Promise<DriversRatingResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/drivers/${driverId}/ratings`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getADriversRatingsKpi = async function (
  driverId: number
): Promise<DriversRatingKpiResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/drivers/${driverId}/ratings/kpi`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createADriver = async function (driver: Driver) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/drivers`,
    driver,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateADriver = async function (driverFieldsToUpdate: Driver) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/drivers`,
    driverFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
