import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
export interface Fare {
  id?: number;
  job_id?: number;
  pick_up_zone_id?: number;
  drop_off_zone_id?: number;
  amount?: number;
  currency?: string;
  enabled?: number;
  pick_up_zone_name?: string;
  pick_up_zone_polygon?: string;
  drop_off_zone_name?: string;
  drop_off_zone_polygon?: string;
  job_name?: string;
}
interface FareResponse {
  success: boolean;
  fares: Fare[];
}

export const getFares = async function (): Promise<FareResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<FareResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/fares`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAFare = async function (
  fareId: number | undefined
): Promise<FareResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/fares/${fareId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const updateAFare = async function (fareFieldsToUpdate: Fare) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/fares`,
    fareFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAFare = async function (fare: Fare) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/fares`,
    fare,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const deleteAFare = async function (fareId: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/fares/${fareId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
