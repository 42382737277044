import React, { useEffect, useState } from "react";
import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Dialog } from "@mui/material";
import polyline from "@mapbox/polyline";

export interface StaticMapPreviewProps {
  polygonString: string;
  markerLocationLat?: number;
  markerLocationLon?: number;
}

export default function StaticMapPreviewModal(props: StaticMapPreviewProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [polygonCoordinates, setPolygonCoordinates] = useState<
    Array<[number, number]>
  >([]);

  useEffect(() => {
    if (!props.polygonString) {
      return;
    }

    let pairs = props.polygonString.trim().split(" ");
    let coordinatesSanitized: Array<[number, number]> = [];
    for (let i = 0; i < pairs.length; i++) {
      let s = pairs[i].split(",");
      let lat = parseFloat(s[0]);
      let lng = parseFloat(s[1]);
      coordinatesSanitized.push([lat, lng]);
    }

    setPolygonCoordinates(coordinatesSanitized);
  }, [props.polygonString]);
  if (
    props.polygonString == null ||
    props.polygonString.length === 0 ||
    props.polygonString === undefined
  ) {
    return <SearchOffIcon></SearchOffIcon>;
  }
  return (
    <div
      className="image-preview-container"
      style={{ marginLeft: "20px" }}
      onMouseDown={(e) => {
        setShowModal(true);
      }}
    >
      <SearchIcon></SearchIcon>
      {showModal && (
        <Dialog open={showModal} onClose={() => setShowModal(false)}>
          <StaticGoogleMap
            size="500x500"
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            // eslint-disable-next-line react/style-prop-object
            style="&style=feature:poi|visibility:off"
          >
            <Path
              points={`enc:${encodeURIComponent(
                polyline.encode(polygonCoordinates)
              )}`}
              color="0066ff"
              weight="3"
            />
            {props.markerLocationLat && props.markerLocationLon && (
              <Marker
                size="small"
                location={{
                  lat: props.markerLocationLat!,
                  lng: props.markerLocationLon!,
                }}
              ></Marker>
            )}
          </StaticGoogleMap>
        </Dialog>
      )}
    </div>
  );
}
