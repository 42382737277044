import axios, { AxiosError } from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

interface WeatherCondition {
  text: string;
  icon: string;
  code: number;
}

interface WeatherData {
  last_updated_epoch: number;
  last_updated: string;
  temp_c: number;
  temp_f: number;
  is_day: number;
  condition: WeatherCondition;
  wind_mph: number;
  wind_kph: number;
  wind_degree: number;
  wind_dir: string;
  pressure_mb: number;
  pressure_in: number;
  precip_mm: number;
  precip_in: number;
  humidity: number;
  cloud: number;
  feelslike_c: number;
  feelslike_f: number;
  vis_km: number;
  vis_miles: number;
  uv: number;
  gust_mph: number;
  gust_kph: number;
}
interface GetWeatherResponse {
  success: boolean;
  weather: WeatherData;
}
export const getWeather = async function (): Promise<GetWeatherResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/weather`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
