import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getJobsWithRoute } from "../api/jobs.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import ShiftModeOptions from "./utility/ShiftModeOptions";
import StatusOptions from "./utility/StatusOptions";

export default function JobsOverview() {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", flex: 0.5, minWidth: 100 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    {
      field: "short_name",
      headerName: "Short Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.short_name ? (
              <div
                style={{
                  backgroundColor: params.row.color || "#EEEEEE",
                  color: params.row.text_color || "#212121",
                  padding: "3px 8px",
                  borderRadius: "20px",
                  fontSize: "12px",
                }}
              >
                {params.row.short_name}
              </div>
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    // {
    //   field: "rider_display_name",
    //   headerName: "Rider Display Name",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.rider_display_name ? (
    //           params.row.rider_display_name
    //         ) : (
    //           <PlaceholderCharacter></PlaceholderCharacter>
    //         )}
    //       </>
    //     );
    //   },
    // },
    { field: "job_type", headerName: "Job Type", flex: 1, minWidth: 150 },
    {
      field: "shift_mode",
      headerName: "Shift Mode",
      flex: 1.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.row.job_type == "Messaging" ? (
              <PlaceholderCharacter></PlaceholderCharacter>
            ) : (
              <ShiftModeOptions
                shift_mode={params.row.shift_mode}
              ></ShiftModeOptions>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "hours_mode",
    //   headerName: "Hours Mode",
    //   flex: 1,
    // },
    {
      field: "eta_max",
      headerName: "ETA Max",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.eta_max == undefined || params.row.eta_max == null ? (
              <PlaceholderCharacter></PlaceholderCharacter>
            ) : (
              <>
                {Math.floor(params.row.eta_max / 60)}m {params.row.eta_max % 60}
                s
              </>
            )}
          </>
        );
      },
    },
    {
      field: "eta_priority",
      headerName: "Priority",
      flex: 1.2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      // flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/jobs/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
    // {
    //   field: "eta_url",
    //   headerName: "ETA Url",
    //   flex: 1,
    // },
  ];

  const {
    data: jobData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getJobs", getJobsWithRoute, { refetchOnMount: true });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Jobs</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/jobs/create");
          }}
        >
          Create New Job
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={jobData?.jobs ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
