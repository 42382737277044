import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { memo, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { awsGetUploadUrl, awsUploadFile } from "../../api/aws.api";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

interface FileUploaderProps {
  labelText?: string;
  queryKey: string;
  success: (successUrl: string) => void;
}

const FileUploader = memo(
  function FileUploader(props: FileUploaderProps) {
    const {
      data: uploadUrl,
      isLoading: isGetUrlLoading,
      refetch: refetchUrl,
    } = useQuery([`getAnUploadUrl${props.queryKey}`, props.queryKey], () =>
      awsGetUploadUrl(props.queryKey)
    );

    const {
      mutateAsync: uploadFile,
      isLoading: isUploadFileLoading,
      isSuccess: isUploadFileSuccess,
    } = useMutation(awsUploadFile);

    const iconInput = useRef<HTMLInputElement>(null);

    async function uploadFileHandler() {
      try {
        if (!iconInput.current?.files) {
          throw new Error("No File attached.");
        }
        const fileName = iconInput.current?.files![0];
        const file = iconInput.current?.files![0] as Blob;
        if (uploadUrl) {
          await uploadFile({
            file: file,
            imageType: fileName.type,
            url: uploadUrl.uploadUrl,
          });
          props.success(uploadUrl.fileUrl);
        }
      } catch (error) {
        console.log(error);
      } finally {
        refetchUrl();
      }
    }

    return (
      <div>
        {props.labelText && <span className="label">{props.labelText}</span>}
        <TextField
          sx={inputBlock}
          size="small"
          type="file"
          onChange={uploadFileHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isUploadFileLoading && <CircularProgress size={20} />}
                {isUploadFileSuccess && <CheckCircleIcon color="success" />}
              </InputAdornment>
            ),
          }}
          inputRef={iconInput}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.labelText === nextProps.labelText &&
      prevProps.queryKey === nextProps.queryKey &&
      prevProps.success === nextProps.success
    );
  }
);

export default FileUploader;
