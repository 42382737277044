import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import {
  CircularProgress,
  Dialog,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  getEtaEarlyLateReport,
  EtaEarlyLateReportInput,
} from "../../api/reporting.api";
import { useAuthStore } from "../../stores/auth.store.zus";
import { formatDateToSimpleString } from "../../utility/formatDateToSimpleString";
import { getLastMonthsStartAndEndDate } from "../../utility/getLastMonthsStartAndEndDate";
import { getJobs } from "../../api/jobs.api";
import { createSafeFilename } from "../../utility/createSafeFilename";
export default function EtaEarlyLateReport() {
  const { db } = useAuthStore();
  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);
  const [etaEarlyLateReportInput, setEtaEarlyLateReportInput] =
    useState<EtaEarlyLateReportInput>({
      startDate: getLastMonthsStartAndEndDate()[0],
      endDate: getLastMonthsStartAndEndDate()[1],
      jobId: 0,
      rideMode: 2,
    });

  const [openModal, setOpenModal] = useState(false);

  function getRideMode(rideModeValue: number) {
    if (rideModeValue == 0) {
      return "On-Demand";
    }
    if (rideModeValue == 1) {
      return "Scheduled";
    } else return "";
  }
  const {
    data: etaEarlyLateReportData,
    isLoading: etaEarlyLateReportIsLoading,
    isRefetching: etaEarlyLateReportIsRefetching,
    refetch: refetchEtaEarlyLateReport,
  } = useQuery(
    "getEtaEarlyLateReport",
    () => getEtaEarlyLateReport(etaEarlyLateReportInput),
    {
      enabled: false,
      onSuccess: (d) => {
        const url = window.URL.createObjectURL(d);
        const a = document.createElement("a");
        a.href = url;
        a.download = createSafeFilename(
          db!.split("/")[2],
          "eta_early_late",
          formatDateToSimpleString(etaEarlyLateReportInput.startDate!),
          formatDateToSimpleString(etaEarlyLateReportInput.endDate!),
          "csv",
          etaEarlyLateReportInput.jobId != 0
            ? jobsData?.jobs.filter((j) => {
                return j.id! == etaEarlyLateReportInput.jobId!;
              })[0].name
            : "",
          getRideMode(etaEarlyLateReportInput.rideMode!)
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        enqueueSnackbar("Eta Early/Late Report generated succesfully.", {
          variant: "success",
        });
        setOpenModal(false);
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this report. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <h2 style={{ marginBottom: "20px" }}>
              Eta Early/Late Report{" "}
              <Tooltip
                placement="top"
                title="Generate an ETA Early/Late report.  Select the start and end dates the report must cover."
                style={{ marginLeft: "10px" }}
              >
                <InfoIcon fontSize="small" color="disabled"></InfoIcon>
              </Tooltip>
            </h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DatePicker
                  label="Start Date"
                  value={dayjs(etaEarlyLateReportInput.startDate)}
                  onChange={(e) => {
                    let newDate = dayjs(e).toDate();
                    if (newDate) {
                      setEtaEarlyLateReportInput({
                        ...etaEarlyLateReportInput,
                        startDate: newDate,
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DatePicker
                  label="End Date"
                  value={dayjs(etaEarlyLateReportInput.endDate)}
                  onChange={(e) => {
                    let newDate = dayjs(e).toDate();
                    if (newDate) {
                      setEtaEarlyLateReportInput({
                        ...etaEarlyLateReportInput,
                        endDate: newDate,
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              size="small"
              style={{ margin: "20px 0px" }}
              fullWidth
              label="Select a Job"
              value={etaEarlyLateReportInput.jobId}
              onChange={(e) => {
                setEtaEarlyLateReportInput({
                  ...etaEarlyLateReportInput,
                  jobId: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>All Jobs</MenuItem>
              {jobsData?.jobs.map((j) => {
                return (
                  <MenuItem value={j.id} key={j.id}>
                    {j.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              size="small"
              fullWidth
              label="Select a Ride Mode"
              value={etaEarlyLateReportInput.rideMode}
              onChange={(e) => {
                setEtaEarlyLateReportInput({
                  ...etaEarlyLateReportInput,
                  rideMode: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={2}>All Modes</MenuItem>
              <MenuItem value={0}>On-Demand</MenuItem>
              <MenuItem value={1}>Scheduled</MenuItem>
            </TextField>
            <div
              className="button-container justify-end"
              style={{ marginTop: "40px" }}
            >
              <button
                onClick={() => setOpenModal(false)}
                className="button-outline"
              >
                Cancel
              </button>
              <button
                onClick={() => refetchEtaEarlyLateReport()}
                className="button-primary"
                disabled={
                  etaEarlyLateReportIsLoading ||
                  etaEarlyLateReportIsRefetching ||
                  !etaEarlyLateReportInput.startDate ||
                  !etaEarlyLateReportInput.endDate
                }
              >
                {etaEarlyLateReportIsLoading ||
                etaEarlyLateReportIsRefetching ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                ) : (
                  <span className="button-container">
                    Download
                    {/* <FileDownloadIcon fontSize="small"></FileDownloadIcon> */}
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog>
        <button
          onClick={() => setOpenModal(true)}
          className="button-primary-sm"
          style={{ padding: "5px 10px" }}
        >
          Generate
        </button>
      </div>
    </div>
  );
}
