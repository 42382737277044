import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getDrivers } from "../api/drivers.api";
import StatusOptions from "./utility/StatusOptions";
import DriverModeOptions from "./utility/DriverModeOptions";
import DriverTypeOptions from "./utility/DriverTypeOptions";

export default function DriversOverview() {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
    },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 0.5,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },

    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 0.75,
    },
    {
      field: "mode",
      headerName: "Mode",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <DriverModeOptions mode={params.row.mode}></DriverModeOptions>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <DriverTypeOptions type={params.row.type}></DriverTypeOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/drivers/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const {
    data: driverData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getDrivers", getDrivers, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {driverData && (
        <>
          <div className="header-container">
            <h2>Drivers</h2>
            <button
              className="button-primary"
              onClick={() => {
                navigate("/dashboard/drivers/create");
              }}
            >
              Create New Driver
            </button>
          </div>
          <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
            <DataGrid
              rowHeight={40}
              headerHeight={45}
              rows={driverData?.drivers || []}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        </>
      )}
    </div>
  );
}
