import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface JobsAccessAccountsRule {
  id?: number;
  job_id?: number;
  access_account_id?: number;
  allowed?: number;
  job_name?: string;
  access_account_name?: string;
}
interface JobsAccessAccountsRulesResponse {
  success: boolean;
  jobs_access_accounts_rules: JobsAccessAccountsRule[];
}

export const getJobsAccessAccountRules =
  async function (): Promise<JobsAccessAccountsRulesResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get<JobsAccessAccountsRulesResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/jobs-access-accounts-rules`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };

export const getAJobsAccessAccountRules = async function (
  id: number | undefined
): Promise<JobsAccessAccountsRulesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-access-accounts-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const deleteAJobsAccessAccountRule = async function (
  id: number | undefined
): Promise<JobsAccessAccountsRulesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-access-accounts-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAJobsAccessAccountRule = async function (
  jobsAccessAccountRule: JobsAccessAccountsRule
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-access-accounts-rules`,
    jobsAccessAccountRule,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAJobsAccessAccountRule = async function (
  jobsAccessAccountRule: JobsAccessAccountsRule
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-access-accounts-rules`,
    jobsAccessAccountRule,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
