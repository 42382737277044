import { CircularProgress, Dialog } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { Fare, deleteAFare, getFares } from "../api/fares.api";
import StaticMapPreviewModal from "./StaticMapPreviewModal";
import DollarAmountChip from "./utility/DollarAmountChip";
import EnabledOptions from "./utility/EnabledOptions";

export default function FaresOverview() {
  const navigate = useNavigate();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedFare, setSelectedFare] = useState<Fare>();
  const {
    mutateAsync: deleteFare,
    isLoading: deleteFareIsLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(deleteAFare, {
    onSuccess: () => {
      queryClient.invalidateQueries("getFares");
      queryClient.invalidateQueries("getAFare");

      setDeleteModalOpen(false);
      enqueueSnackbar("Succesfully deleted this fare.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      setDeleteModalOpen(false);
      enqueueSnackbar(
        `There was a problem deleting this fare. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  async function performDeleteFare(id: number) {
    await deleteFare(id);
  }

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.pick_up_zone_id == 0 &&
    //         params.row.drop_off_zone_id == 0 &&
    //         params.row.job_id == 0 ? (
    //           <>**ANY**</>
    //         ) : (
    //           <>{params.row.id}</>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "job_name",
      headerName: "Job",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.job_name == null ? (
              <>**ANY**</>
            ) : (
              <>{params.row.job_name}</>
            )}
          </>
        );
      },
    },
    {
      field: "pick_up_zone_name",
      headerName: "Pickup Zone",
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <>
            {params.row.pick_up_zone_name == null ? (
              <>**ANY**</>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {params.row.pick_up_zone_name}{" "}
                  <StaticMapPreviewModal
                    polygonString={params.row.pick_up_zone_polygon}
                  ></StaticMapPreviewModal>
                </div>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "drop_off_zone_name",
      headerName: "Dropoff Zone",
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <>
            {params.row.drop_off_zone_name == null ? (
              <>**ANY**</>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {params.row.drop_off_zone_name}{" "}
                  <StaticMapPreviewModal
                    polygonString={params.row.drop_off_zone_polygon}
                  ></StaticMapPreviewModal>
                </div>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      renderCell: (param) => {
        return (
          <DollarAmountChip amountCents={param.row.amount}></DollarAmountChip>
        );
      },
    },
    {
      field: "enabled",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (param) => {
        return (
          <>
            <EnabledOptions status={param.row.enabled}></EnabledOptions>
          </>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/fares/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
            <button
              className="button-error-sm"
              style={{ margin: "0px 5px" }}
              onClick={(e) => {
                setSelectedFare(params.row);
                setDeleteModalOpen(true);
              }}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];

  const {
    data: faresData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getFares", getFares, { refetchOnMount: true });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Fares</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/fares/create");
          }}
        >
          Create New Fare
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={faresData?.fares ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
        <Dialog
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ padding: "40px" }}>
            <h2>Delete Fare</h2>
            <p>Are you sure you want to delete this fare?</p>
            <div
              className="button-container"
              style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
            >
              <button
                className="button-outline btn"
                type="button"
                onClick={(e) => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>

              <button
                className="button-error"
                disabled={deleteFareIsLoading}
                onClick={(e) => {
                  if (selectedFare!.id) {
                    performDeleteFare(selectedFare?.id!);
                  }
                }}
              >
                {deleteFareIsLoading ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                ) : (
                  <span className="button-container">Delete Fare</span>
                )}
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
