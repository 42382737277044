import jwt_decode from "jwt-decode";
import { Navigate } from "react-router";
import { useAuthStore } from "../stores/auth.store.zus";
interface TokenStorage {
  iat: number;
  exp: number;
  db: string;
  user: {
    username: string;
    type: number;
  };
}

export default function AuthenticatedRoute({
  children,
}: {
  children: JSX.Element;
}) {
  try {
    let key = sessionStorage.getItem("token");
    if (!key || key == "") {
      return <Navigate to="/" />;
    }

    let decode = jwt_decode(key) as TokenStorage;
    if (decode) {
      let expireTime = decode.exp;
      if (Date.now() > expireTime * 1000) {
        sessionStorage.setItem("token", JSON.stringify(""));
        useAuthStore.getState().setLogin(false);
        return <Navigate to="/" />;
      }
      if (Date.now() < expireTime * 1000) {
        useAuthStore.getState().setToken(key);
        useAuthStore
          .getState()
          .setDb(`${process.env.REACT_APP_RUN_MODE}/db/${decode.db}`);
        useAuthStore.getState().setServiceName(decode.db);
        useAuthStore.getState().setAccessType(decode.user.type);
        useAuthStore.getState().setLogin(true);
        return children;
      }
    }

    if (useAuthStore.getState().isLoggedIn) {
      return children;
    }

    return <Navigate to="/" />;
  } catch (error) {
    return <Navigate to="/" />;
  }
}
