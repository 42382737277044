import RectangleIcon from "@mui/icons-material/Rectangle";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getVehicleTypes } from "../api/vehicle_types.api";

export default function VehicleTypesOverview() {
  const columns: GridColDef[] = [
    // { field: "id", headerName: "Id", flex: 1, minWidth: 200 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    {
      field: "max_passengers",
      headerName: "Max Passengers",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.max_passengers} / {params.row.max_passengers_hops}
          </>
        );
      },
    },
    // {
    //   field: "max_passengers_hops",
    //   headerName: "Walk-on Passengers",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "default_color",
      headerName: "Default Color",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div>
            <RectangleIcon
              sx={{ color: params.row.default_color, marginBottom: "-6px" }}
            ></RectangleIcon>
            {params.row.default_color}
          </div>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/vehicle-types/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
    // { field: "route_id", headerName: "Route Id", flex: 1, minWidth: 100 },
  ];

  const navigate = useNavigate();

  const {
    data: vehicleTypesData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getVehicleTypes", getVehicleTypes, { refetchOnMount: true });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Vehicle Types</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/vehicle-types/create");
          }}
        >
          Create New Vehicle Type
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={vehicleTypesData?.vehicle_types ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
