import SearchIcon from "@mui/icons-material/Search";
import { Alert, LinearProgress, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import React from "react";

import debounce from "@mui/material/utils/debounce";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../App";
import { Address, searchAddresses } from "../api/address.api";
import { useAddressesStore } from "../stores/addresses.store.zus";
import StaticMapPreviewPoint from "./StaticMapPreviewPoint";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
const inputBlock = {
  display: "block",
  margin: "20px 20px 20px 0px",
};

export default function AddressesOverview() {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1.5, minWidth: 200 },
    {
      field: "additional_info",
      headerName: "Driver Notes",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.additional_info && <>{params.row.additional_info}</>}
            {!params.row.additional_info && (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <StaticMapPreviewPoint
            coordinate={{
              lat: params.row.latitude,
              lng: params.row.longitude,
            }}
          ></StaticMapPreviewPoint>
        );
      },
    },
    {
      field: "",
      headerName: "Options",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <button
              onClick={(e) => {
                mouseViewHandler(params.row.id);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
  const { addressesToEdit, setAddressesToEdit } = useAddressesStore();

  const navigate = useNavigate();
  async function searchHandler(e: React.ChangeEvent<HTMLInputElement>) {
    await searchForAnAddress(e.target.value);
  }

  const {
    mutateAsync: searchForAnAddress,
    data,
    isError,
    isLoading: isMutationLoading,
  } = useMutation(searchAddresses, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAddresses");
      queryClient.invalidateQueries("getAnAddress");
    },
  });
  function selectionHandler(selectionModel: GridSelectionModel) {
    const selectedRows = selectionModel
      .map((id) => data!.addresses.find((row) => row.id === id))
      .filter((row): row is Address => row !== undefined);

    setAddressesToEdit(selectedRows);
  }
  function mouseViewHandler(id: string) {
    navigate(`/dashboard/addresses/${id}`);
  }
  return (
    <div>
      <h2>Addresses</h2>
      <p>
        Find addresses that are cached in the system. Search for an address to
        adjust the latitude/longitude, or to add driver notes.
      </p>
      <div className="display-flex-center">
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon></SearchIcon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 32,
            required: true,
          }}
          size="small"
          sx={inputBlock}
          label="Search for an Address"
          onChange={debounce(searchHandler, 500)}
        ></TextField>
        {addressesToEdit.length > 1 && (
          <button
            className="button-primary-sm"
            onClick={(e) => {
              navigate("/dashboard/addresses/edit-multiple");
            }}
          >
            Edit Multiple
          </button>
        )}
      </div>

      {data && data.addresses.length == 0 && (
        <Alert severity="info">No Results found.</Alert>
      )}
      {data && data.addresses.length > 0 && (
        <div style={{ height: 500, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={data.addresses}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
            onSelectionModelChange={selectionHandler}
          />
        </div>
      )}
      {isMutationLoading && <LinearProgress />}
    </div>
  );
}
