import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface UserSys {
  id?: number;
  email?: string;
  type?: number;
  dashboard_default_access_code?: string | null;
  status?: number;
  creation_date?: number;
  update_date?: number;
  delete_date?: number;
  delete_reason?: string;
}

interface GetUsersSysResponse {
  success: boolean;
  users: UserSys[];
}
interface GenericResponse {
  success: boolean;
}

export async function getUsersSys(): Promise<GetUsersSysResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users-sys`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getAUsersSys(
  userSysId: number
): Promise<GetUsersSysResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users-sys/${userSysId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function createUserSys(
  newUserSys: UserSys
): Promise<GenericResponse> {
  const token = useAuthStore.getState().token;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/users-sys`,
    newUserSys,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function updateAUserSys(userSysFieldsToUpdate: UserSys) {
  const token = useAuthStore.getState().token;

  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/users-sys`,
    userSysFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
