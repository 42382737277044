import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { getAccessAccounts } from "../api/accessAccounts.api";
import { getJobs } from "../api/jobs.api";
import {
  JobsAccessAccountsRule,
  createAJobsAccessAccountRule,
} from "../api/jobs_access_accounts_rules.api";
import BackButton from "./BackButton";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function JobsAccessAccountsRulesCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync: createJobsAccessAccountRuleMutation,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAJobsAccessAccountRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getJobsAccessAccountRules");
      enqueueSnackbar("Jobs access account rule was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/access-accounts");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this Jobs access account rule  Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);

  const {
    data: accessAccountData,
    isLoading: accessAccountIsLoading,
    isError: accessAccountIsError,
  } = useQuery("getAccessAccounts", getAccessAccounts);

  const [createJobAccessAccountRule, setCreateJobAccessAccountRule] =
    useState<JobsAccessAccountsRule>({
      job_id: 0,
      allowed: 0,
      access_account_id: 0,
    });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await createJobsAccessAccountRuleMutation(createJobAccessAccountRule);
  }
  if (jobsIsLoading || accessAccountIsLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Job Access Account Rule</h2>
      <div className="create-asset-form">
        <form onSubmit={submitHandler} style={{ width: "300px" }}>
          <TextField
            required
            select
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Job"
            fullWidth
            value={createJobAccessAccountRule.job_id}
            onChange={(e) => {
              setCreateJobAccessAccountRule({
                ...createJobAccessAccountRule,
                job_id: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>**ANY**</MenuItem>
            {jobsData?.jobs.map((j) => {
              return <MenuItem value={j.id}>{j.name}</MenuItem>;
            })}
          </TextField>
          <TextField
            required
            select
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Access Account"
            fullWidth
            value={createJobAccessAccountRule.access_account_id}
            onChange={(e) => {
              setCreateJobAccessAccountRule({
                ...createJobAccessAccountRule,
                access_account_id: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={-1}>(None)</MenuItem>
            <MenuItem value={0}>**ANY**</MenuItem>
            {accessAccountData?.access_accounts.map((a) => {
              return <MenuItem value={a.id}>{a.name}</MenuItem>;
            })}
          </TextField>
          <TextField
            required
            select
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Allowed"
            fullWidth
            value={createJobAccessAccountRule.allowed}
            onChange={(e) => {
              setCreateJobAccessAccountRule({
                ...createJobAccessAccountRule,
                allowed: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Not Allowed</MenuItem>
            <MenuItem value={1}>Allowed</MenuItem>
          </TextField>

          <button className="button-primary" type="submit">
            Create Job Access Account Rule
          </button>
        </form>
      </div>
    </div>
  );
}
