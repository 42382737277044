import { LinearProgress, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

import { enqueueSnackbar } from "notistack";
import {
  MerchantCategory,
  getAMerchantCategory,
  updateAMerchantCategory,
} from "../api/merchants_categories.api";
import BackButton from "./BackButton";
import FileUploader from "./utility/FileUploader";
import StatusOptions from "./utility/StatusOptions";
import ImagePreview from "./ImagePreview";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function MerchantCategorySingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAMerchantCategory",
    () => getAMerchantCategory(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAMerchantCategory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMerchantCategories");
        queryClient.invalidateQueries("getAMerchantCategory");
        enqueueSnackbar("Merchant category was succesfully updated.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem updating this merchhant category Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedMerchantCategory, setUpdatedMerchantCategory] =
    useState<MerchantCategory>({
      id: parseInt(id),
    });
  function handleSelectChange(e: React.ChangeEvent<any>) {
    setUpdatedMerchantCategory({
      ...updatedMerchantCategory,
      status: e.target.value,
    });
  }
  function iconUploadHandler(url: string) {
    setUpdatedMerchantCategory({
      ...updatedMerchantCategory,
      icon: url,
    });
  }
  async function updateMerchantCategory() {
    await mutateAsync(updatedMerchantCategory);
    setEditEnabled(false);
  }

  useEffect(() => {
    setUpdatedMerchantCategory({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Merchant Category Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateMerchantCategory}>
          <div className="field-block">
            <span className="label">Name</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ maxLength: 128 }}
                size="small"
                defaultValue={data?.merchants_categories[0].name}
                placeholder={data?.merchants_categories[0].name}
                onChange={(e) =>
                  setUpdatedMerchantCategory({
                    ...updatedMerchantCategory,
                    name: e.target.value,
                  })
                }
              ></TextField>
            ) : (
              data?.merchants_categories[0].name
            )}
          </div>
          <div className="field-block">
            <span className="label">Priority</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ min: 0, max: 255 }}
                type="number"
                size="small"
                defaultValue={data?.merchants_categories[0].priority}
                label="Priority"
                onChange={(e) =>
                  setUpdatedMerchantCategory({
                    ...updatedMerchantCategory,
                    priority: parseInt(e.target.value),
                  })
                }
              ></TextField>
            ) : (
              data?.merchants_categories[0].priority
            )}
          </div>
          <div className="field-block">
            <span className="label">Status</span>
            {editEnabled ? (
              <TextField
                inputProps={{ min: 0, max: 1 }}
                size="small"
                type="number"
                label="Status"
                select
                defaultValue={data?.merchants_categories[0].status}
                value={updatedMerchantCategory.status}
                onChange={handleSelectChange}
              >
                <MenuItem value={0}>Enabled</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
              </TextField>
            ) : (
              <div>
                <StatusOptions
                  status={data?.merchants_categories[0]!.status!}
                ></StatusOptions>
              </div>
            )}
          </div>
          <div className="field-block">
            <span className="label">Icon</span>
            {editEnabled ? (
              <FileUploader
                queryKey={"merchantCategoryIconUrl"}
                success={iconUploadHandler}
              ></FileUploader>
            ) : data?.merchants_categories[0].icon! ? (
              <img src={data?.merchants_categories[0].icon!}></img>
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button className="button-primary btn">Save</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
