import { Alert, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Address, getAddressesWithInfo } from "../api/address.api";
import { useAddressesStore } from "../stores/addresses.store.zus";
import StaticMapPreviewPoint from "./StaticMapPreviewPoint";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

const inputBlock = {
  display: "block",
  margin: "20px 20px 20px 0px",
};

export default function AddressesWithInfoOverview() {
  const { addressesToEdit, setAddressesToEdit } = useAddressesStore();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1.5, minWidth: 200 },
    {
      field: "additional_info",
      headerName: "Driver Notes",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.additional_info && <>{params.row.additional_info}</>}
            {!params.row.additional_info && (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <StaticMapPreviewPoint
            coordinate={{
              lat: params.row.latitude,
              lng: params.row.longitude,
            }}
          ></StaticMapPreviewPoint>
        );
      },
    },
    {
      field: "",
      headerName: "Options",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <button
              onClick={(e) => {
                mouseViewHandler(params.row.id);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const {
    data: addressesWithInfoData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getAddressesWithInfo", getAddressesWithInfo, {
    refetchOnMount: true,
  });

  function selectionHandler(selectionModel: GridSelectionModel) {
    const selectedRows = selectionModel
      .map((id) =>
        addressesWithInfoData!.addresses.find((row) => row.id === id)
      )
      .filter((row): row is Address => row !== undefined);

    setAddressesToEdit(selectedRows);
  }
  function mouseViewHandler(id: string) {
    navigate(`/dashboard/addresses/${id}`);
  }
  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <h2>Addresses with Driver Notes</h2>
      <div className="display-flex-center">
        {addressesToEdit.length > 1 && (
          <button
            className="button-primary-sm"
            onClick={(e) => {
              navigate("/dashboard/addresses/edit-multiple");
            }}
          >
            Edit Multiple
          </button>
        )}
      </div>

      {addressesWithInfoData && addressesWithInfoData.addresses.length > 0 && (
        <div style={{ height: 500, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={addressesWithInfoData.addresses}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
            onSelectionModelChange={selectionHandler}
          />
        </div>
      )}
      {addressesWithInfoData && addressesWithInfoData.addresses.length == 0 && (
        <Alert severity="info" style={{ marginTop: "20px" }}>
          No Results found.
        </Alert>
      )}
    </div>
  );
}
