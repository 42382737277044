import { create } from "zustand";
import { Address } from "../api/address.api";

interface AddressesStore {
  addressesToEdit: Address[];
  setAddressesToEdit: (addresses: Address[]) => void;
}

export const useAddressesStore = create<AddressesStore>((set) => ({
  addressesToEdit: [],
  setAddressesToEdit: (addresses) =>
    set((state) => ({ addressesToEdit: addresses })),
}));
