import { Dialog } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import {
  JobsAccessAccountsRule,
  deleteAJobsAccessAccountRule,
  getJobsAccessAccountRules,
} from "../api/jobs_access_accounts_rules.api";
import AllowedOptions from "./utility/AllowedOptions";

export default function JobsAccessAccountsRulesOverview() {
  const navigate = useNavigate();
  async function performDeleteJobAccessAccountRule(id: number) {
    await deleteJobAccessAccountRule(id);
  }
  const [selectedJobAccessAccountRule, setSelectedJobAccessAccountRule] =
    useState<JobsAccessAccountsRule>();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const {
    mutateAsync: deleteJobAccessAccountRule,
    isLoading: deleteJobAccessAccountRuleIsLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(deleteAJobsAccessAccountRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAJobsAccountAccessRules");
      queryClient.invalidateQueries("getJobsAccessAccountsRules");
      setDeleteModalOpen(false);
      enqueueSnackbar("Succesfully deleted this job access account rule.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      setDeleteModalOpen(false);
      enqueueSnackbar(
        `There was a problem deleting this job access account rule. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
    },
    {
      field: "job_name",
      headerName: "Job Name",
      flex: 1,
    },
    {
      field: "access_account_name",
      headerName: "Access Account",
      flex: 1,
    },
    {
      field: "Allowed",
      headerName: "Allowed",
      flex: 1,
      renderCell: (params) => {
        return <AllowedOptions allowed={params.row.allowed}></AllowedOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(
                  `/dashboard/jobs-access-accounts-rules/${params.row.id}`
                );
              }}
              className="button-primary-sm"
            >
              View
            </button>
            <button
              className="button-error-sm"
              style={{ margin: "0px 5px" }}
              onClick={(e) => {
                setSelectedJobAccessAccountRule(params.row);
                setDeleteModalOpen(true);
              }}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: jobsAccessAccountsRulesData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getJobsAccessAccountsRules", getJobsAccessAccountRules, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Job Rules</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/jobs-access-accounts-rules/create");
          }}
        >
          Create Job Rule
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={jobsAccessAccountsRulesData?.jobs_access_accounts_rules ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "40px" }}>
          <h2>Job Access Account Rule</h2>
          <p>Are you sure you want to delete this job access account rule?</p>
          <div
            className="button-container"
            style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
          >
            <button
              className="button-outline btn"
              type="button"
              onClick={(e) => {
                setDeleteModalOpen(false);
              }}
            >
              Cancel
            </button>

            <button
              className="button-error"
              onClick={(e) => {
                if (selectedJobAccessAccountRule!.id) {
                  performDeleteJobAccessAccountRule(
                    selectedJobAccessAccountRule?.id!
                  );
                }
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
