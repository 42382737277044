import axios from "axios";
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
interface AddressFromLatLngResponse {
  results: AddressFromlatLngResponseResults[];
}

interface AddressFromlatLngResponseResults {
  address_components: AddressComponent[];
}
interface Coordinate {
  lat?: number;
  lng?: number;
}
export async function getAddressFromLatLng(coordinate: Coordinate) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinate.lat},${coordinate.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const res = await axios.get<AddressFromLatLngResponse>(url);
  let addressData = res.data.results[0].address_components;
  let city = addressData.filter((a) => {
    return a.types.includes("locality");
  });
  let state = addressData.filter((a) => {
    return a.types.includes("administrative_area_level_1");
  });
  let country = addressData.filter((a) => {
    return a.types.includes("country");
  });
  let outputString = "";
  if (city && state && country) {
    outputString = `${city[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`;
  }
  console.log(outputString);
  return outputString;
}
