import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { Job } from "./jobs.api";
import { ShiftScheduledCreate } from "../components/ShiftsScheduledCreate";

export interface ShiftScheduled {
  id?: number;
  job_id?: number;
  job_name?: string;
  vehicle_id?: number;
  vehicle_type_name?: string;
  start_date?: string;
  end_date?: string;
  start_lead_time?: number | null;
  start_time?: number;
  end_time?: number;
  days?: string;
  status?: number;
}

interface GetAShiftScheduledResponse {
  success: boolean;
  shift: ShiftScheduled[];
}
interface GetShiftsResponse {
  success: boolean;
  shifts: ShiftScheduled[];
}

interface AllowableVehicle {
  vehicle_id: number;
  vehicle_type_name: string;
}

interface GetShiftsAllowableVehiclesResponse {
  success: boolean;
  vehicles: AllowableVehicle[];
}
interface GetShiftsAllowableJobsResponse {
  success: boolean;
  jobs: Job[];
}

export const getShifts = async function (): Promise<GetShiftsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetShiftsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

export const getAShiftScheduled = async function (
  shiftId: number | undefined
): Promise<GetAShiftScheduledResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled/${shiftId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getShiftAllowableVehicles =
  async function (): Promise<GetShiftsAllowableVehiclesResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get<GetShiftsAllowableVehiclesResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled/allowed-vehicles`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  };
export const getShiftAllowableJobs =
  async function (): Promise<GetShiftsAllowableJobsResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get<GetShiftsAllowableJobsResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled/allowed-jobs`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  };
export const updateAShift = async function (
  shiftFieldsToUpdate: ShiftScheduled
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled`,
    shiftFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAShift = async function (shift: ShiftScheduledCreate) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled`,
    shift,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const deleteAShift = async function (
  shiftId: number | undefined
): Promise<GetAShiftScheduledResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/shifts-scheduled/${shiftId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
