import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Chip, Stack } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/Visibility";

interface AutohideOptionsProps {
  autohide: number;
}

export default function AutohideOptions(props: AutohideOptionsProps) {
  return (
    <>
      {props.autohide == 0 && (
        <>No</>
        // <Stack direction="row" spacing={1}>
        //   <Chip
        //     size="small"
        //     // color="success"
        //     icon={<VisibilityOffIcon />}
        //     label="Disabled"
        //   />
        // </Stack>
      )}
      {props.autohide == 1 && (
        <>Yes</>
        // <Stack direction="row" spacing={1}>
        //   <Chip
        //     size="small"
        //     icon={<CheckCircleIcon />}
        //     label="Enabled"
        //     color="success"
        //   />
        // </Stack>
      )}
    </>
  );
}
