import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getZonesDeviated } from "../api/zonesDeviated.api";
import { useZoneStore } from "../stores/zone.store.zus";
import StaticMapPreviewModal from "./StaticMapPreviewModal";
import PriorityOptions from "./utility/PriorityOptions";
import StatusOptions from "./utility/StatusOptions";
import ZoneDeviatedButtonGroup from "./zoneDeviated/ZoneDeviatedButtonGroup";

export default function ZoneDeviatedOverview() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", flex: 0.5, minWidth: 100 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 100 },
    { field: "stop_name", headerName: "Stop", flex: 1, minWidth: 100 },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <>{params.row.priority}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "polygon",
      headerName: "Preview",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <div>
            <StaticMapPreviewModal
              polygonString={params.row.polygon}
              markerLocationLat={params.row.stop_latitude}
              markerLocationLon={params.row.stop_longitude}
            ></StaticMapPreviewModal>
          </div>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/zones-deviated/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    useZoneStore.getState().clearZoneStore();
  }, []);

  const navigate = useNavigate();
  const {
    data: zoneData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getZonesDeviated", getZonesDeviated, { refetchOnMount: true });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Zones Deviated</h2>
        <ZoneDeviatedButtonGroup></ZoneDeviatedButtonGroup>
      </div>
      {zoneData && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={zoneData.zones || []}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}
    </div>
  );
}
