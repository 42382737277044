import { Alert, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import doLogin from "../api/login.api";
import { useAuthStore } from "../stores/auth.store.zus";
import jwtDecode from "jwt-decode";

export default function Login() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedDatabase, setSelectedDatabase] = useState<string>("");
  const [serviceName, setServiceName] = useState<string>("");

  const navigate = useNavigate();
  const { mutateAsync, isLoading, isError, error } = useMutation(doLogin);

  async function loginHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const data = await mutateAsync({
      database: selectedDatabase.split("/")[2],
      password: password,
      username: username,
    });
    await sessionStorage.setItem("token", data.token);
    await useAuthStore.getState().setToken(data.token);
    await useAuthStore.getState().setLogin(true);
    await useAuthStore.getState().setDb(selectedDatabase);
    await useAuthStore.getState().setServiceName(serviceName);
    await useAuthStore
      .getState()
      .setAccessType((jwtDecode(data.token) as any).user.type);
    navigate("/dashboard");
  }

  return (
    <div>
      <div className="skew"></div>
      <div className="login-container">
        <div className="logo-branding">
          <img
            style={{ height: "50px", margin: "20px 0px" }}
            src="./dt-hub-logo-white.png"
            alt=""
          />
        </div>
        <div className="login-content">
          <div className="login-header">
            <h1>Sign In</h1>
          </div>
          <div className="login-inputs">
            <form onSubmit={loginHandler}>
              <TextField
                disabled={isLoading}
                sx={{ width: 1, margin: "10px 0px" }}
                label="Client ID"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedDatabase(
                    `${
                      process.env.REACT_APP_RUN_MODE
                    }/db/${e.target.value.toLocaleLowerCase()}`
                  );
                  setServiceName(e.target.value.trim().toLowerCase());
                }}
              />
              <TextField
                disabled={isLoading}
                sx={{ width: 1, margin: "10px 0px" }}
                label="Username"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUsername(e.target.value);
                }}
              />
              <TextField
                disabled={isLoading}
                sx={{ width: 1, margin: "10px 0px" }}
                label="Password"
                type="password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
              />
              <div className="button-container-login">
                <button
                  className="button-primary-full-width"
                  style={{ marginTop: "20px" }}
                  // disabled={isLoading || !username || !password || !serviceName}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      style={{ marginLeft: "18px", marginRight: "18px" }}
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
            {isError && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                Invalid login credentials.
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
