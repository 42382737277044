import InfoIcon from "@mui/icons-material/Info";
import {
  CircularProgress,
  Dialog,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useQuery } from "react-query";
import { getJobs } from "../../api/jobs.api";
import {
  RiderNotFoundReportInput,
  getRiderNotFoundReport,
} from "../../api/reporting.api";
import { useAuthStore } from "../../stores/auth.store.zus";
import { createSafeFilename } from "../../utility/createSafeFilename";
import { formatDateToSimpleString } from "../../utility/formatDateToSimpleString";
import { getLastMonthsStartAndEndDate } from "../../utility/getLastMonthsStartAndEndDate";

export default function RiderNotFoundReport() {
  const { db } = useAuthStore();
  const [riderNotFoundReportInput, setRiderNotFoundReportInput] =
    useState<RiderNotFoundReportInput>({
      startDate: getLastMonthsStartAndEndDate()[0],
      endDate: getLastMonthsStartAndEndDate()[1],
      jobId: 0,
    });
  const [openModal, setOpenModal] = useState(false);
  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);
  const {
    data: riderNotFoundReportData,
    isLoading: riderNotFoundReportIsLoading,
    isRefetching: riderNotFoundReportIsRefetching,
    refetch: refetchRiderNotFoundReport,
  } = useQuery(
    "getRiderNotFoundReport",
    () => getRiderNotFoundReport(riderNotFoundReportInput),
    {
      enabled: false,
      onSuccess: (d) => {
        const url = window.URL.createObjectURL(d);
        const a = document.createElement("a");
        a.href = url;
        a.download = createSafeFilename(
          db!.split("/")[2],
          "rider_not_found",
          formatDateToSimpleString(riderNotFoundReportInput.startDate!),
          formatDateToSimpleString(riderNotFoundReportInput.endDate!),
          "csv",
          riderNotFoundReportInput.jobId != 0
            ? jobsData?.jobs.filter((j) => {
                return j.id! == riderNotFoundReportInput.jobId!;
              })[0].name
            : ""
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        enqueueSnackbar("Rider Not Found Report generated succesfully.", {
          variant: "success",
        });
        setOpenModal(false);
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this report. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <h2 style={{ marginBottom: "20px" }}>
              Rider Not Found Report{" "}
              <Tooltip
                placement="top"
                title="Generate a rider not found report.  Select the start and end dates the report must cover."
                style={{ marginLeft: "10px" }}
              >
                <InfoIcon fontSize="small" color="disabled"></InfoIcon>
              </Tooltip>
            </h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DatePicker
                  label="Start Date"
                  sx={{ marginBottom: "10px" }}
                  value={dayjs(riderNotFoundReportInput.startDate)}
                  onChange={(e) => {
                    let newDate = dayjs(e).toDate();
                    if (newDate) {
                      setRiderNotFoundReportInput({
                        ...riderNotFoundReportInput,
                        startDate: newDate,
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DatePicker
                  label="End Date"
                  value={dayjs(riderNotFoundReportInput.endDate)}
                  onChange={(e) => {
                    let newDate = dayjs(e).toDate();
                    if (newDate) {
                      setRiderNotFoundReportInput({
                        ...riderNotFoundReportInput,
                        endDate: newDate,
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              size="small"
              style={{ margin: "20px 0px" }}
              fullWidth
              label="Select a Job"
              value={riderNotFoundReportInput.jobId}
              onChange={(e) => {
                setRiderNotFoundReportInput({
                  ...riderNotFoundReportInput,
                  jobId: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>All Jobs</MenuItem>
              {jobsData?.jobs.map((j) => {
                return (
                  <MenuItem value={j.id} key={j.id}>
                    {j.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <div
              className="button-container justify-end"
              style={{ marginTop: "40px" }}
            >
              <button
                onClick={() => setOpenModal(false)}
                className="button-outline"
              >
                Cancel
              </button>
              <button
                onClick={() => refetchRiderNotFoundReport()}
                className="button-primary"
                disabled={
                  riderNotFoundReportIsLoading ||
                  riderNotFoundReportIsRefetching ||
                  !riderNotFoundReportInput.startDate ||
                  !riderNotFoundReportInput.endDate
                }
              >
                {riderNotFoundReportIsLoading ||
                riderNotFoundReportIsRefetching ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                ) : (
                  <span className="button-container">
                    Download
                    {/* <FileDownloadIcon fontSize="small"></FileDownloadIcon> */}
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog>
        <button
          onClick={() => setOpenModal(true)}
          className="button-primary-sm"
          style={{ padding: "5px 10px" }}
        >
          Generate
        </button>
      </div>
    </div>
  );
}
