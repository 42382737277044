import { Autocomplete, MenuItem, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { queryClient } from "../App";
import {
  RouteStop,
  createARouteStop,
  updateARouteStop,
} from "../api/routesStops.api";
import { getStops } from "../api/stops.api";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
interface RoutesSingleRouteStopsEditProps {
  closeModal: Function;
  routeStop: RouteStop;
}
export default function RoutesSingleRouteStopsEdit(
  props: RoutesSingleRouteStopsEditProps
) {
  const { id } = useParams() as { id: string };
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = routeStop;
    if (payload.description == "") {
      payload.description = null;
    }
    delete payload.stop_id;
    await editRouteStop(payload);
    props.closeModal();
  }
  const [routeStop, setRouteStop] = useState<RouteStop>({
    id: props.routeStop.id,
    route_id: props.routeStop.route_id,
    stop_id: props.routeStop.stop_id,
    description: props.routeStop.description,
    optional: props.routeStop.optional,
    sequence: props.routeStop.sequence,
    status: props.routeStop.status,
    default_service_time: props.routeStop.default_service_time,
    default_travel_time: props.routeStop.default_travel_time,
  });
  const {
    mutateAsync: editRouteStop,
    isLoading: isEditRouteStopSaving,
    isSuccess,
    isError,
    error,
  } = useMutation(updateARouteStop, {
    onSuccess: () => {
      enqueueSnackbar("Succesfully edited route stop.", {
        variant: "success",
      });
      queryClient.invalidateQueries("getRouteStops");
      queryClient.invalidateQueries("getARouteStop");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem editting this route stop. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const {
    data: stopsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getStops", getStops, {
    refetchOnMount: true,
  });

  return (
    <div style={{ width: "400px" }}>
      {stopsData && stopsData.stops && (
        <form onSubmit={submitHandler}>
          <h2>Edit Stop on Route</h2>
          <Autocomplete
            disabled
            style={{ marginTop: "40px" }}
            disablePortal
            id="stop-select"
            defaultValue={
              stopsData.stops.filter((s) => {
                return s.id == routeStop.stop_id;
              })[0]
            }
            onChange={(val, newVal) =>
              setRouteStop({
                ...routeStop,
                stop_id: newVal?.id!,
              })
            }
            options={stopsData.stops}
            getOptionLabel={(stop) => stop.name || ""}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Stop Select" />
            )}
          />

          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            label={"Description"}
            value={routeStop.description}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                description: e.target.value,
              })
            }
          ></TextField>
          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            type="number"
            label={"Sequence"}
            value={routeStop.sequence}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                sequence: parseInt(e.target.value),
              })
            }
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            type="number"
            label={"Default Travel Time"}
            value={routeStop.default_travel_time}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                default_travel_time: parseInt(e.target.value),
              })
            }
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            type="number"
            label={"Default Service Time"}
            value={routeStop.default_service_time}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                default_service_time: parseInt(e.target.value),
              })
            }
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            inputProps={{ required: true, min: 0 }}
            select
            size="small"
            fullWidth
            sx={inputBlock}
            label="Status"
            defaultValue={0}
            value={routeStop?.status}
            onChange={(e) => {
              setRouteStop({
                ...routeStop,
                status: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
            <MenuItem value={2}>Hidden</MenuItem>
          </TextField>
          <TextField
            inputProps={{ required: true, min: 0 }}
            select
            size="small"
            fullWidth
            sx={inputBlock}
            label="Optional"
            defaultValue={0}
            value={routeStop?.optional}
            onChange={(e) => {
              setRouteStop({
                ...routeStop,
                optional: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Required</MenuItem>
            <MenuItem value={1}>Optional</MenuItem>
          </TextField>
          <div
            className="button-container"
            style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
          >
            <button
              className="button-outline btn"
              type="button"
              onClick={(e) => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="button-primary"
              disabled={isEditRouteStopSaving}
            >
              {!isEditRouteStopSaving && <>Save</>}
              {isEditRouteStopSaving && (
                <CircularProgress color="inherit" size={12} />
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
