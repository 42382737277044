import axios from "axios";
import { string } from "yargs";
import { useAuthStore } from "../stores/auth.store.zus";

export enum ZoneStatusTypes {
  Enabled = 0,
  Disabled = 1,
  Hidden = 2,
}

export interface Zone {
  id?: number;
  name?: string;
  status?: number;
  polygon?: string | null;
  redirect_merchant_id?: number;
  priority?: number;
  redirect_merchant_name?: string;
  redirect_merchant_latitude?: string;
  redirect_merchant_longitude?: string;
}
interface ResponseGetAZone {
  status: boolean;
  zones: Zone[];
}

export const getZones = async function (): Promise<ResponseGetAZone> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/zones`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const getZonesWithAny = async function (): Promise<ResponseGetAZone> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/zones`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  res.data.zones.unshift({ id: 0, name: "**ANY**" });
  return res.data;
};
export const getAZone = async function (
  zoneId: number | undefined
): Promise<ResponseGetAZone> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/zones/${zoneId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAZone = async function (zoneToUpdate: Zone) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/zones`,
    zoneToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAZone = async function (zone: Zone) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/zones`,
    zone,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getZoneKml = async function (zoneId: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/kml/${zoneId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
};

export const getAllZonesKml = async function () {
  const token = useAuthStore.getState().token;
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/kml/all`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
  return res.data;
};
