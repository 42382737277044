import { Box, Checkbox, MenuItem, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { queryClient } from "../App";
import { JobsHours, createJobHours } from "../api/jobs.api";
import dayjs from "dayjs";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
interface JobSingleHoursCreateProps {
  closeModal: Function;
}
export default function JobSingleHoursCreate(props: JobSingleHoursCreateProps) {
  const { id } = useParams() as { id: string };
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payloads: JobsHours[] = [];
    selectedDays.forEach((day) => {
      selectedCreatedFrom.forEach((rcf) => {
        let pl = { ...jobHours, ride_created_from: rcf, day_of_week: day };
        if (pl.end_time == "0000") {
          pl.end_time = "2400";
        }
        if (noAccess) {
          pl.end_time = "0000";
          pl.start_time = "0000";
        }
        payloads.push(pl);
      });
    });
    for (const payload of payloads) {
      await saveNewJobHours(payload);
    }
    enqueueSnackbar("Succesfully created new job hours.", {
      variant: "success",
    });
    queryClient.invalidateQueries("getJobsHours");
    props.closeModal();
  }
  const [noAccess, setNoAccess] = useState(false);
  const [selectedDays, setSelectedDays] = useState<Array<number>>([]);
  const [selectedCreatedFrom, setSelectedCreatedFrom] = useState<Array<number>>(
    []
  );
  const [jobHours, setJobHours] = useState<JobsHours>({
    day_of_week: 0,
    description: "",
    status: 0,
    job_id: parseInt(id),
  });
  // useEffect(() => {
  //   setJobHours({
  //     day_of_week: 0,
  //     description: "",
  //     status: 0,
  //     job_id: parseInt(id),
  //   });
  // }, []);
  function checkboxHandler(value: number) {
    if (!selectedDays.includes(value)) {
      setSelectedDays([...selectedDays, value]);
      return;
    }
    if (selectedDays.includes(value)) {
      let i = selectedDays.filter((v) => {
        return v != value;
      });
      setSelectedDays(i);
      return;
    }
  }
  function checkboxHandlerCreatedFrom(value: number) {
    if (!selectedCreatedFrom.includes(value)) {
      setSelectedCreatedFrom([...selectedCreatedFrom, value]);
      return;
    }
    if (selectedCreatedFrom.includes(value)) {
      let i = selectedCreatedFrom.filter((v) => {
        return v != value;
      });
      setSelectedCreatedFrom(i);
      return;
    }
  }
  function formComplete() {
    if (noAccess && selectedCreatedFrom.length > 0 && selectedDays.length > 0) {
      return true;
    } else if (
      jobHours.end_time &&
      jobHours.start_time &&
      selectedCreatedFrom.length > 0 &&
      selectedDays.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
  const {
    mutateAsync: saveNewJobHours,
    isLoading: isSaveNewJobHoursLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(createJobHours, {
    // onSuccess: () => {
    // },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating hours for this job. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  return (
    <div style={{ width: "500px" }}>
      <form onSubmit={submitHandler}>
        <h2>Create job hours</h2>
        <span className="label" style={{ margin: "20px 0px 10px 0px" }}>
          Apply hours to these days
        </span>
        <Box sx={{ display: "flex" }}>
          <FormGroup style={{ marginRight: "20px" }}>
            <FormControlLabel
              value={0}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Every Day"
            />
            <FormControlLabel
              value={1}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Monday"
            />
            <FormControlLabel
              value={2}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              value={3}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Wednesday"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              value={4}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              value={5}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Friday"
            />
            <FormControlLabel
              value={6}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              value={7}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandler(parseInt(e.target.value));
                  }}
                />
              }
              label="Sunday"
            />
          </FormGroup>
        </Box>

        <span className="label" style={{ margin: "20px 0px 10px 0px" }}>
          Created from location
        </span>
        <Box sx={{ display: "flex" }}>
          <FormGroup>
            <FormControlLabel
              value={0}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandlerCreatedFrom(parseInt(e.target.value));
                  }}
                />
              }
              label="Dashboard"
            />
            <FormControlLabel
              value={1}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandlerCreatedFrom(parseInt(e.target.value));
                  }}
                />
              }
              label="iOS Rider"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              value={2}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandlerCreatedFrom(parseInt(e.target.value));
                  }}
                />
              }
              label="Driver App"
            />
            <FormControlLabel
              value={3}
              control={
                <Checkbox
                  onChange={(e) => {
                    checkboxHandlerCreatedFrom(parseInt(e.target.value));
                  }}
                />
              }
              label="Android Rider"
            />
          </FormGroup>
        </Box>
        <span className="label" style={{ margin: "20px 0px 10px 0px" }}>
          Job hour timing
        </span>
        <FormControlLabel
          checked={noAccess}
          control={
            <Checkbox
              onChange={(e) => {
                setNoAccess(!noAccess);
              }}
            />
          }
          label="No Access"
        />
        {!noAccess && (
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Start Time"
                  onChange={(e: Date | null) => {
                    setJobHours({
                      ...jobHours,
                      start_time: undefined,
                    });
                    if (dayjs(e).isValid() && e) {
                      let d = new Date(e);
                      let dateString = d.toTimeString();
                      let ds = dateString.split(" ");
                      let dsNoSeconds = ds[0].split(":");
                      let timeFormatted = dsNoSeconds[0] + dsNoSeconds[1];
                      setJobHours({
                        ...jobHours,
                        start_time: timeFormatted,
                      });
                    }
                  }}
                ></TimePicker>
                <TimePicker
                  label="End Time"
                  onChange={(e: Date | null) => {
                    setJobHours({
                      ...jobHours,
                      end_time: undefined,
                    });
                    if (dayjs(e).isValid() && e) {
                      let d = new Date(e);
                      let dateString = d.toTimeString();
                      let ds = dateString.split(" ");
                      let dsNoSeconds = ds[0].split(":");
                      let timeFormatted = dsNoSeconds[0] + dsNoSeconds[1];
                      setJobHours({
                        ...jobHours,
                        end_time: timeFormatted,
                      });
                    }
                  }}
                ></TimePicker>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
        <TextField
          inputProps={{ required: true, min: 0 }}
          select
          size="small"
          style={{ width: "200px" }}
          sx={inputBlock}
          label="Status"
          defaultValue={0}
          value={jobHours?.status}
          onChange={(e) => {
            setJobHours({
              ...jobHours,
              status: parseInt(e.target.value),
            });
          }}
        >
          <MenuItem value={0}>Enabled</MenuItem>
          <MenuItem value={1}>Disabled</MenuItem>
        </TextField>
        <TextField
          size="small"
          inputProps={{ maxLength: 255 }}
          label="Description"
          onChange={(e) =>
            setJobHours({
              ...jobHours,
              description: e.target.value,
            })
          }
        ></TextField>
        <div
          className="button-container"
          style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
        >
          <button
            className="button-outline"
            type="button"
            onClick={(e) => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button-primary"
            disabled={isSaveNewJobHoursLoading || !formComplete()}
          >
            {!isSaveNewJobHoursLoading && <>Save</>}
            {isSaveNewJobHoursLoading && (
              <CircularProgress color="inherit" size={12} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
