import { Dialog } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import {
  AccessCode,
  getAnAccessAccountsAccessCodes,
} from "../api/accessCodes.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import AccessAccountSingleCodesEdit from "./AccessAccountSingleCodesEdit";
import AccessCodeCreateButtonGroup from "./accessCodeCreate/AccessCodeCreateButtonGroup";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";
import TypeOptions from "./utility/TypeOptions";

export default function AccessAccountSingleCodes() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [activeAccessCode, setActiveAccessCode] = useState<AccessCode>();
  function closeEditModalHandler() {
    setEditModalOpen(false);
  }

  const columns: GridColDef[] = [
    // {
    //   field: "access_account_id",
    //   headerName: "Access Account Id",
    //   flex: 1,
    // },
    { field: "code", headerName: "Code", flex: 1 },
    {
      field: "expiration_date",
      headerName: "Expiration",
      flex: 2,
      renderCell: (params) => {
        return (
          <div
            style={{
              color:
                new Date(params.row.expiration_date * 1000) < new Date()
                  ? "red"
                  : "",
            }}
          >
            {params.row.expiration_date ? (
              dayjs(params.row.expiration_date * 1000)
                .tz(
                  useSysParamsStore.getState().getParamByName("global_timezone")
                    ?.value
                )
                .format("MM/DD/YYYY hh:mm:ss a")
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "max_rider_accounts",
    //   headerName: "Max Rider Accounts",
    //   flex: 1,
    // },
    {
      field: "nickname",
      headerName: "Nickname",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.nickname ? (
              params.row.nickname
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    // {
    //   field: "created_by_email",
    //   headerName: "Created By",
    //   flex: 1,
    // },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => {
        return <>{<TypeOptions type={params.row.type}></TypeOptions>}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <StatusOptions status={params.row.status}></StatusOptions>
          </>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                setActiveAccessCode(params.row);
                setEditModalOpen(true);
              }}
              className="button-primary-sm"
            >
              Edit
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: accessCodesData,
    status,
    isLoading,
    isRefetching,
  } = useQuery(
    "getAccessCodes", // TODO change key, add id
    () => getAnAccessAccountsAccessCodes(parseInt(id)),
    {
      refetchOnMount: true,
    }
  );

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Access Codes</h2>
        <AccessCodeCreateButtonGroup></AccessCodeCreateButtonGroup>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={accessCodesData?.access_codes ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <div>
        <Dialog
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <AccessAccountSingleCodesEdit
              closeModal={closeEditModalHandler}
              accessCode={activeAccessCode!}
            ></AccessAccountSingleCodesEdit>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
