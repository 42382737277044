import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Chip, Stack } from "@mui/material";

interface StatusOptions {
  status: number;
}

export default function StatusOptions(props: StatusOptions) {
  return (
    <>
      {props.status == 0 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<CheckCircleIcon />}
            label="Enabled"
          />
        </Stack>
      )}
      {props.status == 1 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<NotInterestedIcon />} label="Disabled" />
        </Stack>
      )}
      {props.status == 2 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<VisibilityOffIcon />} label="Hidden" />
        </Stack>
      )}
    </>
  );
}
