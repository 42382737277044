import PlaceholderCharacter from "./PlaceholderCharacter";

interface RideSingleModeOptions {
  mode: number;
}
export default function RideSingleModeOptions(props: RideSingleModeOptions) {
  return (
    <>
      {props.mode == 0 && <>Normal</>}
      {props.mode == 1 && <>Scheduled</>}
      {(props.mode == undefined || null) && (
        <PlaceholderCharacter></PlaceholderCharacter>
      )}
    </>
  );
}
