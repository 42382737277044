import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAStop } from "../api/stops.api";
import BackButton from "./BackButton";
import RoutesSingleRoutesStops from "./RoutesSingleRoutesStops";
import StopsSingleInformation from "./StopsSingleInformation";
import StopsSingleConnectedRoutes from "./StopsSingleConnectedRoutes";
export default function StopsSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    ["getAStop", parseInt(id)],
    () => getAStop(parseInt(id)),
    { refetchOnMount: true }
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <BackButton></BackButton>
      {data && <h2 style={{ margin: "20px 0px" }}>{data?.stops[0].name} </h2>}

      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "50px" }}
      >
        <Tab label="Information"></Tab>
        <Tab label="Connected Routes"></Tab>
      </Tabs>

      {value == 0 && <StopsSingleInformation></StopsSingleInformation>}
      {value == 1 && <StopsSingleConnectedRoutes></StopsSingleConnectedRoutes>}
    </div>
  );
}
