import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import debounce from "@mui/material/utils/debounce";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { UsersRider, getUsersRiders } from "../api/usersRiders.api";
import { useAuthStore } from "../stores/auth.store.zus";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export default function UsersRidersOverview() {
  const { serviceName } = useAuthStore();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 50,
      renderCell: (params) => {
        return (
          <a
            href={`https://${serviceName}1.downtownerapp.com/server/modules/analytics/view_rider.php?id=${params.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            {params.id}
          </a>
        );
      },
    },
    { field: "first_name", headerName: "First Name", flex: 1, minWidth: 200 },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params.row.last_name ? (
          params.row.last_name
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "is_stripe_setup",
    //   headerName: "Is Stripe Setup",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     return params.row.is_stripe_setup ? (
    //       params.row.is_stripe_setup
    //     ) : (
    //       <PlaceholderCharacter></PlaceholderCharacter>
    //     );
    //   },
    // },

    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => (
        <button
          onClick={() => navigate(`/dashboard/users-riders/${params.row.id}`)}
          className="button-primary-sm"
        >
          View
        </button>
      ),
    },
  ];

  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [previousDataSet, setPreviousDataSet] = useState<Array<UsersRider>>([]);

  const [queryString, setQueryString] = useState("");

  const navigate = useNavigate();

  const {
    data: usersRidersData,
    status,
    isLoading,
    isRefetching,
  } = useQuery(
    ["getUsersRiders", page, queryString],
    () => getUsersRiders(page, queryString),
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (usersRidersData?.pageSize) {
      setPageSize(usersRidersData?.pageSize);
    }
    if (usersRidersData?.countTotalUsersRiders) {
      setRowCount(usersRidersData?.countTotalUsersRiders);
    }
    if (usersRidersData?.users_riders) {
      setPreviousDataSet(usersRidersData.users_riders);
    }
  }, [usersRidersData]);

  const debouncedSearchHandler = debounce((value) => {
    setQueryString(value);
  }, 500);

  function searchHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    debouncedSearchHandler(value);
  }
  return (
    <div>
      <div className="header-container">
        <h2>Riders</h2>
      </div>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
        }}
        size="small"
        label="Search Riders"
        placeholder="Search by name, email or phone"
        onChange={searchHandler}
        sx={{ marginTop: "20px", width: "400px" }}
      ></TextField>
      {previousDataSet && isLoading && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={previousDataSet}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            paginationMode="server"
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            loading={isLoading || isRefetching}
          />
        </div>
      )}
      {usersRidersData?.users_riders && !isLoading && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={usersRidersData.users_riders}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            paginationMode="server"
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            loading={isLoading || isRefetching}
          />
        </div>
      )}
    </div>
  );
}
