import React from "react";
interface SystemParameterUnitProps {
  unit: number;
}
export default function SystemParameterUnits(props: SystemParameterUnitProps) {
  return (
    <div style={{ textTransform: "lowercase" }}>
      {props.unit == 0 && <>Generic Number</>}
      {props.unit == 1 && <>Generic String</>}
      {props.unit == 2 && <>Meters</>}
      {props.unit == 3 && <>Seconds</>}
      {props.unit == 4 && <>Meters * Second</>}
      {props.unit == 5 && <>Degrees </>}
      {props.unit == 6 && <>Percent </>}
    </div>
  );
}
