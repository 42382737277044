import NightsStayIcon from "@mui/icons-material/NightsStay";
import { Dialog, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { queryClient } from "../App";
import { JobsHours, deleteJobHours, getJobsHours } from "../api/jobs.api";
import { timeStringToReadableTime } from "../utility/timeStringToReadableTime";
import JobSingleHoursCreate from "./JobSingleHoursCreate";
import JobSingleHoursEdit from "./JobSingleHoursEdit";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";

export default function JobSingleHours() {
  const columns: GridColDef[] = [
    // { field: "id", headerName: "id", flex: 0.5, minWidth: 50 },
    {
      field: "ride_created_from",
      headerName: "Ride Created From",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            {params.row.ride_created_from == 0 && "Dashboard"}
            {params.row.ride_created_from == 1 && "iOS Rider"}
            {params.row.ride_created_from == 2 && "Driver App"}
            {params.row.ride_created_from == 3 && "Android Rider"}
          </>
        );
      },
    },
    {
      field: "day_of_week",
      headerName: "Day of Week",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            {params.row.day_of_week == 0 && "Every"}
            {params.row.day_of_week == 1 && "Monday"}
            {params.row.day_of_week == 2 && "Tuesday"}
            {params.row.day_of_week == 3 && "Wednesday"}
            {params.row.day_of_week == 4 && "Thursday"}
            {params.row.day_of_week == 5 && "Friday"}
            {params.row.day_of_week == 6 && "Saturday"}
            {params.row.day_of_week == 7 && "Sunday"}
          </>
        );
      },
    },
    {
      field: "start_time",
      headerName: "Start Time",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {params.row.start_time == params.row.end_time
                ? "No Access"
                : timeStringToReadableTime(params.row.start_time)}
            </div>
          </>
        );
      },
    },
    {
      field: "end_time",
      headerName: "End Time",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {params.row.start_time == params.row.end_time
                ? "No Access"
                : timeStringToReadableTime(params.row.end_time)}
              {parseInt(params.row.end_time) <
                parseInt(params.row.start_time) && (
                <NightsStayIcon
                  style={{ marginLeft: "10px", marginBottom: "3px" }}
                ></NightsStayIcon>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            {params.row.description ? (
              params.row.description
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <StatusOptions status={params.row.status}></StatusOptions>
          </>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            <button
              className="button-primary-sm"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setJobHoursToEdit(params.row);
                setEditModalOpen(true);
              }}
            >
              edit
            </button>
            <button
              className="button-error-sm"
              onClick={() => deleteConfirmation(params.row.id)}
            >
              delete
            </button>
          </>
        );
      },
    },
  ];
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    "getJobsHours",
    () => getJobsHours(parseInt(id)),
    { refetchOnMount: true }
  );
  const {
    mutateAsync: doDeleteJobHours,
    isLoading: deleteJobHoursIsLoading,
    isError: deleteJobHoursIsError,
  } = useMutation(deleteJobHours, {
    onSuccess: () => {
      queryClient.invalidateQueries("getJobsHours");
      enqueueSnackbar("Job hours succesfully deleted.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(`There was a problem deleting these job hours.`, {
        variant: "error",
      });
    },
  });
  function deleteConfirmation(jobHoursIdToDelete: number) {
    setJobHoursToDelete(jobHoursIdToDelete);
    setDeleteModalOpen(true);
  }
  async function performDeleteJobHours() {
    if (jobHoursToDelete) {
      await doDeleteJobHours(jobHoursToDelete);
      setDeleteModalOpen(false);
    }
  }
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [jobHoursToEdit, setJobHoursToEdit] = useState<JobsHours>();
  function closeEditModalHandler() {
    setEditModalOpen(false);
  }
  function closeModalHandler() {
    setCreateModalOpen(false);
  }
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [jobHoursToDelete, setJobHoursToDelete] = useState<number>();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [newJobHours, setNewJobHours] = useState<JobsHours>({
    day_of_week: 0,
    description: "",
    status: 0,
    job_id: parseInt(id),
  });
  useEffect(() => {
    setNewJobHours({
      day_of_week: 0,
      description: "",
      status: 0,
      job_id: parseInt(id),
    });
  }, [createModalOpen]);

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Job Hours</h2>
        <button
          className="button-primary"
          onClick={() => setCreateModalOpen(true)}
        >
          Create New Job Hours
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={data?.jobs_hours ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <div>
        <Dialog
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <JobSingleHoursEdit
              closeModal={closeEditModalHandler}
              jobHoursInformation={jobHoursToEdit!}
            ></JobSingleHoursEdit>
          </div>
        </Dialog>
        <Dialog
          open={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <JobSingleHoursCreate
              closeModal={closeModalHandler}
            ></JobSingleHoursCreate>
          </div>
        </Dialog>
        <Dialog
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <h2>Delete job hour</h2>
            <p>Are you sure you want to delete this job hour?</p>

            <div className="button-container justify-end">
              <button
                className="button-outline btn"
                type="button"
                onClick={(e) => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>

              <button
                className="button-error"
                onClick={(e) => {
                  performDeleteJobHours();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
