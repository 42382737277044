import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface MerchantCategory {
  id?: number;
  name?: string;
  priority?: number;
  status?: number;
  icon?: string;
}

interface MerchantCategoryResponse {
  success: boolean;
  merchants_categories: MerchantCategory[];
}
interface GenericResponse {
  success: boolean;
}

export async function getMerchantCategories(): Promise<MerchantCategoryResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/merchants-categories`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getAMerchantCategory(
  merchantCategoryId: number
): Promise<MerchantCategoryResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/merchants-categories/${merchantCategoryId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function createMerchantCategory(
  newMerchantCategory: MerchantCategory
): Promise<GenericResponse> {
  const token = useAuthStore.getState().token;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/merchants-categories`,
    newMerchantCategory,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function updateAMerchantCategory(
  merchantCategoryToUpdate: MerchantCategory
) {
  const token = useAuthStore.getState().token;

  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/merchants-categories`,
    merchantCategoryToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
