import SearchIcon from "@mui/icons-material/Search";
import { Dialog } from "@mui/material";
import { useState } from "react";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export interface ImagePreviewProps {
  imageUrl: string;
  showPlaceHolderDashes?: boolean;
}

export default function ImagePreview(props: ImagePreviewProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  if (
    props.imageUrl == null ||
    props.imageUrl.length == 0 ||
    props.imageUrl == undefined
  ) {
    return (
      <>
        {!props.showPlaceHolderDashes && <></>}
        {props.showPlaceHolderDashes && (
          <>
            <PlaceholderCharacter></PlaceholderCharacter>
          </>
        )}
      </>
    );
  }
  return (
    <div
      className="image-preview-container"
      style={{ marginLeft: "20px" }}
      onMouseDown={(e) => {
        setShowModal(true);
      }}
    >
      <SearchIcon></SearchIcon>

      {showModal && (
        <Dialog open={showModal} onClose={() => setShowModal(false)}>
          <img src={props.imageUrl}></img>
        </Dialog>
      )}
    </div>
  );
}
