import axios, { AxiosResponse } from "axios";

import { useAuthStore } from "../stores/auth.store.zus";
export interface AccessCode {
  id?: number;
  access_account_id?: number;
  code?: string;
  expiration_date?: number | null;
  max_rider_accounts?: number | null;
  nickname?: string | null;
  creation_date?: number;
  created_by_email?: string;
  update_date?: number;
  delete_date?: number;
  delete_reason?: string | null;
  type?: number;
  status?: number;
}
export interface AccessCodeBatchPayload {
  amount: number;
  access_account_id?: number;
  expiration_date?: number | null;
  max_rider_accounts?: number | null;
  nickname?: string | null;
  created_by_email?: string;
  type?: number;
  status?: number;
}

export interface AcessCodeBatchDateRangePayload {
  date_day?: number;
  date_option?: number;
  date_range_start?: string;
  date_range_end?: string;
  access_account_id?: number;
  max_rider_accounts?: number | null;
  nickname?: string | null;
  created_by_email?: string;
  type?: number;
  status?: number;
  expiration_time?: string;
}

export interface AccessCodeResponse {
  success: boolean;
  access_codes: AccessCode[];
}

export interface GeneratedAccessCodeResponse {
  success: boolean;
  code: string;
}

interface CodeHistory {
  creation_date: number;
  number_of_codes: number;
}

export interface AccessCodeHistoryResponse {
  success: boolean;
  code_history: CodeHistory[];
}
export const getAccessCodes = async function (): Promise<AccessCodeResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<AccessCodeResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const checkAccessCodeValidity = async function (
  code: string
): Promise<AccessCodeResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<AccessCodeResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/valid?accessCode=${code}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAnAccessCode = async function (
  codeId: number | undefined
): Promise<AccessCodeResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/${codeId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAnAccessAccountsAccessCodes = async function (
  codeId: number | undefined
): Promise<AccessCodeResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<AccessCodeResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/access-account/${codeId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAnAccessAccountsCodeHistory = async function (
  accessAccountId: number | undefined
): Promise<AccessCodeHistoryResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<AccessCodeHistoryResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/access-account/${accessAccountId}/code-history`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getAnAccessAccountsAccessCodesByCreationDate = async function (
  accessAccountId: number | undefined,
  creationDate: number | undefined
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<AccessCodeResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/access-account/${accessAccountId}/code-history/creation-date/${creationDate}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
};

export const updateAnAccessCode = async function (
  accessCodeToUpdate: AccessCode
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes`,
    accessCodeToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const createAnAccessCode = async function (accessCode: AccessCode) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes`,
    accessCode,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAccessCodeBatch = async function (
  accessCode: AccessCodeBatchPayload
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/batch`,
    accessCode,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAccessCodeBatchDateRange = async function (
  accessCode: AcessCodeBatchDateRangePayload
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/access-codes/batch/dated`,
    accessCode,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const generateAnAccessCode =
  async function (): Promise<GeneratedAccessCodeResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/access-codes/generate`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };
