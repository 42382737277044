import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { AccessAccount, getAccessAccounts } from "../api/accessAccounts.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";
import { Dialog, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AccessAccountSingleCodesCreate from "./AccessAccountSingleCodesCreate";
import SearchIcon from "@mui/icons-material/Search";

export default function AccessAccountsOverview() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 50,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "email",
      flex: 1,
      headerName: "Email",
      renderCell: (params) => {
        return (
          <>
            {params.row.email ? (
              params.row.email
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <StatusOptions status={params.row.status}></StatusOptions>
          </>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.75,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={(e) => {
                navigate(`/dashboard/access-accounts/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
            <button
              onClick={(e) => {
                setActiveRow(params.row);
                setCreateModalOpen(true);
              }}
              className="button-primary-sm"
              style={{ marginLeft: "5px" }}
            >
              Create Code
            </button>
          </div>
        );
      },
    },
  ];
  const {
    data: accessAccountData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getAccessAccounts", getAccessAccounts);

  const [activeRow, setActiveRow] = useState<AccessAccount>();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  function closeCreateModalHandler() {
    setCreateModalOpen(false);
  }
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [accessAccounts, setAccessAccounts] = useState<AccessAccount[]>([]);

  useEffect(() => {
    if (accessAccountData) {
      setAccessAccounts(accessAccountData.access_accounts);
    }
  }, [accessAccountData]);
  useEffect(() => {
    if (!accessAccountData) {
      return;
    }
    let d = accessAccountData?.access_accounts;
    if (searchQuery.length > 0) {
      let searchQueryLowercase = searchQuery.toLocaleLowerCase();
      console.log(searchQueryLowercase);
      d = d.filter((accessAccount) => {
        return (
          accessAccount.name?.toLowerCase().includes(searchQueryLowercase) ||
          accessAccount.email?.toLowerCase().includes(searchQueryLowercase) ||
          accessAccount.address?.toLowerCase().includes(searchQueryLowercase)
        );
      });
    }
    setAccessAccounts(d);
  }, [searchQuery, accessAccountData]);
  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Access Accounts</h2>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "30px 0px",
        }}
      >
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon></SearchIcon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 32,
            required: true,
          }}
          size="small"
          label="Search"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        ></TextField>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/access-accounts/create");
          }}
        >
          Create New Access Account
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={accessAccounts}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <Dialog
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      >
        <div style={{ padding: "40px" }}>
          <AccessAccountSingleCodesCreate
            closeModal={closeCreateModalHandler}
            id={activeRow?.id}
            headingText={activeRow?.name || ""}
          ></AccessAccountSingleCodesCreate>
        </div>
      </Dialog>
    </div>
  );
}
