import InfoIcon from "@mui/icons-material/Info";
import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { CustomReport, getCustomReport } from "../../api/reporting.api";
import { useAuthStore } from "../../stores/auth.store.zus";
import CustomReportJobSelectorInput from "./customReportInputs/JobSelector";
import CustomReportDateSelectorInput from "./customReportInputs/DateSelector";
import CustomReportNumberSelectorInput from "./customReportInputs/NumberSelector";
import CustomReportTextSelectorInput from "./customReportInputs/TextSelector";
import CustomReportDateRangeSelectorInput from "./customReportInputs/DateRangeSelector";

interface CustomReportComponentProps {
  report: CustomReport;
}
interface CustomReportInputValue {
  variable: string;
  value: any;
  type: string;
}

function sanitizeFileNameString(input: string) {
  const sanitized = input
    .trim() // Trim leading and trailing spaces
    .toLowerCase() // Convert the string to lowercase
    .replace(/\//g, "-") // Replace slashes (/) with dashes (-), useful for YYYY/MM/DD
    .replace(/[^a-z0-9-]+/g, "_") // Replace non-alphanumeric and non-dash characters with underscores
    .replace(/_+/g, "_") // Replace multiple underscores with a single one
    .replace(/^-+|-+$/g, "") // Trim leading or trailing dashes
    .replace(/^_+|_+$/g, ""); // Trim leading or trailing underscores

  return sanitized || "default"; // Return sanitized string or "default" if empty
}
export default function CustomReportComponent({
  report,
}: CustomReportComponentProps) {
  const { db } = useAuthStore();
  useEffect(() => {
    if (report.form_params) {
      let arr = report.form_params.map((param) => {
        return {
          variable: param.variable,
          type: param.type,
          value: null,
        };
      });
      setSelectedValues(arr);
    }
  }, [report]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState<
    Array<CustomReportInputValue>
  >([]);
  const handleInput = (value: any, variable: string, type: string) => {
    let replacedWithValuesArray = selectedValues.map((sv) => {
      if (sv.variable == variable) {
        sv.value = value;
        sv.type = type;
      }
      return sv;
    });
    setSelectedValues(replacedWithValuesArray);
  };
  const [fileNameWithValues, setFileNameWithValues] = useState("");

  useEffect(() => {
    if (!report || !report.filename) {
      return;
    }
    let filename = report.filename;
    selectedValues.forEach((sv) => {
      if (sv.value !== undefined && sv.value !== null) {
        if (filename.includes(`$${sv.variable}$`)) {
          filename = filename.replace(`$${sv.variable}$`, sv.value.toString());
        }
      }
    });
    if (filename) {
      setFileNameWithValues(filename);
    }
  }, [report, selectedValues]);
  const { data, status, isLoading, isRefetching, refetch } = useQuery(
    ["getCustomReport", report.id],
    () => getCustomReport({ data: selectedValues, id: report.id }),
    {
      enabled: false,
      retry: false,
      onSuccess: (d) => {
        const url = window.URL.createObjectURL(d);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${sanitizeFileNameString(
          `${db}_${fileNameWithValues || report.name}`
        )}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        enqueueSnackbar("Custom Report generated successfully.", {
          variant: "success",
        });
        setOpenModal(false);
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this report. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <h2 style={{ marginBottom: "20px" }}>
              {report.name}
              <Tooltip
                placement="top"
                title={report.description ? report.description : ""}
                style={{ marginLeft: "10px" }}
              >
                <InfoIcon fontSize="small" color="disabled"></InfoIcon>
              </Tooltip>
            </h2>
            <div className="custom-input-container">
              {report.form_params &&
                report.form_params.map((params, i) => {
                  if (params.type == "job_selector") {
                    return (
                      <CustomReportJobSelectorInput
                        key={i}
                        label={params.form_label}
                        variable={params.variable}
                        type={params.type}
                        job_required={
                          params.job_required ? params.job_required : undefined
                        }
                        jobSelected={handleInput}
                      ></CustomReportJobSelectorInput>
                    );
                  }
                  if (params.type == "date_selector") {
                    return (
                      <CustomReportDateSelectorInput
                        key={i}
                        label={params.form_label}
                        variable={params.variable}
                        type={params.type}
                        defaultValue={params.default_value}
                        dateSelected={handleInput}
                      ></CustomReportDateSelectorInput>
                    );
                  }
                  if (params.type == "date_range_selector") {
                    return (
                      <CustomReportDateRangeSelectorInput
                        key={i}
                        label={params.form_label}
                        variable={params.variable}
                        type={params.type}
                        defaultValue={params.default_value}
                        dateSelected={handleInput}
                      ></CustomReportDateRangeSelectorInput>
                    );
                  }
                  if (params.type == "number_input") {
                    return (
                      <CustomReportNumberSelectorInput
                        key={i}
                        label={params.form_label}
                        variable={params.variable}
                        type={params.type}
                        defaultValue={params.default_value}
                        numberSelected={handleInput}
                      ></CustomReportNumberSelectorInput>
                    );
                  }
                  if (params.type == "text_input") {
                    return (
                      <CustomReportTextSelectorInput
                        key={i}
                        label={params.form_label}
                        variable={params.variable}
                        type={params.type}
                        defaultValue={params.default_value}
                        textSelected={handleInput}
                      ></CustomReportTextSelectorInput>
                    );
                  }
                  return <></>;
                })}
            </div>
            <div
              className="button-container justify-end"
              style={{ marginTop: "40px" }}
            >
              <button
                onClick={() => setOpenModal(false)}
                className="button-outline"
                disabled={isLoading || isRefetching}
              >
                Cancel
              </button>
              <button onClick={() => refetch()} className="button-primary">
                {isLoading || isRefetching ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                ) : (
                  <span className="button-container">Download</span>
                )}
              </button>
            </div>
          </div>
        </Dialog>
        <button
          onClick={() => setOpenModal(true)}
          className="button-primary-sm"
          style={{ padding: "5px 10px" }}
        >
          Generate
        </button>
      </div>
    </div>
  );
}
