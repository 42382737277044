import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getADriversShifts } from "../api/drivers.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import dayjs from "dayjs";

export default function DriversSingleShifts() {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.5,
    },
    {
      field: "job_name",
      headerName: "Job",
      flex: 0.5,
    },
    {
      field: "vehicle_nickname",
      headerName: "Vehicle",
      flex: 1,
      renderCell: (params) => {
        return `${params.row.vehicle_nickname} (${params.row.vehicle_type_name})`;
      },
    },
    {
      field: "started_timestamp",
      headerName: "Started Time",
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.started_timestamp * 1000)
          .tz(
            useSysParamsStore.getState().getParamByName("global_timezone")
              ?.value
          )
          .format("ddd, MMM D, YYYY, h:mm a");
      },
    },
    {
      field: "ended_timestamp",
      headerName: "Ended Time",
      flex: 1,
      renderCell: (params) => {
        if (params.row.ended_timestamp == 0) {
          return <>In Progress</>;
        } else {
          return dayjs(params.row.ended_timestamp * 1000)
            .tz(
              useSysParamsStore.getState().getParamByName("global_timezone")
                ?.value
            )
            .format("ddd, MMM D, YYYY, h:mm a");
        }
      },
    },
    {
      field: "end_timestamp",
      headerName: "Duration",
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.ended_timestamp == 0) {
          return <>In Progress</>;
        } else {
          const duration = Math.max(
            0,
            params.row.ended_timestamp - params.row.started_timestamp
          );
          return (
            <>
              {Math.floor(duration / 3600)}h{" "}
              {Math.floor((duration % 3600) / 60)}m{" "}
            </>
          );
        }
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/shifts/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const { data, status, isLoading, isRefetching } = useQuery(
    "getADriversShifts",
    () => getADriversShifts(parseInt(id))
  );

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {data && (
        <>
          <div className="header-container">
            <h2>Shifts</h2>
          </div>
          <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
            <DataGrid
              rowHeight={40}
              headerHeight={45}
              rows={data?.shifts || []}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        </>
      )}
    </div>
  );
}
