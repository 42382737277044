import { LinearProgress } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAnAccessAccount } from "../api/accessAccounts.api";
import AccessAccountSingleCodes from "./AccessAccountSingleCodes";
import AccessAccountSingleInformation from "./AccessAccountSingleInformation";
import BackButton from "./BackButton";
import AccessAccountSingleCodeHistory from "./AccessAccountSingleCodeHistory";
export default function Jobsingle() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    ["getAnAccessAccount", parseInt(id)],
    () => getAnAccessAccount(parseInt(id)),
    { refetchOnMount: true }
  );

  return (
    <div>
      <BackButton></BackButton>
      {data && (
        <h2 style={{ margin: "20px 0px" }}>
          {data.access_accounts[0].name} - {data.access_accounts[0].address}
        </h2>
      )}
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Information"></Tab>
        <Tab label="Access Codes"></Tab>
        <Tab label="Download CSV"></Tab>
      </Tabs>

      {value == 0 && (
        <AccessAccountSingleInformation></AccessAccountSingleInformation>
      )}
      {value == 1 && <AccessAccountSingleCodes></AccessAccountSingleCodes>}
      {value == 2 && (
        <AccessAccountSingleCodeHistory></AccessAccountSingleCodeHistory>
      )}
    </div>
  );
}
