export const getLastMonthsStartAndEndDate = function () {
  let date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  let firstDayOfLastMonth = new Date(date);
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  let lastDayOfLastMonth = new Date(date);
  return [firstDayOfLastMonth, lastDayOfLastMonth];
};
