import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getVehicles } from "../api/vehicles.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";

export default function VehicleOverview() {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.5,
      //resizable: true,
    },
    {
      field: "nickname",
      headerName: "Nickname",
      flex: 1,
      //resizable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.nickname ? (
              params.row.nickname
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    // {
    //   field: "rider_display_name",
    //   headerName: "Rider Display Name",
    //   flex: 1,
    //   minWidth: 150,
    //   resizable: true,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.rider_display_name ? (
    //           params.row.rider_display_name
    //         ) : (
    //           <PlaceholderCharacter></PlaceholderCharacter>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "tag_number",
    //   headerName: "Tag Number",
    //   flex: 1,
    //   minWidth: 100,
    //   resizable: true,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.tag_number ? (
    //           params.row.tag_number
    //         ) : (
    //           <PlaceholderCharacter></PlaceholderCharacter>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "vin_number",
    //   headerName: "VIN",
    //   flex: 1,
    //   minWidth: 100,
    //   resizable: true,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.vin_number ? (
    //           params.row.vin_number
    //         ) : (
    //           <PlaceholderCharacter></PlaceholderCharacter>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "name",
      headerName: "Vehicle Type",
      flex: 2,
      //resizable: true,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.name ? (
              <span>
                {params.row.name} - {params.row.max_passengers}/
                {params.row.max_passengers_hops}
              </span>
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    {
      field: "internal_number",
      headerName: "Internal Number",
      flex: 1,
      minWidth: 100,
      //resizable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.internal_number == 0 || params.row.internal_number ? (
              params.row.internal_number
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      //resizable: true,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      //resizable: true,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/vehicles/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const {
    data: vehicleData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getVehicles", getVehicles, { refetchOnMount: true });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Vehicles</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/vehicles/create");
          }}
        >
          Create New Vehicle
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={vehicleData.vehicles}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
