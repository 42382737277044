import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { createAFare } from "../api/fares.api";
import { getJobsWithRoute } from "../api/jobs.api";
import { getZones } from "../api/zones.api";
import BackButton from "./BackButton";
import DollarAmountChip from "./utility/DollarAmountChip";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
  width: "200px",
};

interface CreateFare {
  job_id: number;
  pick_up_zone_id: number;
  drop_off_zone_id: number;
  amount: number;
  enabled: number;
}

export default function FareCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync: createFareMutation,
    isLoading: createFareMutationIsLoading,
    isSuccess,
  } = useMutation(createAFare, {
    onSuccess: () => {
      queryClient.invalidateQueries("getFares");
      queryClient.invalidateQueries("getAFare");
      enqueueSnackbar("Fare was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/fares");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this Fare. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const [createFarePayload, setCreateFarePayload] = useState<CreateFare>({
    job_id: 0,
    pick_up_zone_id: 0,
    drop_off_zone_id: 0,
    amount: 100,
    enabled: 1,
  });

  //fetch jobs
  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isRefetching: jobDataIsRefetching,
  } = useQuery("getJobs", getJobsWithRoute, { refetchOnMount: true });

  //fetch zones
  const {
    data: zoneData,
    isLoading: zoneDataIsLoading,
    isRefetching: zoneDataIsRefetching,
  } = useQuery("getZones", getZones, { refetchOnMount: true });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (createFarePayload) {
      await createFareMutation(createFarePayload);
    }
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Fare</h2>
      {jobDataIsLoading && zoneDataIsLoading && <LinearProgress />}
      <div></div>
      {zoneData && jobData && (
        <div className="create-asset-form">
          <form onSubmit={submitHandler} style={{ width: "300px" }}>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              fullWidth
              style={{ margin: "20px 0px" }}
              size="small"
              label="Select a Job"
              placeholder="Select a Job"
              value={createFarePayload.job_id}
              onChange={(e) => {
                setCreateFarePayload({
                  ...createFarePayload,
                  job_id: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>**ANY**</MenuItem>
              {jobData?.jobs.map((j) => {
                return <MenuItem value={j.id}>{j.name}</MenuItem>;
              })}
            </TextField>

            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              fullWidth
              style={{ margin: "20px 0px" }}
              size="small"
              label="Pickup Zone"
              value={createFarePayload.pick_up_zone_id}
              onChange={(e) => {
                setCreateFarePayload({
                  ...createFarePayload,
                  pick_up_zone_id: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>**ANY**</MenuItem>
              {zoneData?.zones.map((z) => {
                return <MenuItem value={z.id}>{z.name}</MenuItem>;
              })}
            </TextField>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              size="small"
              fullWidth
              style={{ margin: "20px 0px" }}
              label="Dropoff Zone"
              value={createFarePayload.drop_off_zone_id}
              onChange={(e) => {
                setCreateFarePayload({
                  ...createFarePayload,
                  drop_off_zone_id: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>**ANY**</MenuItem>
              {zoneData?.zones.map((z) => {
                return <MenuItem value={z.id}>{z.name}</MenuItem>;
              })}
            </TextField>
            <div className="display-flex-center">
              <TextField
                inputProps={{ required: true, min: 0 }}
                required
                size="small"
                style={{ display: "block", width: "200px", margin: "20px 0px" }}
                label="Amount in cents"
                type="text"
                value={createFarePayload.amount}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  let s = inputValue;
                  let r;

                  if (s == "" || s == undefined) {
                    r = 0;
                  } else {
                    r = parseInt(e.target.value);
                  }
                  setCreateFarePayload({
                    ...createFarePayload,
                    amount: r,
                  });
                }}
              ></TextField>
              <span style={{ marginLeft: "20px" }}>
                {createFarePayload.amount != undefined ||
                createFarePayload.amount != null ? (
                  <DollarAmountChip
                    amountCents={createFarePayload.amount}
                  ></DollarAmountChip>
                ) : (
                  // <Chip
                  //   icon={<PaidIcon />}
                  //   label={`${(createFarePayload.amount / 100).toFixed(2)}`}
                  // />
                  ""
                )}
              </span>
            </div>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              size="small"
              sx={inputBlock}
              label="Status"
              value={createFarePayload.enabled}
              onChange={(e) => {
                setCreateFarePayload({
                  ...createFarePayload,
                  enabled: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
            </TextField>

            <button className="button-primary" type="submit">
              Create Fare
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
