import { MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import {
  AccessAccount,
  createAnAccessAccount,
} from "../api/accessAccounts.api";
import BackButton from "./BackButton";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function AccessAccountCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync: createAccessAccountMutation,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAnAccessAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAccessAccounts");
      enqueueSnackbar("Access account was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/access-accounts");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this access account Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  const [createAccessAccountPayload, setCreateAccessAccountPayload] =
    useState<AccessAccount>({
      address: null,
      email: null,
      free_rides_yearly: 100000,
      internal_code: null,
      max_rides_daily: 0,
      name: null,
      rider_address_id: 0,
      status: 0,
    });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    let payload = createAccessAccountPayload;
    if (payload.email?.trim() == "") {
      payload.email = null;
    }
    if (payload.internal_code?.trim() == "") {
      payload.internal_code = null;
    }
    e.preventDefault();
    await createAccessAccountMutation(payload);
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create an Access Account</h2>
      <div className="create-asset-form">
        <form onSubmit={submitHandler}>
          <TextField
            required
            inputProps={{ maxLength: 255, required: true, autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            label="Name"
            value={createAccessAccountPayload.name}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                name: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            inputProps={{ maxLength: 255, required: true, autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            label="Address"
            value={createAccessAccountPayload.address}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                address: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            type="number"
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Rider Address Id"
            value={createAccessAccountPayload.rider_address_id}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                rider_address_id: parseInt(e.target.value),
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ maxLength: 255, autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            label="Email"
            value={createAccessAccountPayload.email}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                email: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            type="number"
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Free Rides Yearly"
            value={createAccessAccountPayload.free_rides_yearly}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                free_rides_yearly: parseInt(e.target.value),
              });
            }}
          ></TextField>
          <TextField
            required
            type="number"
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Max Rides Daily"
            value={createAccessAccountPayload.max_rides_daily}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                max_rides_daily: parseInt(e.target.value),
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            label="Internal Code"
            value={createAccessAccountPayload.internal_code}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                internal_code: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            select
            inputProps={{ required: true }}
            size="small"
            sx={inputBlock}
            label="Status"
            value={createAccessAccountPayload.status}
            onChange={(e) => {
              setCreateAccessAccountPayload({
                ...createAccessAccountPayload,
                status: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
          </TextField>

          <button className="button-primary" type="submit">
            Create Access Account
          </button>
        </form>
      </div>
    </div>
  );
}
