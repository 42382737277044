import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { getCustomReportsList } from "../api/reporting.api";
import CustomReportComponent from "./reporting/CustomReport";
import { LinearProgress } from "@mui/material";

export default function CustomReportsOverview() {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Report Type", flex: 1, minWidth: 100 },
    { field: "description", headerName: "Description", flex: 1, minWidth: 200 },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <CustomReportComponent report={params.row}></CustomReportComponent>
        );
      },
    },
  ];
  const {
    data: customReportdata,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getCustomReportsList", getCustomReportsList, {
    refetchOnMount: true,
  });
  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={customReportdata?.reports || []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
