import { MenuItem, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { queryClient } from "../App";
import { JobZonesRules, editJobZoneRule } from "../api/jobs.api";

interface JobZoneRuleEditProps {
  closeModal: Function;
  jobZoneRule: JobZonesRules;
}

export default function JobZoneRulesSingleEdit(props: JobZoneRuleEditProps) {
  useEffect(() => {
    setJobZoneRule(props.jobZoneRule);
  }, [props.jobZoneRule]);

  const [jobZoneRule, setJobZoneRule] = useState<JobZonesRules>();
  const {
    mutateAsync: updateJobZoneRuleMutation,
    isLoading: isUpdatingJobZoneRuleLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(editJobZoneRule, {
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem updating these job hours. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (jobZoneRule) {
      let payload = {
        id: jobZoneRule.id,
        pick_up_zone_id: jobZoneRule.pick_up_zone_id,
        drop_off_zone_id: jobZoneRule.drop_off_zone_id,
        allowed: jobZoneRule.allowed,
      };
      await updateJobZoneRuleMutation(payload);
      enqueueSnackbar("Succesfully updated job zone rule.", {
        variant: "success",
      });
      queryClient.invalidateQueries("getJobZoneRules");
      queryClient.invalidateQueries("getAJobsZoneRules");
      props.closeModal();
    }
  }
  return (
    <div style={{ maxWidth: "300px" }}>
      {props.jobZoneRule && jobZoneRule && (
        <form onSubmit={submitHandler}>
          <h2>Edit Job Zone Rule</h2>

          <TextField
            label="Pickup Zone"
            fullWidth
            size="small"
            value={jobZoneRule.pick_up_zone_name || "**ANY**"}
            disabled
            style={{ margin: "30px 0px 10px 0px" }}
          ></TextField>
          <TextField
            label="Dropoff Zone"
            fullWidth
            size="small"
            value={jobZoneRule.drop_off_zone_name || "**ANY**"}
            disabled
            style={{ margin: "10px 0px" }}
          ></TextField>
          <TextField
            label="Allowed"
            fullWidth
            select
            size="small"
            value={jobZoneRule.allowed}
            onChange={(e) =>
              setJobZoneRule({
                ...jobZoneRule,
                allowed: parseInt(e.target.value),
              })
            }
            style={{ margin: "10px 0px" }}
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
          </TextField>
          <div
            className="button-container"
            style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
          >
            <button
              className="button-outline"
              type="button"
              onClick={(e) => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            <button
              disabled={isUpdatingJobZoneRuleLoading}
              type="submit"
              className="button-primary"
            >
              {!isUpdatingJobZoneRuleLoading && <>Save</>}
              {isUpdatingJobZoneRuleLoading && (
                <CircularProgress color="inherit" size={12} />
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
