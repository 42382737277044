import { Alert, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getJobsRelatedSysParams } from "../api/sys_params.api";

export default function JobSingleRelatedSysParams() {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const {
    data: jobRelatedSystemParams,
    isLoading: jobRelatedSystemParamsIsLoading,
    isRefetching: jobRelatedSystemParamsIsRefetching,
  } = useQuery(
    "getJobRelatedSystemParams",
    () => getJobsRelatedSysParams(parseInt(id)),
    {
      refetchOnMount: true,
    }
  );
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 250 },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   flex: 1.5,
    //   minWidth: 200,
    // },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "update_date",
    //   headerName: "Update Date",
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.update_date == 0 && (
    //           <PlaceholderCharacter></PlaceholderCharacter>
    //         )}
    //         {params.row.update_date != 0 && (
    //           <>{new Date(params.row.update_date * 1000).toLocaleString()}</>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "context",
    //   headerName: "Context",
    //   flex: 0.75,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return SystemParameterContext(params.row.context);
    //   },
    // },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/system-parameters/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  if (jobRelatedSystemParamsIsLoading || jobRelatedSystemParamsIsRefetching) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <>
      {!jobRelatedSystemParams?.sys_params ||
        (jobRelatedSystemParams?.sys_params.length == 0 && (
          <Alert severity="info">No related system parameters found.</Alert>
        ))}
      {jobRelatedSystemParams?.sys_params &&
        jobRelatedSystemParams?.sys_params.length > 0 && (
          <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
            <DataGrid
              rowHeight={40}
              headerHeight={45}
              rows={jobRelatedSystemParams.sys_params}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        )}
    </>
  );
}
