import {
  Alert,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { BlockPicker } from "react-color";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { Route, createARoute } from "../api/routes.api";
import BackButton from "./BackButton";
import StaticMapPreview from "./StaticMapPreview";
import * as turf from "@turf/turf";
import { LineString } from "geojson";
import {
  coordinatesToPolygonString,
  isValidPolygonString,
  kmlStringToGeoJson,
} from "../utility/geoUtils";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function RouteCreate() {
  const navigate = useNavigate();
  const {
    mutateAsync: createRoute,
    isLoading: isRouteSaving,
    isSuccess,
  } = useMutation(createARoute, {
    onSuccess: () => {
      queryClient.invalidateQueries("getRoutes");
      queryClient.invalidateQueries("getARoute");
      enqueueSnackbar("Route was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/routes");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this route. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });
  const [selectedRadio, setSelectedRadio] = useState<string>("kml");
  const kmlInput = useRef<HTMLInputElement>();
  const [kmlUpdateError, setKmlUpdateError] = useState<boolean>(false);
  const [isPolyStringValid, setIsPolyStringValid] = useState<boolean>(false);

  function radioSelectHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setIsPolyStringValid(false);
    setCreateRoutePayload({
      ...createRoutePayload,
      polygon: "",
    });
    setSelectedRadio(e.target.value);
  }
  async function kmlInputHandler() {
    try {
      //Check File Exists
      setKmlUpdateError(false);
      setIsPolyStringValid(false);
      if (!kmlInput.current?.files) {
        throw `No File attached.`;
      }
      //Check File name contains .kml
      let fileName = kmlInput.current?.files![0];
      if (fileName.name.match(/.kml/) == null) {
        setKmlUpdateError(true);
        throw `Incorrect file type.`;
      }
      let file = kmlInput.current?.files![0] as Blob;

      if (file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              if (reader.result) {
                const kmlGeoJson = kmlStringToGeoJson(reader.result as string);

                let firstLineString: LineString | null = null;
                turf.flattenEach(kmlGeoJson, (feature) => {
                  if (feature.geometry.type === "LineString") {
                    firstLineString = feature.geometry;
                    return false; // break out of flattenEach
                  }
                });

                if (!firstLineString) {
                  throw new Error("Object must contain a LineString");
                }

                let isSufficientlySmallCoordinateString = false;
                let outputString = "";
                let tolerance = 0.000005;

                while (isSufficientlySmallCoordinateString === false) {
                  let simplified = turf.simplify(
                    firstLineString as LineString,
                    {
                      tolerance: tolerance,
                      highQuality: false,
                    }
                  );

                  let simplifiedCoordinateString = coordinatesToPolygonString(
                    simplified.coordinates
                  );

                  if (simplifiedCoordinateString.length < 65535) {
                    isSufficientlySmallCoordinateString = true;
                    outputString = simplifiedCoordinateString;
                  } else {
                    tolerance *= 2;
                    if (tolerance > 1) {
                      throw new Error(
                        "Escaping while loop, try a different polygon string."
                      );
                    }
                  }
                }

                if (!isValidPolygonString(outputString)) {
                  throw new Error("Invalid Polygon String.");
                }

                setCreateRoutePayload({
                  ...createRoutePayload,
                  polygon: outputString,
                });
                setIsPolyStringValid(true);
              }
            } catch (error) {
              console.log(error);
              setKmlUpdateError(true);
            }
          },
          false
        );
        reader.readAsText(file);
      }
    } catch (error) {
      setKmlUpdateError(true);
    }
  }

  const [createRoutePayload, setCreateRoutePayload] = useState<Route>({
    name: "",
    description: "",
    redirect_description: "",
    auto_hide: 1,
    color: "#000000",
    text_color: "#FFFFFF",
    polygon: "",
    prediction_depth: 0,
    priority: 0,
    status: 0,
    type: 0,
  });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await createRoute(createRoutePayload);
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Route</h2>
      <div className="create-asset-form" style={{ width: "300px" }}>
        <form onSubmit={submitHandler}>
          <TextField
            inputProps={{ autoComplete: "off", required: true }}
            sx={inputBlock}
            size="small"
            required
            select
            fullWidth
            value={createRoutePayload.type}
            label="Type"
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                type: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Fixed</MenuItem>
            <MenuItem value={1}>Deviated</MenuItem>
          </TextField>
          <TextField
            required
            inputProps={{ autoComplete: "off", required: true, maxLength: 255 }}
            sx={inputBlock}
            size="small"
            label="Name"
            fullWidth
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                name: e.target.value,
              });
            }}
          ></TextField>

          <TextField
            inputProps={{ autoComplete: "off", maxLength: 1024 }}
            sx={inputBlock}
            size="small"
            label="Description"
            fullWidth
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                description: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ autoComplete: "off", maxLength: 255 }}
            sx={inputBlock}
            size="small"
            label="Redirect Description"
            fullWidth
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                redirect_description: e.target.value,
              });
            }}
          ></TextField>
          {/* CUSTOM POLYGON RADIO OPTIONS */}
          <FormLabel id="zone-definition-radio-buttons-group">
            Route Definition
          </FormLabel>
          <RadioGroup
            aria-labelledby="zone-definition-buttons-group-label"
            value={selectedRadio}
            name="radio-buttons-group"
            onChange={radioSelectHandler}
          >
            <FormControlLabel
              value="kml"
              control={<Radio />}
              label="KML Upload"
            />
            <FormControlLabel
              value="coordinateString"
              control={<Radio />}
              label="Coordinate String"
            />
          </RadioGroup>
          {selectedRadio == "coordinateString" && (
            <div>
              <div className="display-flex-center">
                <TextField
                  sx={inputBlock}
                  required
                  inputProps={{ autoComplete: "off", required: true }}
                  size="small"
                  label="Polygon"
                  fullWidth
                  error={!isValidPolygonString(createRoutePayload.polygon!)}
                  onChange={(e) => {
                    setCreateRoutePayload({
                      ...createRoutePayload,
                      polygon: e.target.value,
                    });
                  }}
                ></TextField>
                {isPolyStringValid && (
                  <StaticMapPreview
                    polygonString={createRoutePayload.polygon!}
                  ></StaticMapPreview>
                )}
              </div>

              {!isPolyStringValid && (
                <Alert severity="error">
                  Invalid Polygon string formatting.
                </Alert>
              )}
            </div>
          )}
          {selectedRadio == "kml" && (
            <div>
              <div className="display-flex-center">
                <TextField
                  type="file"
                  sx={inputBlock}
                  error={!isPolyStringValid}
                  onChange={(e) => {
                    kmlInputHandler();
                  }}
                  inputRef={kmlInput}
                ></TextField>
                {isPolyStringValid && (
                  <StaticMapPreview
                    polygonString={createRoutePayload.polygon!}
                  ></StaticMapPreview>
                )}
              </div>
              {kmlUpdateError && (
                <Alert severity="error">
                  There was a problem reading this file.
                </Alert>
              )}
              {createRoutePayload.polygon && !kmlUpdateError && (
                <Alert severity="success">Succesfully uploaded file.</Alert>
              )}
            </div>
          )}
          <h4>Color</h4>

          <BlockPicker
            color={createRoutePayload.color}
            triangle="hide"
            colors={[
              "#E74C3C",
              "#3498DB",
              "#2ECC71",
              "#F1C40F",
              "#E67E22",
              "#9B59B6",
              "#8E664D",
              "#E84393",
              "#2C3E50",
              "#FFFFFF",
            ]}
            onChangeComplete={(c, e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                color: c.hex,
              });
            }}
          ></BlockPicker>
          <h4>Text Color</h4>

          <BlockPicker
            color={createRoutePayload.text_color}
            triangle="hide"
            colors={[
              "#E74C3C",
              "#3498DB",
              "#2ECC71",
              "#F1C40F",
              "#E67E22",
              "#9B59B6",
              "#8E664D",
              "#E84393",
              "#2C3E50",
              "#FFFFFF",
            ]}
            onChangeComplete={(c, e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                text_color: c.hex,
              });
            }}
          ></BlockPicker>
          <TextField
            inputProps={{
              autoComplete: "off",
              required: true,
              min: 0,
              max: 255,
            }}
            sx={inputBlock}
            size="small"
            type="number"
            required
            fullWidth
            value={createRoutePayload.prediction_depth}
            label="Prediction Depth"
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                prediction_depth: parseInt(e.target.value),
              });
            }}
          ></TextField>

          <TextField
            inputProps={{ autoComplete: "off", required: true }}
            sx={inputBlock}
            size="small"
            required
            select
            fullWidth
            value={createRoutePayload.auto_hide}
            label="Auto Hide"
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                auto_hide: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
          </TextField>
          <TextField
            inputProps={{
              autoComplete: "off",
              required: true,
              min: 0,
              max: 255,
            }}
            fullWidth
            sx={inputBlock}
            type="number"
            size="small"
            required
            value={createRoutePayload.priority}
            label="Priority"
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                priority: parseInt(e.target.value),
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ autoComplete: "off", required: true }}
            sx={inputBlock}
            size="small"
            required
            select
            value={createRoutePayload.status}
            label="Status"
            fullWidth
            onChange={(e) => {
              setCreateRoutePayload({
                ...createRoutePayload,
                status: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
            <MenuItem value={2}>Hidden</MenuItem>
          </TextField>
          <button
            className="button-primary"
            type="submit"
            disabled={!createRoutePayload.name}
          >
            Create Route
          </button>
        </form>
      </div>
    </div>
  );
}
