import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
export interface JobCategory {
  id?: number;
  name?: string;
  priority?: number;
}

interface JobCategoryResponse {
  success: boolean;
  jobs_categories: JobCategory[];
}

export const getJobCategories =
  async function (): Promise<JobCategoryResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get<JobCategoryResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/jobs-categories`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };

export const getAJobCategory = async function (
  jobId: number | undefined
): Promise<JobCategoryResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-categories/${jobId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const updateAJobCategory = async function (
  jobCategoryFieldsToUpdate: JobCategory
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-categories`,
    jobCategoryFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAJobCategory = async function (jobCategory: JobCategory) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-categories`,
    jobCategory,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
