import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Chip, CircularProgress, Dialog, Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import {
  JobZonesRules,
  deleteJobZoneRule,
  getJobZoneRulesTransformed,
} from "../api/jobs.api";
import JobZoneRuleCreate from "./JobZoneRuleCreate";
import JobZoneRulesSingleEdit from "./JobZoneRulesSingleEdit";
import StaticMapPreviewModal from "./StaticMapPreviewModal";

export default function JobZoneRules() {
  const { id } = useParams() as { id: string };
  const columns: GridColDef[] = [
    {
      field: "pick_up_zone_name",
      headerName: "Pickup Zone",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.pick_up_zone_id == "0" ? (
              "**ANY**"
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {params.row.pick_up_zone_name}{" "}
                <div style={{ marginTop: 8 }}>
                  <StaticMapPreviewModal
                    polygonString={params.row.pick_up_zone_polygon}
                  ></StaticMapPreviewModal>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "direction",
    //   headerName: "Direction",
    //   flex: 0.25,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row.pick_up_zone_id == params.row.drop_off_zone_id && (
    //           <CompareArrowsIcon></CompareArrowsIcon>
    //         )}
    //         {params.row.direction == 0 &&
    //           params.row.pick_up_zone_id != params.row.drop_off_zone_id && (
    //             <ArrowForwardIcon></ArrowForwardIcon>
    //           )}
    //         {params.row.direction == 1 && (
    //           <CompareArrowsIcon></CompareArrowsIcon>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "drop_off_zone_name",
      headerName: "Dropoff Zone",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.drop_off_zone_id == "0" ? (
              "**ANY**"
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {params.row.drop_off_zone_name}{" "}
                <div style={{ marginTop: 8 }}>
                  <StaticMapPreviewModal
                    polygonString={params.row.drop_off_zone_polygon}
                  ></StaticMapPreviewModal>
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: "allowed",
      headerName: "Allowed",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.allowed == 0 && (
              <Stack direction="row" spacing={1}>
                <Chip size="small" icon={<NotInterestedIcon />} label="No" />
              </Stack>
            )}
            {params.row.allowed == 1 && (
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  color="success"
                  icon={<CheckCircleIcon />}
                  label="Yes"
                />
              </Stack>
            )}
          </>
        );
      },
    },
    {
      field: "",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <button
              className="button-primary-sm"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setEditModalOpen(true);
                setJobZoneRuleToEdit(params.row);
              }}
            >
              edit
            </button>
            <button
              className="button-error-sm"
              onClick={() => {
                setDeleteModalOpen(true);
                setJobToDelete(params.row.id);
              }}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];
  let { data: jobZoneRulesData, isLoading } = useQuery(
    "getAJobsZoneRules",
    () => getJobZoneRulesTransformed(parseInt(id)),
    { refetchOnMount: true }
  );
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  function closeModalHandler() {
    setCreateModalOpen(false);
  }
  const {
    mutateAsync: deleteAJobZoneRule,
    isLoading: isDeleteAJobZoneRuleLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(deleteJobZoneRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAJobsZoneRules");
      setDeleteModalOpen(false);
      enqueueSnackbar("Succesfully delete this job zone rule..", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      setDeleteModalOpen(false);
      enqueueSnackbar(
        `There was a problem deleting this job zone rule. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  async function performDeleteJobZone(id: number) {
    await deleteAJobZoneRule(id);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  function closeEditModalHandler() {
    setEditModalOpen(false);
  }

  const [jobToDelete, setJobToDelete] = useState<number>();
  const [jobZoneRuleToEdit, setJobZoneRuleToEdit] = useState<JobZonesRules>();

  return (
    <div>
      <div className="header-container">
        <h2>Job Zone Rules</h2>
        <button
          className="button-primary"
          onClick={() => {
            setCreateModalOpen(true);
          }}
        >
          Create New Job Zone Rule
        </button>
      </div>
      {jobZoneRulesData && (
        <div style={{ height: 400, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={jobZoneRulesData.jobs_zones_rules}
            getRowId={(row) => `${row.id}`}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}
      <Dialog
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
        maxWidth={"md"}
        fullWidth
      >
        <div style={{ padding: "40px" }}>
          <JobZoneRuleCreate closeModal={closeModalHandler}></JobZoneRuleCreate>
        </div>
      </Dialog>
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "40px" }}>
          <h2>Job Zone Rule</h2>
          <p>Are you sure you want to delete this job zone rule?</p>
          <div
            className="button-container"
            style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
          >
            <button
              className="button-outline btn"
              type="button"
              onClick={(e) => {
                setDeleteModalOpen(false);
              }}
            >
              Cancel
            </button>

            <button
              className="button-error"
              disabled={isDeleteAJobZoneRuleLoading}
              onClick={(e) => {
                if (jobToDelete) {
                  performDeleteJobZone(jobToDelete);
                }
              }}
            >
              {isDeleteAJobZoneRuleLoading ? (
                <CircularProgress
                  color="inherit"
                  size={12}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                />
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
      >
        <div style={{ padding: "40px" }}>
          <JobZoneRulesSingleEdit
            closeModal={closeEditModalHandler}
            jobZoneRule={jobZoneRuleToEdit!}
          ></JobZoneRulesSingleEdit>
        </div>
      </Dialog>
    </div>
  );
}
