import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { SystemParams, getSystemParams } from "../api/sys_params.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import SystemParameterContext from "./utility/SystemParameterContext";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function SystemParametersOverview() {
  const {
    data: systemParamsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getSystemParams", getSystemParams, { refetchOnMount: true });

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchValueQuery, setSearchValueQuery] = useState<string>("");

  const [systemParams, setSystemParams] = useState<SystemParams[]>([]);
  const [contextFilter, setContextFilter] = useState<number>();

  useEffect(() => {
    if (systemParamsData) {
      setSystemParams(systemParamsData.sys_params);
    }
  }, [systemParamsData]);
  //Filter handler
  useEffect(() => {
    if (!systemParamsData) {
      return;
    }
    let d = systemParamsData?.sys_params;
    if (contextFilter != null && contextFilter < 99) {
      d = d.filter((systemParam) => {
        return systemParam.context == contextFilter;
      });
    }
    if (searchQuery.length > 0) {
      d = d.filter((systemParam) => {
        return systemParam
          .name!.toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase());
      });
    }
    if (searchValueQuery.length > 0) {
      console.log(searchValueQuery);
      d = d.filter((systemParam) => {
        return systemParam
          .value!.toLocaleLowerCase()
          .includes(searchValueQuery.toLocaleLowerCase());
      });
    }
    setSystemParams(d);
  }, [contextFilter, searchQuery, searchValueQuery]);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", flex: 0.3, minWidth: 100 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 250 },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   flex: 0.2,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return SystemParameterTypes(params.row.type);
    //   },
    // },
    // {
    //   field: "unit",
    //   headerName: "Unit",
    //   flex: 0.2,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return SystemParameterUnits(params.row.unit);
    //   },
    // },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "update_date",
      headerName: "Update Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.update_date == 0 && (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
            {params.row.update_date != 0 && (
              <>{new Date(params.row.update_date * 1000).toLocaleString()}</>
            )}
          </>
        );
      },
    },
    {
      field: "context",
      headerName: "Context",
      flex: 0.75,
      minWidth: 150,
      renderCell: (params) => {
        return SystemParameterContext(params.row.context);
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/system-parameters/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {systemParams && systemParamsData && (
        <>
          <div className="header-container">
            <h2>System Parameters</h2>
          </div>
          <div className="display-flex-center">
            <TextField
              size="small"
              style={{ width: "200px", marginRight: "20px" }}
              select
              defaultValue={99}
              onChange={(e) => {
                setContextFilter(parseInt(e.target.value));
              }}
              placeholder="Select a context"
              label="Context Filter"
            >
              <MenuItem value={99}>None</MenuItem>
              <MenuItem value={0}>Global</MenuItem>
              <MenuItem value={1}>Server</MenuItem>
              <MenuItem value={2}>Coral/Delegator</MenuItem>
              <MenuItem value={3}>Admin</MenuItem>
              <MenuItem value={4}>Dashboard</MenuItem>
              <MenuItem value={5}>Driver</MenuItem>
              <MenuItem value={6}>Rider</MenuItem>
              <MenuItem value={7}>Monitor</MenuItem>
            </TextField>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon></SearchIcon>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 32,
                required: true,
              }}
              size="small"
              label="Search by Name"
              style={{ marginRight: "20px" }}
              sx={inputBlock}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            ></TextField>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon></SearchIcon>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 32,
                required: true,
              }}
              size="small"
              label="Search by Value"
              sx={inputBlock}
              onChange={(e) => {
                setSearchValueQuery(e.target.value);
              }}
            ></TextField>
          </div>
          <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
            <DataGrid
              // onSelectionModelChange={(e) => {
              //   rowClickHandler(e);
              // }}
              rowHeight={40}
              headerHeight={45}
              rows={systemParams}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        </>
      )}
    </div>
  );
}
