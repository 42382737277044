import { Alert, CircularProgress, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Driver, createADriver } from "../api/drivers.api";
import { queryClient } from "../App";
import BackButton from "./BackButton";
import ImageUploaderCrop from "./utility/ImageUploaderCrop";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function DriverCreate() {
  const navigate = useNavigate();
  const phoneInput = useRef<any | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [createDriverPayload, setCreateDriverPayload] = useState<Driver>({
    status: 0,
    phone_number: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const { mutateAsync: saveNewDriver, isLoading: isSaveNewDriverLoading } =
    useMutation(createADriver, {
      onSuccess: () => {
        queryClient.invalidateQueries("getDrivers");
        enqueueSnackbar("Driver was succesfully created.", {
          variant: "success",
        });
        navigate("/dashboard/drivers");
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this access code. Error: ${
            (error as any).response.data.error
          }`,
          {
            variant: "error",
          }
        );
      },
    });
  function thumbIconHandler(url: string) {
    setCreateDriverPayload({
      ...createDriverPayload,
      thumb: url,
    });
  }
  function emailRegexCheck() {
    if (
      createDriverPayload.email == "" ||
      createDriverPayload.email == undefined
    ) {
      return true;
    }
    if (createDriverPayload.email) {
      let v = !/^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
        createDriverPayload.email
      );
      return v;
    }
  }
  function formComplete() {
    if (
      createDriverPayload.email &&
      createDriverPayload.first_name &&
      createDriverPayload.last_name &&
      createDriverPayload.status != undefined &&
      createDriverPayload.phone_number &&
      isPossiblePhoneNumber(createDriverPayload.phone_number) &&
      !emailRegexCheck()
    ) {
      return true;
    } else return false;
  }
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = createDriverPayload;
    await saveNewDriver(payload);
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Driver</h2>
      <div
        className="create-asset-form"
        style={{ marginTop: "20px", maxWidth: "350px" }}
      >
        <form onSubmit={submitHandler}>
          <TextField
            inputProps={{ required: true }}
            required
            fullWidth
            style={{ margin: "10px 0px" }}
            size="small"
            label="Email"
            error={emailRegexCheck()}
            helperText={
              emailRegexCheck()
                ? "Please enter a properly formatted email address."
                : ""
            }
            value={createDriverPayload.email}
            onChange={(e) => {
              setCreateDriverPayload({
                ...createDriverPayload,
                email: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ required: true }}
            required
            fullWidth
            style={{ margin: "10px 0px" }}
            size="small"
            label="First Name"
            value={createDriverPayload.first_name}
            onChange={(e) => {
              setCreateDriverPayload({
                ...createDriverPayload,
                first_name: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ required: true }}
            required
            fullWidth
            style={{ margin: "10px 0px" }}
            size="small"
            label="Last Name"
            value={createDriverPayload.last_name}
            onChange={(e) => {
              setCreateDriverPayload({
                ...createDriverPayload,
                last_name: e.target.value,
              });
            }}
          ></TextField>
          <PhoneInput
            ref={phoneInput}
            placeholder="Phone Number *"
            value={createDriverPayload.phone_number}
            defaultCountry="US"
            onChange={(e) => {
              setCreateDriverPayload({
                ...createDriverPayload,
                phone_number: e,
              });
            }}
          />
          {createDriverPayload.phone_number &&
            !isPossiblePhoneNumber(createDriverPayload.phone_number) && (
              <Alert severity="warning">Invalid Phone Number</Alert>
            )}
          <TextField
            inputProps={{ required: true }}
            required
            fullWidth
            select
            style={{ margin: "10px 0px" }}
            size="small"
            label="Status"
            value={createDriverPayload.status}
            onChange={(e) => {
              setCreateDriverPayload({
                ...createDriverPayload,
                status: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
          </TextField>
          <ImageUploaderCrop
            labelText="Upload a thumbnail"
            queryKey={"driverCreateThumbnailUrl"}
            success={thumbIconHandler}
          ></ImageUploaderCrop>
          <button
            className="button-primary"
            style={{ margin: "20px 0px" }}
            type="submit"
            disabled={!formComplete() || isSaveNewDriverLoading}
          >
            {isSaveNewDriverLoading ? (
              <CircularProgress
                size="15px"
                style={{ color: "white" }}
              ></CircularProgress>
            ) : (
              <span className="button-container">Create Driver</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
