import { create } from "zustand";
import { getStartOfYesterday } from "../utility/getStartOfYesterday";

interface RideRecapOverviewState {
  jobId: number | undefined;
  rideId: number | string | undefined;
  driverId: number | undefined;
  rideStatus: number[] | undefined;
  rideMode: number[] | undefined;
  createdFrom: number[] | undefined;
  rideException: string | undefined;
  rideRating: number[] | undefined;
  pickupAddress: string | undefined;
  dropoffAddress: string | undefined;
  riderName: string | undefined;
  startDate: Date;
  endDate: Date;
  page: number;
  setJobId: (jobId: number | undefined) => void;
  setRideId: (rideId: number | string | undefined) => void;
  setDriverId: (driverId: number | undefined) => void;
  setRideStatus: (rideStatus: number[] | undefined) => void;
  setRideMode: (rideMode: number[] | undefined) => void;
  setCreatedFrom: (createdFrom: number[] | undefined) => void;
  setRideException: (rideException: string | undefined) => void;
  setRideRating: (rideRating: number[] | undefined) => void;
  setPickupAddress: (pickupAddress: string | undefined) => void;
  setDropoffAddress: (dropoffAddress: string | undefined) => void;
  setRiderName: (riderName: string | undefined) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  setPage: (page: number) => void;
}

export const useRideRecapOverviewStore = create<RideRecapOverviewState>(
  (set, get) => ({
    jobId: 0,
    rideId: "",
    driverId: 0,
    rideStatus: [0, 1, 2, 3, 4, 5, 6, 7],
    rideMode: [9, 9, 9],
    createdFrom: [0, 1, 2, 3],
    rideException: "any",
    rideRating: [9, 9, 9],
    pickupAddress: undefined,
    dropoffAddress: undefined,
    riderName: undefined,
    startDate: getStartOfYesterday(),
    endDate: getStartOfYesterday(),
    page: 0,
    setJobId: (jobId) => set({ jobId }),
    setRideId: (rideId) => set({ rideId }),
    setDriverId: (driverId) => set({ driverId }),
    setRideStatus: (rideStatus) => set({ rideStatus }),
    setRideMode: (rideMode) => set({ rideMode }),
    setCreatedFrom: (createdFrom) => set({ createdFrom }),
    setRideException: (rideException) => set({ rideException }),
    setRideRating: (rideRating) => set({ rideRating }),
    setPickupAddress: (pickupAddress) => set({ pickupAddress }),
    setDropoffAddress: (dropoffAddress) => set({ dropoffAddress }),
    setRiderName: (riderName) => set({ riderName }),
    setStartDate: (startDate) => set({ startDate }),
    setEndDate: (endDate) => set({ endDate }),
    setPage: (page) => set({ page }),
  })
);
