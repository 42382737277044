import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Chip, Stack } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/Visibility";

interface AllowedOptions {
  allowed: number;
}

export default function AllowedOptions(props: AllowedOptions) {
  return (
    <>
      {props.allowed == 0 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<NotInterestedIcon />} label="Not Allowed" />
        </Stack>
      )}
      {props.allowed == 1 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<CheckCircleIcon />}
            label="Allowed"
          />
        </Stack>
      )}
    </>
  );
}
