import { MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BlockPicker } from "react-color";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { createAVehicleType } from "../api/vehicle_types.api";
import BackButton from "./BackButton";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

const defaults = {
  name: "",
  default_color: "#04A451",
  enableAllZones: 1,
  max_passengers: 1,
  max_passengers_hops: 1,
};

interface ICreateVehicleTypePayload {
  name?: string;
  max_passengers?: number;
  max_passengers_hops?: number;
  default_color?: string;
  route_id?: number;
  enableAllZones?: number;
}

export default function VehicleTypeCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAVehicleType, {
    onSuccess: () => {
      queryClient.invalidateQueries("getVehicles");
      queryClient.invalidateQueries("getAVehicles");
      queryClient.invalidateQueries("getVehicleTypes");
      queryClient.invalidateQueries("getAVehicleType");
      enqueueSnackbar("Vehicle was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/vehicle-types");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this vehicle type. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  // useEffect(() => {
  //   setCreateVehicleTypePayload({
  //     default_color: "#04A451",
  //     enableAllZones: 1,
  //     max_passengers: 0,
  //     max_passengers_hops: 0,
  //   });
  // }, []);
  const [createVehicleTypePayload, setCreateVehicleTypePayload] =
    useState<ICreateVehicleTypePayload>(defaults);

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    console.log(createVehicleTypePayload);
    await mutateAsync(createVehicleTypePayload);
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a vehicle type</h2>
      <div className="create-asset-form" style={{ maxWidth: "250px" }}>
        <form onSubmit={submitHandler}>
          <TextField
            required
            inputProps={{ maxLength: 128, autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            fullWidth
            label="Name"
            // error={/[^\w_-]/.test(createVehicleTypePayload.name!)}
            onChange={(e) => {
              setCreateVehicleTypePayload({
                ...createVehicleTypePayload,
                name: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            inputProps={{ min: 1, max: 1000 }}
            size="small"
            sx={inputBlock}
            fullWidth
            type="number"
            label="Max Passengers"
            value={createVehicleTypePayload.max_passengers}
            onChange={(e) => {
              // if (e.target.value.length < 1) {
              //   setCreateVehicleTypePayload({
              //     ...createVehicleTypePayload,
              //     max_passengers: 0,
              //   });
              //   return;
              // }
              setCreateVehicleTypePayload({
                ...createVehicleTypePayload,
                max_passengers: parseInt(e.target.value),
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            required
            inputProps={{ min: 1, max: 1000 }}
            size="small"
            sx={inputBlock}
            fullWidth
            type="number"
            label="Walk-on Passengers"
            value={createVehicleTypePayload.max_passengers_hops}
            onChange={(e) => {
              setCreateVehicleTypePayload({
                ...createVehicleTypePayload,
                max_passengers_hops: parseInt(e.target.value),
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            inputProps={{ required: true }}
            sx={inputBlock}
            fullWidth
            size="small"
            label="Default Zone Rules"
            select
            defaultValue={1}
            onChange={(e) =>
              setCreateVehicleTypePayload({
                ...createVehicleTypePayload,
                enableAllZones: parseInt(e.target.value),
              })
            }
          >
            <MenuItem value={1}>Enable - Any Zone to Any Zone</MenuItem>
            <MenuItem value={0}>Disable - Any Zone to Any Zone</MenuItem>
          </TextField>
          <h4>Default Color</h4>
          <BlockPicker
            color={createVehicleTypePayload.default_color}
            triangle="hide"
            colors={[
              "#E74C3C",
              "#3498DB",
              "#2ECC71",
              "#F1C40F",
              "#E67E22",
              "#9B59B6",
              "#8E664D",
              "#E84393",
              "#2C3E50",
              "#FFFFFF",
            ]}
            onChangeComplete={(c, e) => {
              setCreateVehicleTypePayload({
                ...createVehicleTypePayload,
                default_color: c.hex,
              });
            }}
          ></BlockPicker>

          <button
            className="button-primary"
            style={{ margin: "20px 0px" }}
            type="submit"
            disabled={createVehicleTypePayload.name!.length <= 0}
          >
            Create Vehicle Type
          </button>
        </form>
      </div>
    </div>
  );
}
