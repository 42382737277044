import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import BackButton from "./BackButton";
import JobSingleHours from "./JobSingleHours";
import JobSingleInformation from "./JobSingleInformation";
import JobZoneRules from "./JobZoneRules";
import { getAJobWithRoute } from "../api/jobs.api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import JobSingleRelatedSysParams from "./JobSingleRelatedSysParams";
export default function Jobsingle() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    "getJobs",
    () => getAJobWithRoute(parseInt(id)),
    { refetchOnMount: true }
  );
  if (isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {data?.jobs && (
        <>
          <BackButton></BackButton>
          <h2 style={{ margin: "20px 0px" }}>{data.jobs[0].name}</h2>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{ marginBottom: "20px" }}
          >
            <Tab label="Job Information"></Tab>
            <Tab label="Job Hours"></Tab>
            <Tab label="Job Zone Rules"></Tab>
            <Tab label="Related System Params"></Tab>
          </Tabs>

          {value == 0 && <JobSingleInformation></JobSingleInformation>}
          {value == 1 && <JobSingleHours></JobSingleHours>}
          {value == 2 && <JobZoneRules></JobZoneRules>}
          {value == 3 && (
            <JobSingleRelatedSysParams></JobSingleRelatedSysParams>
          )}
        </>
      )}
    </div>
  );
}
