import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { GetRidesFilteredInterface } from "../components/utility/RidesFiltered";

interface RideSingle {
  id: string;
  creation_date: number;
  created_from: number;
  mode: number;
  pickup_name: string;
  pickup_lat: number;
  pickup_lon: number;
  dropoff_name: string;
  dropoff_lat: number;
  dropoff_lon: number;
  passengers: number;
  status: number;
  job_name: string;
  driver_id: number;
  driver_first_name: string;
  driver_last_name: string;
  vehicle_nickname: string;
  vehicle_type_name: string;
  rider_id: number;
  rider_first_name: string;
  rider_last_name: string;
  ride_rating?: number;
  feedback_comment?: string;
  job_color?: string;
  job_text_color?: string;
  original_eta?: number;
  otp_time?: number;
}
interface RideSingleResponse {
  success: boolean;
  page: number;
  pageSize: number;
  total: number;
  rides: RideSingle[];
}

export const getRidesFiltered = async function (
  payload: GetRidesFilteredInterface
): Promise<RideSingleResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/rides`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getARide = async function (
  id: number | undefined
): Promise<RideSingleResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/rides/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
