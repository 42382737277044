import { create } from "zustand";
import { Zone } from "../api/zones.api";

interface ZoneState {
  id: number | undefined;
  name: string | undefined;
  status: number | undefined;
  redirect_merchant_id: number | undefined;
  priority: number | undefined;
  polygon: string | undefined;
  editEnabled: boolean;
  setId: (id: number) => void;
  setName: (name: string) => void;
  setStatus: (status: number) => void;
  setRedirectMerchantId: (redirect_merchant_id: number) => void;
  setPriority: (priority: number) => void;
  setPolygon: (polygonString: string) => void;
  setEditEnabled: (newEditState: boolean) => void;
  clearZoneStore: () => void;
}

export const useZoneStore = create<ZoneState>((set) => ({
  id: undefined,
  name: undefined,
  status: undefined,
  redirect_merchant_id: undefined,
  priority: undefined,
  polygon: undefined,
  editEnabled: false,
  setEditEnabled: (newEditState) =>
    set((state) => ({ editEnabled: newEditState })),
  setId: (newId) => set((state) => ({ id: newId })),
  setName: (newName) => set((state) => ({ name: newName })),
  setStatus: (newStatus) => set((state) => ({ status: newStatus })),
  setRedirectMerchantId: (newRDM) =>
    set((state) => ({ redirect_merchant_id: newRDM })),
  setPriority: (newPriority) => set((state) => ({ priority: newPriority })),
  setPolygon: (newPolygon) => set((state) => ({ polygon: newPolygon })),
  clearZoneStore: () =>
    set(() => ({
      id: undefined,
      name: undefined,
      status: undefined,
      redirect_merchant_id: undefined,
      priority: undefined,
      polygon: undefined,
    })),
}));
