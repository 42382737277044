import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

interface HubRecapKPIBodyParams {
  startDate: string;
  endDate: string;
  jobId: number;
}

interface HubRecapKPIResponse {
  success: boolean;
  kpi: {
    passengers: number;
    rides: number;
    averageRating: number;
    averageWaitSeconds: number;
    percentOnTime: number;
    totalVehicleHours: number;
    totalRevenueHours: number;
    percentTvhOnBreak: number;
    percentVrhIdle: number;
    paxPerVrh: number;
  };
}

interface HubRecapHourlyBodyParams {
  startDate: string;
  endDate: string;
  jobId: number;
}
interface HourlyStat {
  hour: number;
  formatted_hour: string;
  rides: number;
  passengers: number;
  averageWait: number;
  percentageOfRides: number;
  percentageOfVrh: number;
  driversOnline: number;
}
interface HubRecapHourlyResponse {
  success: boolean;
  hubRecapHourlyStats: HourlyStat[];
}

export const getHubRecapKPI = async function (
  hubRecapKPI: HubRecapKPIBodyParams
): Promise<HubRecapKPIResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/hub-recap/kpi`,
    hubRecapKPI,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getHubRecapHourly = async function (
  hubRecapKPI: HubRecapHourlyBodyParams
): Promise<HubRecapHourlyResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/hub-recap/hourly`,
    hubRecapKPI,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
