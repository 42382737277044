import RectangleIcon from "@mui/icons-material/Rectangle";
import { LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

//Add zone rule

//Zone Search
import { enqueueSnackbar } from "notistack";
import { BlockPicker } from "react-color";
import {
  VehicleTypes,
  getAVehicleType,
  updateAVehicleType,
} from "../api/vehicle_types.api";
export default function VehicleTypesSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAVehicleType",
    () => getAVehicleType(parseInt(id)),
    { refetchOnMount: true }
  );

  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAVehicleType,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getVehicles");
        queryClient.invalidateQueries("getAVehicle");
        queryClient.invalidateQueries("getVehicleTypes");
        queryClient.invalidateQueries("getAVehicleType");
        enqueueSnackbar("Vehicle type was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this vehicle type. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    setUpdatedVehicleType({
      ...updatedVehicleType,
      id: parseInt(id),
    });
  }, []);
  const [updatedVehicleType, setUpdatedVehicleType] = useState<VehicleTypes>({
    id: parseInt(id),
  });

  const disableVehicleTypeUpdate = function () {
    if (Object.keys(updatedVehicleType).length > 1) {
      return false;
    }
    if (Object.keys(updatedVehicleType).length <= 1) {
      return true;
    }
    return true;
  };

  async function updateVehicleType() {
    await mutateAsync(updatedVehicleType);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedVehicleType({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <h2>
        Vehicle Type Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateVehicleType}>
          <div className="field-block">
            <span className="label">Name</span>
            {editEnabled ? (
              <TextField
                size="small"
                required
                inputProps={{ maxLength: 255 }}
                defaultValue={data.vehicle_type[0].name}
                placeholder={data.vehicle_type[0].name}
                onChange={(e) =>
                  setUpdatedVehicleType({
                    ...updatedVehicleType,
                    name: e.target.value,
                  })
                }
              ></TextField>
            ) : (
              data.vehicle_type[0].name
            )}
          </div>
          <div className="field-block">
            <span className="label">Max Passengers</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ min: 0, max: 1000 }}
                type="number"
                size="small"
                defaultValue={data.vehicle_type[0].max_passengers}
                placeholder={data.vehicle_type[0].max_passengers}
                onChange={(e) =>
                  setUpdatedVehicleType({
                    ...updatedVehicleType,
                    max_passengers: parseInt(e.target.value),
                  })
                }
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            ) : (
              data.vehicle_type[0].max_passengers
            )}
          </div>
          <div className="field-block">
            <span className="label">Walk-on Passengers</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ min: 0, max: 1000 }}
                type="number"
                size="small"
                defaultValue={data.vehicle_type[0].max_passengers_hops}
                placeholder={data.vehicle_type[0].max_passengers_hops}
                onChange={(e) =>
                  setUpdatedVehicleType({
                    ...updatedVehicleType,
                    max_passengers_hops: parseInt(e.target.value),
                  })
                }
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            ) : (
              data.vehicle_type[0].max_passengers_hops
            )}
          </div>
          <div className="field-block">
            <span className="label">Default Color</span>
            {editEnabled ? (
              <BlockPicker
                triangle="hide"
                color={
                  updatedVehicleType.default_color ||
                  data.vehicle_type[0].default_color
                }
                colors={[
                  "#E74C3C",
                  "#3498DB",
                  "#2ECC71",
                  "#F1C40F",
                  "#E67E22",
                  "#9B59B6",
                  "#8E664D",
                  "#E84393",
                  "#2C3E50",
                  "#FFFFFF",
                ]}
                onChangeComplete={(c, e) => {
                  setUpdatedVehicleType({
                    ...updatedVehicleType,
                    default_color: c.hex,
                  });
                }}
              ></BlockPicker>
            ) : (
              <div>
                <RectangleIcon
                  sx={{
                    color: data.vehicle_type[0].default_color,
                    marginBottom: "-6px",
                  }}
                ></RectangleIcon>
                {data.vehicle_type[0].default_color}
              </div>
            )}
          </div>
          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={disableVehicleTypeUpdate()}
                className="button-primary btn"
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
