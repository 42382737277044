import AndroidIcon from "@mui/icons-material/Android";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Chip, Stack } from "@mui/material";
interface UserRiderCreatedFromOptions {
  status: number;
}

export default function UserRiderCreatedFromOptions(
  props: UserRiderCreatedFromOptions
) {
  return (
    <>
      {props.status == 0 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<DashboardIcon />}
            label="Dashboard"
          />
        </Stack>
      )}
      {props.status == 1 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<PhoneIphoneIcon />} label="iOS Rider App" />
        </Stack>
      )}
      {props.status == 2 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<DirectionsCarIcon />} label="Driver App" />
        </Stack>
      )}
      {props.status == 3 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<AndroidIcon />} label="Android Rider App" />
        </Stack>
      )}
    </>
  );
}
