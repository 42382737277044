import React, { useEffect, useState } from "react";
import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";

export interface StaticMapPreviewPointProps {
  coordinate: Coordinate;
}
export interface Coordinate {
  lat: number;
  lng: number;
}
export default function StaticMapPreviewPoint(
  props: StaticMapPreviewPointProps
) {
  const [showHover, setShowHover] = useState<boolean>(false);
  const [markerPosition, setMarkerPosition] = useState<Coordinate>();

  useEffect(() => {
    if (!props.coordinate) {
      return;
    }

    setMarkerPosition(props.coordinate);
  }, [props.coordinate]);
  if (props.coordinate == null) {
    return <SearchOffIcon></SearchOffIcon>;
  }
  return (
    <div
      style={{ marginLeft: "20px" }}
      onMouseEnter={(e) => {
        setShowHover(true);
      }}
      onMouseLeave={(e) => {
        setShowHover(false);
      }}
    >
      <SearchIcon></SearchIcon>
      {showHover && (
        <div className="hover-image">
          <StaticGoogleMap
            size="500x500"
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          >
            <Marker location={props.coordinate} color="blue"></Marker>
          </StaticGoogleMap>
        </div>
      )}
    </div>
  );
}
