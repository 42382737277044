import PlaceIcon from "@mui/icons-material/Place";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import {
  RouteStop,
  getARoutesRouteStops,
  updateRouteStopSequence,
} from "../api/routesStops.api";
import { NotInterested } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { queryClient } from "../App";

export default function RoutesSingleRoutesStopsSequence() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const {
    data: routeStopsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getRouteStops", () => getARoutesRouteStops(parseInt(id)), {
    refetchOnMount: true,
  });
  //SAVE ROUTE STOP SEQUENCING
  const {
    mutateAsync: saveRouteStopSequence,
    isLoading: isUpdateRouteStopSequenceLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(updateRouteStopSequence, {
    onSuccess: () => {
      enqueueSnackbar("Succesfully updated route stop sequence.", {
        variant: "success",
      });
      queryClient.invalidateQueries("getRouteStops");
      queryClient.invalidateQueries("getARouteStop");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem updating this route stop sequence. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const [routeStopsArray, setRouteStopsArray] = useState<Array<RouteStop>>([]);
  useEffect(() => {
    if (routeStopsData?.routes_stops) {
      setRouteStopsArray(routeStopsData.routes_stops);
    }
  }, [routeStopsData]);

  async function saveRouteStopSequenceHandler() {
    let sanitizedArray = routeStopsArray.map((rs, index) => {
      rs.sequence = index + 1;
      return rs;
    });
    await saveRouteStopSequence(sanitizedArray);
  }

  function onDragEnd(result: any) {
    const newItems = [...routeStopsArray];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setRouteStopsArray(newItems);
  }

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container align-center">
        <h2 style={{ marginBottom: "20px" }}>Change Stop Order</h2>
        <button
          disabled={isUpdateRouteStopSequenceLoading}
          onClick={saveRouteStopSequenceHandler}
          className="button-primary"
        >
          {!isUpdateRouteStopSequenceLoading && <>Save</>}
          {isUpdateRouteStopSequenceLoading && (
            <CircularProgress color="inherit" size={12} />
          )}
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {routeStopsArray?.map((item, index) => (
                <Draggable
                  draggableId={item.id!.toString()}
                  key={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item.status != 1 && (
                        <Alert
                          style={{
                            marginTop: "5px",
                            userSelect: "none",
                            width: "300px",
                          }}
                          color="info"
                          icon={<PlaceIcon></PlaceIcon>}
                        >
                          <AlertTitle>
                            {index + 1} - {item.stop_name}
                          </AlertTitle>
                          <div style={{ fontSize: "12px", margin: "-5px 0px" }}>
                            {item.description ? `${item.description}` : ``}
                          </div>
                        </Alert>
                      )}
                      {item.status == 1 && (
                        <Alert
                          style={{
                            marginTop: "5px",
                            userSelect: "none",
                            width: "300px",
                          }}
                          color="error"
                          icon={<NotInterested></NotInterested>}
                        >
                          <AlertTitle>
                            {index + 1} - {item.stop_name}
                          </AlertTitle>
                          <div style={{ fontSize: "12px", margin: "-5px 0px" }}>
                            {item.description ? `${item.description}` : ``}
                          </div>
                        </Alert>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
