import { Dialog } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";

import { useState } from "react";
import {
  SystemParams,
  SystemParamsHistory,
  getASystemParamsHistory,
  updateASystemParam,
} from "../api/sys_params.api";
import { queryClient } from "../App";
import { enqueueSnackbar } from "notistack";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function SystemParametersSingleHistory() {
  const { id } = useParams() as { id: string };
  const {
    data: systemParamsHistoryData,
    status,
    isLoading,
    isRefetching,
  } = useQuery(
    ["getASystemParamsHistory", parseInt(id)],
    () => getASystemParamsHistory(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateASystemParam,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getASystemParam", parseInt(id)]);
        queryClient.invalidateQueries([
          "getASystemParamsHistory",
          parseInt(id),
        ]);
        queryClient.invalidateQueries("getSystemParams");
        setOpenRestoreModal(false);
        enqueueSnackbar("System Parameter was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        setOpenRestoreModal(false);

        enqueueSnackbar(
          `There was a problem editing this System Parameter. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", flex: 0.3, minWidth: 100 },
    // { field: "name", headerName: "Name", flex: 1, minWidth: 250 },
    // {
    //   field: "old_value",
    //   headerName: "Old Value",
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "new_value",
      headerName: "Value",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.timestamp == 0 && (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
            {params.row.timestamp > 0 &&
              new Date(params.row.timestamp * 1000).toLocaleString()}
          </>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.25,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <button
              disabled={
                params.row.new_value == null || params.row.old_value == null
              }
              onClick={(e) => {
                setOpenRestoreModal(true);
                setRestoreValue(params.row);
              }}
              className="button-primary-sm"
            >
              Restore
            </button>
          </>
        );
      },
    },
  ];

  const [openRestoreModal, setOpenRestoreModal] = useState<boolean>(false);
  const [restoreValue, setRestoreValue] = useState<SystemParamsHistory>();

  async function performRestore() {
    let payload: SystemParams = {
      id: parseInt(id),
      value: restoreValue?.new_value,
    };
    await mutateAsync(payload);
  }
  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>System Parameter History</h2>
      </div>
      {systemParamsHistoryData && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={systemParamsHistoryData.sys_params_history}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}
      <Dialog
        open={openRestoreModal}
        onClose={() => {
          setOpenRestoreModal(false);
        }}
      >
        <div style={{ padding: "40px" }}>
          <h2>Restore System Parameter</h2>
          <p>
            Are you sure you want to restore this system parameter to this
            previous value?
          </p>

          <div className="button-container justify-end">
            <button
              className="button-outline btn"
              type="button"
              onClick={(e) => {
                setOpenRestoreModal(false);
              }}
            >
              Cancel
            </button>

            <button
              className="button-primary"
              onClick={(e) => {
                performRestore();
              }}
            >
              Restore
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
