import {
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { BlockPicker } from "react-color";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { JobWithCancelMessage, createAJob } from "../api/jobs.api";
import { getJobCategories } from "../api/jobs_categories.api";
import { getDeviatedRoutes } from "../api/routes.api";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

const defaults = {
  short_name: "",
  name: "",
  rider_display_name: null,
  driver_default_access_code: null,
  description: "",
  color: "#000000",
  text_color: "#FFFFFF",
  route_id: null,
  category_id: 1,
  status: 1,
  shift_mode: 0,
  hours_mode: 0,
  eta_priority: 100,
  eta_max: null,
  eta_url: "disabled",
  grandfather_access_account_id: null,
  default_job_zone_rules: 0,
};

export default function JobCreateDeviated() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutateAsync: isMutationSaving,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAJob, {
    onSuccess: () => {
      queryClient.invalidateQueries("getJobs");
      queryClient.invalidateQueries("getAJob");
      queryClient.invalidateQueries("getJobCategories");
      queryClient.invalidateQueries("getAJobCategory");

      enqueueSnackbar("Job was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/jobs");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this job. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  const { data: jobCategoriesData, isLoading } = useQuery(
    "getJobCategories",
    getJobCategories,
    { refetchOnMount: true }
  );
  const { data: routesDeviatedData, isLoading: routesDeviatedLoading } =
    useQuery("getDeviatedRoutes", getDeviatedRoutes, { refetchOnMount: true });
  // useEffect(() => {
  //   //Set Defaults
  //   setCreateJobPayload({
  //     ...createJobPayload,
  //     ...defaults,
  //   });
  // }, []);

  const [createJobPayload, setCreateJobPayload] =
    useState<JobWithCancelMessage>(defaults);
  const [etaMaxEnabled, setEtaMaxEnabled] = useState<boolean>(false);

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (createJobPayload.rider_display_name?.trim().length === 0) {
      createJobPayload.rider_display_name = null;
    }
    if (createJobPayload.description?.trim().length === 0) {
      createJobPayload.description = "";
    }
    await isMutationSaving(createJobPayload);
  }
  if (isLoading) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <div className="create-asset-container">
      <h2>Deviated Route Job</h2>
      {routesDeviatedLoading && (
        <LinearProgress style={{ margin: "10px 0px" }}></LinearProgress>
      )}
      {createJobPayload &&
        jobCategoriesData &&
        jobCategoriesData.jobs_categories.length > 0 &&
        routesDeviatedData && (
          <div className="create-asset-form">
            <form onSubmit={submitHandler}>
              <TextField
                inputProps={{ required: true, min: 0 }}
                select
                size="small"
                style={{ width: "300px", marginTop: "20px" }}
                label="Select a route"
                error={routesDeviatedData.routes.length == 0}
                helperText={
                  routesDeviatedData.routes.length == 0
                    ? "Please create a deviated route."
                    : ""
                }
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    route_id: parseInt(e.target.value),
                  });
                }}
              >
                {routesDeviatedData.routes.map((route) => {
                  return (
                    <MenuItem value={route.id}>
                      {route.id} - {route.name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                inputProps={{
                  maxlength: 64,
                  required: true,
                  autoComplete: "off",
                }}
                size="small"
                sx={inputBlock}
                label="Name"
                value={createJobPayload.name}
                helperText="For example, City Deviated Route"
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    name: e.target.value,
                  });
                }}
              ></TextField>
              <TextField
                inputProps={{
                  maxlength: 16,
                  required: true,
                  autoComplete: "off",
                }}
                size="small"
                sx={inputBlock}
                label="Short Name"
                helperText="For example, CITY-DEVIATED"
                value={createJobPayload.short_name}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    short_name: e.target.value,
                  });
                }}
              ></TextField>
              <TextField
                inputProps={{ required: true, min: 0 }}
                size="small"
                style={{ width: "300px" }}
                label="Category"
                select
                value={createJobPayload.category_id}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    category_id: parseInt(e.target.value),
                  });
                }}
              >
                {jobCategoriesData?.jobs_categories.map((jc) => {
                  return (
                    <MenuItem key={jc.id} value={jc.id}>
                      {jc.name}
                    </MenuItem>
                  );
                })}
              </TextField>
              {/* STATUS SELECT */}
              <TextField
                inputProps={{ required: true, min: 0 }}
                select
                size="small"
                sx={inputBlock}
                label="Status"
                value={createJobPayload.status}
                helperText={"All jobs start as disabled"}
                disabled
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    status: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Enabled</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
              </TextField>
              {/* SHIFT MODE SELECT */}
              <TextField
                inputProps={{ required: true, min: 0 }}
                select
                size="small"
                style={{ width: "300px" }}
                label="Shift Type"
                value={createJobPayload.shift_mode}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    shift_mode: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Login/Logout</MenuItem>
                <MenuItem value={1}>Start & End Time</MenuItem>
                <MenuItem value={3}>Scheduled Shifts</MenuItem>
              </TextField>
              <TextField
                inputProps={{ required: true, min: 0, max: 255 }}
                size="small"
                sx={inputBlock}
                label="Priority"
                type="number"
                value={createJobPayload.eta_priority}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    eta_priority: parseInt(e.target.value),
                  });
                }}
              ></TextField>
              <h4>Color</h4>
              <div style={{ margin: "0px 0px 20px 0px" }}>
                <BlockPicker
                  color={createJobPayload.color}
                  triangle="hide"
                  colors={[
                    "#E74C3C",
                    "#3498DB",
                    "#2ECC71",
                    "#F1C40F",
                    "#E67E22",
                    "#9B59B6",
                    "#8E664D",
                    "#E84393",
                    "#2C3E50",
                    "#FFFFFF",
                  ]}
                  onChangeComplete={(c, e) => {
                    setCreateJobPayload({
                      ...createJobPayload,
                      color: c.hex,
                    });
                  }}
                ></BlockPicker>
              </div>
              <h4>Text Color</h4>
              <BlockPicker
                color={createJobPayload.text_color}
                triangle="hide"
                colors={[
                  "#E74C3C",
                  "#3498DB",
                  "#2ECC71",
                  "#F1C40F",
                  "#E67E22",
                  "#9B59B6",
                  "#8E664D",
                  "#E84393",
                  "#2C3E50",
                  "#FFFFFF",
                ]}
                onChangeComplete={(c, e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    text_color: c.hex,
                  });
                }}
              ></BlockPicker>
              <FormGroup style={{ margin: "20px 0px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      value={etaMaxEnabled}
                      onChange={(e) => {
                        setEtaMaxEnabled(e.target.checked);
                        if (!e.target.checked) {
                          setCreateJobPayload({
                            ...createJobPayload,
                            eta_max: null,
                          });
                        }
                      }}
                    />
                  }
                  label="Enable ETA Max"
                />
              </FormGroup>
              {etaMaxEnabled && (
                <TextField
                  inputProps={{ required: true }}
                  size="small"
                  sx={inputBlock}
                  label="ETA Max"
                  type="number"
                  helperText="ETA in seconds. Rides will skip this job if the ETA exceeds this value."
                  value={createJobPayload.eta_max}
                  onChange={(e) => {
                    setCreateJobPayload({
                      ...createJobPayload,
                      eta_max: parseInt(e.target.value),
                    });
                  }}
                ></TextField>
              )}
              {/* <TextField
                inputProps={{ required: true }}
                size="small"
                sx={inputBlock}
                label="ETA Max"
                type="number"
                helperText="Rides will skip this job if the ETA exceeds this value."
                value={createJobPayload.eta_max}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    eta_max: parseInt(e.target.value),
                  });
                }}
              ></TextField> */}
              {/* CREATE THIS URL AFTER THE JOB IS CREATED */}
              <TextField
                inputProps={{ maxlength: 255, required: true }}
                size="small"
                disabled
                sx={inputBlock}
                label="ETA URL"
                value={createJobPayload.eta_url}
                helperText={
                  "ETA URL starts as disabled. ETA URL must be configured by tech team prior to enabling job."
                }
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    eta_url: e.target.value,
                  });
                }}
              ></TextField>
              <TextField
                inputProps={{ required: true }}
                size="small"
                style={{ width: "300px" }}
                label="Initial Zone Rules"
                select
                value={createJobPayload.default_job_zone_rules}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    default_job_zone_rules: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Deny ANY {"<>"} ANY</MenuItem>
                <MenuItem value={1}>Allow ANY {"<>"} ANY</MenuItem>
              </TextField>
              <hr />
              <h2>Optional</h2>
              <TextField
                inputProps={{ maxlength: 255 }}
                size="small"
                sx={inputBlock}
                label="Rider Display Name"
                helperText="The job name when displayed inside of the rider app."
                value={createJobPayload.rider_display_name}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    rider_display_name: e.target.value,
                  });
                }}
              ></TextField>
              <TextField
                inputProps={{ maxlength: 255 }}
                size="small"
                sx={inputBlock}
                label="Job Closed Message"
                fullWidth
                helperText="Message shown to riders when job is closed.  For example, 'Sorry, we are closed. Hours: 9am to 5pm'"
                value={createJobPayload.cancel_message}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    cancel_message: e.target.value,
                  });
                }}
              ></TextField>
              <TextField
                inputProps={{ maxlength: 255 }}
                size="small"
                sx={inputBlock}
                label="Description"
                fullWidth
                helperText="Optional job subtitle inside of the driver app."
                value={createJobPayload.description}
                onChange={(e) => {
                  setCreateJobPayload({
                    ...createJobPayload,
                    description: e.target.value,
                  });
                }}
              ></TextField>

              <button className="button-primary" type="submit">
                Create Job
              </button>
            </form>
          </div>
        )}
    </div>
  );
}
