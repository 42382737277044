import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

interface CustomReportTextSelectorInputProps {
  label?: string;
  variable: string;
  type: string;
  defaultValue?: string;
  textSelected: (value: string, variable: string, type: string) => void;
}

export default function CustomReportTextSelectorInput({
  label,
  textSelected,
  variable,
  type,
  defaultValue,
}: CustomReportTextSelectorInputProps) {
  const initialValue = "";

  const [value, setValue] = useState<string>(defaultValue || initialValue);
  useEffect(() => {
    textSelected(value, variable, type);
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    textSelected(newValue, variable, type);
  };

  return (
    <TextField
      size="small"
      style={{ margin: "20px 0px" }}
      fullWidth
      label={label}
      value={value}
      onChange={handleChange}
    />
  );
}
