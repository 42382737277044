import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getARoutesRouteStops } from "../api/routesStops.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";

export default function RouteStopsPerRoute() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
    },
    {
      field: "route_name",
      headerName: "Route Name",
      flex: 1,
    },
    {
      field: "stop_name",
      headerName: "Stop Name",
      flex: 1,
    },
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return params.row.description ? (
          params.row.description
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "default_travel_time",
      headerName: "Default Travel Time",
      flex: 1,
    },
    {
      field: "default_service_time",
      headerName: "Default Service Time",
      flex: 1,
    },
    {
      field: "optional",
      headerName: "Optional",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/stops/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];

  const {
    data: routeStopsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getRouteStops", () => getARoutesRouteStops(parseInt(id)), {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Route Stops</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/stops/create");
          }}
        >
          Create Route Stop
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={routeStopsData?.routes_stops ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
