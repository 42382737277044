import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
export default function RidesExceptionOptions(props: {
  ride_exception: number | null;
}) {
  const buttonStyle = {
    minWidth: "auto",
    padding: "2px 1px",
    cursor: "default",
    color: "orange",
  };

  return (
    <>
      {props.ride_exception && (
        <Tooltip title={`Ride Exception: ${props.ride_exception}`}>
          <Button style={{ ...buttonStyle }}>
            <WarningIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
