import { LinearProgress, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

//Add zone rule
import Autocomplete from "@mui/material/Autocomplete";
import { getZones } from "../api/zones.api";

//Zone Search
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Chip, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import {
  VehicleTypes,
  createVehicleTypeZoneRule,
  deleteVehicleTypeZoneRule,
  getVehicleTypesZonesRulesTransformed,
} from "../api/vehicle_types.api";
import { VtzrOverviewTableEntry } from "../utility/vehicleTypeZoneRulesOverviewTransform";
import StaticMapPreview from "./StaticMapPreview";
export default function VehicleTypesSingleZoneRules() {
  const columns: GridColDef[] = [
    {
      field: "pick_up_zone_name",
      headerName: "Pickup Zone",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.pick_up_zone_id == "0" ? (
              "**ANY**"
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.row.pick_up_zone_name}{" "}
                <StaticMapPreview
                  polygonString={params.row.pick_up_zone_polygon}
                ></StaticMapPreview>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      flex: 0.25,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.row.pick_up_zone_id == params.row.drop_off_zone_id && (
              <CompareArrowsIcon></CompareArrowsIcon>
            )}
            {params.row.direction == 0 &&
              params.row.pick_up_zone_id != params.row.drop_off_zone_id && (
                <ArrowForwardIcon></ArrowForwardIcon>
              )}
            {params.row.direction == 1 && (
              <CompareArrowsIcon></CompareArrowsIcon>
            )}
          </div>
        );
      },
    },
    {
      field: "drop_off_zone_name",
      headerName: "Dropoff Zone",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.drop_off_zone_id == "0" ? (
              "**ANY**"
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.row.drop_off_zone_name}{" "}
                <StaticMapPreview
                  polygonString={params.row.drop_off_zone_polygon}
                ></StaticMapPreview>
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: "allowed",
      headerName: "Allowed",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.allowed == 0 && (
              <Stack direction="row" spacing={1}>
                <Chip size="small" icon={<NotInterestedIcon />} label="No" />
              </Stack>
            )}
            {params.row.allowed == 1 && (
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  color="success"
                  icon={<CheckCircleIcon />}
                  label="Yes"
                />
              </Stack>
            )}
          </>
        );
      },
    },
    {
      field: "",
      headerName: "Options",
      sortable: false,
      // flex: 1,
      // minWidth: 100,
      renderCell: (params) => {
        return (
          <div>
            <button
              className="button-error-sm"
              onClick={() => deleteConfirmation(params.row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];
  const { id } = useParams() as { id: string };
  const {
    data: vtzrOverviewData,
    status: vtzrOverviewStatus,
    isLoading: vtzrOverviewIsLoading,
    isRefetching: vtzrOverviewIsRefetching,
  } = useQuery(
    "getAVehicleTypesZoneRulesTransformed",
    () => getVehicleTypesZonesRulesTransformed(parseInt(id)),
    { refetchOnMount: true }
  );
  const {
    mutateAsync: saveNewZoneRule,
    isLoading: isNewZoneRuleLoading,
    isError: isNewZoneRuleError,
  } = useMutation(createVehicleTypeZoneRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getVehicles");
      queryClient.invalidateQueries("getAVehicle");
      queryClient.invalidateQueries("getVehicleTypes");
      queryClient.invalidateQueries("getAVehicleType");
      queryClient.invalidateQueries("getAVehicleTypesZoneRules");
      queryClient.invalidateQueries("getAVehicleTypesZoneRulesTransformed");
      enqueueSnackbar("New zone rule succesfully created.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(`There was a problem creating this zone rule.`, {
        variant: "error",
      });
    },
  });
  const {
    mutateAsync: deleteZoneRule,
    isLoading: isDeleteZoneLoading,
    isError: isDeleteZoneError,
  } = useMutation(deleteVehicleTypeZoneRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getVehicles");
      queryClient.invalidateQueries("getAVehicle");
      queryClient.invalidateQueries("getVehicleTypes");
      queryClient.invalidateQueries("getAVehicleType");
      queryClient.invalidateQueries("getAVehicleTypesZoneRules");
      queryClient.invalidateQueries("getAVehicleTypesZoneRulesTransformed");
      enqueueSnackbar("Zone rule succesfully deleted.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(`There was a problem deleting this zone rule.`, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    setUpdatedVehicleType({
      ...updatedVehicleType,
      id: parseInt(id),
    });
  }, []);
  const [updatedVehicleType, setUpdatedVehicleType] = useState<VehicleTypes>({
    id: parseInt(id),
  });

  //Delete functionality
  const [open, setOpen] = React.useState(false);
  const [zoneForDeletion, setZoneForDeletion] =
    useState<VtzrOverviewTableEntry>();

  function deleteConfirmation(payload: VtzrOverviewTableEntry) {
    setZoneForDeletion(payload);
    setOpen(true);
  }
  async function deleteVtzr(payload: VtzrOverviewTableEntry) {
    setOpen(false);
    if (payload.direction == 0) {
      await deleteZoneRule(payload);
    }
    if (payload.direction == 1) {
      let payload1: VtzrOverviewTableEntry = {
        drop_off_zone_id: payload.drop_off_zone_id,
        pick_up_zone_id: payload.pick_up_zone_id,
        allowed: payload.allowed,
        vehicle_type_id: payload.vehicle_type_id,
        drop_off_zone_name: payload.drop_off_zone_name,
        pick_up_zone_name: payload.pick_up_zone_name,
      };
      let payload2: VtzrOverviewTableEntry = {
        drop_off_zone_id: payload.pick_up_zone_id,
        pick_up_zone_id: payload.drop_off_zone_id,
        allowed: payload.allowed,
        vehicle_type_id: payload.vehicle_type_id,
        drop_off_zone_name: payload.drop_off_zone_name,
        pick_up_zone_name: payload.pick_up_zone_name,
      };
      await deleteZoneRule(payload1);
      await deleteZoneRule(payload2);
    }
  }
  //Create a zone
  const disableCreateNewZoneRuleHandler = function () {
    if (
      zoneRulePayload.selectedZoneA == undefined ||
      zoneRulePayload.selectedZoneA == null ||
      zoneRulePayload.selectedZoneB == undefined ||
      zoneRulePayload.selectedZoneB == null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [openCreateZoneRuleModal, setOpenCreateZoneRuleModal] = useState(false);
  const {
    data: zoneData,
    status: zoneStatus,
    isLoading: zoneIsLoading,
    isRefetching: zoneIsRefetching,
  } = useQuery("getZones", () => getZones(), {
    refetchOnMount: true,
  });
  useEffect(() => {
    if (!openCreateZoneRuleModal) {
      setZoneRulePayload({
        selectedZoneA: 0,
        selectedZoneB: 0,
        direction: 2,
        allowed: 1,
      });
    }
  }, [openCreateZoneRuleModal]);
  const [zoneRulePayload, setZoneRulePayload] = useState({
    selectedZoneA: 0,
    selectedZoneB: 0,
    direction: 2,
    allowed: 1,
  });
  async function saveZoneRuleHandler() {
    let payload = {
      vehicle_type_id: parseInt(id),
      allowed: zoneRulePayload.allowed,
      pick_up_zone_id: 0,
      drop_off_zone_id: 0,
    };
    let payload2 = {
      vehicle_type_id: parseInt(id),
      allowed: zoneRulePayload.allowed,
      pick_up_zone_id: 0,
      drop_off_zone_id: 0,
    };
    if (zoneRulePayload.direction == 0) {
      payload.pick_up_zone_id = zoneRulePayload.selectedZoneA;
      payload.drop_off_zone_id = zoneRulePayload.selectedZoneB;
    }
    if (zoneRulePayload.direction == 1) {
      payload.pick_up_zone_id = zoneRulePayload.selectedZoneB;
      payload.drop_off_zone_id = zoneRulePayload.selectedZoneA;
    }
    if (zoneRulePayload.direction == 2) {
      payload.pick_up_zone_id = zoneRulePayload.selectedZoneA;
      payload.drop_off_zone_id = zoneRulePayload.selectedZoneB;
      payload2.pick_up_zone_id = zoneRulePayload.selectedZoneB;
      payload2.drop_off_zone_id = zoneRulePayload.selectedZoneA;
    }
    if (zoneRulePayload.direction != 2) {
      await saveNewZoneRule(payload);
    }
    if (zoneRulePayload.direction == 2) {
      if (
        payload.drop_off_zone_id == payload2.drop_off_zone_id &&
        payload.pick_up_zone_id == payload2.pick_up_zone_id
      ) {
        await saveNewZoneRule(payload);
      }
      if (
        payload.drop_off_zone_id !== payload2.drop_off_zone_id &&
        payload.pick_up_zone_id !== payload2.pick_up_zone_id
      ) {
        await saveNewZoneRule(payload);
        await saveNewZoneRule(payload2);
      }
    }
    setOpenCreateZoneRuleModal(false);
  }
  if (
    isDeleteZoneLoading ||
    isNewZoneRuleLoading ||
    zoneIsLoading ||
    zoneIsRefetching ||
    vtzrOverviewIsLoading ||
    vtzrOverviewIsRefetching
  ) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Vehicle Type Zone Rules </h2>
        <button
          className="button-primary"
          onClick={() => {
            setOpenCreateZoneRuleModal(true);
          }}
        >
          Create new zone rule
        </button>
      </div>
      {vtzrOverviewData && (
        <div style={{ height: 800, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={vtzrOverviewData}
            getRowId={(row) =>
              `${row.vehicle_type_id}${row.pick_up_zone_id}${row.drop_off_zone_id}${row.allowed}`
            }
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this zone rule?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking delete this zone rule will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="button-outline btn"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className="button-error"
            onClick={() => {
              deleteVtzr(zoneForDeletion!);
            }}
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCreateZoneRuleModal}
        onClose={() => {
          setOpenCreateZoneRuleModal(false);
        }}
        maxWidth={"md"}
        fullWidth
      >
        <div style={{ padding: "40px" }}>
          <h2>Create Vehicle Type Zone Rule</h2>

          <div className="create-zone-rule-container">
            <Autocomplete
              disablePortal
              id="zone-a-select"
              onChange={(val, newVal) =>
                setZoneRulePayload({
                  ...zoneRulePayload,
                  selectedZoneA: newVal?.id!,
                })
              }
              defaultValue={{ id: 0, name: "**ANY**" }}
              options={[{ id: 0, name: "**ANY**" }, ...zoneData!.zones]}
              getOptionLabel={(zone) => zone.name || ""}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Pickup Zone" />
              )}
            />
            <TextField
              label="Direction"
              select
              value={zoneRulePayload.direction}
              onChange={(e) => {
                setZoneRulePayload({
                  ...zoneRulePayload,
                  direction: parseInt(e.target.value),
                });
              }}
              sx={{ width: 75 }}
              style={{ margin: "0px 10px" }}
              size="small"
            >
              <MenuItem value={0}>
                <ArrowForwardIcon
                  style={{ fontSize: "20px", margin: "10px 0px 5px 0px" }}
                ></ArrowForwardIcon>
              </MenuItem>
              <MenuItem value={2}>
                <CompareArrowsIcon
                  style={{ fontSize: "20px", margin: "10px 0px 5px 0px" }}
                ></CompareArrowsIcon>
              </MenuItem>
            </TextField>
            <Autocomplete
              disablePortal
              id="zone-b-select"
              onChange={(val, newVal) =>
                setZoneRulePayload({
                  ...zoneRulePayload,
                  selectedZoneB: newVal?.id!,
                })
              }
              defaultValue={{ id: 0, name: "**ANY**" }}
              options={[{ id: 0, name: "**ANY**" }, ...zoneData!.zones]}
              getOptionLabel={(zone) => zone.name || ""}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Dropoff Zone" />
              )}
            />{" "}
            <TextField
              label="Allowed"
              select
              value={zoneRulePayload.allowed}
              onChange={(e) =>
                setZoneRulePayload({
                  ...zoneRulePayload,
                  allowed: parseInt(e.target.value),
                })
              }
              sx={{ width: 150 }}
              style={{ margin: "0px 10px" }}
            >
              <MenuItem value={0}>No</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
            </TextField>
          </div>
          <DialogActions>
            <button
              className="button-outline btn"
              onClick={() => {
                setOpenCreateZoneRuleModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="button-primary"
              onClick={() => {
                saveZoneRuleHandler();
              }}
              disabled={disableCreateNewZoneRuleHandler()}
            >
              Create
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
