import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { useAuthStore } from "../stores/auth.store.zus";
import AccessAccountsOverview from "./AccessAcountsOverview";
import JobsAccessAccountsRulesOverview from "./JobsAccessAccountsRulesOverview";
export default function AccessAccountLanding() {
  const type = useAuthStore((state) => state.accessType);
  const allowableTypes = [255];
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Access Accounts"></Tab>
        {allowableTypes.includes(type) && <Tab label="Job Rules"></Tab>}
      </Tabs>

      {value == 0 && <AccessAccountsOverview></AccessAccountsOverview>}
      {value == 1 && (
        <JobsAccessAccountsRulesOverview></JobsAccessAccountsRulesOverview>
      )}
    </div>
  );
}
