import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { getMerchantsExport } from "../../api/reporting.api";
import { useAuthStore } from "../../stores/auth.store.zus";
import { enqueueSnackbar } from "notistack";
export interface OriginDestinationReportInput {
  startDate: string | null;
  endDate: string | null;
}
export default function ExportMerchantReport() {
  const { db } = useAuthStore();

  const [openModal, setOpenModal] = useState(false);
  const { data, status, isLoading, isRefetching, refetch } = useQuery(
    "getMerchantsExport",
    getMerchantsExport,
    {
      enabled: false,
      onSuccess: (d) => {
        const url = window.URL.createObjectURL(d);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${db}_export_merchant_report.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        enqueueSnackbar("Export Merchant Report generated succesfully.", {
          variant: "success",
        });
        setOpenModal(false);
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this report. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <h2 style={{ marginBottom: "20px" }}>
              Export Merchant Report{" "}
              <Tooltip
                placement="top"
                title="Generate an export merchant report."
                style={{ marginLeft: "10px" }}
              >
                <InfoIcon fontSize="small" color="disabled"></InfoIcon>
              </Tooltip>
            </h2>
            <div
              className="button-container justify-end"
              style={{ marginTop: "40px" }}
            >
              <button
                onClick={() => setOpenModal(false)}
                className="button-outline"
                disabled={isLoading || isRefetching}
              >
                Cancel
              </button>
              <button onClick={() => refetch()} className="button-primary">
                {isLoading || isRefetching ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                ) : (
                  <span className="button-container">
                    Download
                    {/* <FileDownloadIcon fontSize="small"></FileDownloadIcon> */}
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog>
        <button
          onClick={() => setOpenModal(true)}
          className="button-primary-sm"
          style={{ padding: "5px 10px" }}
        >
          Generate
        </button>
      </div>
    </div>
  );
}
