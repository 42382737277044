import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getStops } from "../api/stops.api";
import StaticMapPreviewModalPoint from "./StaticMapPreviewModalPoint";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export default function StopsOverview() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return params.row.description ? (
          params.row.description
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <StaticMapPreviewModalPoint
            coordinate={{ lat: params.row.latitude, lng: params.row.longitude }}
          ></StaticMapPreviewModalPoint>
        );
      },
    },
    // {
    //   field: "latitude",
    //   headerName: "Latitude",
    //   flex: 1,
    // },
    // {
    //   field: "longitude",
    //   headerName: "Longitude",
    //   flex: 1,
    // },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/stops/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: stopsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getStops", getStops, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Stops</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/stops/create");
          }}
        >
          Create Stop
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={stopsData?.stops ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
