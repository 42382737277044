import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Chip, Stack } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
interface ShiftModeOptions {
  shift_mode: number;
}

export default function ShiftModeOptions(props: ShiftModeOptions) {
  return (
    <>
      {props.shift_mode == 0 && (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<CheckIcon />} label="Login/Logout" />
        </Stack>
      )}
      {props.shift_mode == 1 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<AccessTimeIcon />}
            label="Start & End Time"
          />
        </Stack>
      )}
      {props.shift_mode == 2 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="info"
            icon={<VisibilityOffIcon />}
            label="Ghost"
          />
        </Stack>
      )}
      {props.shift_mode == 3 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="info"
            icon={<CalendarMonthIcon />}
            label="Scheduled"
          />
        </Stack>
      )}
    </>
  );
}
