import PlaceholderCharacter from "./PlaceholderCharacter";

interface RideSingleStatusOptions {
  status: number;
}
export default function RideSingleStatusOptions(
  props: RideSingleStatusOptions
) {
  return (
    <>
      {props.status == 0 && <>Unassigned</>}
      {props.status == 1 && <>Pending</>}
      {props.status == 2 && <>On The Way</>}
      {props.status == 3 && <>Near Pickup</>}
      {props.status == 4 && <>At Pickup</>}
      {props.status == 5 && <>On Ride</>}
      {props.status == 6 && <>Completed</>}
      {props.status == 7 && <>Cancelled</>}
      {props.status == undefined ||
        null ||
        (!props.status && <PlaceholderCharacter></PlaceholderCharacter>)}
    </>
  );
}
