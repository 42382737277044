import { Coordinate } from "../components/StaticMapPreview";
import { polygonStringValidation } from "./polygonStringValidation";

export function polygonStringToGoogleMapCoordinates(
  polygonString: string
): Coordinate[] {
  if (!polygonStringValidation(polygonString)) {
    throw "Invalid input string.";
  }
  let s = polygonString.trim().split(" ");
  s.pop(); // Google Polygon Paths are closed automatically; do not repeat the first vertex of the path as the last vertex.
  let polygonCoordinatesArray: Coordinate[] = [];
  s.forEach((p) => {
    let splitAtComma = p.split(",");
    let lat = parseFloat(splitAtComma[0]);
    let lng = parseFloat(splitAtComma[1]);
    polygonCoordinatesArray.push({ lat: lat, lng: lng });
  });
  return polygonCoordinatesArray;
}
