import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import {
  MerchantUpdatePayload,
  getAMerchant,
  updateAMerchant,
} from "../api/merchants.api";
import { getMerchantCategories } from "../api/merchants_categories.api";
import BackButton from "./BackButton";
import StaticMapPreviewPoint from "./StaticMapPreviewPoint";
import StatusOptions from "./utility/StatusOptions";
import ImagePreview from "./ImagePreview";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import FileUploader from "./utility/FileUploader";
export default function MerchantsSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAMerchant",
    () => getAMerchant(parseInt(id)),
    { refetchOnMount: true }
  );
  // INITIALIZE MERCHANT CATEGORIES
  const { data: merchantCategoryData } = useQuery(
    "getMerchantCategories",
    getMerchantCategories,
    {
      refetchOnMount: true,
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);

  const defaultUpdatedMerchant = {
    merchant: {
      id: parseInt(id),
    },
    address: {},
    merchant_categories: [],
  };

  const [updatedMerchant, setUpdatedMerchant] = useState<MerchantUpdatePayload>(
    defaultUpdatedMerchant
  );
  useEffect(() => {
    if (
      !data?.merchants[0].address_longitude ||
      !data?.merchants[0].address_latitude
    ) {
      return;
    }

    setUpdatedMerchant({
      ...updatedMerchant,
      merchant: {
        id: parseInt(id),
        description: data?.merchants[0].description,
        phone_number: data?.merchants[0].phone_number,
        priority: data?.merchants[0].priority,
        status: data?.merchants[0].status,
        subtitle: data?.merchants[0].subtitle,
        website_url: data?.merchants[0].website_url,
        name: data?.merchants[0].name,
      },
      merchant_categories:
        data?.merchants[0].category_ids?.split(",").map((mc) => {
          return parseInt(mc);
        }) || [],
      address: {
        ...updatedMerchant.address,
        name: data?.merchants[0].address_name,
        latitude: parseFloat(data?.merchants[0].address_latitude),
        longitude: parseFloat(data?.merchants[0].address_longitude),
      },
    });
  }, [data, merchantCategoryData, editEnabled]);

  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAMerchant,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAMerchant");
        queryClient.invalidateQueries("getMerchants");
        enqueueSnackbar("Merchant was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this Merchant. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  function merchantCategoriesCheckboxHandler(value: number) {
    let a = updatedMerchant.merchant_categories;
    if (!a) {
      return;
    }
    if (!a.includes(value)) {
      a.push(value);
    } else {
      a.splice(a.indexOf(value), 1);
    }
    setUpdatedMerchant({ ...updatedMerchant, merchant_categories: [...a] });
  }

  async function updateMerchant(e: React.FormEvent<HTMLFormElement>) {
    await mutateAsync(updatedMerchant);
    setEditEnabled(false);
  }

  function logoUploadHandler(url: string) {
    setUpdatedMerchant({
      ...updatedMerchant,
      merchant: {
        ...updatedMerchant.merchant,
        logo: url,
      },
    });
  }
  function imageUploadHandler(url: string) {
    setUpdatedMerchant({
      ...updatedMerchant,
      merchant: {
        ...updatedMerchant.merchant,
        image: url,
      },
    });
  }
  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Merchant Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      {data && merchantCategoryData && (
        <div
          className={
            editEnabled ? "asset-container editmode" : "asset-container"
          }
        >
          <form onSubmit={updateMerchant}>
            <div className="field-block">
              <span className="label">Id</span>
              {data?.merchants[0].id}
            </div>
            <div className="field-block">
              <span className="label">Name</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 128 }}
                  defaultValue={data?.merchants[0].name}
                  onChange={(e) => {
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        name: e.target.value,
                      },
                    });

                    if (
                      data?.merchants[0].address_name!.split(",")[0] ==
                      data?.merchants[0].name
                    ) {
                      let s = data?.merchants[0].address_name!.split(",");
                      s[0] = e.target.value.trim();
                      let j = s.join(",");
                      setUpdatedMerchant({
                        ...updatedMerchant,
                        merchant: {
                          ...updatedMerchant.merchant,
                          name: e.target.value,
                        },
                        address: {
                          ...updatedMerchant.address,
                          name: j,
                        },
                      });
                    }
                  }}
                ></TextField>
              ) : (
                data?.merchants[0].name
              )}
            </div>
            {/* CATEGORIES */}
            {!editEnabled && (
              <div className="field-block">
                <span className="label">Merchant Categories</span>
                {data?.merchants[0].category_names ? (
                  data?.merchants[0].category_names?.split(",").join(", ")
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}
            {editEnabled && (
              <>
                <span className="label" style={{ margin: "20px 0px" }}>
                  Merchant Categories
                </span>
                <div className="paste-this"></div>
                <Box
                  sx={{
                    display: "flex",
                    maxHeight: "300px",
                    margin: "20px 0px 40px 0px",
                  }}
                >
                  <FormGroup style={{ marginRight: "20px" }}>
                    {merchantCategoryData?.merchants_categories.map(
                      (m, index) => {
                        return (
                          <FormControlLabel
                            key={m.id}
                            value={m.id}
                            control={
                              <Checkbox
                                disabled={!editEnabled}
                                defaultChecked={data?.merchants[0].category_ids
                                  ?.split(",")
                                  .map((mc) => {
                                    return parseInt(mc);
                                  })
                                  .includes(m.id!)}
                                onChange={(e) => {
                                  merchantCategoriesCheckboxHandler(
                                    parseInt(e.target.value)
                                  );
                                }}
                              />
                            }
                            label={m.name}
                          />
                        );
                      }
                    )}
                  </FormGroup>
                </Box>
              </>
            )}

            {editEnabled && <hr style={{ margin: "40px 0px" }} />}
            <div className="field-block">
              <span className="label">Address</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  disabled
                  inputProps={{ maxLength: 255 }}
                  value={
                    updatedMerchant.address.name ||
                    data?.merchants[0].address_name
                  }
                ></TextField>
              ) : (
                <span className="display-flex-center">
                  {data?.merchants[0].address_name}{" "}
                  <StaticMapPreviewPoint
                    coordinate={{
                      lat: parseFloat(data?.merchants[0].address_latitude!),
                      lng: parseFloat(data?.merchants[0].address_longitude!),
                    }}
                  ></StaticMapPreviewPoint>
                </span>
              )}
            </div>
            <div className="field-block">
              <span className="label">Latitude</span>
              {editEnabled ? (
                <TextField
                  required
                  inputProps={{ min: -90.0, max: 90.0, step: "any" }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  size="small"
                  style={{ width: "210px" }}
                  type="number"
                  value={updatedMerchant.address.latitude}
                  placeholder={data?.merchants[0].address_latitude}
                  onChange={(e) => {
                    let v = parseFloat(e.target.value);
                    if (isNaN(v)) {
                      v = 0;
                    }
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      address: {
                        ...updatedMerchant.address,
                        latitude: v,
                      },
                    });
                  }}
                ></TextField>
              ) : (
                <>{data?.merchants[0].address_latitude}</>
              )}
            </div>
            <div className="field-block">
              <span className="label">Longitude</span>
              {editEnabled ? (
                <TextField
                  required
                  inputProps={{ min: -180.0, max: 180.0, step: "any" }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  size="small"
                  type="number"
                  style={{ width: "210px" }}
                  defaultValue={updatedMerchant.address.longitude}
                  placeholder={data?.merchants[0].address_longitude}
                  // value={updatedMerchant.address.longitude}
                  onChange={(e) => {
                    let v = parseFloat(e.target.value);
                    if (isNaN(v)) {
                      v = 0;
                    }
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      address: {
                        ...updatedMerchant.address,
                        longitude: v,
                      },
                    });
                  }}
                ></TextField>
              ) : (
                <>{data?.merchants[0].address_longitude}</>
              )}
            </div>
            {editEnabled && <hr style={{ margin: "40px 0px" }} />}

            <div className="field-block">
              <span className="label">Subtitle</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 255 }}
                  // defaultValue={data?.merchants[0].subtitle}
                  value={updatedMerchant.merchant.subtitle}
                  placeholder={data?.merchants[0].subtitle}
                  onChange={(e) =>
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        subtitle: e.target.value,
                      },
                    })
                  }
                ></TextField>
              ) : data?.merchants[0].subtitle ? (
                data?.merchants[0].subtitle
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            {/* Image */}
            {/* Logo */}
            <div className="field-block">
              <span className="label">Description</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 1000 }}
                  value={updatedMerchant.merchant.description}
                  // defaultValue={data?.merchants[0].description}
                  placeholder={data?.merchants[0].description}
                  onChange={(e) =>
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        description: e.target.value,
                      },
                    })
                  }
                ></TextField>
              ) : data?.merchants[0].description ? (
                data?.merchants[0].description
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>

            <div className="field-block">
              <span className="label">Phone Number</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 255 }}
                  // defaultValue={data?.merchants[0].phone_number}
                  value={updatedMerchant.merchant.phone_number}
                  placeholder={data?.merchants[0].phone_number}
                  onChange={(e) =>
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        phone_number: e.target.value,
                      },
                    })
                  }
                ></TextField>
              ) : data?.merchants[0].phone_number ? (
                data?.merchants[0].phone_number
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Priority</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  type="number"
                  inputProps={{ max: 255, min: 0 }}
                  value={updatedMerchant.merchant.priority}
                  // defaultValue={data?.merchants[0].priority}
                  onChange={(e) =>
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        priority: parseInt(e.target.value),
                      },
                    })
                  }
                ></TextField>
              ) : (
                data?.merchants[0].priority
              )}
            </div>
            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  label="Status"
                  select
                  // defaultValue={data?.merchants[0].status}
                  value={updatedMerchant.merchant.status}
                  onChange={(e) =>
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        status: parseInt(e.target.value),
                      },
                    })
                  }
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                </TextField>
              ) : (
                <StatusOptions
                  status={data?.merchants[0].status!}
                ></StatusOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Website URL</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 1000 }}
                  value={updatedMerchant.merchant.website_url}
                  // defaultValue={data?.merchants[0].website_url}
                  placeholder={data?.merchants[0].website_url}
                  onChange={(e) =>
                    setUpdatedMerchant({
                      ...updatedMerchant,
                      merchant: {
                        ...updatedMerchant.merchant,
                        website_url: e.target.value,
                      },
                    })
                  }
                ></TextField>
              ) : data?.merchants[0].website_url ? (
                <a href={data?.merchants[0].website_url} target="_blank">
                  {data?.merchants[0].website_url}{" "}
                </a>
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Image</span>
              {editEnabled ? (
                <>
                  <FileUploader
                    labelText="Upload an Image"
                    queryKey={"merchantEditImageUrl"}
                    success={imageUploadHandler}
                  ></FileUploader>
                </>
              ) : (
                <ImagePreview
                  imageUrl={data?.merchants[0].image!}
                  showPlaceHolderDashes={true}
                ></ImagePreview>
              )}
            </div>
            <div className="field-block">
              <span className="label">Logo</span>
              {editEnabled ? (
                <>
                  <FileUploader
                    labelText="Upload a Logo"
                    queryKey={"merchantEditLogoUrl"}
                    success={logoUploadHandler}
                  ></FileUploader>
                </>
              ) : (
                <ImagePreview
                  imageUrl={data?.merchants[0].logo!}
                  showPlaceHolderDashes={true}
                ></ImagePreview>
              )}
            </div>
            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button className="button-primary btn">Save</button>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
}
