import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { RouteStop, getARoutesRouteStops } from "../api/routesStops.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";
import { useState } from "react";
import RoutesSingleRouteStopsCreate from "./RoutesSingleRouteStopsCreate";
import { Dialog } from "@mui/material";
import RoutesSingleRouteStopsEdit from "./RoutesSingleRouteStopsEdit";
import RoutesStopsOptionalOptions from "./utility/RoutesStopsOptionalOptions";

export default function RoutesSingleRoutesStops() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const columns: GridColDef[] = [
    {
      field: "stop_name",
      headerName: "Stop Name",
      flex: 2,
    },
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return params.row.description ? (
          params.row.description
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "default_travel_time",
      headerName: "Default Travel Time",
      flex: 1,
    },
    {
      field: "default_service_time",
      headerName: "Default Service Time",
      flex: 1,
    },
    {
      field: "optional",
      headerName: "Optional",
      flex: 1,
      renderCell: (params) => {
        return (
          <RoutesStopsOptionalOptions
            optional={params.row.optional}
          ></RoutesStopsOptionalOptions>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                setSelectedRouteStop(params.row);
                setEditModalOpen(true);
              }}
              className="button-primary-sm"
            >
              Edit
            </button>
          </>
        );
      },
    },
  ];

  const {
    data: routeStopsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getRouteStops", () => getARoutesRouteStops(parseInt(id)), {
    refetchOnMount: true,
  });
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  function closeCreateModalHandler() {
    setCreateModalOpen(false);
  }
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  function closeEditModalHandler() {
    setEditModalOpen(false);
  }
  const [selectedRouteStop, setSelectedRouteStop] = useState<RouteStop>({});

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Stops on route</h2>
        <button
          className="button-primary"
          onClick={() => {
            setCreateModalOpen(true);
          }}
        >
          Add stop to route
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={routeStopsData?.routes_stops ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <div>
        <Dialog
          open={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <RoutesSingleRouteStopsCreate
              closeModal={closeCreateModalHandler}
            ></RoutesSingleRouteStopsCreate>
          </div>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <RoutesSingleRouteStopsEdit
              closeModal={closeEditModalHandler}
              routeStop={selectedRouteStop}
            ></RoutesSingleRouteStopsEdit>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
