import dayjs from "dayjs";

export const getSecondsFromMidnight = function (d: Date) {
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const totalSeconds = hours * 3600 + minutes * 60;
  return totalSeconds;
};

export const getDayjsFromSecondsSinceMidnight = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Set the time explicitly to avoid DST issues
  return dayjs()
    .startOf("day")
    .hour(hours)
    .minute(minutes)
    .second(remainingSeconds);
};

export const secondsToTimeString = function (seconds: number): string {
  // Ensure the input is within a day
  const totalSecondsInADay = 86400;
  const validSeconds = seconds % totalSecondsInADay;

  // Calculate hours and minutes
  const hours = Math.floor(validSeconds / 3600);
  const minutes = Math.floor((validSeconds % 3600) / 60);

  // Format the time string
  // Adjust hours for 12-hour format and handle midnight and noon cases
  const hoursForDisplay = hours % 12 === 0 ? 12 : hours % 12;
  const minutesForDisplay = minutes < 10 ? `0${minutes}` : minutes;
  const amPm = hours < 12 ? "am" : "pm";

  return `${hoursForDisplay}:${minutesForDisplay}${amPm}`;
};
