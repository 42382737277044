import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getDrivers } from "../api/drivers.api";
import { getJobs } from "../api/jobs.api";
import { useRideRecapOverviewStore } from "../stores/rideRecapOverview.store.zus";
import { formatDateToSimpleString } from "../utility/formatDateToSimpleString";
import { getStartOfYesterday } from "../utility/getStartOfYesterday";
import RidesFiltered from "./utility/RidesFiltered";

export default function RidesRecapOverview() {
  const {
    jobId,
    rideId,
    driverId,
    rideStatus,
    rideMode,
    createdFrom,
    rideException,
    rideRating,
    pickupAddress,
    dropoffAddress,
    riderName,
    startDate,
    endDate,
    setJobId,
    setRideId,
    setDriverId,
    setRideStatus,
    setRideMode,
    setCreatedFrom,
    setRideException,
    setRideRating,
    setPickupAddress,
    setDropoffAddress,
    setRiderName,
    setStartDate,
    setEndDate,
  } = useRideRecapOverviewStore();

  const { data: driversData } = useQuery("getDrivers", getDrivers, {
    refetchOnMount: true,
  });
  const { data: jobsData } = useQuery("getJobs", getJobs, {
    refetchOnMount: true,
  });

  const handleClear = () => {
    setJobId(0);
    setRideId("");
    setDriverId(0);
    setRideStatus([0, 1, 2, 3, 4, 5, 6, 7]);
    setCreatedFrom([0, 1, 2, 3]);
    setRideMode([9, 9, 9]);
    setRideException("any");
    setRideRating([9, 9, 9]);
    setPickupAddress(undefined);
    setDropoffAddress(undefined);
    setRiderName(undefined);
    setStartDate(getStartOfYesterday());
    setEndDate(getStartOfYesterday());
  };

  return (
    <div>
      <div className="header-container">
        <h2>Ride Recap</h2>
        <button
          className="button-primary-outline-sm"
          type="button"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      <form style={{ margin: "20px 0" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
            margin: "20px 0",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start"
              slotProps={{ textField: { size: "small", sx: { flex: 3 } } }}
              value={dayjs(startDate)}
              onChange={(newValue) => {
                if (newValue?.isValid()) {
                  let newDate = dayjs(newValue).toDate();
                  setStartDate(newDate);
                }
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End"
              slotProps={{ textField: { size: "small", sx: { flex: 3 } } }}
              value={dayjs(endDate)}
              onChange={(newValue) => {
                if (newValue?.isValid()) {
                  let newDate = dayjs(newValue).toDate();
                  setEndDate(newDate);
                }
              }}
            />
          </LocalizationProvider>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 32,
            }}
            size="small"
            label="Pickup Address"
            value={pickupAddress || ""}
            sx={{ flex: 3 }}
            onChange={(e) => {
              setPickupAddress(e.target.value);
            }}
          ></TextField>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 32,
            }}
            size="small"
            label="Dropoff Address"
            value={dropoffAddress || ""}
            sx={{ flex: 3 }}
            onChange={(e) => {
              setDropoffAddress(e.target.value);
            }}
          ></TextField>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 32,
            }}
            size="small"
            label="Rider Name"
            value={riderName || ""}
            sx={{ flex: 3 }}
            onChange={(e) => {
              setRiderName(e.target.value);
            }}
          ></TextField>

          <TextField
            size="small"
            label="Ride ID"
            value={rideId}
            type="number"
            sx={{
              flex: 1.5,
              "& input[type=number]": {
                "-moz-appearance": "textfield",
                "&::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "&::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              },
            }}
            inputProps={{ min: 1 }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={(e) => {
              e.target.value
                ? setRideId(parseInt(e.target.value))
                : setRideId("");
            }}
          ></TextField>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <TextField
            SelectProps={{
              MenuProps: {
                transitionDuration: 0,
              },
            }}
            size="small"
            select
            sx={{ flex: 1 }}
            label="Driver"
            value={driverId}
            onChange={(e) => {
              setDriverId(parseInt(e.target.value));
            }}
          >
            <MenuItem value={0}>Any</MenuItem>
            {driversData?.drivers.map((dr) => {
              return (
                <MenuItem value={dr.id} key={dr.id}>
                  {`${dr.first_name} ${dr.last_name}`}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            SelectProps={{
              MenuProps: {
                transitionDuration: 0,
              },
            }}
            size="small"
            select
            sx={{ flex: 1 }}
            label="Job"
            value={jobId}
            onChange={(e) => {
              setJobId(parseInt(e.target.value));
            }}
          >
            <MenuItem value={0}>Any</MenuItem>
            {jobsData?.jobs
              .filter((j) => {
                return j.eta_url;
              })
              .map((j) => {
                return (
                  <MenuItem value={j.id} key={j.id}>
                    {j.name}
                  </MenuItem>
                );
              })}
          </TextField>
          <TextField
            size="small"
            sx={{ flex: 1 }}
            select
            value={rideStatus?.join(",")}
            onChange={(e) => {
              let value = e.target.value
                .split(",")
                .map((value) => parseInt(value));
              setRideStatus(value);
            }}
            label="Ride Status"
          >
            <MenuItem value={"0,1,2,3,4,5,6,7"}>Any</MenuItem>
            <MenuItem value={"6"}>Completed</MenuItem>
            <MenuItem value={"7"}>Canceled</MenuItem>
          </TextField>
          <TextField
            size="small"
            sx={{ flex: 1 }}
            select
            value={createdFrom?.join(",")}
            onChange={(e) => {
              let value = e.target.value
                .split(",")
                .map((value) => parseInt(value));
              setCreatedFrom(value);
            }}
            label="Created From"
          >
            <MenuItem value={"0,1,2,3"}>Any</MenuItem>
            <MenuItem value={"0"}>Dashboard</MenuItem>
            <MenuItem value={"1,3"}>Rider App</MenuItem>
            <MenuItem value={"2"}>Driver App</MenuItem>
          </TextField>
          <TextField
            size="small"
            sx={{ flex: 1 }}
            select
            value={rideRating?.join(",")}
            onChange={(e) => {
              let value = e.target.value
                .split(",")
                .map((value) => parseInt(value));
              setRideRating(value);
            }}
            label="Ride Rating"
          >
            <MenuItem value={"9,9,9"}>Any</MenuItem>
            <MenuItem value={"1,2,3,4,5"}>Rides With Rating</MenuItem>
            <MenuItem value={"1,2"}>1 - 2</MenuItem>
            <MenuItem value={"1,2,3"}>1 - 3</MenuItem>
            <MenuItem value={"4,5"}>4 - 5 </MenuItem>
          </TextField>

          {/* TODO MULTISELECT VALUES */}
          <TextField
            size="small"
            sx={{ flex: 1 }}
            select
            value={rideMode?.join(",")}
            onChange={(e) => {
              let value = e.target.value
                .split(",")
                .map((value) => parseInt(value));
              setRideMode(value);
            }}
            label="Ride Mode"
          >
            <MenuItem value={"9,9,9"}>Any</MenuItem>
            <MenuItem value={"0"}>Normal</MenuItem>
            <MenuItem value={"1"}>Scheduled</MenuItem>
          </TextField>
          <TextField
            size="small"
            sx={{ flex: 1 }}
            select
            onChange={(e) => {
              setRideException(e.target.value);
            }}
            label="Ride Exception"
            value={rideException}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"rider:cancelled"}>Rider Cancelled</MenuItem>
            <MenuItem value={"driver:rider_cancelled"}>
              Driver: Rider Cancelled
            </MenuItem>
            <MenuItem value={"driver:rider_not_found"}>
              Driver: Rider Not Found
            </MenuItem>
            <MenuItem value={"driver:unsafe"}>Driver: Rider Unsafe</MenuItem>
            <MenuItem value={"dashboard:cancelled"}>
              Dashboard Cancelled
            </MenuItem>
            <MenuItem value={"assigner:no_drivers_online"}>
              No Shifts Online
            </MenuItem>
            <MenuItem value={"delegator:outside_of_shift"}>
              Outside of Shift
            </MenuItem>
            <MenuItem value={"tollbooth:rider_payment_failed"}>
              Rider Payment Failed
            </MenuItem>
            <MenuItem value={"delegator:ride_too_short"}>
              Ride Too Short
            </MenuItem>
            <MenuItem value={"delegator:no_service"}>
              Service Not Offered
            </MenuItem>
          </TextField>
        </div>
      </form>
      <RidesFiltered
        startDate={formatDateToSimpleString(startDate)}
        endDate={formatDateToSimpleString(endDate)}
        rideId={typeof rideId == "number" ? rideId : undefined}
        rideStatus={
          JSON.stringify(rideStatus) == JSON.stringify([0, 1, 2, 3, 4, 5, 6, 7])
            ? undefined
            : rideStatus
        }
        createdFrom={
          JSON.stringify(createdFrom) == JSON.stringify([0, 1, 2, 3])
            ? undefined
            : createdFrom
        }
        rideMode={
          JSON.stringify(rideMode) == JSON.stringify([9, 9, 9])
            ? undefined
            : rideMode
        }
        rideException={rideException == "any" ? undefined : rideException}
        jobId={jobId == 0 ? undefined : jobId}
        driverId={driverId || undefined}
        riderName={riderName || undefined}
        pickupAddress={!pickupAddress ? undefined : pickupAddress}
        dropoffAddress={!dropoffAddress ? undefined : dropoffAddress}
        rideRating={
          JSON.stringify(rideRating) == JSON.stringify([9, 9, 9])
            ? undefined
            : rideRating
        }
        dateOrderBy="asc"
        pageSize={20}
      ></RidesFiltered>
    </div>
  );
}
