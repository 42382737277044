import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getSystemParams } from "../api/sys_params.api";
import EtaEarlyLateReport from "./reporting/EtaEarlyLateReport";
import ExportMerchantReport from "./reporting/ExportMerchantReport";
import FareReport from "./reporting/FareReport";
import KioskReport from "./reporting/KioskReport";
import OriginDestinationReport from "./reporting/OriginDestinationReport";
import RideDistributionReport from "./reporting/RideDistributionReport";
import RiderFeedbackReport from "./reporting/RiderFeedbackReport";
import RiderNotFoundReport from "./reporting/RiderNotFoundReport";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export default function ReportingOverview() {
  const navigate = useNavigate();
  const {
    data: systemParamsData,
    isLoading: systemParamsIsLoading,
    isError: systemParamsIsError,
  } = useQuery("getSystemParams", getSystemParams);
  const columns: GridColDef[] = [
    { field: "report", headerName: "Report Type", flex: 1, minWidth: 100 },
    // { field: "description", headerName: "Description", flex: 1, minWidth: 200 },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.id === 1) {
          return <OriginDestinationReport></OriginDestinationReport>;
        } else if (params.row.id === 2) {
          return <EtaEarlyLateReport></EtaEarlyLateReport>;
        } else if (params.row.id === 3) {
          return <ExportMerchantReport></ExportMerchantReport>;
        } else if (params.row.id === 4) {
          return <RideDistributionReport></RideDistributionReport>;
        } else if (params.row.id === 5) {
          return <RiderFeedbackReport></RiderFeedbackReport>;
        } else if (params.row.id === 6) {
          return <RiderNotFoundReport></RiderNotFoundReport>;
        } else if (params.row.id === 7) {
          return <KioskReport></KioskReport>;
        } else if (params.row.id === 8) {
          return <FareReport></FareReport>;
        } else {
          return (
            <>
              <PlaceholderCharacter></PlaceholderCharacter>
            </>
          );
        }
      },
    },
  ];
  const rows = [
    {
      id: 1,
      report: "Origin Destination",
      description: "this is a description",
    },
    {
      id: 2,
      report: "Eta Early/Late",
      description: "this is a description",
    },
    {
      id: 3,
      report: "Export Merchant",
      description: "this is a description",
    },
    {
      id: 4,
      report: "Ride Distribution",
      description: "this is a description",
    },
    {
      id: 5,
      report: "Rider Feedback",
      description: "this is a description",
    },
    {
      id: 6,
      report: "Rider Not Found",
      description: "this is a description",
    },
    {
      id: 7,
      report: "Kiosk",
      description: "this is a description",
    },
    {
      id: 8,
      report: "Fare",
      description: "this is a description",
    },
  ];
  return (
    <div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={rows}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
