import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import BackButton from "./BackButton";
import SystemParametersSingleInformation from "./SystemParametersSingleInformation";
import SystemParametersSingleHistory from "./SystemParamsSingleHistory";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getASystemParam } from "../api/sys_params.api";
import { LinearProgress } from "@mui/material";

export default function SystemParametersSingle() {
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    ["getASystemParam", parseInt(id)],
    () => getASystemParam(parseInt(id)),
    { refetchOnMount: true }
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <BackButton></BackButton>
      {data && (
        <h2 style={{ margin: "20px 0px" }}>{data.sys_params[0].name}</h2>
      )}

      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Information"></Tab>
        <Tab label="History"></Tab>
      </Tabs>

      {value == 0 && (
        <SystemParametersSingleInformation></SystemParametersSingleInformation>
      )}
      {value == 1 && (
        <SystemParametersSingleHistory></SystemParametersSingleHistory>
      )}
    </div>
  );
}
