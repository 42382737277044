import { CircularProgress, MenuItem, TextField } from "@mui/material";

//Add zone rule
import Autocomplete from "@mui/material/Autocomplete";

//Zone Search
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { createJobZoneRule } from "../api/jobs.api";
import { getZones } from "../api/zones.api";
interface JobZoneRuleCreateProps {
  closeModal: Function;
}
interface JobZoneRule {
  selectedZoneA: undefined | number;
  selectedZoneB: undefined | number;
  direction: number;
  allowed: number;
}
export default function JobZoneRuleCreate(props: JobZoneRuleCreateProps) {
  const { id } = useParams() as { id: string };
  const [zoneRulePayload, setZoneRulePayload] = useState<JobZoneRule>({
    selectedZoneA: undefined,
    selectedZoneB: undefined,
    direction: 2,
    allowed: 1,
  });
  const {
    mutateAsync: saveNewZoneRule,
    isLoading: isNewZoneRuleLoading,
    isError: isNewZoneRuleError,
  } = useMutation(createJobZoneRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAJobsZoneRules");
      props.closeModal();
      enqueueSnackbar("New zone rule succesfully created.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      props.closeModal();
      enqueueSnackbar(`There was a problem creating this zone rule.`, {
        variant: "error",
      });
    },
  });
  async function saveZoneRuleHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = {
      job_id: parseInt(id),
      allowed: zoneRulePayload.allowed,
      pick_up_zone_id: 0,
      drop_off_zone_id: 0,
    };
    let payload2 = {
      job_id: parseInt(id),
      allowed: zoneRulePayload.allowed,
      pick_up_zone_id: 0,
      drop_off_zone_id: 0,
    };
    if (zoneRulePayload.direction == 0) {
      payload.pick_up_zone_id = zoneRulePayload.selectedZoneA!;
      payload.drop_off_zone_id = zoneRulePayload.selectedZoneB!;
    }
    if (zoneRulePayload.direction == 1) {
      payload.pick_up_zone_id = zoneRulePayload.selectedZoneB!;
      payload.drop_off_zone_id = zoneRulePayload.selectedZoneA!;
    }
    if (zoneRulePayload.direction == 2) {
      payload.pick_up_zone_id = zoneRulePayload.selectedZoneA!;
      payload.drop_off_zone_id = zoneRulePayload.selectedZoneB!;
      payload2.pick_up_zone_id = zoneRulePayload.selectedZoneB!;
      payload2.drop_off_zone_id = zoneRulePayload.selectedZoneA!;
    }
    if (zoneRulePayload.direction != 2) {
      await saveNewZoneRule(payload);
    }
    if (zoneRulePayload.direction == 2) {
      if (
        payload.drop_off_zone_id == payload2.drop_off_zone_id &&
        payload.pick_up_zone_id == payload2.pick_up_zone_id
      ) {
        await saveNewZoneRule(payload);
      }
      if (
        payload.drop_off_zone_id !== payload2.drop_off_zone_id &&
        payload.pick_up_zone_id !== payload2.pick_up_zone_id
      ) {
        await saveNewZoneRule(payload);
        await saveNewZoneRule(payload2);
      }
    }
  }
  const {
    data: zoneData,
    status: zoneStatus,
    isLoading: zoneIsLoading,
    isRefetching: zoneIsRefetching,
  } = useQuery("getZones", () => getZones(), {
    refetchOnMount: true,
  });
  return (
    <div>
      <form onSubmit={saveZoneRuleHandler}>
        <h2>Create New Job Zone Rule</h2>
        {zoneData && (
          <div>
            <div className="create-zone-rule-container">
              <Autocomplete
                disablePortal
                id="zone-a-select"
                onChange={(val, newVal) =>
                  setZoneRulePayload({
                    ...zoneRulePayload,
                    selectedZoneA: newVal?.id!,
                  })
                }
                // defaultValue={{ id: 0, name: "**ANY**" }}
                options={[{ id: 0, name: "**ANY**" }, ...zoneData!.zones]}
                getOptionLabel={(zone) => zone.name || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Pickup Zone" />
                )}
              />
              <TextField
                label="Direction"
                select
                value={zoneRulePayload.direction}
                onChange={(e) => {
                  setZoneRulePayload({
                    ...zoneRulePayload,
                    direction: parseInt(e.target.value),
                  });
                }}
                sx={{ width: 100 }}
                style={{ margin: "0px 10px" }}
                size="small"
              >
                <MenuItem value={0}>
                  <ArrowForwardIcon
                    style={{ fontSize: "20px", margin: "10px 0px 5px 0px" }}
                  ></ArrowForwardIcon>
                </MenuItem>
                <MenuItem value={2}>
                  <CompareArrowsIcon
                    style={{ fontSize: "20px", margin: "10px 0px 5px 0px" }}
                  ></CompareArrowsIcon>
                </MenuItem>
              </TextField>
              <Autocomplete
                disablePortal
                id="zone-b-select"
                onChange={(val, newVal) =>
                  setZoneRulePayload({
                    ...zoneRulePayload,
                    selectedZoneB: newVal?.id!,
                  })
                }
                // defaultValue={{ id: 0, name: "**ANY**" }}
                options={[{ id: 0, name: "**ANY**" }, ...zoneData!.zones]}
                getOptionLabel={(zone) => zone.name || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Drop off zone" />
                )}
              />{" "}
              <TextField
                label="Allowed"
                select
                value={zoneRulePayload.allowed}
                onChange={(e) =>
                  setZoneRulePayload({
                    ...zoneRulePayload,
                    allowed: parseInt(e.target.value),
                  })
                }
                sx={{ width: 150 }}
                style={{ margin: "20px 10px" }}
              >
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </TextField>
            </div>
          </div>
        )}
        <div
          className="button-container"
          style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
        >
          <button
            className="button-outline"
            type="button"
            onClick={(e) => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button-primary"
            disabled={
              isNewZoneRuleLoading ||
              zoneRulePayload.selectedZoneA == undefined ||
              zoneRulePayload.selectedZoneB == undefined
            }
          >
            {isNewZoneRuleLoading ? (
              <CircularProgress
                color="inherit"
                size={12}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
