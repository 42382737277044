import { MenuItem, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { createAUsersRidersSegment } from "../api/usersRidersSegments.api";
import BackButton from "./BackButton";

interface UserRiderSegment {
  name?: string;
  priority?: number;
  status?: number;
}
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function UsersRidersSegmentCreate() {
  const navigate = useNavigate();
  const {
    mutateAsync: createUserRiderSegment,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAUsersRidersSegment, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUsersRidersSegments");
      enqueueSnackbar("Rider segment was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/users-riders-segments");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this rider segment. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });
  const [createUserRiderSegmentPayload, setCreateUserRiderSegmentPayload] =
    useState<UserRiderSegment>({
      name: "",
      priority: 0,
      status: 0,
    });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    console.log(createUserRiderSegmentPayload);
    await createUserRiderSegment(createUserRiderSegmentPayload);
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Rider Segment</h2>
      <div className="create-asset-form" style={{ width: "300px" }}>
        <form onSubmit={submitHandler}>
          <TextField
            required
            inputProps={{ maxLength: 255, autoComplete: "off" }}
            sx={inputBlock}
            size="small"
            label="Name"
            fullWidth
            onChange={(e) => {
              setCreateUserRiderSegmentPayload({
                ...createUserRiderSegmentPayload,
                name: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            inputProps={{ min: 0, max: 255 }}
            sx={inputBlock}
            size="small"
            value={createUserRiderSegmentPayload.priority}
            type="number"
            label="Priority"
            fullWidth
            onChange={(e) => {
              setCreateUserRiderSegmentPayload({
                ...createUserRiderSegmentPayload,
                priority: parseInt(e.target.value),
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            inputProps={{ min: 0, max: 1, required: true }}
            sx={inputBlock}
            size="small"
            type="number"
            label="Status"
            select
            value={createUserRiderSegmentPayload.status}
            onChange={(e) => {
              setCreateUserRiderSegmentPayload({
                ...createUserRiderSegmentPayload,
                status: parseInt(e.target.value),
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
          </TextField>
          <button
            className="button-primary"
            type="submit"
            disabled={
              !createUserRiderSegmentPayload.name ||
              createUserRiderSegmentPayload.priority == undefined ||
              createUserRiderSegmentPayload.priority == null
            }
          >
            Create Rider Segment
          </button>
        </form>
      </div>
    </div>
  );
}
