import { create } from "zustand";
import { SystemParams } from "../api/sys_params.api";

interface SysParamsState {
  sysParams: SystemParams[];
  setSysParams: (sysParams: SystemParams[]) => void;
  getAllParams: () => SystemParams[];
  getParamByName: (name: string) => SystemParams | undefined;
}

export const useSysParamsStore = create<SysParamsState>((set, get) => ({
  sysParams: [],
  setSysParams: (sysParams) => set({ sysParams }),
  getAllParams: () => get().sysParams,
  getParamByName: (name) => {
    const { sysParams } = get();
    return sysParams.find((param) => param.name === name);
  },
}));
