import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

interface AWSGetUploadUrlResponse {
  success: boolean;
  uploadUrl: string;
  fileUrl: string;
}
interface AWSFileUploadPayload {
  url: string;
  file: Blob;
  imageType: string;
}
// export const awsGetUploadUrl =
//   async function (): Promise<AWSGetUploadUrlResponse> {
//     const token = useAuthStore.getState().token;
//     const res = await axios.get(
//       `${process.env.REACT_APP_API_BASE_URL}/aws/s3-upload-url`,
//       {
//         headers: {
//           authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return res.data;
//   };
export const awsGetUploadUrl = async function (
  cacheDateTime: string
): Promise<AWSGetUploadUrlResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/aws/s3-upload-url/${cacheDateTime}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const awsUploadFile = async function (params: AWSFileUploadPayload) {
  console.log(params);
  const res = await axios.put(params.url, params.file, {
    headers: {
      "Content-Type": params.imageType,
    },
  });
  console.log(res.status);
  return res.status;
};
