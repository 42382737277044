import React from "react";

export default function SystemParameterContext(type: number) {
  return (
    <div>
      {type == 0 && <>Global</>}
      {type == 1 && <>Server</>}
      {type == 2 && <>Coral/Delegator</>}
      {type == 3 && <>Admin</>}
      {type == 4 && <>Dashboard </>}
      {type == 5 && <>Driver </>}
      {type == 6 && <>Rider </>}
      {type == 7 && <>Monitor </>}
    </div>
  );
}
