import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Chip, Stack } from "@mui/material";
interface EnabledOptions {
  status: number;
}

export default function EnabledOptions(props: EnabledOptions) {
  return (
    <>
      {props.status ? (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<CheckCircleIcon />}
            label="Enabled"
          />
        </Stack>
      ) : (
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<NotInterestedIcon />} label="Disabled" />
        </Stack>
      )}
    </>
  );
}
