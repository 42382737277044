import { CheckCircle, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  TimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GeoSuggest from "@ubilabs/react-geosuggest";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import {
  ShiftScheduled,
  createAShift,
  getShiftAllowableJobs,
  getShiftAllowableVehicles,
} from "../api/shiftsScheduled.api";
import { queryClient } from "../App";
import { useShiftScheduledCreateStore } from "../stores/shiftsCreate.store.zus";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import {
  getDayjsFromSecondsSinceMidnight,
  getSecondsFromMidnight,
  secondsToTimeString,
} from "../utility/shiftsScheduledTimeHelper";
import BackButton from "./BackButton";
import StaticMapPreviewPoint from "./StaticMapPreviewPoint";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export interface ShiftScheduledCreate extends ShiftScheduled {
  amount?: number;
}

export interface ShiftBreak {
  start_time: undefined | number;
  end_time: undefined | number;
  end_latitude: undefined | number;
  end_longitude: undefined | number;
  duration?: number;
}

export default function ShiftsScheduledCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: saveNewShift, isLoading: isSaveNewShiftLoading } =
    useMutation(createAShift, {
      onSuccess: () => {
        queryClient.invalidateQueries("getShifts");
        setDefault();
        enqueueSnackbar("Shift was succesfully created.", {
          variant: "success",
        });
        navigate("/dashboard/shifts-scheduled");
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this shift. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    });
  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isRefetching: jobDataIsRefetching,
  } = useQuery("getJobs", getShiftAllowableJobs, { refetchOnMount: true });
  const {
    data: vehicleData,
    isLoading: vehicleDataIsLoading,
    isRefetching: vehicleDataIsRefetching,
  } = useQuery("getAllowedVehicleData", getShiftAllowableVehicles, {
    refetchOnMount: true,
  });
  const {
    job_name,
    job_id,
    vehicle_id,
    days,
    start_date,
    end_date,
    start_time,
    end_time,
    start_lead_time,
    status,
    startLeadTimeEnabled,
    selectedDays,
    amount,
    shiftBreaks,
    setDays,
    setEndDate,
    setEndTime,
    setJobId,
    setStartDate,
    setStartTime,
    setVehicleId,
    setStatus,
    setAmount,
    setStartLeadTime,
    setSelectedDays,
    getFullShiftCreated,
    setStartLeadTimeEnabled,
    setShiftBreaks,
    removeShiftBreak,
    setDefault,
  } = useShiftScheduledCreateStore();

  useEffect(() => {
    if (days) {
      if (days == "1,2,3,4,5,6,7") {
        setSelectedDays([0]);
      } else {
        const d = days.split(",").map((day) => parseInt(day, 10));
        setSelectedDays(d);
      }
    } else {
      setSelectedDays([0]);
    }
  }, [days]);
  function isLoadingOrRefetching() {
    if (jobDataIsLoading || jobDataIsRefetching) {
      return true;
    }
    if (vehicleDataIsLoading || vehicleDataIsRefetching) {
      return true;
    }
    return false;
  }
  function formComplete() {
    if (
      end_date &&
      start_date &&
      start_time !== undefined &&
      end_time !== undefined &&
      start_time !== end_time &&
      job_id &&
      vehicle_id &&
      !isEndDateError() &&
      endTimeError == null &&
      endDateError == null &&
      !isBreakWithError()
    ) {
      return true;
    }
    return false;
  }
  function checkboxHandler(value: number) {
    let array = selectedDays;
    if (value == 0) {
      array = [0];
    }
    if (value != 0) {
      if (array.includes(value)) {
        let s = array.filter((num) => num != value);
        array = s;
      } else {
        array.push(value);
        let s = array.filter((num) => num > 0);
        array = s;
      }
    }
    setSelectedDays(array);
  }
  const [endDateError, setEndDateError] = useState<DateValidationError | null>(
    null
  );
  function isEndDateError() {
    if (
      start_date &&
      end_date &&
      dayjs(start_date).toDate() > dayjs(end_date).toDate()
    ) {
      return true;
    }
    return false;
  }
  const [endTimeError, setEndTimeError] = useState<TimeValidationError | null>(
    null
  );

  useEffect(() => {
    if (start_date == end_date) {
      setSelectedDays([0]);
    }
  }, [start_date, end_date]);
  function showDayOfTheWeek() {
    if (
      start_date &&
      end_date &&
      start_date !== end_date &&
      !isEndDateError()
    ) {
      return true;
    }
    return false;
  }
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = { ...getFullShiftCreated() };
    if (!startLeadTimeEnabled) {
      payload.start_lead_time = null;
    }
    if (payload.start_lead_time != null) {
      payload.start_lead_time *= 60;
    }
    if (selectedDays[0] == 0) {
      payload.days = "1,2,3,4,5,6,7";
    } else {
      payload.days = selectedDays.sort((a, b) => a - b).join(",");
    }
    console.log(payload);
    await saveNewShift(payload);
  }

  const [searchValue, setSearchValue] = useState("");
  const [showAddBreakModal, setShowAddBreakModal] = useState(false);
  const [createShiftBreak, setCreateShiftBreak] = useState<ShiftBreak>({
    start_time: undefined,
    end_time: undefined,
    duration: 30,
    end_latitude: undefined,
    end_longitude: undefined,
  });
  const [addressString, setAddressString] = useState("");
  // function createShiftBreakComplete() {
  //   if (
  //     createShiftBreak.start_time !== undefined &&
  //     createShiftBreak.end_time !== undefined &&
  //     breakEndTimeError == null &&
  //     breakStartTimeError == null
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }
  const geoSuggestInput = useRef<GeoSuggest>(null);
  const [breakEndTimeError, setBreakEndTimeError] =
    useState<TimeValidationError | null>(null);
  const [breakStartTimeError, setBreakStartTimeError] =
    useState<TimeValidationError | null>(null);

  function isBreakWithError() {
    let errorArray = shiftBreaks.map((sb, i) => {
      return validateBreak(sb, i);
    });
    let isError = errorArray.filter((error) => {
      return error.error;
    });
    if (isError.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function validateBreak(shiftBreak: ShiftBreak, index: number) {
    let errorObj = {
      error: false,
      message: "",
    };

    const totalSecondsInADay = 86400;

    if (start_time !== undefined && end_time !== undefined) {
      // can check to see if break is within the shift
      const overnightShift = start_time > end_time;

      let shift_start_time = start_time;
      let shift_end_time = end_time;

      if (overnightShift) {
        shift_end_time += totalSecondsInADay;
      }

      let break_start_time = shiftBreak.start_time!;
      let break_end_time = shiftBreak.end_time!;

      if (break_start_time > break_end_time) {
        break_end_time += totalSecondsInADay;
      }

      if (overnightShift && break_start_time < shift_start_time) {
        // shift goes overnight, and break starts before shift, give it a chance by shifting it forward one day
        break_start_time += totalSecondsInADay;
        break_end_time += totalSecondsInADay;
      }

      if (break_start_time < shift_start_time) {
        errorObj.error = true;
        errorObj.message =
          "Break start time must be after the shift start time.";
        return errorObj;
      }

      if (break_end_time > shift_end_time) {
        errorObj.error = true;
        errorObj.message = "Break end time must be before the shift end time.";
        return errorObj;
      }
    }

    if (breakOverlapsAnotherBreak(index)) {
      errorObj.error = true;
      errorObj.message = "Break times cannot overlap with other breaks";
      return errorObj;
    }
    return errorObj;
  }

  function breakOverlapsAnotherBreak(index: number): boolean {
    const interval1 = shiftBreaks[index];

    for (let i = 0; i < shiftBreaks.length; i++) {
      if (i === index) {
        // skip self
        continue;
      }
      const interval2 = shiftBreaks[i];

      let interval1_start_time = interval1.start_time!;
      let interval1_end_time = interval1.end_time!;

      if (interval1_start_time > interval1_end_time) {
        interval1_end_time += 86400;
      }

      let interval2_start_time = interval2.start_time!;
      let interval2_end_time = interval2.end_time!;

      if (interval2_start_time > interval2_end_time) {
        interval2_end_time += 86400;
      }

      let interval1_times: string[] = [];
      for (let x = interval1_start_time; x < interval1_end_time; x += 60) {
        interval1_times.push(secondsToTimeString(x));
      }

      let interval2_times: string[] = [];
      for (let x = interval2_start_time; x < interval2_end_time; x += 60) {
        interval2_times.push(secondsToTimeString(x));
      }

      const hasOverlappingTime = interval1_times.some((item) =>
        interval2_times.includes(item)
      );

      // Check if the two intervals overlap
      if (hasOverlappingTime) {
        return true;
      }
    }

    return false;
  }

  function addShiftFormHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = { ...createShiftBreak };
    const totalSecondsInADay = 86400;
    payload.end_time = payload.start_time! + payload.duration! * 60;
    payload.end_time %= totalSecondsInADay;
    if (payload.end_time == 0) {
      payload.end_time = totalSecondsInADay;
    }
    delete payload.duration;
    setShiftBreaks([...shiftBreaks, payload]);
    setCreateShiftBreak({
      end_latitude: undefined,
      end_longitude: undefined,
      start_time: undefined,
      end_time: undefined,
      duration: 30,
    });
    setShowAddBreakModal(false);
    setAddressString("");
  }

  const { getParamByName } = useSysParamsStore();

  if (isLoadingOrRefetching()) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Shift Block</h2>
      {jobData && vehicleData && (
        <div
          className="create-asset-form"
          style={{ marginTop: "20px", maxWidth: "250px" }}
        >
          <form onSubmit={submitHandler}>
            <TextField
              inputProps={{ required: true, min: 0 }}
              required
              style={{ width: "300px", margin: "20px 0px 20px 0px" }}
              label="Job"
              select
              value={job_id}
              error={jobData?.jobs.length == 0}
              helperText={
                jobData?.jobs.length == 0
                  ? "Please create an enabled job with shift mode scheduled."
                  : ""
              }
              onChange={(e) => {
                setJobId(parseInt(e.target.value));
              }}
            >
              {jobData?.jobs.map((j) => {
                return (
                  <MenuItem key={j.id} value={j.id}>
                    {j.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              inputProps={{ required: true, min: 0 }}
              required
              style={{ width: "300px" }}
              label="Vehicle Type"
              select
              value={vehicle_id}
              onChange={(e) => {
                setVehicleId(parseInt(e.target.value));
              }}
            >
              {vehicleData?.vehicles.map((v) => {
                return (
                  <MenuItem key={v.vehicle_id} value={v.vehicle_id}>
                    {v.vehicle_type_name}
                  </MenuItem>
                );
              })}
            </TextField>
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{ minWidth: 300 }}
                  label="Start Time"
                  value={
                    start_time
                      ? getDayjsFromSecondsSinceMidnight(start_time)
                      : (null as any)
                  }
                  onChange={(e: Date | null) => {
                    setStartTime(undefined);
                    if (dayjs(e).isValid()) {
                      setStartTime(getSecondsFromMidnight(dayjs(e).toDate()));
                    }
                  }}
                ></TimePicker>
              </LocalizationProvider>
            </div>
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{ minWidth: 300 }}
                  label="End Time"
                  value={
                    end_time
                      ? getDayjsFromSecondsSinceMidnight(end_time)
                      : (null as any)
                  }
                  onError={(error) => {
                    setEndTimeError(error);
                  }}
                  onChange={(e: Date | null) => {
                    setEndTime(undefined);
                    if (dayjs(e).isValid()) {
                      let end_seconds = getSecondsFromMidnight(
                        dayjs(e).toDate()
                      );
                      if (end_seconds === 0) {
                        end_seconds = 86400;
                      }
                      setEndTime(end_seconds);
                    }
                  }}
                ></TimePicker>
              </LocalizationProvider>
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={startLeadTimeEnabled}
                    onChange={(e) => {
                      setStartLeadTimeEnabled(!startLeadTimeEnabled);
                    }}
                  />
                }
                label="Enable Start Lead Time"
              />
            </FormGroup>
            {startLeadTimeEnabled && (
              <TextField
                required
                fullWidth
                type="number"
                inputProps={{ required: true }}
                sx={inputBlock}
                label="Start Lead Time"
                helperText={
                  "Number of minutes before shift starts in which shift can start receiving rides"
                }
                value={start_lead_time}
                onChange={(e) => {
                  setStartLeadTime(parseInt(e.target.value));
                }}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            )}

            {getParamByName("hub_experimental_scheduled_breaks_enabled")
              ?.value == "true" && (
              <div>
                <hr />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2>Scheduled Breaks</h2>
                  <button
                    type="button"
                    onClick={(e) => {
                      setShowAddBreakModal(true);
                    }}
                    className="button-primary-sm"
                  >
                    Add
                  </button>
                </div>
                {shiftBreaks.length == 0 && (
                  <Alert
                    color="info"
                    sx={{ width: "300px", margin: "20px 0px" }}
                  >
                    There are currently no scheduled breaks.{" "}
                  </Alert>
                )}
                {shiftBreaks
                  .sort((a, b) => {
                    return a.start_time! - b.start_time!;
                  })
                  .map((b, index) => (
                    <Alert
                      color={validateBreak(b, index).error ? "error" : "info"}
                      onClose={() => removeShiftBreak(index)}
                      icon={
                        validateBreak(b, index).error ? (
                          <Tooltip
                            title={
                              validateBreak(b, index).error
                                ? validateBreak(b, index).message
                                : ""
                            }
                          >
                            <Warning></Warning>
                          </Tooltip>
                        ) : (
                          <CheckCircle></CheckCircle>
                        )
                      }
                      style={{ width: "400px", margin: "20px 0px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "300px",
                        }}
                      >
                        <div
                          style={{
                            margin: "0px 0px 3px 0px",
                            padding: "0px 20px",
                            width: "175px",
                          }}
                        >
                          {secondsToTimeString(b.start_time!)} to{" "}
                          {secondsToTimeString(b.end_time!)}
                        </div>
                        <StaticMapPreviewPoint
                          coordinate={{
                            lat: b.end_latitude!,
                            lng: b.end_longitude!,
                          }}
                        ></StaticMapPreviewPoint>
                      </div>
                    </Alert>
                  ))}
              </div>
            )}
            <div style={{ margin: "20px 0px" }}>
              <hr />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ minWidth: 300 }}
                  label="Start Date"
                  value={
                    start_date
                      ? dayjs(start_date) // Ensure this is a dayjs object
                      : (null as any)
                  }
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = dayjs(e);
                      if (d.isValid()) {
                        setStartDate(d.format("YYYY-MM-DD"));
                      }
                    }
                  }}
                ></DatePicker>
              </LocalizationProvider>
            </div>
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ minWidth: 300 }}
                  label="End Date"
                  value={end_date ? dayjs(end_date) : (null as any)}
                  onError={(error) => {
                    setEndDateError(error);
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: endDateError == "invalidDate" || isEndDateError(),
                      helperText: isEndDateError()
                        ? "End date must be after the start date."
                        : "",
                    },
                  }}
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = dayjs(e);
                      if (d.isValid()) {
                        setEndDate(d.format("YYYY-MM-DD"));
                      }
                    }
                  }}
                ></DatePicker>
              </LocalizationProvider>
            </div>
            {showDayOfTheWeek() && (
              <>
                <hr />
                <h2 style={{ margin: "20px 0px" }}>
                  Which days of the week does this scheduled apply to?
                </h2>
                <div>
                  <Box sx={{ display: "flex" }}>
                    <FormGroup style={{ marginRight: "60px" }}>
                      <FormControlLabel
                        value={0}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(0)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="All Days"
                      />
                      <FormControlLabel
                        value={1}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(1)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Monday"
                      />
                      <FormControlLabel
                        value={2}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(2)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Tuesday"
                      />
                      <FormControlLabel
                        value={3}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(3)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Wednesday"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        value={4}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(4)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Thursday"
                      />
                      <FormControlLabel
                        value={5}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(5)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Friday"
                      />
                      <FormControlLabel
                        value={6}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(6)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Saturday"
                      />
                      <FormControlLabel
                        value={7}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(7)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Sunday"
                      />
                    </FormGroup>
                  </Box>
                </div>
              </>
            )}
            {showDayOfTheWeek() && <hr />}
            <div>
              <TextField
                style={{ width: "300px", margin: "15px 0px" }}
                label="How many shifts per day?"
                select
                value={amount}
                onChange={(e) => {
                  setAmount(parseInt(e.target.value));
                }}
              >
                {Array.from({ length: 10 }, (_, i) => (
                  <MenuItem key={i} value={i + 1}>
                    {i + 1} {i == 0 ? "(Default)" : ""}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <button
              className="button-primary"
              style={{ margin: "20px 0px" }}
              type="submit"
              disabled={!formComplete() || isSaveNewShiftLoading}
            >
              {isSaveNewShiftLoading ? (
                <CircularProgress
                  size="15px"
                  style={{ color: "white" }}
                ></CircularProgress>
              ) : (
                <span className="button-container">Create Shift Block</span>
              )}
            </button>
          </form>
          <Dialog
            open={showAddBreakModal}
            onClose={() => {
              setShowAddBreakModal(false);
              setCreateShiftBreak({
                end_latitude: undefined,
                end_longitude: undefined,
                start_time: undefined,
                end_time: undefined,
                duration: 30,
              });
              setAddressString("");
            }}
            fullWidth
          >
            <div style={{ padding: "40px" }}>
              <form onSubmit={addShiftFormHandler}>
                <h2>Add Scheduled Break</h2>

                <div style={{ margin: "20px 0px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      sx={{ minWidth: 300 }}
                      label="Start Time"
                      onChange={(e: Date | null) => {
                        setCreateShiftBreak({
                          ...createShiftBreak,
                          start_time: undefined,
                        });
                        if (dayjs(e).isValid()) {
                          setCreateShiftBreak({
                            ...createShiftBreak,
                            start_time: getSecondsFromMidnight(
                              dayjs(e).toDate()
                            ),
                          });
                        }
                      }}
                    ></TimePicker>
                  </LocalizationProvider>
                </div>
                <TextField
                  type="number"
                  inputProps={{ required: true, min: 0, max: 480 }}
                  required
                  sx={{ width: "300px" }}
                  label="Break Duration (minutes)"
                  value={createShiftBreak.duration}
                  onChange={(e) => {
                    setCreateShiftBreak({
                      ...createShiftBreak,
                      duration: parseInt(e.target.value),
                    });
                  }}
                ></TextField>
                <div style={{ margin: "20px 0px", maxWidth: "300px" }}>
                  <GeoSuggest
                    ref={geoSuggestInput}
                    placeholder="Type to search for an address."
                    onChange={(value) => {
                      setSearchValue(value);
                    }}
                    inputClassName=""
                    types={["establishment"]}
                    location={
                      new google.maps.LatLng(
                        parseFloat(
                          getParamByName(
                            "web_dashboard_google_maps_initial_latitude"
                          )?.value!
                        ),
                        parseFloat(
                          getParamByName(
                            "web_dashboard_google_maps_initial_longitude"
                          )?.value!
                        )
                      )
                    }
                    radius="1000"
                    placeDetailFields={[
                      "formatted_address",
                      "name",
                      "geometry",
                      "type",
                    ]}
                    renderSuggestItem={(suggest) => {
                      if (suggest.isFixture) {
                        return "☆ " + suggest.label.split(",")[0];
                      }

                      return suggest.label;
                    }}
                    onSuggestSelect={(suggestion) => {
                      if (suggestion && searchValue != "") {
                        let addressString =
                          (suggestion.gmaps as any).name +
                          ", " +
                          suggestion.gmaps!.formatted_address;
                        setAddressString(addressString);
                        setCreateShiftBreak({
                          ...createShiftBreak,
                          end_latitude: suggestion.location.lat,
                          end_longitude: suggestion.location.lng,
                        });
                      }
                      setSearchValue("");
                      geoSuggestInput.current?.clear();
                    }}
                  ></GeoSuggest>
                </div>
                {addressString && (
                  <Alert severity="success">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {addressString}
                      <StaticMapPreviewPoint
                        coordinate={{
                          lat: createShiftBreak.end_latitude!,
                          lng: createShiftBreak.end_longitude!,
                        }}
                      ></StaticMapPreviewPoint>
                    </div>
                  </Alert>
                )}

                <div
                  className="button-container"
                  style={{ justifyContent: "end" }}
                >
                  <button
                    className="button-outline btn"
                    type="button"
                    onClick={(e) => {
                      setShowAddBreakModal(false);
                      setCreateShiftBreak({
                        end_latitude: undefined,
                        end_longitude: undefined,
                        start_time: undefined,
                        end_time: undefined,
                        duration: 30,
                      });
                      setAddressString("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="button-primary"
                    style={{ margin: "20px 0px" }}
                    type="submit"
                    disabled={
                      createShiftBreak.start_time === undefined ||
                      !createShiftBreak.end_latitude ||
                      !createShiftBreak.end_longitude ||
                      !createShiftBreak.duration
                    }
                  >
                    <span className="button-container">Add Break</span>
                  </button>
                </div>
              </form>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
}
