import axios from "axios";

interface LoginBody {
  database: string;
  username: string;
  password: string;
}
interface LoginResponse {
  success: boolean;
  token: string;
}

async function doLogin(body: LoginBody): Promise<LoginResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/auth`,
    body
  );
  return res.data;
}

export default doLogin;
