import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getJobs } from "../../../api/jobs.api";

interface CustomReportJobSelectorInputProps {
  label?: string;
  variable: string;
  type: string;
  job_required?: string;
  jobSelected: (value: number, variable: string, type: string) => void;
}

export default function CustomReportJobSelectorInput({
  label,
  jobSelected,
  variable,
  type,
  job_required = "false",
}: CustomReportJobSelectorInputProps) {
  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);
  const defaultValue = 0;

  useEffect(() => {
    jobSelected(value, variable, type);
  }, []);
  useEffect(() => {
    if (job_required == "true") {
      const newValue = jobsData?.jobs[0].id;
      setValue(newValue!);
      jobSelected(newValue!, variable, type);
    }
  }, [jobsData]);
  const [value, setValue] = useState<number>(defaultValue);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(newValue);
    jobSelected(newValue, variable, type);
  };
  return (
    <TextField
      inputProps={{ required: true, min: 0 }}
      select
      size="small"
      style={{ margin: "20px 0px" }}
      fullWidth
      label={label}
      onChange={handleChange}
      value={value}
    >
      {job_required == "false" && <MenuItem value={0}>All Jobs</MenuItem>}
      {jobsData?.jobs.map((j) => {
        return <MenuItem value={j.id}>{j.name}</MenuItem>;
      })}
    </TextField>
  );
}
