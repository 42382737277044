import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getZones } from "../api/zones.api";
import { useZoneStore } from "../stores/zone.store.zus";
import StaticMapPreviewModal from "./StaticMapPreviewModal";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import PriorityOptions from "./utility/PriorityOptions";
import StatusOptions from "./utility/StatusOptions";
import ZoneOverviewButtonGroup from "./zone/ZoneOverviewButtonGroup";

export default function ZonesOverview() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", flex: 0.5, minWidth: 100 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 100 },
    {
      field: "redirect_merchant_name",
      headerName: "Redirect Merchant Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.redirect_merchant_name ? (
              params.row.redirect_merchant_name
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <PriorityOptions priority={params.row.priority}></PriorityOptions>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "polygon",
      headerName: "Preview",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <div>
            <StaticMapPreviewModal
              polygonString={params.row.polygon}
              markerLocationLat={params.row.redirect_merchant_latitude}
              markerLocationLon={params.row.redirect_merchant_longitude}
            ></StaticMapPreviewModal>
          </div>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/zones/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    useZoneStore.getState().clearZoneStore();
  }, []);

  const navigate = useNavigate();
  const {
    data: zoneData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getZones", getZones, { refetchOnMount: true });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Zones</h2>
        <ZoneOverviewButtonGroup></ZoneOverviewButtonGroup>
      </div>
      {zoneData?.zones && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={zoneData.zones}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}
    </div>
  );
}
