import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getYesterdaysDate } from "../../../utility/getYesterdaysDate";
interface CustomReportDateSelectorInputProps {
  label?: string;
  variable: string;
  type: string;
  defaultValue?: string;
  dateSelected: (value: string, variable: string, type: string) => void;
}

export default function CustomReportDateSelectorInput({
  label,
  dateSelected,
  variable,
  type,
  defaultValue,
}: CustomReportDateSelectorInputProps) {
  const initialValue = getYesterdaysDate();
  const [date, setDate] = useState<Date>(
    defaultValue ? dayjs(defaultValue).toDate() : initialValue
  );

  useEffect(() => {
    dateSelected(dayjs(date).format("YYYY/MM/DD"), variable, type);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dayjs(date) as any}
        slotProps={{
          textField: {
            size: "small",
            sx: { width: 250, display: "block", marginTop: "20px" },
          },
        }}
        onChange={(e: Date | null) => {
          if (dayjs(e).isValid()) {
            let d = dayjs(e);
            let x = d.toDate();
            setDate(x);
            dateSelected(dayjs(x).format("YYYY/MM/DD"), variable, type);
          }
        }}
        sx={{ marginBottom: "10px" }}
      />
    </LocalizationProvider>
  );
}
