import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
export default function BackButton() {
  const navigate = useNavigate();
  return (
    <>
      <KeyboardBackspaceIcon
        onClick={() => navigate(-1)}
        sx={{
          fontSize: "32px",
          cursor: "pointer",
          margin: "0px 0px 20px -5px",
        }}
      ></KeyboardBackspaceIcon>
    </>
  );
}
