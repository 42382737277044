import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getPromotions } from "../api/promotions.api";
import StatusOptions from "./utility/StatusOptions";
import ImagePreview from "./ImagePreview";

export default function PromotionsOverview() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
    },
    {
      field: "merchant_name",
      headerName: "Merchant",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },

    {
      field: "details",
      headerName: "Details",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "thumb",
      headerName: "Thumbnail",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <ImagePreview
            imageUrl={params.row.thumb}
            showPlaceHolderDashes
          ></ImagePreview>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/promotions/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: promotionsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getPromotions", getPromotions, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Promotions</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/promotions/create");
          }}
        >
          Create Promotion
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={promotionsData?.promotions ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
