import { Checkbox, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../App";
import { updateMultipleAddresses } from "../api/address.api";
import { useAddressesStore } from "../stores/addresses.store.zus";
import BackButton from "./BackButton";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export interface EditMultipleAddressesBody {
  ids: number[];
  additional_info?: string;
  latitude?: number;
  longitude?: number;
}

export default function AddressesEditMultiple() {
  const navigate = useNavigate();
  const { addressesToEdit, setAddressesToEdit } = useAddressesStore();

  useEffect(() => {
    if (addressesToEdit.length == 0) {
      navigate("/dashboard/addresses");
    }
  }, []);
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateMultipleAddresses,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAddresses");
        queryClient.invalidateQueries("getAnAddress");
        enqueueSnackbar("Addresses were succesfully edited.", {
          variant: "success",
        });
        setAddressesToEdit([]);
        navigate("/dashboard/addresses");
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing these addresses. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const [checkboxIUnderstand, setCheckboxIUnderstand] =
    useState<boolean>(false);
  const [
    editAdditionalNotesForAllAddressesEnabled,
    setEditAdditionalNotesForAllAddressesEnabled,
  ] = useState<boolean>(false);
  const [
    editLongitudeForAllAddressesEnabled,
    setEditLongitudeForAllAddressesEnabled,
  ] = useState<boolean>(false);
  const [
    editLatitudeForAllAddressesEnabled,
    setEditLatitudeForAllAddressesEnabled,
  ] = useState<boolean>(false);
  const [newLatitude, setNewLatitude] = useState<number>();
  const [newLongitude, setNewLongitude] = useState<number>();
  const [newAdditionalInfo, setNewAdditionalInfo] = useState<string>("");

  async function submitChangesToTheseAddressesHandler() {
    let editPayload: EditMultipleAddressesBody = {
      ids: addressesToEdit.map((a) => a.id!),
    };
    if (editLatitudeForAllAddressesEnabled && newLatitude) {
      editPayload.latitude = newLatitude;
    }
    if (editLongitudeForAllAddressesEnabled && newLongitude) {
      editPayload.longitude = newLongitude;
    }
    if (editAdditionalNotesForAllAddressesEnabled && newAdditionalInfo) {
      editPayload.additional_info = newAdditionalInfo;
    }
    if (editAdditionalNotesForAllAddressesEnabled && newAdditionalInfo == "") {
      editPayload.additional_info = "";
    }
    await mutateAsync(editPayload);
    setEditAdditionalNotesForAllAddressesEnabled(false);
    setEditLatitudeForAllAddressesEnabled(false);
    setEditLongitudeForAllAddressesEnabled(false);
  }
  function renderSaveButtonHandler() {
    if (editLatitudeForAllAddressesEnabled && !newLatitude) {
      return false;
    }
    if (editLongitudeForAllAddressesEnabled && !newLongitude) {
      return false;
    }
    if (
      !editLatitudeForAllAddressesEnabled &&
      !editLongitudeForAllAddressesEnabled &&
      !editAdditionalNotesForAllAddressesEnabled
    ) {
      return false;
    }
    return true;
  }
  const columns: GridColDef[] = [
    { field: "name", headerName: "Address", flex: 1.5, minWidth: 200 },
    {
      field: "additional_info",
      headerName: "Additional Info",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.additional_info && <>{params.row.additional_info}</>}
            {!params.row.additional_info && (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
        );
      },
    },
    {
      field: "latitude",
      headerName: "Latitude",
      flex: 0.3,
    },
    {
      field: "longitude",
      headerName: "Longitude",
      flex: 0.3,
    },
  ];

  if (isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>Edit Multiple Addresses</h2>
      <FormGroup style={{ maxWidth: "200px", margin: "20px 0px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={editLatitudeForAllAddressesEnabled}
              onChange={() => {
                setEditLatitudeForAllAddressesEnabled(
                  !editLatitudeForAllAddressesEnabled
                );
                if (!editLatitudeForAllAddressesEnabled) {
                  setNewLatitude(undefined);
                }
              }}
            ></Switch>
          }
          label="Latitude"
        />
        <FormControlLabel
          control={
            <Switch
              checked={editLongitudeForAllAddressesEnabled}
              onChange={() => {
                setEditLongitudeForAllAddressesEnabled(
                  !editLongitudeForAllAddressesEnabled
                );
                if (!editLongitudeForAllAddressesEnabled) {
                  setNewLongitude(undefined);
                }
              }}
            ></Switch>
          }
          label="Longitude"
        />
        <FormControlLabel
          control={
            <Switch
              checked={editAdditionalNotesForAllAddressesEnabled}
              onChange={() => {
                setEditAdditionalNotesForAllAddressesEnabled(
                  !editAdditionalNotesForAllAddressesEnabled
                );
                setNewAdditionalInfo("");
              }}
            ></Switch>
          }
          label="Driver Notes"
        />
      </FormGroup>

      {editLatitudeForAllAddressesEnabled && (
        <div className="field-block">
          <span className="label">Latitude</span>
          <TextField
            style={{ width: "200px" }}
            type="number"
            size="small"
            value={newLatitude}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            InputProps={{ inputProps: { min: -90, max: 90 } }}
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              // setNewLatitude(value);
              if (isNaN(value)) {
                setNewLatitude(undefined);
              }
              if (value > -90 && value < 90) {
                setNewLatitude(value);
              }
              if (value > 90) {
                setNewLatitude(90);
              }
              if (value < -90) {
                setNewLatitude(-90);
              }
            }}
          ></TextField>
        </div>
      )}
      {editLongitudeForAllAddressesEnabled && (
        <div className="field-block">
          <span className="label">Longitude</span>
          <TextField
            style={{ width: "200px" }}
            type="number"
            size="small"
            value={newLongitude}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            InputProps={{ inputProps: { min: -180, max: 180 } }}
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              if (isNaN(value)) {
                setNewLongitude(undefined);
              }
              if (value > -180 && value < 180) {
                setNewLongitude(value);
              }
              if (value > 180) {
                setNewLongitude(180);
              }
              if (value < -180) {
                setNewLongitude(-180);
              }
            }}
          ></TextField>
        </div>
      )}
      {editAdditionalNotesForAllAddressesEnabled && (
        <div className="field-block">
          <span className="label">Driver Notes</span>
          <TextField
            type="text"
            size="small"
            inputProps={{ maxLength: 255 }}
            onChange={(e) => {
              setNewAdditionalInfo(e.target.value);
            }}
          ></TextField>
        </div>
      )}
      {newAdditionalInfo.length == 0 &&
        editAdditionalNotesForAllAddressesEnabled && (
          <Alert severity="warning" style={{ marginBottom: "10px" }}>
            Leaving <b>Driver Notes</b> blank will remove all driver notes for
            the selected addresses.
          </Alert>
        )}
      {addressesToEdit.length > 10 && (
        <Alert severity="warning">
          <b>
            Warning: This operation affects all of the ({addressesToEdit.length}
            ) addresses below.
          </b>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="warning"
                  checked={checkboxIUnderstand}
                  onChange={(e) => {
                    setCheckboxIUnderstand(!checkboxIUnderstand);
                  }}
                />
              }
              label="I understand"
            />
          </FormGroup>
        </Alert>
      )}

      {renderSaveButtonHandler() && addressesToEdit.length < 10 && (
        <Alert severity="info">
          The above changes will be applied to all of the following{" "}
          <b>({addressesToEdit.length})</b> addresses:
          <br />
        </Alert>
      )}

      {renderSaveButtonHandler() && (
        <button
          style={{ margin: "20px 0px" }}
          className="button-primary"
          disabled={addressesToEdit.length > 10 && !checkboxIUnderstand}
          onClick={submitChangesToTheseAddressesHandler}
        >
          SAVE CHANGES
        </button>
      )}

      {addressesToEdit && (
        <div style={{ height: 1000, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={addressesToEdit}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
          />
        </div>
      )}
    </div>
  );
}
