import ClearIcon from "@mui/icons-material/Clear";
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import GeoSuggest from "@ubilabs/react-geosuggest";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import {
  checkAccessCodeValidity,
  getAccessCodes,
} from "../api/accessCodes.api";
import { getMerchantFixtures, getMerchants } from "../api/merchants.api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  UsersRider,
  getAUserRider,
  updateAUsersRiders,
} from "../api/usersRiders.api";
import { getUsersRidersSegments } from "../api/usersRidersSegments.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import UserRiderCreatedFromOptions from "./utility/UserRiderCreatedFromOptions";
import UserStatusOptions from "./utility/UserStatusOptions";
import ErrorIcon from "@mui/icons-material/Error";
import debounce from "lodash.debounce";
dayjs.extend(advancedFormat);

export default function UsersRidersSingleInformation() {
  const { id } = useParams() as { id: string };
  const sysParamsStore = useSysParamsStore();
  const { data, status, isLoading, isRefetching } = useQuery(
    ["getAUserRider", parseInt(id)],
    () => getAUserRider(parseInt(id))
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const geoSuggestInput = useRef<GeoSuggest>(null);
  useEffect(() => {
    setUpdatedUserRider({
      ...updatedUserRider,
      id: parseInt(id),
    });
  }, []);
  const [updatedUserRider, setUpdatedUserRider] = useState<UsersRider>({
    id: parseInt(id),
  });
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAUsersRiders,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUsersRiders");
        queryClient.invalidateQueries(["getAUserRider", parseInt(id)]);
        enqueueSnackbar("Rider was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this rider. Error: ${
            (error as any).response.data.error.message || error
          }`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const {
    data: accessCodeData,
    isLoading: accessCodeDataIsLoading,
    isRefetching: accessCodeDataIsRefetching,
  } = useQuery("getAccessCodes", () => getAccessCodes(), {});
  const {
    data: accessCodeValid,
    isLoading: accessCodeValidIsLoading,
    isRefetching: accessCodeValidIsRefetching,
    isError: accessCodeValidError,
    refetch: refetchAccessCodeValid,
  } = useQuery(
    "checkAccessCodeValidity",
    () => checkAccessCodeValidity(updatedUserRider.access_code!),
    { enabled: !!updatedUserRider.access_code, retry: false }
  );
  const debouncedRefetch = debounce(refetchAccessCodeValid, 500);

  useEffect(() => {
    if (updatedUserRider.access_code) {
      debouncedRefetch();
    }
    return () => {
      debouncedRefetch.cancel();
    };
  }, [updatedUserRider.access_code]);
  const {
    data: fixtureData,
    isLoading: fixtureDataIsLoading,
    isRefetching: fixtureDataIsRefetching,
  } = useQuery("getMerchantFixtures", () => getMerchantFixtures(), {});

  const {
    data: usersRidersSegmentData,
    isLoading: usersRidersSegmentDataIsLoading,
    isRefetching: usersRidersSegmentDataIsRefetching,
  } = useQuery("getUsersRidersSegments", () => getUsersRidersSegments(), {});

  async function updateUserRider(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let payload = { ...updatedUserRider };
    if (!payload.address_id == undefined || null) {
      payload.address_id = null;
    }
    if (payload.segment_id == 0) {
      payload.segment_id = null;
    }
    if (payload.access_code == "") {
      payload.access_code = null;
      payload.access_code_id = null;
    }
    if (geoSuggestInput.current) {
      if (
        geoSuggestInput.current.state.userInput == "" &&
        updatedUserRider.address_payload === null
      ) {
        payload.address_payload = null;
      }
    }
    await mutateAsync(payload);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedUserRider({ id: parseInt(id) });
  }, [editEnabled]);

  function renderDeleteFields() {
    if (editEnabled && updatedUserRider.status! == 0) {
      return false;
    }
    if (data?.users_riders[0].status! > 0 || updatedUserRider.status! > 0) {
      return true;
    }
    return false;
  }
  function emailRegexCheck() {
    if (updatedUserRider.email) {
      let v = !/^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
        updatedUserRider.email
      );
      return v;
    }
    if (data?.users_riders[0].email) {
      return false;
    }
  }
  function phoneRegexCheck() {
    if (updatedUserRider.phone_number) {
      let v = !/^[\d+ -]{5,32}$/.test(updatedUserRider.phone_number);
      return v;
    }
    if (data?.users_riders[0].phone_number) {
      return false;
    }
  }
  function validBirthdayCheck() {
    if (
      dayjs(updatedUserRider.birthdate).isValid() ||
      updatedUserRider.birthdate == null
    ) {
      return false;
    } else {
      return true;
    }
  }
  function accessCodeCheck() {
    if (accessCodeValidIsLoading || accessCodeValidIsRefetching) {
      return true;
    }
    if (!accessCodeValidError || updatedUserRider.access_code == "") {
      return false;
    } else {
      return true;
    }
  }

  if (
    isLoading ||
    accessCodeDataIsLoading ||
    accessCodeDataIsRefetching ||
    usersRidersSegmentDataIsLoading ||
    usersRidersSegmentDataIsRefetching ||
    isRefetching ||
    isMutationLoading
  ) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  if (data && accessCodeData && usersRidersSegmentData) {
    return (
      <div>
        <h2>
          Rider Information{" "}
          {parseInt(id) != 1 && (
            <button
              onClick={(e) => {
                setEditEnabled(!editEnabled);
              }}
              className="button-primary-sm"
            >
              Edit
            </button>
          )}
        </h2>
        <div
          className={
            editEnabled ? "asset-container editmode" : "asset-container"
          }
        >
          <form onSubmit={updateUserRider}>
            <div className="field-block">
              <span className="label">ID</span>
              {editEnabled ? (
                <>{data?.users_riders[0].id}</>
              ) : (
                data?.users_riders[0].id
              )}
            </div>
            <div className="field-block">
              <span className="label">First Name{editEnabled ? "*" : ""}</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  required
                  sx={{ width: 300 }}
                  inputProps={{ maxLength: 64, minLength: 2, required: true }}
                  defaultValue={data?.users_riders[0].first_name}
                  placeholder={data?.users_riders[0].first_name}
                  onChange={(e) =>
                    setUpdatedUserRider({
                      ...updatedUserRider,
                      first_name: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.users_riders[0].first_name
              )}
            </div>
            <div className="field-block">
              <span className="label">Last Name</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  sx={{ width: 300 }}
                  inputProps={{ maxLength: 64, minLength: 2 }}
                  defaultValue={data?.users_riders[0].last_name}
                  placeholder={data?.users_riders[0].last_name || ""}
                  onChange={(e) =>
                    setUpdatedUserRider({
                      ...updatedUserRider,
                      last_name: e.target.value,
                    })
                  }
                ></TextField>
              ) : data?.users_riders[0].last_name ? (
                data?.users_riders[0].last_name
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>

            <div className="field-block">
              <span className="label">Email{editEnabled ? "*" : ""}</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  required
                  error={emailRegexCheck()}
                  sx={{ width: 300 }}
                  inputProps={{ maxLength: 255 }}
                  defaultValue={data?.users_riders[0].email}
                  placeholder={data?.users_riders[0].email}
                  onChange={(e) =>
                    setUpdatedUserRider({
                      ...updatedUserRider,
                      email: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.users_riders[0].email
              )}
            </div>
            <div className="field-block">
              <span className="label">
                Phone Number{editEnabled ? "*" : ""}
              </span>
              {editEnabled ? (
                <TextField
                  size="small"
                  required
                  error={phoneRegexCheck()}
                  sx={{ width: 300 }}
                  inputProps={{ maxLength: 32 }}
                  defaultValue={data?.users_riders[0].phone_number}
                  placeholder={data?.users_riders[0].phone_number}
                  onChange={(e) =>
                    setUpdatedUserRider({
                      ...updatedUserRider,
                      phone_number: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.users_riders[0].phone_number
              )}
            </div>

            <div className="field-block">
              <span className="label">Creation Date</span>
              {data?.users_riders[0].creation_date ? (
                dayjs(new Date(data?.users_riders[0].creation_date * 1000))
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm A z")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Update Date</span>
              {data?.users_riders[0].update_date ? (
                dayjs(new Date(data?.users_riders[0].update_date * 1000))
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm A z")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>

            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  select
                  size="small"
                  sx={{ width: 300 }}
                  defaultValue={data?.users_riders[0].status}
                  onChange={(e) =>
                    setUpdatedUserRider({
                      ...updatedUserRider,
                      status: parseInt(e.target.value),
                    })
                  }
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                  <MenuItem value={2}>Blocked</MenuItem>
                </TextField>
              ) : (
                <UserStatusOptions
                  status={data?.users_riders[0].status!}
                ></UserStatusOptions>
              )}
            </div>
            {renderDeleteFields() && (
              <div className="field-block">
                <span className="label">Delete Date</span>
                {data?.users_riders[0].delete_date ? (
                  dayjs(new Date(data?.users_riders[0].delete_date * 1000))
                    .tz(
                      useSysParamsStore
                        .getState()
                        .getParamByName("global_timezone")?.value
                    )
                    .format("MM/DD/YYYY hh:mm A z")
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}
            {renderDeleteFields() && (
              <div className="field-block">
                <span className="label">Delete Reason</span>
                {editEnabled ? (
                  <TextField
                    size="small"
                    sx={{ width: 300 }}
                    inputProps={{ maxLength: 255 }}
                    defaultValue={data?.users_riders[0].delete_reason}
                    placeholder={data?.users_riders[0].delete_reason}
                    onChange={(e) =>
                      setUpdatedUserRider({
                        ...updatedUserRider,
                        delete_reason: e.target.value,
                      })
                    }
                  ></TextField>
                ) : data?.users_riders[0].delete_reason ? (
                  data?.users_riders[0].delete_reason
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}
            <div className="field-block">
              <span className="label">Address</span>
              {editEnabled ? (
                <div>
                  {data?.users_riders[0].address_name &&
                    updatedUserRider.address_payload === undefined && (
                      <Alert severity="info" style={{ margin: "20px 0px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <>{data?.users_riders[0].address_name}</>
                          <div
                            onClick={() => {
                              setUpdatedUserRider({
                                ...updatedUserRider,
                                address_payload: null,
                              });
                              geoSuggestInput.current?.clear();
                            }}
                          >
                            <ClearIcon fontSize="small"></ClearIcon>
                          </div>
                        </div>
                      </Alert>
                    )}
                  {updatedUserRider.address_payload === null && (
                    <Alert severity="warning" style={{ margin: "20px 0px" }}>
                      <AlertTitle>
                        There is no address currently selected.
                      </AlertTitle>
                    </Alert>
                  )}
                  {updatedUserRider.address_payload && (
                    <Alert severity="success" style={{ margin: "20px 0px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {updatedUserRider.address_payload.label}
                        <div
                          onClick={() => {
                            setUpdatedUserRider({
                              ...updatedUserRider,
                              address_payload: null,
                            });
                            geoSuggestInput.current?.clear();
                          }}
                        >
                          <ClearIcon fontSize="small"></ClearIcon>
                        </div>
                      </div>
                    </Alert>
                  )}
                  <GeoSuggest
                    ref={geoSuggestInput}
                    placeholder="Type to search for an address."
                    inputClassName=""
                    types={["establishment"]}
                    location={
                      new google.maps.LatLng(
                        parseFloat(
                          sysParamsStore.getParamByName(
                            "web_dashboard_google_maps_initial_latitude"
                          )?.value!
                        ),
                        parseFloat(
                          sysParamsStore.getParamByName(
                            "web_dashboard_google_maps_initial_longitude"
                          )?.value!
                        )
                      )
                    }
                    radius="1000"
                    placeDetailFields={[
                      "formatted_address",
                      "name",
                      "geometry",
                      "type",
                    ]}
                    renderSuggestItem={(suggest) => {
                      if (suggest.isFixture) {
                        return "☆ " + suggest.label.split(",")[0];
                      }

                      return suggest.label;
                    }}
                    onSuggestSelect={(suggestion) => {
                      if (suggestion?.gmaps) {
                        let addressString =
                          (suggestion.gmaps as any).name +
                          suggestion.gmaps.formatted_address;
                        setUpdatedUserRider({
                          ...updatedUserRider,
                          address_payload: {
                            ...updatedUserRider.address_payload,
                            label: addressString,
                            location: {
                              lat: suggestion.location.lat,
                              lng: suggestion.location.lng,
                            },
                          },
                        });
                      } else {
                        if (suggestion) {
                          setUpdatedUserRider({
                            ...updatedUserRider,
                            address_payload: {
                              ...updatedUserRider.address_payload,
                              label: suggestion.label,
                              location: {
                                lat: suggestion.location.lat,
                                lng: suggestion.location.lng,
                              },
                            },
                          });
                        }
                      }
                      geoSuggestInput.current?.clear();
                    }}
                    fixtures={fixtureData?.fixtures.map((fixture) => {
                      return {
                        label: fixture.address_name!,
                        location: {
                          lat: fixture.address_latitude!,
                          lng: fixture.address_longitude!,
                        },
                      };
                    })}
                  ></GeoSuggest>
                </div>
              ) : data?.users_riders[0].address_name ? (
                data?.users_riders[0].address_name
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Created From</span>
              {editEnabled ? (
                <UserRiderCreatedFromOptions
                  status={data?.users_riders[0].created_from!}
                ></UserRiderCreatedFromOptions>
              ) : (
                <UserRiderCreatedFromOptions
                  status={data?.users_riders[0].created_from!}
                ></UserRiderCreatedFromOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Claimed</span>
              {editEnabled ? (
                <>
                  {data?.users_riders[0].claimed == 0 && <>Not Claimed</>}
                  {data?.users_riders[0].claimed == 1 && <>Claimed</>}
                </>
              ) : (
                <>
                  {data?.users_riders[0].claimed == 0 && <>Not Claimed</>}
                  {data?.users_riders[0].claimed == 1 && <>Claimed</>}
                </>
              )}
            </div>
            <div className="field-block">
              <span className="label">Claimed Date</span>
              {data?.users_riders[0].claimed_date ? (
                dayjs(new Date(data?.users_riders[0].claimed_date * 1000))
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm A z")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Access Code</span>
              {editEnabled ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    size="small"
                    sx={{ width: 300 }}
                    inputProps={{ maxLength: 64 }}
                    defaultValue={data?.users_riders[0].access_code}
                    placeholder={data?.users_riders[0].access_code ?? ""}
                    error={
                      accessCodeValidError &&
                      updatedUserRider.access_code !== ""
                    }
                    onChange={(e) =>
                      setUpdatedUserRider({
                        ...updatedUserRider,
                        access_code: e.target.value,
                      })
                    }
                  ></TextField>
                  {(accessCodeValidIsLoading ||
                    accessCodeValidIsRefetching) && (
                    <CircularProgress size={20}></CircularProgress>
                  )}
                  {accessCodeValid &&
                    !accessCodeValidError &&
                    !accessCodeValidIsLoading &&
                    !accessCodeValidIsRefetching && (
                      <CheckCircleIcon
                        color="success"
                        fontSize="small"
                      ></CheckCircleIcon>
                    )}
                  {accessCodeValidError &&
                    updatedUserRider.access_code !== "" &&
                    !accessCodeValidIsLoading &&
                    !accessCodeValidIsRefetching && (
                      <ErrorIcon color="error" fontSize="small"></ErrorIcon>
                    )}
                </div>
              ) : data?.users_riders[0].access_code_id ? (
                data?.users_riders[0].access_code
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Birthdate</span>
              {editEnabled ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="Birthdate"
                      defaultValue={
                        data?.users_riders[0]?.birthdate
                          ? (dayjs(data.users_riders[0].birthdate) as any)
                          : null
                      }
                      onChange={(e: Date | null) => {
                        if (e === null) {
                          setUpdatedUserRider({
                            ...updatedUserRider,
                            birthdate: null,
                          });
                        } else {
                          setUpdatedUserRider({
                            ...updatedUserRider,
                            birthdate: dayjs(e).format("YYYY-MM-DD"),
                          });
                        }
                      }}
                    ></DatePicker>
                  </DemoContainer>
                </LocalizationProvider>
              ) : data?.users_riders[0].birthdate ? (
                dayjs(data?.users_riders[0].birthdate).format("MM/DD/YYYY")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Rider Segment</span>
              {editEnabled ? (
                <TextField
                  select
                  size="small"
                  sx={{ width: 300 }}
                  defaultValue={data?.users_riders[0].segment_id || 0}
                  onChange={(e) =>
                    setUpdatedUserRider({
                      ...updatedUserRider,
                      segment_id: parseInt(e.target.value),
                    })
                  }
                >
                  <MenuItem value={0}>
                    <PlaceholderCharacter></PlaceholderCharacter>
                  </MenuItem>
                  {usersRidersSegmentData.users_riders_segments.map(
                    (segment) => {
                      return (
                        <MenuItem value={segment.id}>{segment.name}</MenuItem>
                      );
                    }
                  )}
                </TextField>
              ) : data?.users_riders[0].segment_id ? (
                data?.users_riders[0].segment_name
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Is Stripe Setup</span>
              {data?.users_riders[0].is_stripe_setup ? "Yes" : "No"}
            </div>
            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="button-primary btn"
                  disabled={
                    emailRegexCheck() ||
                    phoneRegexCheck() ||
                    validBirthdayCheck() ||
                    accessCodeCheck()
                  }
                >
                  Save
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
  return <></>;
}
