import { create } from "zustand";
import {
  ShiftBreak,
  ShiftScheduledCreate,
} from "../components/ShiftsScheduledCreate";
interface ShiftScheduledCreateStore extends ShiftScheduledCreate {
  startLeadTimeEnabled: boolean;
  selectedDays: Array<number>;
  amount: number;
  shiftBreaks: Array<ShiftBreak>;
  setAmount: (amount: number) => void;
  setSelectedDays: (selectedDays: Array<number>) => void;
  setJobId: (jobId: number) => void;
  setVehicleId: (vehicleId: number) => void;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  setStartTime: (startTime: number | undefined) => void;
  setEndTime: (endTime: number | undefined) => void;
  setDays: (days: string) => void;
  setStatus: (status: number) => void;
  setStartLeadTime: (start_lead_time: number) => void;
  setStartLeadTimeEnabled: (startLeadTimeEnabled: boolean) => void;
  getFullShiftCreated: () => ShiftScheduledCreate;
  setDefault: () => void;
  setShiftBreaks: (shiftBreaks: ShiftBreak[]) => void;
  removeShiftBreak: (index: number) => void;
}

export const useShiftScheduledCreateStore = create<ShiftScheduledCreateStore>(
  (set, get) => ({
    job_id: 0,
    vehicle_id: 0,
    start_date: "",
    end_date: "",
    start_time: 0 || undefined,
    end_time: 0,
    days: "",
    status: 0,
    amount: 1,
    start_lead_time: 0,
    startLeadTimeEnabled: false,
    selectedDays: [],
    shiftBreaks: [],
    setAmount: (amount) => set({ amount: amount }),
    setJobId: (jobId) => set({ job_id: jobId }),
    setVehicleId: (vehicleId) => set({ vehicle_id: vehicleId }),
    setStartDate: (startDate) => set({ start_date: startDate }),
    setEndDate: (endDate) => set({ end_date: endDate }),
    setStartTime: (startTime) => set({ start_time: startTime }),
    setEndTime: (endTime) => set({ end_time: endTime }),
    setDays: (days) => set({ days: days }),
    setStatus: (status) => set({ status: status }),
    setStartLeadTime: (start_lead_time) =>
      set({ start_lead_time: start_lead_time }),
    setSelectedDays: (selectedDays) => set({ selectedDays: selectedDays }),
    setStartLeadTimeEnabled: (startLeadTimeEnabled) =>
      set({ startLeadTimeEnabled: startLeadTimeEnabled }),
    getFullShiftCreated: () => {
      return {
        job_id: get().job_id,
        vehicle_id: get().vehicle_id,
        start_date: get().start_date,
        end_date: get().end_date,
        start_time: get().start_time,
        end_time: get().end_time,
        days: get().days,
        status: get().status,
        amount: get().amount,
        start_lead_time: get().start_lead_time,
        shift_breaks: get().shiftBreaks,
      };
    },
    setShiftBreaks: (shiftBreaks: ShiftBreak[]) => {
      set({ shiftBreaks });
    },
    removeShiftBreak: (index: number) => {
      set((state) => ({
        shiftBreaks: state.shiftBreaks.filter((_, i) => i !== index),
      }));
    },
    setDefault: () => {
      set({
        job_id: 0,
        vehicle_id: 0,
        start_date: "",
        end_date: "",
        start_time: 0 || undefined,
        end_time: 0,
        days: "",
        status: 0,
        amount: 1,
        start_lead_time: 0,
        startLeadTimeEnabled: false,
        selectedDays: [],
        shiftBreaks: [],
      });
    },
  })
);
