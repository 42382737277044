import { useAuthStore } from "../stores/auth.store.zus";

import { queryClient } from "../App";

export default function DashboardTopNavigation() {
  const { isLoggedIn, db, serviceName } = useAuthStore();
  function signoutHandler() {
    useAuthStore.getState().setToken(null);
    useAuthStore.getState().setLogin(false);
    useAuthStore.getState().setDb("");
    sessionStorage.setItem("token", "");
    queryClient.clear();
    window.location.href = "/";
  }

  return (
    <div className="dashboard-nav-container">
      <div className="dashboard-nav-left"></div>
      <div className="dashboard-nav-right">
        {process.env.REACT_APP_RUN_MODE == "prod" && (
          <div
            className="link-container"
            style={{ margin: "0px 40px 0px 0px" }}
          >
            <a
              href={`https://${serviceName}1.downtownerapp.com/dashboard/modules/login/login.php`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Dashboard
            </a>
            <a
              href={`https://${serviceName}1.downtownerapp.com/admin/modules/login/login.php`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Classic Admin
            </a>
          </div>
        )}
        {isLoggedIn && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button className="button-primary-outline" onClick={signoutHandler}>
              Sign out
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
