import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getHubRecapHourly, getHubRecapKPI } from "../api/hubRecap.api";
import { getJobs } from "../api/jobs.api";
import { getYesterdaysDate } from "../utility/getYesterdaysDate";
import StatsBlock from "./widgets/StatBlock";

dayjs.extend(timezone);
export default function HubRecapOverview() {
  const [startDate, setStartDate] = useState<Date | null>(getYesterdaysDate());
  const [endDate, setEndDate] = useState<Date | null>(getYesterdaysDate());
  const columns: GridColDef[] = [
    {
      field: "formatted_hour",
      headerName: "Hour",
      flex: 1,
    },
    {
      field: "rides",
      headerName: "Rides",
      flex: 1,
    },
    {
      field: "passengers",
      headerName: "Passengers",
      flex: 1,
    },
  ];
  const navigate = useNavigate();
  function dateFormatter(date: Date) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  const [selectedJobId, setSelectedJobId] = useState<number>();
  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);
  const {
    data: hubRecapHourlyData,
    status,
    isLoading,
    isRefetching,
    refetch: refetchHubRecapHourly,
  } = useQuery(
    "getHubRecapHourly",
    () =>
      getHubRecapHourly({
        endDate: dateFormatter(endDate!),
        startDate: dateFormatter(startDate!),
        jobId: selectedJobId || jobsData?.jobs[0].id!,
      }),
    {
      enabled: false,
    }
  );
  const {
    data: hubRecapKPIData,
    status: hubRecapKPIStatus,
    isLoading: hubRecapKPIisLoading,
    isRefetching: hubRecapKPIisRefetching,
    refetch: refetchHubRecapKPI,
  } = useQuery(
    "getHubRecapKPI",
    () =>
      getHubRecapKPI({
        endDate: dateFormatter(endDate!),
        startDate: dateFormatter(startDate!),
        jobId: selectedJobId || jobsData?.jobs[0].id!,
      }),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (jobsData) {
      setSelectedJobId(jobsData?.jobs[0].id);
      refetchHubRecapHourly();
      refetchHubRecapKPI();
    }
  }, [jobsData]);

  if (jobsIsLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {jobsData && (
        <>
          <div className="header-container">
            <h2>Daily Recap</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              margin: "20px 0",
            }}
          >
            <TextField
              size="small"
              sx={{ width: 250 }}
              label="Job"
              select
              value={selectedJobId || ""}
              onChange={(e) => {
                setSelectedJobId(parseInt(e.target.value));
              }}
            >
              {jobsData?.jobs ? (
                jobsData.jobs.map((job) => (
                  <MenuItem key={job.id} value={job.id}>
                    {job.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Loading...</MenuItem>
              )}
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={dayjs(startDate) as any}
                slotProps={{
                  textField: { size: "small", sx: { width: 250 } },
                }}
                onChange={(e: Date | null) => {
                  if (dayjs(e).isValid()) {
                    let x = dayjs(e).toDate();
                    setStartDate(x);
                  }
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                slotProps={{
                  textField: { size: "small", sx: { width: 250 } },
                }}
                value={dayjs(endDate) as any}
                onChange={(e: Date | null) => {
                  if (dayjs(e).isValid()) {
                    let x = dayjs(e).toDate();
                    setEndDate(x);
                  }
                }}
              />
            </LocalizationProvider>
            <button
              className="button-primary"
              onClick={(e) => {
                e.preventDefault();
                refetchHubRecapHourly();
                refetchHubRecapKPI();
              }}
            >
              Submit
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              margin: "20px 0",
            }}
          >
            <StatsBlock
              label={"Rides"}
              value={hubRecapKPIData?.kpi.rides!.toLocaleString()!}
              loading={hubRecapKPIisLoading || hubRecapKPIisRefetching}
            ></StatsBlock>
            <StatsBlock
              label={"Passengers"}
              value={hubRecapKPIData?.kpi.passengers!.toLocaleString()!}
              loading={hubRecapKPIisLoading || hubRecapKPIisRefetching}
            ></StatsBlock>
            <StatsBlock
              label={"Average Wait"}
              value={`${Math.round(
                hubRecapKPIData?.kpi.averageWaitSeconds! / 60
              )}m ${hubRecapKPIData?.kpi.averageWaitSeconds! % 60}s`}
              loading={hubRecapKPIisLoading || hubRecapKPIisRefetching}
            ></StatsBlock>
            <StatsBlock
              label={"Average Rating"}
              value={hubRecapKPIData?.kpi.averageRating?.toFixed(2) ?? "-"}
              loading={hubRecapKPIisLoading || hubRecapKPIisRefetching}
            ></StatsBlock>
          </div>

          <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
            <DataGrid
              rowHeight={40}
              headerHeight={45}
              rows={hubRecapHourlyData?.hubRecapHourlyStats || []}
              getRowId={(row) => row.hour}
              columns={columns}
              pageSize={24}
              rowsPerPageOptions={[24]}
              loading={isLoading || isRefetching}
            />
          </div>
        </>
      )}
    </div>
  );
}
