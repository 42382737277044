import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

import { enqueueSnackbar } from "notistack";
import { getAccessAccounts } from "../api/accessAccounts.api";
import { getJobs } from "../api/jobs.api";
import {
  JobsAccessAccountsRule,
  getAJobsAccessAccountRules,
  updateAJobsAccessAccountRule,
} from "../api/jobs_access_accounts_rules.api";
import BackButton from "./BackButton";
import AllowedOptions from "./utility/AllowedOptions";

export default function JobsAccessAccountsRulesSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAJobsAccessAccountsRules",
    () => getAJobsAccessAccountRules(parseInt(id)),
    { refetchOnMount: true }
  );
  const {
    mutateAsync: updateJobsAccessAccountsRuleMutation,
    isLoading: isMutationLoading,
  } = useMutation(updateAJobsAccessAccountRule, {
    onSuccess: () => {
      queryClient.invalidateQueries("getJobsAccessAccountsRules");
      queryClient.invalidateQueries("getAJobsAccessAccountsRules");
      enqueueSnackbar("Jobs access account rule was succesfully edited.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem editing this jobs access account rule . Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);

  const {
    data: accessAccountData,
    isLoading: accessAccountIsLoading,
    isError: accessAccountIsError,
  } = useQuery("getAccessAccounts", getAccessAccounts);
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedJobAccessAccountRule, setUpdatedJobAccessAccountRule] =
    useState<JobsAccessAccountsRule>({
      id: parseInt(id),
    });
  async function updateJobsAccessAccountRule() {
    await updateJobsAccessAccountsRuleMutation(updatedJobAccessAccountRule);
    setEditEnabled(false);
  }

  useEffect(() => {
    setUpdatedJobAccessAccountRule({ id: parseInt(id) });
  }, [editEnabled]);

  if (
    isLoading ||
    jobsIsLoading ||
    accessAccountIsLoading ||
    isMutationLoading
  ) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div style={{ marginTop: "40px" }}>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateJobsAccessAccountRule}>
          <BackButton></BackButton>
          <h2>
            Job Access Account Rule{" "}
            <button
              onClick={(e) => {
                e.preventDefault();
                setEditEnabled(!editEnabled);
              }}
              className="button-primary-sm"
            >
              Edit
            </button>
          </h2>
          <div className="field-block">
            <span className="label">Access Account</span>
            {editEnabled ? (
              <TextField
                disabled={editEnabled}
                select
                size="small"
                defaultValue={
                  data?.jobs_access_accounts_rules[0].access_account_id
                }
                placeholder={
                  data?.jobs_access_accounts_rules[0].access_account_name
                    ? data?.jobs_access_accounts_rules[0].access_account_name
                    : ""
                }
                onChange={(e) =>
                  setUpdatedJobAccessAccountRule({
                    ...updatedJobAccessAccountRule,
                    access_account_id: parseInt(e.target.value),
                  })
                }
              >
                <MenuItem value={-1}>Missing</MenuItem>
                <MenuItem value={0}>Any</MenuItem>
                {accessAccountData?.access_accounts.map((a) => {
                  return (
                    <MenuItem key={a.id} value={a.id}>
                      {a.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            ) : (
              data?.jobs_access_accounts_rules[0].access_account_name
            )}
          </div>
          <div className="field-block">
            <span className="label">Job Name</span>
            {editEnabled ? (
              <TextField
                disabled={editEnabled}
                select
                size="small"
                defaultValue={data?.jobs_access_accounts_rules[0].job_id}
                placeholder={
                  data?.jobs_access_accounts_rules[0].job_name
                    ? data?.jobs_access_accounts_rules[0].job_name
                    : ""
                }
                onChange={(e) =>
                  setUpdatedJobAccessAccountRule({
                    ...updatedJobAccessAccountRule,
                    job_id: parseInt(e.target.value),
                  })
                }
              >
                <MenuItem value={-1}>Missing</MenuItem>
                <MenuItem value={0}>Any</MenuItem>
                {jobsData?.jobs.map((j) => {
                  return (
                    <MenuItem key={j.id} value={j.id}>
                      {j.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            ) : (
              data?.jobs_access_accounts_rules[0].job_name
            )}
          </div>
          <div className="field-block">
            <span className="label">Allowed</span>
            {editEnabled ? (
              <TextField
                select
                size="small"
                defaultValue={data?.jobs_access_accounts_rules[0].allowed}
                onChange={(e) =>
                  setUpdatedJobAccessAccountRule({
                    ...updatedJobAccessAccountRule,
                    allowed: parseInt(e.target.value),
                  })
                }
              >
                <MenuItem value={0}>Not Allowed</MenuItem>
                <MenuItem value={1}>Allowed</MenuItem>
              </TextField>
            ) : (
              <AllowedOptions
                allowed={data?.jobs_access_accounts_rules[0].allowed!}
              ></AllowedOptions>
            )}
          </div>

          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button className="button-primary btn">Save</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
