import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComputerIcon from "@mui/icons-material/Computer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SecurityIcon from "@mui/icons-material/Security";
import { Chip, Stack } from "@mui/material";
interface TypeOptions {
  type: number;
}

export default function UserSysTypeOptions(props: TypeOptions) {
  if (props.type == 0) {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="warning"
            icon={<SecurityIcon />}
            label="Admin"
          />
        </Stack>
      </>
    );
  }
  if (props.type == 1) {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            icon={<DashboardIcon />}
            color="success"
            label="Dashboard"
          />
        </Stack>
      </>
    );
  }
  if (props.type == 4) {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <Chip size="small" icon={<ComputerIcon />} label="Kiosk" />
        </Stack>
      </>
    );
  }
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Chip
          size="small"
          icon={<AccountCircleIcon></AccountCircleIcon>}
          color="error"
          label="Other"
        />
      </Stack>
    </>
  );
}
