interface RoutesStopesOptionalOptions {
  optional: number;
}

export default function RoutesStopsOptionalOptions(
  props: RoutesStopesOptionalOptions
) {
  return (
    <>
      {props.optional == 0 && <>Required</>}
      {props.optional == 1 && <>Optional</>}
    </>
  );
}
