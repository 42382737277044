import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Chip, Stack } from "@mui/material";

interface DriverModeOptions {
  mode: number;
}

export default function DriverModeOptions(props: DriverModeOptions) {
  return (
    <>
      {props.mode == 0 && <>Online</>}
      {props.mode == 1 && <>In Break</>}
      {props.mode == 2 && <>Offline</>}
    </>
  );
}
