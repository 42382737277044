import React, { useState } from "react";
import { GoogleMap, PolygonF } from "@react-google-maps/api";
import { polygonStringToGoogleMapCoordinates } from "../utility/polygonStringToGoogleMapsCoordinates";
import { polygonStringToGoogleMapsCoordinateGetFirstCoordinate } from "../utility/polygonStringToGoogleMapsCoordinateGetFirstCoordinate";
import { useZoneStore } from "../stores/zone.store.zus";
const containerStyle = {
  width: "600px",
  height: "400px",
};
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
const options = {
  fillColor: "lightblue",
  fillOpacity: 0,
  strokeColor: "black",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: true,
  geodesic: false,
  zIndex: 1,
};
export default function EditableMap() {
  const zoneStore = useZoneStore();
  const [activePolygon, setActivePolygon] = useState<google.maps.Polygon>();

  const onMouseUpHandler = async (e: google.maps.MapMouseEvent) => {
    if (activePolygon) {
      let coordinates = await activePolygon?.getPath();
      let coordData = coordinates.getArray();
      let coordinatesFormatted = [];
      let i = 0;
      for (i; i < coordData.length; i++) {
        let formattedLat = coordData[i].lat().toFixed(6);
        let formattedLng = coordData[i].lng().toFixed(6);
        let formattedCoordinatePair = [formattedLat, formattedLng];
        coordinatesFormatted.push(formattedCoordinatePair);
      }
      let formattedString = "";
      coordinatesFormatted.forEach((coordArray) => {
        formattedString += coordArray.toString() + " ";
      });
      formattedString += coordinatesFormatted[0].toString() + " ";
      zoneStore.setPolygon(formattedString);
    }
  };
  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{
          streetViewControl: false,
        }}
        center={
          zoneStore.polygon
            ? polygonStringToGoogleMapsCoordinateGetFirstCoordinate(
                zoneStore.polygon
              )
            : undefined
        }
        zoom={16}
        id="drawing-manager-example"
      >
        <PolygonF
          onLoad={(polygon: google.maps.Polygon) => setActivePolygon(polygon)}
          onMouseUp={onMouseUpHandler}
          options={options}
          paths={
            zoneStore.polygon
              ? polygonStringToGoogleMapCoordinates(zoneStore.polygon)
              : undefined
          }
        ></PolygonF>
      </GoogleMap>
    </>
  );
}
