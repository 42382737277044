import polyline from "@mapbox/polyline";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useEffect, useState } from "react";
import { Path, StaticGoogleMap } from "react-static-google-map";

export interface StaticMapPreviewProps {
  polygonString: string;
  noMargin?: boolean;
  lineColor?: string;
}
export interface Coordinate {
  lat: number;
  lng: number;
}
export default function StaticMap(props: StaticMapPreviewProps) {
  const [polygonCoordinates, setPolygonCoordinates] = useState<
    Array<[number, number]>
  >([]);

  useEffect(() => {
    if (!props.polygonString) {
      return;
    }

    let pairs = props.polygonString.trim().split(" ");
    let coordinatesSanitized: Array<[number, number]> = [];
    for (let i = 0; i < pairs.length; i++) {
      let s = pairs[i].split(",");
      let lat = parseFloat(s[0]);
      let lng = parseFloat(s[1]);
      coordinatesSanitized.push([lat, lng]);
    }

    setPolygonCoordinates(coordinatesSanitized);
  }, [props.polygonString]);
  if (
    props.polygonString == null ||
    props.polygonString.length === 0 ||
    props.polygonString === undefined
  ) {
    return <SearchOffIcon></SearchOffIcon>;
  }
  return (
    <div>
      <div>
        <StaticGoogleMap
          size="500x500"
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          style="&style=feature:poi|visibility:off"
        >
          <Path
            points={`enc:${encodeURIComponent(
              polyline.encode(polygonCoordinates)
            )}`}
            color={props.lineColor || "0066ff"} //.replace("#", "0x")
            weight="3"
          />
        </StaticGoogleMap>
      </div>
    </div>
  );
}
