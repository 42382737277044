import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CircularProgress,
  Dialog,
  InputAdornment,
  Slider,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import { useMutation, useQuery } from "react-query";
import { awsGetUploadUrl, awsUploadFile } from "../../api/aws.api";
import { getCroppedImage } from "../../utility/getCroppedImage";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

interface FileUploaderProps {
  labelText?: string;
  queryKey: string;
  success: (successUrl: string) => void;
}

const ImageUploaderCrop = memo(
  function FileUploader(props: FileUploaderProps) {
    const {
      data: uploadUrl,
      isLoading: isGetUrlLoading,
      refetch: refetchUrl,
    } = useQuery([`getAnUploadUrl${props.queryKey}`, props.queryKey], () =>
      awsGetUploadUrl(props.queryKey)
    );

    const {
      mutateAsync: uploadFile,
      isLoading: isUploadFileLoading,
      isSuccess: isUploadFileSuccess,
    } = useMutation(awsUploadFile);

    const iconInput = useRef<HTMLInputElement>(null);

    const [showEditModal, setShowEditModal] = useState(false);

    const [imageSrc, setImageSrc] = useState<null | any>(null);
    const [croppedImageFile, setCroppedImageFile] = useState<null | File>(null);
    const [croppedImage, setCroppedImage] = useState<null | any>(null);

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<null | Area>(
      null
    );
    const onCropComplete = useCallback(
      (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
        // onCrop();
      },
      []
    );
    const onCrop = useCallback(async () => {
      try {
        if (croppedAreaPixels) {
          const maxOutputSize = { width: 512, height: 512 };
          const croppedImage = await getCroppedImage(
            imageSrc,
            croppedAreaPixels,
            maxOutputSize
          );
          if (croppedImage) {
            setCroppedImageFile(croppedImage);
            const croppedImageUrl = URL.createObjectURL(croppedImage);
            setCroppedImage(croppedImageUrl);
          }
          setShowEditModal(false);
          setZoom(1);
        }
      } catch (e) {
        console.error(e);
      }
    }, [croppedAreaPixels, imageSrc]);
    // Handle image selection
    const onImageChange = (e: any) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setShowEditModal(true);
        e.target.value = null;
      };
      reader.readAsDataURL(file);
    };
    async function uploadFileHandler() {
      try {
        if (!iconInput.current?.files) {
          throw new Error("No File attached.");
        }
        if (!croppedImage) {
          throw new Error("No File attached.");
        }
        const file = croppedImageFile || (iconInput.current?.files![0] as Blob);
        if (uploadUrl) {
          await uploadFile({
            file: file,
            imageType: file.type,
            url: uploadUrl.uploadUrl,
          });
          props.success(uploadUrl.fileUrl);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setImageSrc(null);
        refetchUrl();
      }
    }

    useEffect(() => {
      if (croppedImage) {
        uploadFileHandler();
      }
    }, [croppedImage]);
    return (
      <div>
        {props.labelText && <span className="label">{props.labelText}</span>}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            sx={inputBlock}
            size="small"
            type="file"
            onChange={onImageChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isUploadFileLoading && <CircularProgress size={20} />}
                  {isUploadFileSuccess && <CheckCircleIcon color="success" />}
                  {!croppedImageFile && (
                    <Tooltip
                      title={
                        "You need to select and then crop an image for it to be used as a thumbnail."
                      }
                    >
                      <InfoIcon color="primary" />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
            inputRef={iconInput}
          />
          {croppedImage && (
            <img
              src={croppedImage}
              alt="thumbnail"
              style={{
                marginLeft: "10px",
                width: "100px",
                borderRadius: "50px",
              }}
            />
          )}
        </div>
        {imageSrc && (
          <Dialog
            open={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
          >
            <>
              <div
                style={{
                  position: "relative",
                  height: "512px",
                  width: "512px",
                  border: "1px solid black",
                  padding: "20px",
                }}
              >
                <Cropper
                  objectFit={"cover"}
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  minZoom={1}
                  maxZoom={5}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  cropSize={{ width: 512, height: 512 }}
                  cropShape="round"
                  onCropComplete={onCropComplete}
                />
              </div>
              <div
                style={{
                  width: "93%",
                  margin: "20px 20px",
                  display: "inline-block",
                }}
              >
                <Slider
                  value={zoom}
                  step={0.1}
                  min={1}
                  max={5}
                  onChange={(e, newZoom) => {
                    setZoom(newZoom as number);
                  }}
                ></Slider>
                <button
                  className="button-primary-full-width"
                  type="button"
                  onClick={onCrop}
                >
                  Finished Cropping
                </button>
              </div>
            </>
          </Dialog>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.labelText === nextProps.labelText &&
      prevProps.queryKey === nextProps.queryKey &&
      prevProps.success === nextProps.success
    );
  }
);

export default ImageUploaderCrop;
