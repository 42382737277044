import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import RectangleIcon from "@mui/icons-material/Rectangle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BlockPicker } from "react-color";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { Job, getAJobWithRoute, updateAJob } from "../api/jobs.api";
import { getJobCategories } from "../api/jobs_categories.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export default function JobSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getJobs",
    () => getAJobWithRoute(parseInt(id)),
    { refetchOnMount: true }
  );
  const {
    data: jobCategoriesData,
    status: jobCategoriesStatus,
    isLoading: jobCategoriesIsLoading,
  } = useQuery("getJobCategories", () => getJobCategories(), {
    refetchOnMount: true,
  });

  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAJob,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getJobs");
        queryClient.invalidateQueries("getAJob");
        enqueueSnackbar("Job was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this Job. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [etaMaxEnabled, setEtaMaxEnabled] = useState(false);
  const [updatedJob, setUpdatedJob] = useState<Job>({
    id: parseInt(id),
  });
  async function updateJob() {
    let payload = updatedJob;
    if (etaMaxEnabled == true) {
      if (updatedJob.eta_max) {
        payload.eta_max = updatedJob.eta_max;
      }
    }
    if (etaMaxEnabled == false) {
      payload.eta_max = null;
    }

    await mutateAsync(updatedJob);
    setEditEnabled(false);
  }
  useEffect(() => {
    if (data?.jobs[0].eta_max == null) {
      setEtaMaxEnabled(false);
    }
    if (data?.jobs[0].eta_max || data?.jobs[0].eta_max == 0) {
      setEtaMaxEnabled(true);
      setUpdatedJob({
        ...updatedJob,
        eta_max: data?.jobs[0].eta_max,
      });
    }
  }, [data, editEnabled]);

  function matchCategoryIdToCategoryName() {
    if (!jobCategoriesData) {
      return;
    }
    let f = jobCategoriesData?.jobs_categories.filter((jc) => {
      return jc.id == data?.jobs[0].category_id;
    });
    if (f[0]) {
      return f[0].name;
    }
    return "";
  }

  useEffect(() => {
    setUpdatedJob({ id: parseInt(id) });
  }, [editEnabled]);

  if (
    isLoading ||
    isRefetching ||
    isMutationLoading ||
    jobCategoriesIsLoading
  ) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {data && jobCategoriesData && (
        <>
          <h2>
            Job Information{" "}
            <button
              onClick={(e) => {
                setEditEnabled(!editEnabled);
              }}
              className="button-primary-sm"
            >
              Edit
            </button>
          </h2>
          <div
            className={
              editEnabled ? "asset-container editmode" : "asset-container"
            }
          >
            {/* <form onSubmit={updateJob}> */}
            <div className="field-block">
              <span className="label">Job ID</span>
              {data?.jobs[0].id}
            </div>
            <div className="field-block">
              <span className="label">Job Type</span>
              {data?.jobs[0].job_type}
            </div>
            <div className="field-block">
              <span className="label">Name</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 64 }}
                  defaultValue={data?.jobs[0].name}
                  placeholder={data?.jobs[0].name}
                  onChange={(e) =>
                    setUpdatedJob({
                      ...updatedJob,
                      name: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.jobs[0].name
              )}
            </div>
            <div className="field-block">
              <span className="label">Short Name</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  inputProps={{ maxLength: 16 }}
                  defaultValue={data?.jobs[0].short_name}
                  placeholder={data?.jobs[0].short_name}
                  onChange={(e) =>
                    setUpdatedJob({
                      ...updatedJob,
                      short_name: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.jobs[0].short_name
              )}
            </div>

            <div className="field-block">
              <span className="label">Category</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  select
                  defaultValue={data?.jobs[0].category_id}
                  onChange={(e) =>
                    setUpdatedJob({
                      ...updatedJob,
                      category_id: parseInt(e.target.value),
                    })
                  }
                >
                  {jobCategoriesData?.jobs_categories.map((jc) => {
                    return <MenuItem value={jc.id}>{jc.name}</MenuItem>;
                  })}
                </TextField>
              ) : (
                <>{matchCategoryIdToCategoryName()}</>
              )}
            </div>

            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ required: true, min: 0 }}
                  select
                  size="small"
                  defaultValue={data?.jobs[0].status}
                  onChange={(e) =>
                    setUpdatedJob({
                      ...updatedJob,
                      status: parseInt(e.target.value),
                    })
                  }
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                </TextField>
              ) : (
                <>
                  {data?.jobs[0].status ? (
                    <Stack direction="row" spacing={1}>
                      <Chip
                        size="small"
                        icon={<NotInterestedIcon />}
                        label="Disabled"
                      />
                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={1}>
                      <Chip
                        size="small"
                        color="success"
                        icon={<CheckCircleIcon />}
                        label="Enabled"
                      />
                    </Stack>
                  )}
                </>
              )}
            </div>
            {data?.jobs[0].job_type != "Messaging" && (
              <div className="field-block">
                <span className="label">Shift Mode</span>
                {editEnabled ? (
                  <TextField
                    inputProps={{ required: true, min: 0 }}
                    select
                    size="small"
                    defaultValue={data?.jobs[0].shift_mode}
                    onChange={(e) =>
                      setUpdatedJob({
                        ...updatedJob,
                        shift_mode: parseInt(e.target.value),
                      })
                    }
                  >
                    <MenuItem value={0}>Login/Logout</MenuItem>
                    <MenuItem value={1}>Start & End Time</MenuItem>
                    <MenuItem value={2}>Ghost Shift</MenuItem>
                    <MenuItem value={3}>Scheduled Shifts</MenuItem>
                  </TextField>
                ) : (
                  <>
                    {data?.jobs[0].shift_mode == 0 && (
                      <Stack direction="row" spacing={1}>
                        <Chip
                          size="small"
                          icon={<CheckIcon />}
                          label="Login/Logout"
                        />
                      </Stack>
                    )}
                    {data?.jobs[0].shift_mode == 1 && (
                      <Stack direction="row" spacing={1}>
                        <Chip
                          size="small"
                          color="success"
                          icon={<AccessTimeIcon />}
                          label="Start & End Time"
                        />
                      </Stack>
                    )}
                    {data?.jobs[0].shift_mode == 2 && (
                      <Stack direction="row" spacing={1}>
                        <Chip
                          size="small"
                          color="info"
                          icon={<VisibilityOffIcon />}
                          label="Ghost Shift"
                        />
                      </Stack>
                    )}
                    {data?.jobs[0].shift_mode == 3 && (
                      <Stack direction="row" spacing={1}>
                        <Chip
                          size="small"
                          color="info"
                          icon={<CalendarMonthIcon />}
                          label="Scheduled Shift"
                        />
                      </Stack>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="field-block">
              <span className="label">Priority</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ min: 0, max: 255 }}
                  type="number"
                  size="small"
                  defaultValue={data?.jobs[0].eta_priority}
                  value={updatedJob.eta_priority}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (isNaN(value)) {
                      setUpdatedJob({
                        ...updatedJob,
                        eta_priority: 0,
                      });
                      return;
                    }
                    if (value > 255) {
                      setUpdatedJob({
                        ...updatedJob,
                        eta_priority: 255,
                      });
                      return;
                    }
                    if (value < 0) {
                      setUpdatedJob({
                        ...updatedJob,
                        eta_priority: 0,
                      });
                      return;
                    }
                    setUpdatedJob({
                      ...updatedJob,
                      eta_priority: parseInt(e.target.value),
                    });
                  }}
                ></TextField>
              ) : (
                data?.jobs[0].eta_priority
              )}
            </div>
            <div className="field-block">
              <span className="label">Color</span>
              {editEnabled ? (
                <BlockPicker
                  color={updatedJob.color || data.jobs[0].color || "#FFFFFF"}
                  triangle="hide"
                  colors={[
                    "#E74C3C",
                    "#3498DB",
                    "#2ECC71",
                    "#F1C40F",
                    "#E67E22",
                    "#9B59B6",
                    "#8E664D",
                    "#E84393",
                    "#2C3E50",
                    "#FFFFFF",
                  ]}
                  onChangeComplete={(c, e) => {
                    setUpdatedJob({
                      ...updatedJob,
                      color: c.hex,
                    });
                  }}
                ></BlockPicker>
              ) : data?.jobs[0].color ? (
                <>
                  {" "}
                  <RectangleIcon
                    sx={{
                      color: data?.jobs[0].color,
                      marginBottom: "-3px",
                    }}
                  ></RectangleIcon>
                  {data?.jobs[0].color}
                </>
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Text Color</span>
              {editEnabled ? (
                <BlockPicker
                  color={
                    updatedJob.text_color ||
                    data.jobs[0].text_color ||
                    "#000000"
                  }
                  triangle="hide"
                  colors={[
                    "#E74C3C",
                    "#3498DB",
                    "#2ECC71",
                    "#F1C40F",
                    "#E67E22",
                    "#9B59B6",
                    "#8E664D",
                    "#E84393",
                    "#2C3E50",
                    "#FFFFFF",
                  ]}
                  onChangeComplete={(c, e) => {
                    setUpdatedJob({
                      ...updatedJob,
                      text_color: c.hex,
                    });
                  }}
                ></BlockPicker>
              ) : data?.jobs[0].text_color ? (
                <>
                  {" "}
                  <RectangleIcon
                    sx={{
                      color: data?.jobs[0].text_color,
                      marginBottom: "-3px",
                    }}
                  ></RectangleIcon>
                  {data?.jobs[0].text_color}
                </>
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            {(data?.jobs[0].job_type == "On-Demand" ||
              data?.jobs[0].job_type == "Deviated") && (
              <div className="field-block">
                <span className="label">ETA Max</span>
                {editEnabled ? (
                  <>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={etaMaxEnabled}
                              onChange={(e) => {
                                setEtaMaxEnabled(!etaMaxEnabled);
                              }}
                            />
                          }
                          label="Enable ETA Max"
                        />
                      </FormGroup>
                      {etaMaxEnabled && (
                        <div>
                          <TextField
                            inputProps={{ min: 0 }}
                            type="number"
                            size="small"
                            defaultValue={data?.jobs[0].eta_max}
                            value={updatedJob.eta_max}
                            label="in Seconds"
                            style={
                              etaMaxEnabled ? { marginBottom: "20px" } : {}
                            }
                            onChange={(e) => {
                              let value = parseInt(e.target.value);
                              if (isNaN(value)) {
                                setUpdatedJob({
                                  ...updatedJob,
                                  eta_max: 0,
                                });
                                return;
                              }
                              if (value < 0) {
                                setUpdatedJob({
                                  ...updatedJob,
                                  eta_max: 0,
                                });
                                return;
                              }
                              setUpdatedJob({
                                ...updatedJob,
                                eta_max: parseInt(e.target.value),
                              });
                            }}
                          ></TextField>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {data.jobs[0].eta_max == undefined ||
                    data.jobs[0].eta_max == null ? (
                      <PlaceholderCharacter></PlaceholderCharacter>
                    ) : (
                      <>
                        {Math.floor(data.jobs[0].eta_max / 60)}m{" "}
                        {data.jobs[0].eta_max % 60}s
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {(data?.jobs[0].job_type == "On-Demand" ||
              data?.jobs[0].job_type == "Deviated") && (
              <div className="field-block">
                <span className="label">ETA URL</span>
                {editEnabled ? (
                  <TextField
                    size="small"
                    inputProps={{ maxLength: 255 }}
                    defaultValue={data?.jobs[0].eta_url}
                    placeholder={"ETA URL"}
                    onChange={(e) =>
                      setUpdatedJob({
                        ...updatedJob,
                        eta_url: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  data?.jobs[0].eta_url
                )}
              </div>
            )}
            {(data?.jobs[0].job_type == "On-Demand" ||
              data?.jobs[0].job_type == "Deviated") && (
              <div className="field-block">
                <span className="label">Rider Display Name</span>
                {editEnabled ? (
                  <TextField
                    size="small"
                    inputProps={{ maxLength: 255 }}
                    defaultValue={data?.jobs[0].rider_display_name || ""}
                    placeholder={"Rider Display Name"}
                    onChange={(e) =>
                      setUpdatedJob({
                        ...updatedJob,
                        rider_display_name: e.target.value,
                      })
                    }
                  ></TextField>
                ) : data?.jobs[0].rider_display_name ? (
                  data?.jobs[0].rider_display_name
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}
            {data?.jobs[0].job_type != "Messaging" && (
              <div className="field-block">
                <span className="label">Description</span>
                {editEnabled ? (
                  <TextField
                    size="small"
                    inputProps={{ maxLength: 255 }}
                    defaultValue={data?.jobs[0].description}
                    placeholder={"Description"}
                    onChange={(e) =>
                      setUpdatedJob({
                        ...updatedJob,
                        description: e.target.value,
                      })
                    }
                  ></TextField>
                ) : data?.jobs[0].description ? (
                  data?.jobs[0].description
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}

            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button className="button-primary btn" onClick={updateJob}>
                  Save
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
