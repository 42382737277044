import axios from "axios";
import { EditMultipleAddressesBody } from "../components/AddressesEditMultiple";
import { useAuthStore } from "../stores/auth.store.zus";

export interface Address {
  id?: number;
  name?: string;
  hash?: string;
  longitude?: number;
  latitude?: number;
  additional_info?: string;
}

interface AddressResponse {
  success: boolean;
  addresses: Address[];
}
interface CreateAddressResponse {
  success: boolean;
}
interface UpdateMultipleAddressesResponse {
  success: boolean;
}

export async function getAddressesWithInfo(): Promise<AddressResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/addresses/info`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getAnAddress(id: number): Promise<AddressResponse> {
  const token = useAuthStore.getState().token;

  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/addresses/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function createAddress(
  newAddress: Address
): Promise<CreateAddressResponse> {
  const token = useAuthStore.getState().token;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/addresses`,
    newAddress,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function updateAnAddress(addressToUpdate: Address) {
  const token = useAuthStore.getState().token;

  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/addresses`,
    addressToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function updateMultipleAddresses(
  addressesToUpdate: EditMultipleAddressesBody
): Promise<UpdateMultipleAddressesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/addresses/multiple`,
    addressesToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function searchAddresses(
  searchParameters: string
): Promise<AddressResponse> {
  const token = useAuthStore.getState().token;

  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/addresses/search`,
    {
      search: searchParameters,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
