interface RoutesTypeOptions {
  type: number;
}

export default function RoutesTypeOptions(props: RoutesTypeOptions) {
  return (
    <>
      {props.type == 0 && <>Fixed</>}
      {props.type == 1 && <>Deviated</>}
    </>
  );
}
