import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

interface AddressPayload {
  label: string;
  location: {
    lat: number;
    lng: number;
  };
}
export interface UsersRider {
  id?: number;
  first_name?: string;
  last_name?: string | null;
  email?: string;
  phone_number?: string;
  creation_date?: number;
  update_date?: number;
  delete_date?: number;
  delete_reason?: string;
  status?: number;
  address_id?: number | null;
  address_name?: string | null;
  address_payload?: AddressPayload | null;
  created_from?: number;
  claimed?: number;
  claimed_date?: number;
  access_code_id?: number | null;
  access_code?: string | null;
  segment_id?: number | null;
  segment_name?: string | null;
  birthdate?: string | null;
  is_stripe_setup?: boolean | null;
}
export interface ResponseGetUsersRiders {
  success: boolean;
  users_riders: UsersRider[];
}
interface ResponseGetUsersRidersPaginated {
  page: number;
  pageSize: number;
  countTotalUsersRiders: number;
  success: boolean;
  users_riders: UsersRider[];
}

export const getUsersRiders = async function (
  page: number,
  query: string = ""
): Promise<ResponseGetUsersRidersPaginated> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders/?page=${page}&queryString=${query}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAUserRider = async function (
  typeId: number
): Promise<ResponseGetUsersRiders> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders/${typeId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const updateAUsersRiders = async function (
  usersRiderFieldsToUpdate: UsersRider
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders`,
    usersRiderFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const createAUsersRiders = async function (userRider: UsersRider) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders`,
    userRider,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
