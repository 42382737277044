import axios, { AxiosError } from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface SystemParams {
  id?: number;
  name?: string;
  type?: number;
  unit?: number;
  description?: string;
  value?: string;
  update_date?: number;
  context?: number;
}
interface GetSystemParamsResponse {
  success: boolean;
  sys_params: SystemParams[];
}

export interface SystemParamsHistory {
  id: number;
  name: string;
  old_value: string;
  new_value: string;
  timestamp: number;
}
interface GetSystemParamsHistoryResponse {
  success: boolean;
  sys_params_history: SystemParamsHistory[];
}

export async function getSystemParams(): Promise<GetSystemParamsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetSystemParamsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getSystemParamsConfig(): Promise<GetSystemParamsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetSystemParamsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params/config`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getZoneCoordinatesAndZoomFromSystemParam() {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetSystemParamsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  const initZoneConfig = {
    lat: res.data.sys_params.filter((param) => {
      return param.name == "web_dashboard_google_maps_initial_latitude";
    })[0].value as string,
    lng: res.data.sys_params.filter((param) => {
      return param.name == "web_dashboard_google_maps_initial_longitude";
    })[0].value as string,
    zoom: res.data.sys_params.filter((param) => {
      return param.name == "web_dashboard_google_maps_initial_zoom";
    })[0].value as string,
  };
  return initZoneConfig;
}
export const getASystemParam = async function (id: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetSystemParamsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateASystemParam = async function (
  updateParamBody: SystemParams
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params`,
    updateParamBody,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getASystemParamsHistory = async function (id: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetSystemParamsHistoryResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params/${id}/history`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export interface RiderHomeAnnouncement {
  rider_home_announcement_1_title?: string;
  rider_home_announcement_1_subtitle?: string;
  rider_home_announcement_1_cta?: string | null;
  rider_home_announcement_1_url?: string | null;
  rider_home_announcement_1_enabled?: number;
  rider_home_announcement_1_cta_enabled?: number;
}
interface GetRiderHomeAnnouncementResponse {
  success: boolean;
  announcement: RiderHomeAnnouncement;
}

export async function getRiderHomeAnnouncement(): Promise<GetRiderHomeAnnouncementResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetRiderHomeAnnouncementResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params/rider-home-announcement`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function createUpdateRiderhomeAnnouncement(
  announcement: RiderHomeAnnouncement
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post<GetRiderHomeAnnouncementResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params/rider-home-announcement`,
    announcement,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
export async function getJobsRelatedSysParams(id: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GetSystemParamsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/sys-params/job-related-system-params/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}
