export function polygonStringValidation(polygonString: string) {
  if (typeof polygonString != "string") {
    return false;
  }
  //Check 3 points exist
  let coordinatePairs = polygonString.trim().split(" ");
  if (coordinatePairs.length < 3) {
    return false;
  }

  let numPairs = coordinatePairs.length;
  let successPairs = 0;

  //Checks validity of coordinate data
  coordinatePairs.forEach((pair) => {
    let s = pair.split(",");
    let lat = parseInt(s[0]);
    let lng = parseInt(s[1]);

    let isLatitude = isFinite(lat) && Math.abs(lat) <= 90;
    let isLongitude = isFinite(lng) && Math.abs(lng) <= 180;
    if (isLatitude && isLongitude) {
      successPairs += 1;
    }
  });

  //Checks validatity of string formatting
  let isValidFormat = false;

  if (coordinatePairs[0] == coordinatePairs[coordinatePairs.length - 1]) {
    isValidFormat = true;
  }
  if (successPairs == numPairs && isValidFormat) {
    return true;
  } else {
    return false;
  }
}
