import {
  Alert,
  Autocomplete,
  CircularProgress,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

import * as turf from "@turf/turf";
import { Polygon } from "geojson";
import { enqueueSnackbar } from "notistack";
import { getMerchants, searchMerchants } from "../api/merchants.api";
import { Zone, getAZone, getZoneKml, updateAZone } from "../api/zones.api";
import { useZoneStore } from "../stores/zone.store.zus";
import {
  coordinatesToPolygonString,
  isValidPolygonString,
  kmlStringToGeoJson,
} from "../utility/geoUtils";
import BackButton from "./BackButton";
import StaticMapPreview from "./StaticMapPreview";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import PriorityOptions from "./utility/PriorityOptions";
import StatusOptions from "./utility/StatusOptions";
import StaticMap from "./StaticMap";
import EditableMap from "./EditableMap";

const inputBlock = {
  display: "block",
  // margin: "20px 0px",
};

export default function ZoneSingle() {
  const { id } = useParams() as { id: string };
  const {
    data: zoneData,
    status,
    isLoading,
    isRefetching,
    refetch: refetchZone,
  } = useQuery("getAZone", () => getAZone(parseInt(id)), {
    refetchOnMount: true,
  });
  const {
    mutateAsync: searchMerchant,
    data: merchantData,
    isError: isMerchantSearchError,
    isLoading: isMerchantSearchLoading,
  } = useMutation(searchMerchants, {
    onSuccess: () => {
      queryClient.invalidateQueries("getMerchants");
    },
  });
  const { data: merchantsData, isLoading: isMerchantDataLoading } = useQuery(
    "getMerchants",
    getMerchants,
    { refetchOnMount: true }
  );

  const {
    data: kmlExportData,
    isLoading: isKmlExportLoading,
    isRefetching: isKmlExportRefetching,
    refetch: refetchKmlExport,
  } = useQuery("getZoneKml", () => getZoneKml(parseInt(id)), {
    enabled: false,
    onSuccess: (d) => {
      const url = window.URL.createObjectURL(d);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${zoneData?.zones[0].name}.kml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      enqueueSnackbar("Zone KML exported succesfully.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem exporting this zone KML. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  const { mutateAsync: updateZoneMutation, isLoading: isZoneUpdating } =
    useMutation(updateAZone, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAZone");
        queryClient.invalidateQueries("getZones");

        enqueueSnackbar("Zone was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this zone. Error: ${
            (error as any).response.data.error
          }`,
          {
            variant: "error",
          }
        );
      },
    });

  const { polygon, editEnabled, clearZoneStore, setPolygon, setEditEnabled } =
    useZoneStore();
  useEffect(() => {
    clearZoneStore();
    setEditEnabled(false);
    setKmlUpdateError(false);
    setAttemptedUpload(false);
    setIsPolyStringValid(false);
    setPolygon(zoneData?.zones[0].polygon!);
  }, [zoneData]);
  useEffect(() => {
    clearZoneStore();
    setKmlUpdateError(false);
    setAttemptedUpload(false);
    setIsPolyStringValid(false);
    setPolygon(zoneData?.zones[0].polygon!);
    setUpdatedZone({
      id: parseInt(id),
    });
  }, [editEnabled]);
  const [updatedZone, setUpdatedZone] = useState<Zone>({
    id: parseInt(id),
  });
  const [attemptedUpload, setAttemptedUpload] = useState(false);

  const kmlInput = useRef<HTMLInputElement>();
  const [kmlUpdateError, setKmlUpdateError] = useState<boolean>(true);
  const [isPolyStringValid, setIsPolyStringValid] = useState<boolean>(false);

  async function updateZone(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (polygon) {
      updatedZone.polygon = polygon;
    }
    await updateZoneMutation(updatedZone);
    setEditEnabled(false);
  }

  async function searchHandler(searchValue: string) {
    await searchMerchant(searchValue);
  }

  async function kmlInputHandler() {
    try {
      //Check File Exists
      setAttemptedUpload(true);
      setKmlUpdateError(false);
      setIsPolyStringValid(false);
      if (!kmlInput.current?.files) {
        throw `No File attached.`;
      }
      //Check File name contains .kml
      let fileName = kmlInput.current?.files![0];
      if (fileName.name.match(/.kml/) == null) {
        setKmlUpdateError(true);
        throw `Incorrect file type.`;
      }
      let file = kmlInput.current?.files![0] as Blob;

      if (file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              if (reader.result) {
                const kmlGeoJson = kmlStringToGeoJson(reader.result as string);

                let firstPolygon: Polygon | null = null;
                turf.flattenEach(kmlGeoJson, (feature) => {
                  if (
                    feature.geometry.type === "Polygon" &&
                    feature.geometry.coordinates.length === 1 // Make sure it is a simple polygon (no holes)
                  ) {
                    firstPolygon = feature.geometry;
                    return false; // break out of flattenEach
                  }
                });

                if (!firstPolygon) {
                  throw new Error("Object must contain a Simple Polygon");
                }

                let coordinateString = coordinatesToPolygonString(
                  (firstPolygon as Polygon).coordinates[0]
                );

                if (!isValidPolygonString(coordinateString)) {
                  throw new Error("Invalid Polygon String.");
                }
                //BIND POLYGON TO ZUS STATE
                setPolygon(coordinateString);
                setIsPolyStringValid(true);
              }
            } catch (error) {
              console.log(error);
              setKmlUpdateError(true);
            }
          },
          false
        );
        reader.readAsText(file);
      }
    } catch (error) {
      setKmlUpdateError(true);
    }
  }

  if (isLoading || isRefetching || isZoneUpdating || isMerchantDataLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Zone Information{" "}
        <span style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={(e) => {
              setEditEnabled(!editEnabled);
            }}
            className="button-primary-sm"
          >
            Edit
          </button>
          <button
            style={{ marginLeft: "10px" }}
            onClick={(e) => {
              refetchKmlExport();
            }}
            className="button-primary-sm"
          >
            Export KML
          </button>
        </span>
      </h2>
      <div
        className={
          editEnabled ? "asset-container editenabled" : "asset-container"
        }
      >
        {zoneData?.zones[0] && (
          <form onSubmit={updateZone}>
            <div className="field-block">
              <span className="label">Zone Name</span>
              {editEnabled ? (
                <TextField
                  required
                  inputProps={{ required: true }}
                  size="small"
                  style={{ width: "300px" }}
                  label="Name"
                  defaultValue={zoneData.zones[0].name}
                  onChange={(e) => {
                    let nameValue = e.target.value;
                    let s = nameValue.split(" ").join("-");
                    setUpdatedZone({ ...updatedZone, name: s });
                  }}
                ></TextField>
              ) : (
                zoneData.zones[0].name
              )}
            </div>
            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ required: true }}
                  size="small"
                  style={{ width: "300px" }}
                  sx={inputBlock}
                  label="Status"
                  select
                  defaultValue={zoneData.zones[0].status}
                  onChange={(e) => {
                    setUpdatedZone({
                      ...updatedZone,
                      status: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                  <MenuItem value={2}>Hidden</MenuItem>
                </TextField>
              ) : (
                <StatusOptions
                  status={zoneData?.zones[0].status!}
                ></StatusOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Priority</span>
              {editEnabled ? (
                <TextField
                  required
                  size="small"
                  inputProps={{ min: 0 }}
                  style={{ width: "300px" }}
                  label="Priority"
                  type="number"
                  defaultValue={zoneData.zones[0].priority}
                  onChange={(e) => {
                    setUpdatedZone({
                      ...updatedZone,
                      priority: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                ></TextField>
              ) : (
                <PriorityOptions
                  priority={zoneData?.zones[0].priority!}
                ></PriorityOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Redirect Merchant</span>
              {editEnabled ? (
                <Autocomplete
                  size="small"
                  id="asynchronous-demo"
                  sx={{ width: 300, margin: "20px 0px" }}
                  filterOptions={(x) => x} // disable the built-in filtering, https://mui.com/material-ui/react-autocomplete/#search-as-you-type
                  defaultValue={{
                    value: zoneData.zones[0].redirect_merchant_id
                      ? merchantsData?.merchants.filter((m) => {
                          return m.id == zoneData.zones[0].redirect_merchant_id;
                        })[0].id!
                      : 0,
                    label: zoneData.zones[0].redirect_merchant_name
                      ? merchantsData?.merchants.filter((m) => {
                          return (
                            m.name == zoneData.zones[0].redirect_merchant_name
                          );
                        })[0].name!
                      : "",
                  }}
                  // value={{
                  //   value: zoneData.zones[0].redirect_merchant_id!,
                  //   label: zoneData.zones[0].redirect_merchant_name!,
                  // }}
                  options={
                    merchantData?.merchants
                      ? merchantData?.merchants.map((m) => {
                          return { value: m.id, label: m.name };
                        })
                      : []
                  }
                  onChange={(e, val, reason) => {
                    if (reason == "clear") {
                      setUpdatedZone({
                        ...updatedZone,
                        redirect_merchant_id: 0,
                      });
                    }
                    if (reason == "selectOption") {
                      setUpdatedZone({
                        ...updatedZone,
                        redirect_merchant_id: val?.value,
                      });
                    }
                  }}
                  onInputChange={debounce((e, newvalue) => {
                    searchHandler(newvalue);
                  }, 500)}
                  loading={isMerchantSearchLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Redirect Merchant"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isMerchantSearchLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <>
                  {zoneData.zones[0].redirect_merchant_name ? (
                    zoneData.zones[0].redirect_merchant_name
                  ) : (
                    <PlaceholderCharacter></PlaceholderCharacter>
                  )}
                </>
              )}
            </div>
            {editEnabled && (
              <div className="field-block">
                <span className="label">KML Upload</span>
                <TextField
                  type="file"
                  sx={inputBlock}
                  error={!isPolyStringValid && attemptedUpload}
                  onChange={(e) => {
                    kmlInputHandler();
                  }}
                  inputRef={kmlInput}
                ></TextField>
                {isPolyStringValid && attemptedUpload && (
                  <StaticMapPreview polygonString={polygon!}></StaticMapPreview>
                )}
                {!isPolyStringValid && attemptedUpload && (
                  <Alert severity="error">
                    Invalid Polygon string formatting.
                  </Alert>
                )}
                {polygon && !kmlUpdateError && attemptedUpload && (
                  <Alert severity="success">Succesfully uploaded file.</Alert>
                )}
              </div>
            )}
            {editEnabled && !isLoading && <EditableMap></EditableMap>}

            {editEnabled && (
              <div className="button-container" style={{ margin: "20px 0px" }}>
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={isZoneUpdating}
                  className="button-primary btn"
                >
                  Save
                </button>
              </div>
            )}
          </form>
        )}
      </div>
      {!editEnabled && <StaticMap polygonString={polygon!}></StaticMap>}
    </div>
  );
}
