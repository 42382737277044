import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getLastMonthsStartAndEndDate } from "../../../utility/getLastMonthsStartAndEndDate";
interface CustomReportDateRangeSelectorInputProps {
  label?: string;
  variable: string;
  type: string;
  defaultValue?: string;
  dateSelected: (value: string, variable: string, type: string) => void;
}

export default function CustomReportDateRangeSelectorInput({
  label,
  dateSelected,
  variable,
  type,
  defaultValue,
}: CustomReportDateRangeSelectorInputProps) {
  const initialValue = new Date();
  const [startDate, setStartDate] = useState<Date>(
    defaultValue
      ? dayjs(defaultValue.split("-")[0]).toDate()
      : getLastMonthsStartAndEndDate()[0]
  );
  const [endDate, setEndDate] = useState<Date>(
    defaultValue
      ? dayjs(defaultValue.split("-")[1]).toDate()
      : getLastMonthsStartAndEndDate()[1]
  );
  useEffect(() => {
    let d = dayjs(initialValue).format("YYYY/MM/DD");
    dateSelected(
      `${dayjs(startDate).format("YYYY/MM/DD")}-${dayjs(endDate).format(
        "YYYY/MM/DD"
      )}`,
      variable,
      type
    );
  }, []);
  useEffect(() => {
    dateSelected(
      `${dayjs(startDate).format("YYYY/MM/DD")}-${dayjs(endDate).format(
        "YYYY/MM/DD"
      )}`,
      variable,
      type
    );
  }, [startDate, endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Start Date"
        value={dayjs(startDate) as any}
        slotProps={{
          textField: {
            size: "small",
            sx: { width: 250, display: "block", marginTop: "20px" },
          },
        }}
        onChange={(e: Date | null) => {
          if (dayjs(e).isValid()) {
            let d = dayjs(e);
            setStartDate(d.toDate());
          }
        }}
        sx={{ marginBottom: "10px" }}
      />
      <DatePicker
        label="End Date"
        value={dayjs(endDate) as any}
        slotProps={{
          textField: {
            size: "small",
            sx: { width: 250, display: "block", marginTop: "20px" },
          },
        }}
        onChange={(e: Date | null) => {
          if (dayjs(e).isValid()) {
            let d = dayjs(e);
            setEndDate(d.toDate());
          }
        }}
        sx={{ marginBottom: "10px" }}
      />
    </LocalizationProvider>
  );
}
