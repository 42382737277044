import { CircularProgress } from "@mui/material";
import React from "react";
import PlaceholderCharacter from "../utility/PlaceholderCharacter";

interface StatsBlockProps {
  value: string | number | null;
  label: string;
  loading?: boolean;
}

const StatsBlock: React.FC<StatsBlockProps> = ({
  value,
  label,
  loading = false,
}) => {
  return (
    <div
      style={{
        textAlign: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "10px",
        width: "200px",
        color: "#0179db",
      }}
    >
      {(value == null || value == undefined) && !loading && (
        <div style={{ fontSize: "2em", fontWeight: "bold" }}>
          <PlaceholderCharacter></PlaceholderCharacter>
        </div>
      )}

      <div style={{ fontSize: "2em", fontWeight: "bold" }}>
        {loading ? <CircularProgress size=".9em"></CircularProgress> : value}
      </div>

      <div style={{ color: "#666" }}>{label}</div>
    </div>
  );
};

export default StatsBlock;
