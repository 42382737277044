import { Autocomplete, MenuItem, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { queryClient } from "../App";
import { RouteStop, createARouteStop } from "../api/routesStops.api";
import { getStops } from "../api/stops.api";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
interface RoutesSingleRouteStopsCreateProps {
  closeModal: Function;
}
export default function RoutesSingleRouteStopsCreate(
  props: RoutesSingleRouteStopsCreateProps
) {
  const { id } = useParams() as { id: string };
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = routeStop;
    if (payload.description == "") {
      payload.description = null;
    }
    await saveNewRouteStop(payload);
    props.closeModal();
  }
  const [routeStop, setRouteStop] = useState<RouteStop>({
    route_id: parseInt(id),
    stop_id: 0,
    description: "",
    optional: 0,
    sequence: 1,
    status: 0,
    default_service_time: 0,
    default_travel_time: 0,
  });
  const {
    mutateAsync: saveNewRouteStop,
    isLoading: isSaveNewRouteStopLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(createARouteStop, {
    onSuccess: () => {
      enqueueSnackbar("Succesfully created new route stop.", {
        variant: "success",
      });
      queryClient.invalidateQueries("getRouteStops");
      queryClient.invalidateQueries("getARouteStop");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this route stop. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const {
    data: stopsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getStops", getStops, {
    refetchOnMount: true,
  });

  return (
    <div style={{ width: "400px" }}>
      {stopsData && (
        <form onSubmit={submitHandler}>
          <h2>Add Stop to Route</h2>
          <Autocomplete
            style={{ marginTop: "40px" }}
            disablePortal
            id="stop-select"
            onChange={(val, newVal, reason) => {
              if (reason == "clear") {
                setRouteStop({
                  ...routeStop,
                  stop_id: 0,
                });
                return;
              }
              setRouteStop({
                ...routeStop,
                stop_id: newVal?.id!,
              });
            }}
            options={stopsData.stops}
            getOptionLabel={(stop) => stop.name || ""}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Stop Select" />
            )}
          />

          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            label={"Description"}
            value={routeStop.description}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                description: e.target.value,
              })
            }
          ></TextField>
          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            type="number"
            label={"Sequence"}
            value={routeStop.sequence}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                sequence: parseInt(e.target.value),
              })
            }
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            type="number"
            label={"Default Travel Time"}
            value={routeStop.default_travel_time}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                default_travel_time: parseInt(e.target.value),
              })
            }
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            size="small"
            fullWidth
            sx={inputBlock}
            type="number"
            label={"Default Service Time"}
            value={routeStop.default_service_time}
            onChange={(e) =>
              setRouteStop({
                ...routeStop,
                default_service_time: parseInt(e.target.value),
              })
            }
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            inputProps={{ required: true, min: 0 }}
            select
            size="small"
            fullWidth
            sx={inputBlock}
            label="Status"
            defaultValue={0}
            value={routeStop?.status}
            onChange={(e) => {
              setRouteStop({
                ...routeStop,
                status: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
            <MenuItem value={2}>Hidden</MenuItem>
          </TextField>
          <TextField
            inputProps={{ required: true, min: 0 }}
            select
            size="small"
            fullWidth
            sx={inputBlock}
            label="Optional"
            defaultValue={0}
            value={routeStop?.optional}
            onChange={(e) => {
              setRouteStop({
                ...routeStop,
                optional: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Required</MenuItem>
            <MenuItem value={1}>Optional</MenuItem>
          </TextField>
          <div
            className="button-container"
            style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
          >
            <button
              disabled={routeStop.stop_id == 0}
              type="submit"
              className="button-primary"
            >
              {!isSaveNewRouteStopLoading && <>Save</>}
              {isSaveNewRouteStopLoading && (
                <CircularProgress color="inherit" size={12} />
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
