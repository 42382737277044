import { useState } from "react";
import PlaceholderCharacter from "./PlaceholderCharacter";

interface StatusOptions {
  dayOfTheWeek: string;
}

const dotw = [
  { key: "7", value: "S" },
  { key: "1", value: "M" },
  { key: "2", value: "T" },
  { key: "3", value: "W" },
  { key: "4", value: "R" },
  { key: "5", value: "F" },
  { key: "6", value: "S" },
];

export default function ShiftsDayOfTheWeek(props: StatusOptions) {
  function computeShiftDayOfTheWeekString(str: string) {
    const selectedDays = str.split(",").map((day) => parseInt(day));

    return dotw.map(({ key, value }) => {
      const dayNumber = parseInt(key);
      if (selectedDays.includes(dayNumber)) {
        return (
          <span key={key} style={{ padding: "0px 3px" }}>
            <b>{value}</b>
          </span>
        );
      } else {
        return (
          <span key={key} style={{ color: "#bfbfbf", padding: "0px 3px" }}>
            {value}
          </span>
        );
      }
    });
  }
  return (
    <>
      {props.dayOfTheWeek ? (
        computeShiftDayOfTheWeekString(props.dayOfTheWeek)
      ) : (
        <PlaceholderCharacter></PlaceholderCharacter>
      )}
    </>
  );
}
