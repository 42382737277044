import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { Chip, Stack } from "@mui/material";
import PlaceholderCharacter from "./PlaceholderCharacter";

interface DriverTypeOptions {
  type: number;
}

export default function DriverTypeOptions(props: DriverTypeOptions) {
  return (
    <>
      {props.type == 0 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="success"
            icon={<AccountCircleIcon />}
            label="Normal"
          />
        </Stack>
      )}
      {props.type == 1 && (
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            color="warning"
            icon={<NoAccountsIcon />}
            label="Ghost"
          />
        </Stack>
      )}
      {props.type != 0 && props.type != 1 && (
        <PlaceholderCharacter></PlaceholderCharacter>
      )}
    </>
  );
}
