import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import {
  CircularProgress,
  Dialog,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useQuery } from "react-query";
import { getJobs } from "../../api/jobs.api";
import {
  RideDistributionReportInput,
  getRideDistributionReport,
} from "../../api/reporting.api";
import { useAuthStore } from "../../stores/auth.store.zus";
import { formatDateToSimpleString } from "../../utility/formatDateToSimpleString";
import { getLastMonthsStartAndEndDate } from "../../utility/getLastMonthsStartAndEndDate";
import { createSafeFilename } from "../../utility/createSafeFilename";

export default function RideDistributionReport() {
  const { db } = useAuthStore();
  const [rideDistributionReportInput, setRideDistributionReportInput] =
    useState<RideDistributionReportInput>({
      startDate: getLastMonthsStartAndEndDate()[0],
      endDate: getLastMonthsStartAndEndDate()[1],
      jobId: 0,
      range: 5,
    });
  const [openModal, setOpenModal] = useState(false);
  const {
    data: jobsData,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
  } = useQuery("getJobs", getJobs);
  const {
    data: rideDistributionReportData,
    isLoading: rideDistributionReportIsLoading,
    isRefetching: rideDistributionReportIsRefetching,
    refetch: refetchRideDistributionReport,
  } = useQuery(
    "getRideDistributionReport",
    () => getRideDistributionReport(rideDistributionReportInput),
    {
      enabled: false,
      onSuccess: (d) => {
        const url = window.URL.createObjectURL(d);
        const a = document.createElement("a");
        a.href = url;
        a.download = createSafeFilename(
          db!.split("/")[2],
          "ride_distribution",
          formatDateToSimpleString(rideDistributionReportInput.startDate!),
          formatDateToSimpleString(rideDistributionReportInput.endDate!),
          "csv",
          rideDistributionReportInput.jobId != 0
            ? jobsData?.jobs.filter((j) => {
                return j.id! == rideDistributionReportInput.jobId!;
              })[0].name
            : ""
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        enqueueSnackbar("Ride Distribution Report generated succesfully.", {
          variant: "success",
        });
        setOpenModal(false);
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this report. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <div style={{ padding: "40px" }}>
            <h2 style={{ marginBottom: "20px" }}>
              Ride Distribution Report{" "}
              <Tooltip
                placement="top"
                title="Generate a ride distribution report.  Select the start and end dates the report must cover."
                style={{ marginLeft: "10px" }}
              >
                <InfoIcon fontSize="small" color="disabled"></InfoIcon>
              </Tooltip>
            </h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DatePicker
                  label="Start Date"
                  sx={{ marginBottom: "10px" }}
                  value={dayjs(rideDistributionReportInput.startDate)}
                  onChange={(e) => {
                    let newDate = dayjs(e).toDate();
                    if (newDate) {
                      setRideDistributionReportInput({
                        ...rideDistributionReportInput,
                        startDate: newDate,
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DatePicker
                  label="End Date"
                  value={dayjs(rideDistributionReportInput.endDate)}
                  onChange={(e) => {
                    let newDate = dayjs(e).toDate();
                    if (newDate) {
                      setRideDistributionReportInput({
                        ...rideDistributionReportInput,
                        endDate: newDate,
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              inputProps={{ required: true, min: 0 }}
              select
              size="small"
              style={{ margin: "20px 0px" }}
              fullWidth
              label="Select a Job"
              defaultValue={0}
              value={rideDistributionReportInput.jobId}
              onChange={(e) => {
                setRideDistributionReportInput({
                  ...rideDistributionReportInput,
                  jobId: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>All Jobs</MenuItem>
              {jobsData?.jobs.map((j) => {
                return (
                  <MenuItem value={j.id} key={j.id}>
                    {j.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              inputProps={{ required: true, min: 1 }}
              size="small"
              fullWidth
              type="number"
              label="Select a Range"
              value={rideDistributionReportInput.range}
              onChange={(e) => {
                setRideDistributionReportInput({
                  ...rideDistributionReportInput,
                  range: parseInt(e.target.value),
                });
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            ></TextField>
            <div
              className="button-container justify-end"
              style={{ marginTop: "40px" }}
            >
              <button
                onClick={() => setOpenModal(false)}
                className="button-outline"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  refetchRideDistributionReport();
                }}
                className="button-primary"
                disabled={
                  rideDistributionReportIsLoading ||
                  rideDistributionReportIsRefetching ||
                  !rideDistributionReportInput.startDate ||
                  !rideDistributionReportInput.endDate
                }
              >
                {rideDistributionReportIsLoading ||
                rideDistributionReportIsRefetching ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "white" }}
                  ></CircularProgress>
                ) : (
                  <span className="button-container">
                    Download
                    {/* <FileDownloadIcon fontSize="small"></FileDownloadIcon> */}
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog>
        <button
          onClick={() => setOpenModal(true)}
          className="button-primary-sm"
          style={{ padding: "5px 10px" }}
        >
          Generate
        </button>
      </div>
    </div>
  );
}
