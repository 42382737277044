import { Avatar } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { useNavigate } from "react-router-dom";

interface DashboardWidgetProps {
  name: string;
  description?: string;
  icon: React.ReactElement<SvgIconProps>;
  link: string;
}

export default function DashboardWidget(props: DashboardWidgetProps) {
  const navigate = useNavigate();
  return (
    <div
      className="widget-block"
      onClick={() => {
        navigate(props.link);
      }}
    >
      <div className="widget-block-icon">
        <Avatar
          sx={{
            width: 40,
            height: 40,
            bgcolor: "var(--primary-blue-lightest)",
            margin: "0px 0px 10px 0px",
          }}
        >
          {props.icon}
        </Avatar>
      </div>
      <h2>{props.name}</h2>
      {props.description && <h4>{props.description}</h4>}
    </div>
  );
}
