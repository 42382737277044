import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { transformVtzrToOverviewData } from "../utility/vehicleTypeZoneRulesOverviewTransform";

export interface VehicleTypes {
  id?: number;
  name?: string;
  max_passengers?: number;
  max_passengers_hops?: number;
  default_color?: string;
  route_id?: number;
}
export interface VehicleTypesZoneRule {
  vehicle_type_id: number;
  pick_up_zone_id: number;
  drop_off_zone_id: number;
  allowed: number;
  pick_up_zone_name?: string;
  drop_off_zone_name?: string;
}
interface ResponseGetVehicleTypes {
  success: boolean;
  vehicle_types: VehicleTypes[];
}
interface ResponseGetVehicleTypesZonesRules {
  success: boolean;
  vehicles_types_zones_rules: VehicleTypesZoneRule[];
}

export const getVehicleTypes =
  async function (): Promise<ResponseGetVehicleTypes> {
    const token = useAuthStore.getState().token;
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/vehicle-types`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };
export const getVehicleTypesZonesRules = async function (
  id: number
): Promise<ResponseGetVehicleTypesZonesRules> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types/zones-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  //do data transform checking for reversed rules
  return res.data;
};
export const getVehicleTypesZonesRulesTransformed = async function (
  id: number
) {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types/zones-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  let d = transformVtzrToOverviewData(res.data.vehicles_types_zones_rules);
  return d;
};
export const createVehicleTypeZoneRule = async function (
  payload: VehicleTypesZoneRule
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types/zones-rules`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const deleteVehicleTypeZoneRule = async function (
  payload: VehicleTypesZoneRule
) {
  const token = useAuthStore.getState().token;
  const res = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types/zones-rules`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: payload,
    }
  );
  return res.data;
};
export const getAVehicleType = async function (typeId: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types/${typeId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const updateAVehicleType = async function (
  vehicleFieldsToUpdate: VehicleTypes
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types`,
    vehicleFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const createAVehicleType = async function (vehicle: VehicleTypes) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types`,
    vehicle,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
