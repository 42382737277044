import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import BackButton from "./BackButton";
import JobsAccessAccountsRulesOverview from "./JobsAccessAccountsRulesOverview";
import VehicleTypesSingle from "./VehicleTypesSingle";
import VehicleTypesSingleZoneRules from "./VehicleTypesSingleZoneRules";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getAVehicleType } from "../api/vehicle_types.api";
export default function VehicleTypesLanding() {
  const [value, setValue] = React.useState(0);
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAVehicleType",
    () => getAVehicleType(parseInt(id)),
    { refetchOnMount: true }
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <BackButton></BackButton>
      {data && (
        <>
          <h2 style={{ margin: "20px 0px" }}>{data.vehicle_type[0].name}</h2>
        </>
      )}
      <h2></h2>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ margin: "40px 0px" }}
      >
        <Tab label="Vehicle Type Information"></Tab>
        <Tab label="Vehicle Type Zone Rules"></Tab>
      </Tabs>

      {value == 0 && <VehicleTypesSingle></VehicleTypesSingle>}
      {value == 1 && (
        <VehicleTypesSingleZoneRules></VehicleTypesSingleZoneRules>
      )}
    </div>
  );
}
