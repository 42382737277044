import { MenuItem, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { createMerchantCategory } from "../api/merchants_categories.api";
import BackButton from "./BackButton";
import FileUploader from "./utility/FileUploader";

interface CreateMerchantCategoryPayload {
  name?: string;
  priority?: number;
  status?: number;
  icon?: string;
}
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function MerchantCategoryCreate() {
  const navigate = useNavigate();
  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createMerchantCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("getMerchantCategories");
      queryClient.invalidateQueries("getAMerchantCategory");
      enqueueSnackbar("Merchant category was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/merchant-categories");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this merchhant category. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });
  const [createMerchantCategoryPayload, setCreateMerchantCategoryPayload] =
    useState<CreateMerchantCategoryPayload>({
      name: "",
      priority: 0,
      status: 0,
      icon: "",
    });

  function handleSelectChange(e: React.ChangeEvent<any>) {
    setCreateMerchantCategoryPayload({
      ...createMerchantCategoryPayload,
      status: e.target.value,
    });
  }
  function iconUploadHandler(url: string) {
    setCreateMerchantCategoryPayload({
      ...createMerchantCategoryPayload,
      icon: url,
    });
  }
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // console.log(createMerchantCategoryPayload);
    await mutateAsync(createMerchantCategoryPayload);
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Merchant Category</h2>
      {createMerchantCategoryPayload && (
        <div className="create-asset-form">
          <form onSubmit={submitHandler}>
            <TextField
              required
              inputProps={{ maxLength: 128, autoComplete: "off" }}
              sx={inputBlock}
              size="small"
              label="Name"
              onChange={(e) => {
                setCreateMerchantCategoryPayload({
                  ...createMerchantCategoryPayload,
                  name: e.target.value,
                });
              }}
            ></TextField>
            <TextField
              required
              inputProps={{ min: 0, max: 255 }}
              sx={inputBlock}
              size="small"
              value={createMerchantCategoryPayload.priority}
              type="number"
              label="Priority"
              error={
                createMerchantCategoryPayload!.priority! > 255 ||
                createMerchantCategoryPayload!.priority! < 0
              }
              onChange={(e) => {
                setCreateMerchantCategoryPayload({
                  ...createMerchantCategoryPayload,
                  priority: parseInt(e.target.value),
                });
              }}
            ></TextField>
            <TextField
              inputProps={{ min: 0, max: 1, required: true }}
              sx={inputBlock}
              size="small"
              type="number"
              label="Status"
              select
              value={createMerchantCategoryPayload.status}
              onChange={handleSelectChange}
            >
              <MenuItem value={0}>Enabled</MenuItem>
              <MenuItem value={1}>Disabled</MenuItem>
            </TextField>
            <FileUploader
              queryKey={"merchantCategoryCreateUrl"}
              success={iconUploadHandler}
              labelText="Upload an Icon"
            ></FileUploader>
            <button className="button-primary" type="submit">
              Create Merchant Category
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
