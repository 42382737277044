import { RiderHomeAnnouncement } from "../../api/sys_params.api";

interface RiderHomeAnnouncementProps {
  announcement: RiderHomeAnnouncement;
}

const buttonStyle = {
  border: "none",
  borderRadius: "20px",
  backgroundColor: "white",
  padding: "10px 20px",
  fontWeight: "bold",
  fontSize: "16px",
};

export default function AnnouncementPreview(props: RiderHomeAnnouncementProps) {
  return (
    <>
      {props.announcement.rider_home_announcement_1_enabled == 1 && (
        <div
          style={{
            backgroundColor: "#bfdff2",
            padding: "20px",
            margin: "40px 0px",
          }}
        >
          <h3>{props.announcement.rider_home_announcement_1_title}</h3>
          <p>{props.announcement.rider_home_announcement_1_subtitle}</p>
          {props.announcement.rider_home_announcement_1_cta_enabled == 1 && (
            <a
              href={props.announcement.rider_home_announcement_1_url!}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button style={buttonStyle}>
                {props.announcement.rider_home_announcement_1_cta}
              </button>
            </a>
          )}
        </div>
      )}
    </>
  );
}
