import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface RideStatusOptions {
  status: number;
  rideException?: string;
}

export default function RideStatusOptions(props: RideStatusOptions) {
  const buttonStyle = {
    minWidth: "auto",
    padding: "2px 1px",
    cursor: "default",
  };

  const tooltipText =
    props.status === 6
      ? "Completed"
      : props.status === 7
      ? props.rideException
        ? props.rideException
        : "Canceled"
      : "";

  return (
    <>
      {props.status === 6 && (
        <Tooltip title={tooltipText}>
          <Button
            variant="contained"
            style={{ ...buttonStyle, backgroundColor: "#B9B9B9" }}
          >
            <CheckIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.status === 7 && (
        <Tooltip title={tooltipText}>
          <Button
            variant="contained"
            style={{ ...buttonStyle, backgroundColor: "#D81B60" }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
