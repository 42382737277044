import { Chip } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";

interface DollarAmountChipProps {
  amountCents: number;
}

export default function DollarAmountChip({
  amountCents,
}: DollarAmountChipProps) {
  return (
    <>
      {amountCents == 0 && (
        <Chip size="small" color="success" icon={<PaidIcon />} label={`FREE`} />
      )}
      {amountCents != 0 && (
        <Chip
          size="small"
          icon={<PaidIcon />}
          label={`${(amountCents / 100).toFixed(2)}`}
        />
      )}
    </>
  );
}
