import * as React from "react";
import { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import AccessAccountSingleCodesCreate from "../AccessAccountSingleCodesCreate";
import AccessAccountSingleCodesCreateBatch from "../AccessAccountSingleCodesCreateBatch";
import AccessAccountSingleCodesCreateBatchDated from "../AccessAccountSingleCodesCreateBatchDated";

const options = ["Create Multiple Codes", "Create Dated Codes"];

export default function AccessCodeCreateButtonGroup() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(undefined);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [createBatchModalOpen, setCreateBatchModalOpen] =
    useState<boolean>(false);
  const [createBatchDatedModalOpen, setCreateBatchDatedModalOpen] =
    useState<boolean>(false);
  function closeCreateModalHandler() {
    setCreateModalOpen(false);
  }
  function closeCreateBatchModalHandler() {
    setCreateBatchModalOpen(false);
  }
  function closeCreateBatchDatedModalHandler() {
    setCreateBatchDatedModalOpen(false);
  }
  const handleClick = () => {
    setCreateModalOpen(true);
    setCreateBatchDatedModalOpen(false);
    setCreateBatchModalOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false);
    if (index === 0) {
      setCreateModalOpen(false);
      setCreateBatchDatedModalOpen(false);
      setCreateBatchModalOpen(true);
    }
    if (index === 1) {
      setCreateModalOpen(false);
      setCreateBatchModalOpen(false);
      setCreateBatchDatedModalOpen(true);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyItems: "space-between" }}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleClick}>Create Code</Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        // style={{
                        //   backgroundColor:
                        //     index === selectedIndex ? "transparent" : "initial", // Set the background color
                        // }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div>
          <Dialog
            open={createModalOpen}
            onClose={() => {
              setCreateModalOpen(false);
            }}
          >
            <div style={{ padding: "40px" }}>
              <AccessAccountSingleCodesCreate
                closeModal={closeCreateModalHandler}
              ></AccessAccountSingleCodesCreate>
            </div>
          </Dialog>
          <Dialog
            open={createBatchModalOpen}
            onClose={() => {
              setCreateBatchModalOpen(false);
            }}
          >
            <div style={{ padding: "40px" }}>
              <AccessAccountSingleCodesCreateBatch
                closeModal={closeCreateBatchModalHandler}
              ></AccessAccountSingleCodesCreateBatch>
            </div>
          </Dialog>
          <Dialog
            open={createBatchDatedModalOpen}
            onClose={() => {
              setCreateBatchDatedModalOpen(false);
            }}
          >
            <div style={{ padding: "40px" }}>
              <AccessAccountSingleCodesCreateBatchDated
                closeModal={closeCreateBatchDatedModalHandler}
              ></AccessAccountSingleCodesCreateBatchDated>
            </div>
          </Dialog>
        </div>
      </div>
    </React.Fragment>
  );
}
