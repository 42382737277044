import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import {
  ShiftScheduled,
  deleteAShift,
  getShifts,
} from "../api/shiftsScheduled.api";
import { secondsToTimeString } from "../utility/shiftsScheduledTimeHelper";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import ShiftsDayOfTheWeek from "./utility/ShiftsDayOfTheWeek";
import StatusOptions from "./utility/StatusOptions";
import { useShiftScheduledCreateStore } from "../stores/shiftsCreate.store.zus";

export default function ShiftsScheduledOverview() {
  const navigate = useNavigate();
  const {
    data: shiftData,
    isLoading,
    isRefetching,
  } = useQuery("getShifts", getShifts, { refetchOnMount: true });

  const [hideDisabledShifts, setHideDisabledShifts] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedShiftSchedule, setSelectedShiftSchedule] =
    useState<ShiftScheduled>();
  const {
    mutateAsync: deleteScheduledShift,
    isLoading: deleteScheduledShiftIsLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(deleteAShift, {
    onSuccess: () => {
      queryClient.invalidateQueries("getShifts");
      setDeleteModalOpen(false);
      enqueueSnackbar("Succesfully disabled this shift schedule.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      setDeleteModalOpen(false);
      enqueueSnackbar(
        `There was a problem deleting this scheduled shift. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  const {
    job_id,
    job_name,
    vehicle_id,
    days,
    start_date,
    end_date,
    start_time,
    end_time,
    start_lead_time,
    status,
    setDays,
    setEndDate,
    setEndTime,
    setJobId,
    setStartDate,
    setStartTime,
    setVehicleId,
    setStatus,
    setStartLeadTime,
    setStartLeadTimeEnabled,
    setDefault,
    setShiftBreaks,
  } = useShiftScheduledCreateStore();

  async function performDeleteShiftSchedule(id: number) {
    await deleteScheduledShift(id);
  }

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   flex: 0.5
    // },
    {
      field: "job_name",
      headerName: "Job Name",
      flex: 0.6,
      // resizable: true,
    },
    // {
    //   field: "vehicle_type",
    //   headerName: "Vehicle Type",
    //   flex: 0.5
    // },
    {
      field: "vehicle_type_name",
      headerName: "Vehicle Type",
      flex: 0.75,
    },
    {
      field: "days",
      headerName: "Days of Week",
      flex: 0.5,
      minWidth: 175,
      renderCell: (params) => {
        return (
          <ShiftsDayOfTheWeek
            dayOfTheWeek={params.row.days}
          ></ShiftsDayOfTheWeek>
        );
      },
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <>{dayjs(params.row.start_date).format("M/D/YY")}</>;
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <div
              style={
                dayjs(params.row.end_date)
                  .startOf("day")
                  .isBefore(dayjs(new Date()).startOf("day")) &&
                params.row.status == 0
                  ? { color: "red" }
                  : {}
              }
            >
              {dayjs(params.row.end_date).format("M/D/YY")}
            </div>
          </>
        );
      },
    },
    {
      field: "start_time",
      headerName: "Start Time",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <>{secondsToTimeString(params.row.start_time)}</>;
      },
    },
    {
      field: "end_time",
      headerName: "End Time",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return <>{secondsToTimeString(params.row.end_time)}</>;
      },
    },
    {
      field: "start_lead_time",
      headerName: "Lead Time",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.start_lead_time ? (
              <>{params.row.start_lead_time / 60} min</>
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    {
      field: "breaks",
      headerName: "Breaks",
      minWidth: 50,
      renderCell: (params) => {
        return params.row.breaks ? (
          <Tooltip
            sx={{
              tooltip: { fontSize: "1.25rem" },
            }}
            title={
              <div>
                {params.row.breaks.map((breakItem: any) => (
                  <div key={`${breakItem.start_time}-${breakItem.end_time}`}>
                    {`${secondsToTimeString(
                      breakItem.start_time
                    )} - ${secondsToTimeString(breakItem.end_time)}`}
                  </div>
                ))}
              </div>
            }
          >
            <div>{params.row.breaks.length}</div>
          </Tooltip>
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 125,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              className="button-primary-sm"
              onClick={(e) => {
                setDefault();
                setSelectedShiftSchedule(params.row);
                setJobId(params.row.job_id);
                setVehicleId(params.row.vehicle_id);
                setDays(params.row.days);
                setStartDate(params.row.start_date);
                setEndDate(params.row.end_date);
                setStartTime(params.row.start_time);
                setEndTime(params.row.end_time);
                setStatus(params.row.status);
                setStartLeadTime(params.row.start_lead_time / 60 || 0);
                setStartLeadTimeEnabled(params.row.start_lead_time);
                if (params.row.breaks) {
                  setShiftBreaks(params.row.breaks);
                }
                navigate("/dashboard/shifts-scheduled/create");
              }}
            >
              Copy
            </button>
            {params.row.status == 0 ? (
              <button
                className="button-error-sm"
                style={{ margin: "0px 5px" }}
                onClick={(e) => {
                  setSelectedShiftSchedule(params.row);
                  setDeleteModalOpen(true);
                }}
              >
                Disable
              </button>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Shift Blocks</h2>

        <button
          className="button-primary"
          onClick={() => {
            setDefault();
            navigate("/dashboard/shifts-scheduled/create");
          }}
        >
          Create New Shift Block
        </button>
      </div>
      <FormGroup style={{ marginRight: "60px", width: "250px" }}>
        <FormControlLabel
          value={0}
          control={
            <Checkbox
              checked={hideDisabledShifts}
              onChange={(e) => {
                setHideDisabledShifts(e.target.checked);
              }}
            />
          }
          label="Hide Disabled Shifts"
        />
      </FormGroup>
      {shiftData && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={
              hideDisabledShifts
                ? shiftData.shifts.filter((s) => {
                    return s.status == 0;
                  })
                : shiftData.shifts
            }
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
          <Dialog
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div style={{ padding: "40px" }}>
              <h2>Disable Shift Block</h2>
              <p>
                Are you sure you want to disable this shift block? All existing
                shifts from this block will be cancelled.
              </p>
              <div
                className="button-container"
                style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
              >
                <button
                  className="button-outline btn"
                  type="button"
                  onClick={(e) => {
                    setDeleteModalOpen(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="button-error"
                  disabled={deleteScheduledShiftIsLoading}
                  onClick={(e) => {
                    if (selectedShiftSchedule!.id) {
                      performDeleteShiftSchedule(selectedShiftSchedule?.id!);
                    }
                  }}
                >
                  {deleteScheduledShiftIsLoading ? (
                    <CircularProgress
                      size="15px"
                      style={{ color: "white" }}
                    ></CircularProgress>
                  ) : (
                    <span className="button-container">
                      Disable & Cancel Shifts
                    </span>
                  )}
                </button>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
}
