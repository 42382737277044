export const getCroppedImage = async (
  imageSrc: string,
  crop: { x: number; y: number; width: number; height: number },
  maxOutputSize: { width: number; height: number }
): Promise<File | null> => {
  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
    });

  try {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Failed to get canvas context");
    }

    // Calculate scaling factors for width and height
    const scaleX = maxOutputSize.width / crop.width;
    const scaleY = maxOutputSize.height / crop.height;

    // Use the smaller scaling factor to maintain aspect ratio and avoid upscaling
    const scale = Math.min(1, scaleX, scaleY);

    // Final canvas dimensions based on scaling factor, rounded to nearest integer
    canvas.width = Math.round(crop.width * scale);
    canvas.height = Math.round(crop.height * scale);

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise<File | null>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        const file = new File([blob], "cropped_image.jpeg", {
          type: "image/jpeg",
        });
        resolve(file);
      }, "image/jpeg");
    });
  } catch (error) {
    console.error("Error cropping the image:", error);
    return null;
  }
};
