import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { getConnectedRoutes } from "../api/stops.api";
import StaticMapPreviewModalPoint from "./StaticMapPreviewModalPoint";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";

export default function StopsSingleConnectedRoutes() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const columns: GridColDef[] = [
    // {
    //   field: "route_id",
    //   headerName: "Route Id",
    //   flex: 0.25,
    // },
    {
      field: "route_name",
      headerName: "Route Name",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Link to={`/dashboard/routes/${params.row.route_id}`}>
            {params.row.route_name}
          </Link>
        );
      },
    },
    {
      field: "stop_name",
      headerName: "Stop Name",
      flex: 1.5,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      renderCell: (params) => {
        return params.row.description ? (
          params.row.description
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "sequence",
      headerName: "Sequence",
      minWidth: 50,
    },
    {
      field: "optional",
      headerName: "Optional",
      minWidth: 50,
      renderCell: (params) => {
        return params.row.optional ? <>Required</> : <>Optional</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },
    // {
    //   field: "options",
    //   headerName: "Options",
    //   sortable: false,
    //   flex: 0.5,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <button
    //           onClick={(e) => {
    //             navigate(`/dashboard/stops/${params.row.id}`);
    //           }}
    //           className="button-primary-sm"
    //         >
    //           View
    //         </button>
    //       </>
    //     );
    //   },
    // },
  ];

  const { data, status, isLoading, isRefetching } = useQuery(
    ["getConnectedRoutes", parseInt(id)],
    () => getConnectedRoutes(parseInt(id))
  );

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Connected Routes</h2>
        {/* <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/stops/create");
          }}
        >
          Create Stop
        </button> */}
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={data?.connectedRoutes ?? []}
          getRowId={(row) => row.route_stop_id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
