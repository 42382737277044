import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import CustomReportsOverview from "./CustomReportsOverview";
import ReportingOverview from "./ReportingOverview";
export default function ReportingLanding() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Standard Reports"></Tab>
        <Tab label="Custom Reports"></Tab>
      </Tabs>

      {value == 0 && <ReportingOverview></ReportingOverview>}
      {value == 1 && <CustomReportsOverview></CustomReportsOverview>}
    </div>
  );
}
