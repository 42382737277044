interface PriorityOptions {
  priority: number;
}

export default function PriorityOptions(props: PriorityOptions) {
  function renderer(value: number) {
    if (value >= 100 && value < 200) {
      return `${value} - Walking Mall`;
    }
    if (value >= 200 && value < 300) {
      return `${value} - Service Area`;
    }
    if (value >= 300 && value < 400) {
      return `${value} - Job Rule`;
    }
    if (value >= 400 && value < 500) {
      return `${value} - Vehicle Rule`;
    }
    return `${value} - Custom`;
  }
  return <>{renderer(props.priority)}</>;
}
