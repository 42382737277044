import RectangleIcon from "@mui/icons-material/Rectangle";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getRoutes } from "../api/routes.api";
import AutohideOptions from "./utility/AutohideOptions";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";
import RoutesTypeOptions from "./utility/RoutesTypeOptions";

export default function RoutesOverview() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return params.row.name ? (
          <div
            style={{
              backgroundColor: params.row.color || "#EEEEEE",
              color: params.row.text_color || "#212121",
              padding: "3px 8px",
              borderRadius: "20px",
              fontSize: "14px",
            }}
          >
            {params.row.name}
          </div>
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return params.row.description ? (
          params.row.description
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    // {
    //   field: "redirect_description",
    //   headerName: "Redirect Description",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return params.row.redirect_description ? (
    //       params.row.redirect_description
    //     ) : (
    //       <PlaceholderCharacter></PlaceholderCharacter>
    //     );
    //   },
    // },
    // {
    //   field: "color",
    //   headerName: "Color",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return params.row.color ? (
    //       <RectangleIcon
    //         sx={{
    //           color: params.row.color,
    //           // marginTop: "5px",
    //           border: "1px solid black",
    //         }}
    //       ></RectangleIcon>
    //     ) : (
    //       <PlaceholderCharacter></PlaceholderCharacter>
    //     );
    //   },
    // },
    // {
    //   field: "text_color",
    //   headerName: "Text Color",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return params.row.text_color ? (
    //       <RectangleIcon
    //         sx={{
    //           color: params.row.text_color,
    //           // marginTop: "5px",
    //           border: "1px solid black",
    //         }}
    //       ></RectangleIcon>
    //     ) : (
    //       <PlaceholderCharacter></PlaceholderCharacter>
    //     );
    //   },
    // },
    // {
    //   field: "prediction_depth",
    //   headerName: "Prediction Depth",
    //   flex: 1,
    // },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      renderCell: (params) => {
        return <RoutesTypeOptions type={params.row.type}></RoutesTypeOptions>;
      },
    },
    {
      field: "auto_hide",
      headerName: "Autohide",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <AutohideOptions autohide={params.row.auto_hide}></AutohideOptions>
        );
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.status !== undefined || params.row.status !== null ? (
          <StatusOptions status={params.row.status}></StatusOptions>
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },

    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/routes/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: routesData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getRoutes", getRoutes, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Routes</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/routes/create");
          }}
        >
          Create Route
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={routesData?.routes ?? []}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
