import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getRidesFiltered } from "../../api/rides.api";
import { useAuthStore } from "../../stores/auth.store.zus";
import { useSysParamsStore } from "../../stores/sysParams.store.zus";
import useDebounce from "../hooks/debounce";
import PlaceholderCharacter from "./PlaceholderCharacter";
import RatingOptions from "./RatingOptions";
import RideModeOptions from "./RideModeOptions";
import RidesExceptionOptions from "./RidesExceptionOptions";
import RideStatusOptions from "./RideStatusOptions";
import { useRideRecapOverviewStore } from "../../stores/rideRecapOverview.store.zus";
import RidesCreatedFromOptions from "./RidesCreatedFromOptions";

export interface RidesFilteredDatesIncluded {
  startDate: number | string;
  endDate: number | string;
}

export interface RidesFilteredDatesExcluded {
  startDate?: never;
  endDate?: never;
}

type DateOrderBy = "asc" | "desc";

export type GetRidesFilteredInterface = (
  | RidesFilteredDatesIncluded
  | RidesFilteredDatesExcluded
) & {
  jobId?: number;
  rideId?: number;
  driverId?: number;
  rideStatus?: number[];
  rideMode?: number[];
  rideException?: string;
  rideRating?: number[];
  pickupAddress?: string;
  dropoffAddress?: string;
  riderName?: string;
  driverName?: string;
  createdFrom?: number[];
  dateOrderBy?: DateOrderBy;
  page?: number;
  pageSize?: number;
};

export default function RidesFiltered(props: GetRidesFilteredInterface) {
  const { serviceName } = useAuthStore();

  const columns: GridColDef[] = [
    {
      field: "demand_date",
      headerName: "Demand Date",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        let d = dayjs(new Date(params.row.demand_date * 1000))
          .tz(
            useSysParamsStore.getState().getParamByName("global_timezone")
              ?.value
          )
          .format("MM/DD/YY hh:mm A");
        if (d) {
          return <>{d}</>;
        } else {
          return <PlaceholderCharacter></PlaceholderCharacter>;
        }
      },
    },
    {
      field: "rider_first_name",
      headerName: "Rider Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <>
            {params.row.rider_first_name} {params.row.rider_last_name}
          </>
        );
      },
    },
    {
      field: "pickup_name",
      headerName: "Pickup",
      flex: 1.5,
      minWidth: 100,
      valueFormatter: (params) => params.value.split(",")[0], // Display text before the first comma
    },
    {
      field: "dropoff_name",
      headerName: "Dropoff",
      flex: 1.5,
      minWidth: 100,
      valueFormatter: (params) => params.value.split(",")[0], // Display text before the first comma
    },
    { field: "passengers", headerName: "Pax", flex: 0.3, minWidth: 50 },
    {
      field: "original_eta",
      headerName: "ETA",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.original_eta === null) {
          return <PlaceholderCharacter></PlaceholderCharacter>;
        }
        const totalSeconds = Math.max(0, parseInt(params.row.original_eta)); // should never be less than 0
        const totalMinutes = Math.floor(totalSeconds / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);

        let formattedTime = "";
        if (hours > 0) formattedTime += `${hours}h `;
        formattedTime += `${minutes}m`;

        return <>{formattedTime}</>;
      },
    },
    {
      field: "otp_time",
      headerName: "OTP",
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.otp_time === null) {
          return <PlaceholderCharacter></PlaceholderCharacter>;
        }
        const totalSeconds = parseInt(params.row.otp_time);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const absMinutes = Math.abs(totalMinutes);

        const hours = Math.floor(absMinutes / 60);
        const minutes = Math.floor(absMinutes % 60);

        let formattedTime = "";
        if (hours > 0) formattedTime += `${hours}h `;
        formattedTime += `${minutes}m`;

        let color = "green"; // Default to on time

        if (totalMinutes >= 5) {
          color = "red"; // 5+ min late
        } else if (totalMinutes <= -5) {
          color = "blue"; // 5+ min early
        }

        formattedTime = totalMinutes < 0 ? `-${formattedTime}` : formattedTime;

        return <div style={{ color }}>{formattedTime}</div>;
      },
    },
    {
      field: "driver_first_name",
      headerName: "Driver Name",
      flex: 0.7,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <>
            {params.row.driver_first_name} {params.row.driver_last_name}
          </>
        );
      },
    },

    {
      field: "id",
      headerName: "Ride ID",
      flex: 0.3,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <a
            href={`https://${serviceName}1.downtownerapp.com/server/modules/analytics/view_ride.php?id=${params.row.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            {params.row.id}
          </a>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      flex: 0.3,
      minWidth: 80,
      renderCell: (params) => {
        return <Link to={`/dashboard/rides/${params.row.id}`}>View</Link>;
      },
    },
    {
      field: "options",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <RideStatusOptions
              status={params.row.status}
              rideException={params.row.ride_exception}
            />
            <RideModeOptions
              mode={params.row.mode}
              jobName={params.row.job_name}
              color={params.row.job_color}
              textColor={params.row.job_text_color}
            />
            <RidesCreatedFromOptions
              created_from={params.row.created_from}
            ></RidesCreatedFromOptions>
            <RatingOptions
              rating={params.row.ride_rating}
              feedback_comment={params.row.feedback_comment}
            />

            <RidesExceptionOptions ride_exception={params.row.ride_exception} />
          </div>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState<number>(props.pageSize || 10);
  const [rowCount, setRowCount] = useState<number>(0);
  const { page, setPage } = useRideRecapOverviewStore();
  const debouncedProps = useDebounce(props, 400);

  const {
    data,
    status,
    refetch: refetch,
    isLoading,
    isRefetching,
  } = useQuery(
    ["getRides", JSON.stringify(debouncedProps), page, pageSize],
    () => getRidesFiltered({ ...debouncedProps, page: page, pageSize }),
    { refetchOnMount: true }
  );
  useEffect(() => {
    if (data) {
      setRowCount(data.total);
    }
  }, [data]);

  return (
    <>
      <div style={{ height: 920 }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={data?.rides || []}
          columns={columns}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          loading={isLoading || isRefetching}
        />
      </div>
    </>
  );
}
