import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import AddressesOverview from "./AddressesOverview";
import AddressesWithInfoOverview from "./AddressesWithInfoOverview";
import BackButton from "./BackButton";
export default function AddressesLanding() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Addresses"></Tab>
        <Tab label="Addresses With Driver Notes"></Tab>
      </Tabs>

      {value == 0 && <AddressesOverview></AddressesOverview>}
      {value == 1 && <AddressesWithInfoOverview></AddressesWithInfoOverview>}
    </div>
  );
}
