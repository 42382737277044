import React from "react";
import Login from "../components/Login";

export default function Home() {
  return (
    <div>
      <Login></Login>
    </div>
  );
}
