import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { getAddressFromLatLng } from "../api/google.api";
import { createAMerchant } from "../api/merchants.api";
import { getMerchantCategories } from "../api/merchants_categories.api";
import BackButton from "./BackButton";
import FileUploader from "./utility/FileUploader";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export interface FormattedCreateMerchantPayload {
  merchant?: {
    name?: string;
    subtitle?: string;
    image?: string;
    logo?: string;
    description?: string;
    phone_number?: string;
    hours?: string;
    website_url?: string;
    priority?: number;
    rating?: number;
    status?: number;
  };
  address?: {
    name?: string;
    longitude?: number;
    latitude?: number;
  };
  merchant_categories?: number[];
}
interface CreateMerchantPayload {
  name?: string;
  subtitle?: string;
  image?: string;
  logo?: string;
  description?: string;
  phone_number?: string;
  hours?: string;
  website_url?: string;
  priority?: number;
  rating?: number;
  status?: number;
  address_string?: string;
  address_lat?: number;
  address_lng?: number;
  merchant_categories?: number[];
}

export default function MerchantCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createMerchantPayload, setCreateMerchantPayload] =
    useState<CreateMerchantPayload>({
      name: "",
      subtitle: "",
      image: "",
      logo: "",
      description: "",
      hours: "",
      phone_number: "",
      priority: 100,
      website_url: "",
      rating: 0,
      status: 0,
      address_lat: 0.0,
      address_lng: 0.0,
      merchant_categories: [],
      address_string: "Delray, FL 33409, USA",
    });

  // INITIALIZE MERCHANT CATEGORIES
  const { data: merchantCategoryData } = useQuery(
    "getMerchantCategories",
    getMerchantCategories,
    {
      refetchOnMount: true,
    }
  );
  const [selectedMerchantCategories, setSelectedMerchantCategories] = useState<
    Array<number>
  >([]);

  function merchantCategoriesCheckboxHandler(value: number) {
    let a = createMerchantPayload.merchant_categories;
    if (!a) {
      return;
    }
    if (!a.includes(value)) {
      a.push(value);
    } else {
      a.splice(a.indexOf(value), 1);
    }
    setCreateMerchantPayload({
      ...createMerchantPayload,
      merchant_categories: a,
    });
  }

  // SET ADDRESS INFORMATION
  const [addressMode, setAddressMode] = useState<number>(0);

  // GET ADDRESS FROM LAT LNG
  const {
    data: addressFromLatLng,
    error: getAddressFromLatLngError,
    refetch,
    isRefetching: getAddressFromLatLngIsRefetching,
    isLoading: getAddressFromLatLngIsLoading,
  } = useQuery(
    [
      "getAddressFromLatLng",
      {
        lat: createMerchantPayload.address_lat,
        lng: createMerchantPayload.address_lng,
      },
    ],
    () =>
      getAddressFromLatLng({
        lat: createMerchantPayload.address_lat,
        lng: createMerchantPayload.address_lng,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    if (addressFromLatLng) {
      setCreateMerchantPayload({
        ...createMerchantPayload,
        address_string: `${addressFromLatLng}`,
      });
    }
  }, [addressFromLatLng]);

  // CREATE MERCHANT
  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAMerchant, {
    onSuccess: () => {
      queryClient.invalidateQueries("getMerchants");
      queryClient.invalidateQueries("getAMerchant");
      enqueueSnackbar("Merchant was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/merchants");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this Merchant. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });

  // ICON UPLOAD
  function iconUploadHandler(url: string) {
    setCreateMerchantPayload({
      ...createMerchantPayload,
      image: url,
    });
  }
  function imageUploadHandler(url: string) {
    setCreateMerchantPayload({
      ...createMerchantPayload,
      logo: url,
    });
  }
  async function submitHandler() {
    let payload = { ...createMerchantPayload };
    payload.address_string = `${createMerchantPayload.name?.trim()}, ${
      createMerchantPayload.address_string
    }`;
    const { address_lat, address_lng, address_string, ...merchantInfo } =
      payload;
    const { merchant_categories: merchant_categories, ...merchant } =
      merchantInfo;
    const formattedPayload: FormattedCreateMerchantPayload = {
      address: {
        latitude: address_lat,
        longitude: address_lng,
        name: address_string,
      },
      merchant,
      merchant_categories,
    };
    await mutateAsync(formattedPayload);
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Merchant</h2>
      {merchantCategoryData && (
        <div
          className="create-asset-form"
          style={{ marginTop: "20px", maxWidth: "350px" }}
        >
          <TextField
            required
            fullWidth
            inputProps={{ maxLength: 128, autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            label="Name"
            onChange={(e) => {
              setCreateMerchantPayload({
                ...createMerchantPayload,
                name: e.target.value,
              });
            }}
          ></TextField>
          <span className="label" style={{ margin: "20px 0px" }}>
            Merchant Categories
          </span>
          <Box
            sx={{
              display: "flex",
              maxHeight: "300px",
              margin: "20px 0px 20px 0px",
            }}
          >
            <FormGroup style={{ marginRight: "20px" }}>
              {merchantCategoryData?.merchants_categories.map((m, index) => {
                return (
                  <FormControlLabel
                    key={m.id}
                    value={m.id}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          merchantCategoriesCheckboxHandler(
                            parseInt(e.target.value)
                          );
                        }}
                      />
                    }
                    label={m.name}
                  />
                );
              })}
            </FormGroup>
          </Box>
          <FormControl style={{ margin: "00px 0px 20px 0px" }}>
            <span className="label" style={{ margin: "0px 0px" }}>
              Address
            </span>
          </FormControl>

          {/* IF ADDRESSMODE == 0 */}
          <>
            <div className="display-flex-center">
              <TextField
                size="small"
                style={{ marginRight: 10, width: "200px" }}
                label="Latitude"
                type="number"
                value={createMerchantPayload.address_lat}
                error={!createMerchantPayload.address_lat}
                InputProps={{
                  inputProps: { min: -90, max: 90, step: ".1" },
                }}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  let newLat;
                  if (isNaN(value)) {
                    newLat = undefined;
                  }
                  if (value > -90 && value < 90) {
                    newLat = value;
                  }
                  if (value > 90) {
                    newLat = 90;
                  }
                  if (value < -90) {
                    newLat = -90;
                  }
                  setCreateMerchantPayload({
                    ...createMerchantPayload,
                    address_lat: newLat,
                  });
                }}
              ></TextField>
              <TextField
                size="small"
                style={{ marginRight: 10, width: "200px" }}
                label="Longitude"
                type="number"
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                value={createMerchantPayload.address_lng}
                error={!createMerchantPayload.address_lng}
                InputProps={{ inputProps: { min: -180, max: 180 } }}
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  let newLng;
                  if (isNaN(value)) {
                    newLng = undefined;
                  }
                  if (value > -180 && value < 180) {
                    newLng = value;
                  }
                  if (value > 180) {
                    newLng = 180;
                  }
                  if (value < -180) {
                    newLng = -180;
                  }
                  setCreateMerchantPayload({
                    ...createMerchantPayload,
                    address_lng: newLng,
                  });
                }}
              ></TextField>
              <button
                className="button-primary"
                disabled={
                  getAddressFromLatLngIsLoading ||
                  getAddressFromLatLngIsRefetching
                }
                onClick={(e) => {
                  if (
                    createMerchantPayload.address_lng &&
                    createMerchantPayload.address_lat
                  ) {
                    refetch();
                  }
                }}
              >
                {getAddressFromLatLngIsLoading ? (
                  <CircularProgress color="inherit" size={12} />
                ) : (
                  <>Lookup Address</>
                )}
              </button>
            </div>
            {createMerchantPayload.address_string &&
              createMerchantPayload.name &&
              addressFromLatLng && (
                <>
                  <TextField
                    size="small"
                    sx={inputBlock}
                    type="text"
                    label="Address Name"
                    helperText={
                      addressFromLatLng
                        ? "The address generated from the address lookup.  You can edit this field if this does not look correct."
                        : ""
                    }
                    value={`${createMerchantPayload.address_string}` || ""}
                    onChange={(e) => {
                      setCreateMerchantPayload({
                        ...createMerchantPayload,
                        address_string: e.target.value,
                      });
                    }}
                  ></TextField>
                  <Alert severity="info">
                    Merchant Address Preview
                    <AlertTitle sx={{ fontWeight: 700 }}>
                      {createMerchantPayload.name.trim()},{" "}
                      {createMerchantPayload.address_string}
                    </AlertTitle>
                  </Alert>
                </>
              )}
          </>
          <TextField
            style={{ marginTop: "60px" }}
            size="small"
            inputProps={{ maxLength: 255 }}
            sx={inputBlock}
            fullWidth
            label="Subtitle"
            onChange={(e) => {
              setCreateMerchantPayload({
                ...createMerchantPayload,
                subtitle: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            size="small"
            sx={inputBlock}
            inputProps={{ maxLength: 65535 }}
            fullWidth
            label="Description"
            onChange={(e) => {
              setCreateMerchantPayload({
                ...createMerchantPayload,
                description: e.target.value,
              });
            }}
          ></TextField>
          <PhoneInput
            placeholder="Phone Number"
            value={createMerchantPayload.phone_number}
            defaultCountry="US"
            onChange={(e) => {
              setCreateMerchantPayload({
                ...createMerchantPayload,
                phone_number: e,
              });
            }}
          />
          {createMerchantPayload.phone_number &&
            !isPossiblePhoneNumber(createMerchantPayload.phone_number) && (
              <Alert severity="warning">Invalid Phone Number</Alert>
            )}

          <TextField
            size="small"
            sx={inputBlock}
            inputProps={{ maxLength: 65535 }}
            fullWidth
            label="Website"
            onChange={(e) => {
              setCreateMerchantPayload({
                ...createMerchantPayload,
                website_url: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            inputProps={{ min: 0, max: 255 }}
            size="small"
            sx={inputBlock}
            type="number"
            label="Priority"
            fullWidth
            // defaultValue={createMerchantPayload.priority}
            value={createMerchantPayload.priority}
            onChange={(e) => {
              let val = parseInt(e.target.value);

              setCreateMerchantPayload({
                ...createMerchantPayload,
                priority: val,
              });
            }}
          ></TextField>
          <TextField
            required
            sx={inputBlock}
            size="small"
            label="Status"
            fullWidth
            select
            defaultValue={0}
            onChange={(e) =>
              setCreateMerchantPayload({
                ...createMerchantPayload,
                status: parseInt(e.target.value),
              })
            }
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
          </TextField>
          <FileUploader
            labelText="Upload an Icon"
            queryKey={"merchantCreateIconUrl"}
            success={iconUploadHandler}
          ></FileUploader>
          <FileUploader
            labelText="Upload an Image"
            queryKey={"merchantCreateImageUrl"}
            success={imageUploadHandler}
          ></FileUploader>
          <button
            className="button-primary"
            onClick={submitHandler}
            disabled={
              !createMerchantPayload.address_lat ||
              !createMerchantPayload.address_lng ||
              !createMerchantPayload.name ||
              (createMerchantPayload.phone_number
                ? !isPossiblePhoneNumber(createMerchantPayload.phone_number)
                : false)
            }
          >
            Create Merchant
          </button>
        </div>
      )}
    </div>
  );
}
