import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface UserRiderSegment {
  id?: number;
  name?: string;
  status?: number;
  priority?: number;
}

interface ResponseGetUserRiderSegments {
  success: boolean;
  users_riders_segments: UserRiderSegment[];
}

export const getUsersRidersSegments =
  async function (): Promise<ResponseGetUserRiderSegments> {
    const token = useAuthStore.getState().token;
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/users-riders-segments`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };
export const getAUsersRidersSegment = async function (
  id: number
): Promise<ResponseGetUserRiderSegments> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders-segments/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const createAUsersRidersSegment = async function (
  userRiderSegment: UserRiderSegment
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders-segments`,
    userRiderSegment,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAUsersRidersSegment = async function (
  userRiderSegmentFieldsToUpdate: UserRiderSegment
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/users-riders-segments`,
    userRiderSegmentFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
