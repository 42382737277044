import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { SystemParams, getSystemParams } from "../api/sys_params.api";
import SystemParameterTypes from "./utility/SystemParameterTypes";
import SystemParameterUnits from "./utility/SystemParameterUnits";
import { useEffect, useState } from "react";
import { Alert, MenuItem, Snackbar, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import SystemParameterContext from "./utility/SystemParameterContext";
import { MerchantXMerchantCategory, getMerchants } from "../api/merchants.api";
import { getMerchantCategories } from "../api/merchants_categories.api";
import StatusOptions from "./utility/StatusOptions";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export default function MerchantsOverview() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", flex: 0.3, minWidth: 100 },
    { field: "name", headerName: "Name", flex: 1 },
    // { field: "subtitle", headerName: "Subtitle", flex: 1 },
    {
      field: "category_names",
      headerName: "Categories",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.category_names ? (
              params.row.category_names.split(",").join(", ")
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <StatusOptions status={params.row.status}></StatusOptions>;
      },
    },

    // { field: "image", headerName: "Image", flex: 1 },
    // { field: "logo", headerName: "Logo", flex: 1 },
    // { field: "description", headerName: "Description", flex: 1 },
    // { field: "address_id", headerName: "Address ID", flex: 1 },
    // {
    //   field: "google_place_id",
    //   headerName: "Google Place ID",
    //   flex: 1,
    //   minWidth: 250,
    // },
    // { field: "hours", headerName: "Hours", flex: 1 },
    // {
    //   field: "phone_number",
    //   headerName: "Phone Number",
    //   flex: 1,
    //   minWidth: 250,
    // },
    // { field: "priority", headerName: "Priority", flex: 1 },
    // { field: "website_url", headerName: "Website URL", flex: 1 },
    // { field: "rating", headerName: "Rating", flex: 1 },
    // {
    //   field: "creation_date",
    //   headerName: "Creation Date",
    //   flex: 1,
    // },
    // { field: "update_date", headerName: "Update Date", flex: 1 },

    // {
    //   field: "category_priority",
    //   headerName: "Category Priority",
    //   flex: 1,
    // },
    // {
    //   field: "category_status",
    //   headerName: "Category Status",
    //   flex: 1,
    // },
    // { field: "icon", headerName: "Icon", flex: 1 },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/merchants/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: merchantsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getMerchants", getMerchants, { refetchOnMount: true });
  const {
    data: merchantCategories,
    status: merchantCategoriesStatus,
    isLoading: merchantCategoriesIsLoading,
    isRefetching: merchantCategoriesIsRefetching,
  } = useQuery("getMerchantCategories", getMerchantCategories, {
    refetchOnMount: true,
  });
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [merchants, setMerchants] = useState<MerchantXMerchantCategory[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string>();

  useEffect(() => {
    if (merchantsData) {
      setMerchants(merchantsData.merchants);
    }
  }, [merchantsData]);
  useEffect(() => {
    if (!merchantsData) {
      return;
    }
    let d = merchantsData?.merchants;
    if (categoryFilter != null && categoryFilter != "None") {
      d = d.filter((merchant) => {
        if (merchant.category_names == null) {
          return;
        }
        return merchant.category_names!.includes(categoryFilter);
      });
    }
    if (searchQuery.length > 0) {
      d = d.filter((merchant) => {
        return merchant.name!.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    setMerchants(d);
  }, [categoryFilter, searchQuery]);

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Merchants</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/merchants/create");
          }}
        >
          Create New Merchant
        </button>
      </div>
      <div className="display-flex-center">
        <TextField
          SelectProps={{
            MenuProps: {
              transitionDuration: 0,
            },
          }}
          size="small"
          style={{ width: "200px", marginRight: "20px" }}
          select
          placeholder="Select a category"
          label="Category Filter"
          onChange={(e) => {
            setCategoryFilter(e.target.value);
          }}
        >
          <MenuItem value={"None"}>None</MenuItem>
          {merchantCategories?.merchants_categories.map((mc) => {
            return (
              <MenuItem value={mc.name} key={mc.id}>
                {mc.name}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon></SearchIcon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 32,
            required: true,
          }}
          size="small"
          label="Search"
          sx={inputBlock}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        ></TextField>
      </div>
      {merchants && (
        <div style={{ height: "700px", width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={merchants}
            getRowId={(row) => `${row.id}-${row.category_id}`}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}
    </div>
  );
}
