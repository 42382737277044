import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import {
  UserRiderSegment,
  getAUsersRidersSegment,
  updateAUsersRidersSegment,
} from "../api/usersRidersSegments.api";
import StatusOptions from "./utility/StatusOptions";
import BackButton from "./BackButton";
export default function UsersRidersSegmentsSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAUsersRidersSegment",
    () => getAUsersRidersSegment(parseInt(id)),
    { refetchOnMount: true }
  );

  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAUsersRidersSegment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUsersRidersSegments");
        queryClient.invalidateQueries("getAUsersRidersSegment");

        enqueueSnackbar("Rider segment was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this rider segment type. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const [editEnabled, setEditEnabled] = useState(false);

  const [updatedRiderSegment, setUpdatedUserRiderSegment] =
    useState<UserRiderSegment>({
      id: parseInt(id),
    });

  async function updateUserRiderSegment() {
    await mutateAsync(updatedRiderSegment);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedUserRiderSegment({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Rider Segment{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        {data?.users_riders_segments[0] && (
          <form onSubmit={updateUserRiderSegment}>
            <div className="field-block">
              <span className="label">Segment ID</span>
              {data?.users_riders_segments[0].id}
            </div>
            <div className="field-block">
              <span className="label">Name</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ maxLength: 255, autoComplete: "off" }}
                  required
                  size="small"
                  label="Name"
                  defaultValue={data?.users_riders_segments[0].name}
                  placeholder={data?.users_riders_segments[0].name}
                  onChange={(e) => {
                    setUpdatedUserRiderSegment({
                      ...updatedRiderSegment,
                      name: e.target.value,
                    });
                  }}
                ></TextField>
              ) : (
                data?.users_riders_segments[0].name
              )}
            </div>
            <div className="field-block">
              <span className="label">Priority</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ min: 0, max: 255 }}
                  size="small"
                  type="number"
                  label="Priority"
                  required
                  defaultValue={data?.users_riders_segments[0].priority}
                  value={updatedRiderSegment.priority}
                  onChange={(e) => {
                    setUpdatedUserRiderSegment({
                      ...updatedRiderSegment,
                      priority: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                ></TextField>
              ) : (
                data?.users_riders_segments[0].priority
              )}
            </div>
            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ min: 0, max: 1, required: true }}
                  size="small"
                  type="number"
                  label="Status"
                  select
                  defaultValue={data?.users_riders_segments[0].status}
                  value={updatedRiderSegment.status}
                  onChange={(e) => {
                    setUpdatedUserRiderSegment({
                      ...updatedRiderSegment,
                      status: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                </TextField>
              ) : (
                <StatusOptions
                  status={data?.users_riders_segments[0].status!}
                ></StatusOptions>
              )}
            </div>
            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="button-primary btn"
                  disabled={Object.keys(updatedRiderSegment).length == 1}
                >
                  Save
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
