import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface AccessAccount {
  id?: number;
  internal_code?: string | null;
  name?: string | null;
  address?: string | null;
  rider_address_id?: number;
  email?: string | null;
  free_rides_yearly?: number;
  max_rides_daily?: number;
  status?: number;
}

export interface AccessAccountResponse {
  success: boolean;
  access_accounts: AccessAccount[];
}

export const getAccessAccounts =
  async function (): Promise<AccessAccountResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get<AccessAccountResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/access-accounts`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };

export const getAnAccessAccount = async function (
  accessAccountId: number | undefined
): Promise<AccessAccountResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/access-accounts/${accessAccountId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const updateAnAccessAccount = async function (
  accessAccountToUpdate: AccessAccount
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/access-accounts`,
    accessAccountToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAnAccessAccount = async function (
  accessAccount: AccessAccount
) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/access-accounts`,
    accessAccount,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
