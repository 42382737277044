import AnnouncementIcon from "@mui/icons-material/Announcement";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import MapIcon from "@mui/icons-material/Map";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getWeather } from "../api/weather.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";

import dayjs from "dayjs";
import DashboardWidget from "./utility/DashboardWidget";
import { useAuthStore } from "../stores/auth.store.zus";
export default function DashboardHome() {
  const [time, setTime] = useState(
    dayjs(new Date())
      .tz(useSysParamsStore.getState().getParamByName("global_timezone")?.value)
      .format("ddd MMM D hh:mma z")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(
        dayjs(new Date())
          .tz(
            useSysParamsStore.getState().getParamByName("global_timezone")
              ?.value
          )
          .format("ddd MMM D hh:mma z")
      );
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const {
    data: weatherData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getWeather", getWeather, { refetchInterval: 60000 });

  return (
    <div style={{ padding: "100px 100px" }}>
      <div className="welcome-splash">
        <div className="welcome-splash-left">
          <h2>Welcome</h2>
          <h4>{time}</h4>
        </div>
        {weatherData && (
          <div className="welcome-splash-right">
            <div className="welcome-splash-right-icon-container">
              <img src={weatherData?.weather.condition.icon}></img>
            </div>
            <div className="welcome-splash-right-weather-text-container">
              <h4>
                <b>{weatherData?.weather.feelslike_f}F</b>
              </h4>
              <h4>
                {weatherData?.weather.wind_dir} {weatherData?.weather.wind_mph}
                mph
              </h4>
            </div>
          </div>
        )}
      </div>
      <div className="widget-block-container">
        {useAuthStore.getState().accessType == 255 && (
          <>
            <DashboardWidget
              name="Create a Vehicle"
              // description="Placeholder"
              icon={
                <DirectionsCarIcon
                  sx={{
                    width: 25,
                    height: 25,
                    color: "var(--primary-blue-med)",
                  }}
                />
              }
              link="/dashboard/vehicles/create"
            ></DashboardWidget>
            <DashboardWidget
              name="Create a Zone"
              // description="Placeholder"
              icon={
                <MapIcon
                  sx={{
                    width: 25,
                    height: 25,
                    color: "var(--primary-blue-med)",
                  }}
                />
              }
              link="/dashboard/zones/create"
            ></DashboardWidget>
            <DashboardWidget
              name="Create a Job"
              // description="Placeholder"
              icon={
                <WorkHistoryIcon
                  sx={{
                    width: 25,
                    height: 25,
                    color: "var(--primary-blue-med)",
                  }}
                />
              }
              link="/dashboard/jobs/create"
            ></DashboardWidget>
          </>
        )}

        <DashboardWidget
          name="Rider Home Announcement"
          // description="Placeholder"
          icon={
            <AnnouncementIcon
              sx={{ width: 25, height: 25, color: "var(--primary-blue-med)" }}
            />
          }
          link="/dashboard/rider-home-announcement"
        ></DashboardWidget>
      </div>
    </div>
  );
}
