import { LinearProgress, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { Stop, getAStop, updateAStop } from "../api/stops.api";
import BackButton from "./BackButton";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export default function StopsSingleInformation() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    ["getAStop", parseInt(id)],
    () => getAStop(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync: updateStop, isLoading: isMutationLoading } = useMutation(
    updateAStop,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getStops");
        queryClient.invalidateQueries(["getAStop", parseInt(id)]);
        enqueueSnackbar("Stop was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this stop. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedStop, setUpdatedStop] = useState<Stop>({
    id: parseInt(id),
  });
  async function updateStopHandler() {
    await updateStop(updatedStop);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedStop({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <h2>
        Stop Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateStopHandler}>
          <div className="field-block">
            <span className="label">Name</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Name"
                defaultValue={data?.stops[0].name}
                onChange={(e) => {
                  setUpdatedStop({
                    ...updatedStop,
                    name: e.target.value,
                  });
                }}
              ></TextField>
            ) : (
              data?.stops[0].name
            )}
          </div>
          <div className="field-block">
            <span className="label">Description</span>
            {editEnabled ? (
              <TextField
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Description"
                defaultValue={data?.stops[0].description}
                onChange={(e) => {
                  setUpdatedStop({
                    ...updatedStop,
                    description: e.target.value,
                  });
                }}
              ></TextField>
            ) : data?.stops[0].description ? (
              data?.stops[0].description
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Latitude</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Latitude"
                type="number"
                value={updatedStop.latitude}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                defaultValue={data?.stops[0].latitude}
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  let val;
                  if (isNaN(value)) {
                    val = undefined;
                  }
                  if (value > -90 && value < 90) {
                    val = value;
                  }
                  if (value > 90) {
                    val = 90;
                  }
                  if (value < -90) {
                    val = -90;
                  }
                  setUpdatedStop({
                    ...updatedStop,
                    latitude: val,
                  });
                }}
              ></TextField>
            ) : (
              data?.stops[0].latitude
            )}
          </div>
          <div className="field-block">
            <span className="label">Longitude</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Longitude"
                type="number"
                value={updatedStop.longitude}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                defaultValue={data?.stops[0].longitude}
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  let val;
                  if (isNaN(value)) {
                    val = undefined;
                  }
                  if (value > -180 && value < 180) {
                    val = value;
                  }
                  if (value > 180) {
                    val = 180;
                  }
                  if (value < -180) {
                    val = -180;
                  }
                  setUpdatedStop({
                    ...updatedStop,
                    longitude: val,
                  });
                }}
              ></TextField>
            ) : (
              data?.stops[0].longitude
            )}
          </div>

          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-primary btn"
                disabled={Object.keys(updatedStop).length <= 1}
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
