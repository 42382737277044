import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { transformJzrToOverviewData } from "../utility/jobZoneRulesOverviewTransform";
export interface Job {
  id?: number;
  short_name?: string;
  name?: string;
  rider_display_name?: string | null;
  driver_default_access_code?: number | null;
  description?: string;
  color?: string;
  text_color?: string;
  route_id?: number | null;
  category_id?: number;
  status?: number;
  shift_mode?: number;
  hours_mode?: number | null;
  eta_priority?: number;
  eta_max?: number | null;
  eta_url?: string | null;
  grandfather_access_account_id?: number | null;
  default_job_zone_rules?: number;
  route_type?: number | null;
  job_type?: string | null;
}

export interface JobWithRiderMessaging extends JobWithCancelMessage {
  rider_messaging_only: boolean;
}

export interface JobWithCancelMessage extends Job {
  cancel_message?: string;
}

export interface JobZonesRules {
  id?: number;
  job_id?: number;
  pick_up_zone_id?: number;
  drop_off_zone_id?: number;
  allowed?: number;
  pick_up_zone_name?: string | null;
  drop_off_zone_name?: string | null;
  pick_up_zone_polygon?: string | null;
  drop_off_zone_polygon?: string | null;
}
export interface JobsHours {
  id?: number;
  job_id?: number;
  ride_created_from?: number;
  day_of_week?: number;
  start_time?: string;
  end_time?: string;
  description?: string;
  status?: number;
}
interface JobsResponse {
  success: boolean;
  jobs: Job[];
}
interface JobZonesRulesResponse {
  success: boolean;
  jobs_zones_rules: JobZonesRules[];
}
interface JobsHoursResponse {
  success: boolean;
  jobs_hours: JobsHours[];
}

export const getJobs = async function (): Promise<JobsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/jobs`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
export const getJobsWithRoute = async function (): Promise<JobsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<JobsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/jobs?route=true`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  let addJobTypes = res.data.jobs.map((j) => {
    if (!j.route_id && j.eta_url) {
      return { ...j, job_type: "On-Demand" };
    }
    if (j.route_id && j.route_type == 0) {
      return { ...j, job_type: "Fixed Route" };
    }
    if (j.route_id && j.route_type == 1) {
      return { ...j, job_type: "Deviated Route" };
    }
    if (!j.route_id && !j.eta_url) {
      return { ...j, job_type: "Messaging" };
    } else {
      return { ...j, job_type: null };
    }
  });
  res.data.jobs = addJobTypes;
  return res.data;
};
export const getAJob = async function (
  jobId: number | undefined
): Promise<JobsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/${jobId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getAJobWithRoute = async function (
  jobId: number | undefined
): Promise<JobsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<JobsResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/${jobId}?route=true`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  let addJobTypes = res.data.jobs.map((j) => {
    if (!j.route_id && j.eta_url) {
      return { ...j, job_type: "On-Demand" };
    }
    if (j.route_id && j.route_type == 0) {
      return { ...j, job_type: "Fixed" };
    }
    if (j.route_id && j.route_type == 1) {
      return { ...j, job_type: "Deviated" };
    }
    if (!j.route_id && !j.eta_url) {
      return { ...j, job_type: "Messaging" };
    } else {
      return { ...j, job_type: null };
    }
  });
  res.data.jobs = addJobTypes;
  return res.data;
};
export const updateAJob = async function (jobFieldsToUpdate: Job) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/jobs`,
    jobFieldsToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAJob = async function (job: Job | JobWithRiderMessaging) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/jobs`,
    job,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getJobsHours = async function (
  id: number
): Promise<JobsHoursResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<JobsHoursResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/${id}/jobs-hours`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const createJobHours = async function (jobHours: JobsHours) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-hours`,
    jobHours,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const deleteJobHours = async function (id: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-hours/${id}}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const updateJobHours = async function (jobHours: JobsHours) {
  const token = useAuthStore.getState().token;
  const { id, ...rest } = jobHours;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/jobs-hours/${id}}`,
    rest,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getAJobsZonesRules = async function (
  id: number
): Promise<JobZonesRulesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<JobZonesRulesResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/zones-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getJobZoneRulesTransformed = async function (id: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get<JobZonesRulesResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/zones-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
  // let d = transformJzrToOverviewData(res.data.jobs_zones_rules);
  // return d;
};
export const createJobZoneRule = async function (payload: JobZonesRules) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/zones-rules`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const editJobZoneRule = async function (payload: JobZonesRules) {
  const token = useAuthStore.getState().token;
  const { pick_up_zone_name, drop_off_zone_name, ...newPayload } = payload;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/zones-rules/${newPayload.id}`,
    newPayload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const deleteJobZoneRule = async function (id: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/jobs/zones-rules/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
