import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

import { enqueueSnackbar } from "notistack";
import { Fare, getAFare, updateAFare } from "../api/fares.api";
import { getJobsWithRoute } from "../api/jobs.api";
import { getZones } from "../api/zones.api";
import BackButton from "./BackButton";
import DollarAmountChip from "./utility/DollarAmountChip";
import EnabledOptions from "./utility/EnabledOptions";
import StaticMapPreviewModal from "./StaticMapPreviewModal";

const inputBlock = {
  display: "block",
  margin: "20px 20px 20px 0px",
};

export default function FaresSingle() {
  const { id } = useParams() as { id: string };

  const {
    data: fareData,
    status,
    isLoading,
    isRefetching,
    refetch: refetchFare,
  } = useQuery("getAFare", () => getAFare(parseInt(id)), {
    refetchOnMount: true,
  });
  const { mutateAsync: updateFareMutation, isLoading: isMutationLoading } =
    useMutation(updateAFare, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAFare");
        queryClient.invalidateQueries("getFares");
        enqueueSnackbar("Fare was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this fare. Error: ${
            (error as any).response.data.error
          }`,
          {
            variant: "error",
          }
        );
      },
    });
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedFare, setUpdatedFare] = useState<Fare>({
    id: parseInt(id),
  });

  //fetch jobs
  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isRefetching: jobDataIsRefetching,
  } = useQuery("getJobs", getJobsWithRoute, { refetchOnMount: true });
  //fetch zones
  const {
    data: zoneData,
    isLoading: zoneDataIsLoading,
    isRefetching: zoneDataIsRefetching,
  } = useQuery("getZones", getZones, { refetchOnMount: true });
  async function updateFare() {
    await updateFareMutation(updatedFare);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedFare({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Fare Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
            if (!editEnabled) {
              setUpdatedFare({
                ...updatedFare,
                amount: fareData?.fares[0].amount,
              });
            }
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        {fareData?.fares[0] && (
          <form onSubmit={updateFare}>
            <div className="field-block">
              <span className="label">Job Name</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ required: true, min: 0 }}
                  select
                  size="small"
                  style={{ width: "200px" }}
                  label="Select a Job"
                  defaultValue={fareData.fares[0].job_id}
                  onChange={(e) => {
                    setUpdatedFare({
                      ...updatedFare,
                      job_id: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>All Jobs</MenuItem>
                  {jobData?.jobs.map((j) => {
                    return <MenuItem value={j.id}>{j.name}</MenuItem>;
                  })}
                </TextField>
              ) : (
                fareData?.fares[0].job_name || "**ANY**"
              )}
            </div>
            <div className="field-block">
              <span className="label">Pickup Zone</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ required: true, min: 0 }}
                  select
                  size="small"
                  style={{ width: "200px" }}
                  label="Pickup Zone"
                  defaultValue={fareData.fares[0].pick_up_zone_id}
                  onChange={(e) => {
                    setUpdatedFare({
                      ...updatedFare,
                      pick_up_zone_id: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>**ANY**</MenuItem>
                  {zoneData?.zones.map((z) => {
                    return <MenuItem value={z.id}>{z.name}</MenuItem>;
                  })}
                </TextField>
              ) : (
                <>
                  {fareData?.fares[0].pick_up_zone_name || "**ANY**"}{" "}
                  {fareData?.fares[0].pick_up_zone_polygon && (
                    <StaticMapPreviewModal
                      polygonString={fareData?.fares[0].pick_up_zone_polygon}
                    ></StaticMapPreviewModal>
                  )}
                </>
              )}
            </div>
            <div className="field-block">
              <span className="label">Dropoff Zone</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ required: true, min: 0 }}
                  select
                  size="small"
                  style={{ width: "200px" }}
                  label="Dropoff Zone"
                  defaultValue={fareData.fares[0].drop_off_zone_id}
                  onChange={(e) => {
                    setUpdatedFare({
                      ...updatedFare,
                      drop_off_zone_id: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>**ANY**</MenuItem>
                  {zoneData?.zones.map((z) => {
                    return <MenuItem value={z.id}>{z.name}</MenuItem>;
                  })}
                </TextField>
              ) : (
                <>
                  {fareData?.fares[0].drop_off_zone_name || "**ANY**"}{" "}
                  {fareData?.fares[0].drop_off_zone_polygon && (
                    <StaticMapPreviewModal
                      polygonString={fareData?.fares[0].drop_off_zone_polygon}
                    ></StaticMapPreviewModal>
                  )}
                </>
              )}
            </div>
            <div className="field-block">
              <span className="label">Amount</span>
              {editEnabled ? (
                <div className="display-flex-center">
                  <TextField
                    size="small"
                    type="text"
                    inputProps={{ required: true }}
                    defaultValue={fareData?.fares[0].amount}
                    value={updatedFare.amount}
                    style={{ marginRight: "20px", width: "200px" }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      let s = inputValue;
                      let r;

                      if (s == "" || s == undefined) {
                        r = 0;
                      } else {
                        r = parseInt(e.target.value);
                      }
                      setUpdatedFare({
                        ...updatedFare,
                        amount: r,
                      });
                    }}
                  ></TextField>
                  <DollarAmountChip
                    amountCents={
                      updatedFare.amount ?? fareData?.fares[0].amount!
                    }
                  ></DollarAmountChip>
                </div>
              ) : (
                <DollarAmountChip
                  amountCents={fareData?.fares[0].amount!}
                ></DollarAmountChip>
              )}
            </div>
            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <div className="display-flex-center">
                  <TextField
                    select
                    size="small"
                    type="number"
                    inputProps={{ required: true }}
                    defaultValue={fareData?.fares[0].enabled}
                    style={{ marginRight: "20px", width: "200px" }}
                    onChange={(e) =>
                      setUpdatedFare({
                        ...updatedFare,
                        enabled: parseInt(e.target.value),
                      })
                    }
                  >
                    <MenuItem value={0}>Disabled</MenuItem>
                    <MenuItem value={1}>Enabled</MenuItem>
                  </TextField>
                </div>
              ) : (
                <EnabledOptions
                  status={fareData?.fares[0].enabled!}
                ></EnabledOptions>
              )}
            </div>
            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button className="button-primary btn">Save</button>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
