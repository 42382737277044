import { kml as kmlToGeoJson } from "@mapbox/togeojson";
import { Position } from "geojson";

/**
 * Converts a KML string into a GeoJSON object.
 *
 * @param kmlString - The KML data as a string.
 * @returns The parsed GeoJSON object.
 * @throws Will throw an error if the KML parsing fails.
 */
export function kmlStringToGeoJson(kml: string): GeoJSON.FeatureCollection {
  const kmlDoc = new DOMParser().parseFromString(kml, "application/xml");

  if (kmlDoc.getElementsByTagName("parsererror").length > 0) {
    throw new Error("Error parsing KML document.");
  }

  return kmlToGeoJson(kmlDoc);
}

/**
 * Validates a polygon string format where each coordinate pair is a latitude and longitude
 * separated by a comma, with pairs separated by spaces. The polygon must:
 * - Contain at least three coordinate pairs
 * - Have valid latitude and longitude values for each pair
 * - Form a closed loop by having the first and last coordinate pairs be identical
 *
 * @param polygonString - The polygon data as a string, formatted as "lat1,lng1 lat2,lng2 ... latN,lngN".
 * @returns `true` if the polygon string is valid and forms a closed loop, `false` otherwise.
 */
export function isValidPolygonString(polygonString: string): boolean {
  if (typeof polygonString !== "string") return false;

  // Split the string into coordinate pairs and check if there are at least 3
  const coordinatePairs = polygonString.trim().split(" ");
  if (coordinatePairs.length < 3) return false;

  // Validate each coordinate pair
  const allPairsValid = coordinatePairs.every((pair) => {
    const [latStr, lngStr] = pair.split(",");
    const lat = parseFloat(latStr);
    const lng = parseFloat(lngStr);
    return (
      isFinite(lat) &&
      Math.abs(lat) <= 90 &&
      isFinite(lng) &&
      Math.abs(lng) <= 180
    );
  });

  // Check if the first and last coordinate pairs are the same
  const isClosedLoop =
    coordinatePairs[0] === coordinatePairs[coordinatePairs.length - 1];

  // Return true if all pairs are valid and it forms a closed loop
  return allPairsValid && isClosedLoop;
}

/**
 * Converts an array of coordinates (Position) into a formatted string representing
 * a polygon. Each coordinate pair is represented as "latitude,longitude" with six
 * decimal places, and pairs are separated by a space.
 *
 * @param coordinates - An array of Position tuples, where each Position is [longitude, latitude].
 * @returns A string where each coordinate pair is formatted as "latitude,longitude" with
 * six decimal places, and pairs are separated by a space.
 */
export function coordinatesToPolygonString(coordinates: Position[]): string {
  return coordinates
    .map(([lng, lat]) => `${lat.toFixed(6)},${lng.toFixed(6)}`)
    .join(" ");
}
