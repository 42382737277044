import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Chip, Stack } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getMerchantCategories } from "../api/merchants_categories.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
export default function MerchantCategories() {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", flex: 0.5, minWidth: 100 },
    {
      field: "icon",
      headerName: "Icon",
      // flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <>
            {params.row.icon ? (
              <img
                src={params.row.icon}
                style={{ height: "30px", width: "30px" }}
                alt=""
              />
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </>
        );
      },
    },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },

    { field: "priority", headerName: "Priority", flex: 1, minWidth: 100 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.row.status ? (
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  icon={<NotInterestedIcon />}
                  label="Disabled"
                />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  color="success"
                  icon={<CheckCircleIcon />}
                  label="Enabled"
                />
              </Stack>
            )}
          </div>
        );
      },
    },

    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/merchant-categories/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: merchantCategoryData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getMerchantCategories", getMerchantCategories, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Merchant Categories</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/merchant-categories/create");
          }}
        >
          Create New Merchant Category
        </button>
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={merchantCategoryData?.merchants_categories!}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
