import { Checkbox, MenuItem, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { queryClient } from "../App";
import { JobsHours, updateJobHours } from "../api/jobs.api";
import { timeStringToReadableTime } from "../utility/timeStringToReadableTime";
dayjs.extend(advancedFormat);
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
interface JobSingleHoursEditProps {
  closeModal: Function;
  jobHoursInformation: JobsHours;
}

export default function JobSingleHoursEdit(props: JobSingleHoursEditProps) {
  useEffect(() => {
    setJobHours(props.jobHoursInformation);
    if (
      props.jobHoursInformation.start_time == "0000" &&
      props.jobHoursInformation.end_time == "0000"
    ) {
      setNoAccess(true);
    }
  }, [props.jobHoursInformation]);

  const [jobHours, setJobHours] = useState<JobsHours>();
  const [noAccess, setNoAccess] = useState(false);

  const {
    mutateAsync: updateJobHoursMutation,
    isLoading: isUpdatingJobHoursLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(updateJobHours, {
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem updating these job hours. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  function transformMilitaryDateStringToDate(dateString: string) {
    let ds = dateString.split("");
    let h = parseInt(`${ds[0]}${ds[1]}`);
    let m = parseInt(`${ds[2]}${ds[3]}`);
    let d = dayjs(new Date()).hour(h).minute(m).toDate();
    return d;
  }
  function formComplete() {
    if (
      jobHours?.end_time &&
      jobHours?.start_time &&
      jobHours?.day_of_week !== undefined &&
      jobHours?.ride_created_from !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (jobHours) {
      let pl = jobHours;
      if (pl.end_time == "0000") {
        pl.end_time = "2400";
      }
      if (noAccess) {
        pl.end_time = "0000";
        pl.start_time = "0000";
      }
      console.log(pl);
      await updateJobHoursMutation(pl);
      enqueueSnackbar("Succesfully updated job hours.", {
        variant: "success",
      });
      queryClient.invalidateQueries("getJobsHours");
      props.closeModal();
    }
  }
  return (
    <div style={{ width: "500px" }}>
      {props.jobHoursInformation && jobHours && (
        <form onSubmit={submitHandler}>
          <h2>Edit job hours</h2>
          <TextField
            required
            inputProps={{ min: 0 }}
            select
            size="small"
            fullWidth
            sx={inputBlock}
            label="Day of Week"
            value={jobHours?.day_of_week}
            onChange={(e) => {
              setJobHours({
                ...jobHours,
                day_of_week: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Every Day</MenuItem>
            <MenuItem value={1}>Monday</MenuItem>
            <MenuItem value={2}>Tuesday</MenuItem>
            <MenuItem value={3}>Wednesday</MenuItem>
            <MenuItem value={4}>Thursday</MenuItem>
            <MenuItem value={5}>Friday</MenuItem>
            <MenuItem value={6}>Saturday</MenuItem>
            <MenuItem value={7}>Sunday</MenuItem>
          </TextField>
          <TextField
            required
            inputProps={{ min: 0 }}
            select
            size="small"
            fullWidth
            sx={inputBlock}
            label="Ride Created From"
            value={jobHours?.ride_created_from}
            onChange={(e) => {
              setJobHours({
                ...jobHours,
                ride_created_from: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Dashboard</MenuItem>
            <MenuItem value={1}>iOS Rider</MenuItem>
            <MenuItem value={2}>Driver App</MenuItem>
            <MenuItem value={3}>Rider App</MenuItem>
          </TextField>

          <span className="label" style={{ margin: "20px 0px 0px 0px" }}>
            Job hour time
          </span>
          <span
            className="label"
            style={{
              margin: "0px 0px 0px 0px",
              width: "100%",
              fontWeight: 200,
              fontSize: 12,
            }}
          >
            {props.jobHoursInformation.start_time == "0000" &&
            props.jobHoursInformation.end_time == "0000" ? (
              <>Current Job Hours: No Access</>
            ) : (
              <>
                Current Job Hours:{" "}
                {timeStringToReadableTime(
                  props.jobHoursInformation.start_time!
                )}{" "}
                -{" "}
                {timeStringToReadableTime(props.jobHoursInformation.end_time!)}
              </>
            )}
          </span>
          <FormControlLabel
            checked={noAccess}
            control={
              <Checkbox
                onChange={(e) => {
                  setNoAccess(!noAccess);
                }}
              />
            }
            label="No Access"
          />
          {!noAccess && (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Start Time"
                    defaultValue={
                      dayjs(
                        new Date(
                          transformMilitaryDateStringToDate(
                            props.jobHoursInformation.start_time!
                          )
                        )
                      ).second(0) as any
                    }
                    onChange={(e: Date | null) => {
                      setJobHours({
                        ...jobHours,
                        start_time: undefined,
                      });
                      if (dayjs(e).isValid() && e) {
                        let d = new Date(e);
                        let dateString = d.toTimeString();
                        let ds = dateString.split(" ");
                        let dsNoSeconds = ds[0].split(":");
                        let timeFormatted = dsNoSeconds[0] + dsNoSeconds[1];
                        setJobHours({
                          ...jobHours,
                          start_time: timeFormatted,
                        });
                      }
                    }}
                  ></TimePicker>
                  <TimePicker
                    label="End Time"
                    defaultValue={
                      dayjs(
                        new Date(
                          transformMilitaryDateStringToDate(
                            props.jobHoursInformation.end_time!
                          )
                        )
                      ).second(0) as any
                    }
                    onChange={(e: Date | null) => {
                      setJobHours({
                        ...jobHours,
                        end_time: undefined,
                      });
                      if (dayjs(e).isValid() && e) {
                        let d = new Date(e);
                        let dateString = d.toTimeString();
                        let ds = dateString.split(" ");
                        let dsNoSeconds = ds[0].split(":");
                        let timeFormatted = dsNoSeconds[0] + dsNoSeconds[1];
                        setJobHours({
                          ...jobHours,
                          end_time: timeFormatted,
                        });
                      }
                    }}
                  ></TimePicker>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          )}
          <div style={{ margin: "20px 0px 20px 0px" }}>
            <TextField
              required
              inputProps={{ min: 0 }}
              select
              size="small"
              fullWidth
              sx={inputBlock}
              label="Status"
              value={jobHours?.status}
              onChange={(e) => {
                setJobHours({
                  ...jobHours,
                  status: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>Enabled</MenuItem>
              <MenuItem value={1}>Disabled</MenuItem>
            </TextField>
            <TextField
              size="small"
              inputProps={{ maxLength: 255 }}
              fullWidth
              placeholder={"Description"}
              value={jobHours?.description}
              onChange={(e) =>
                setJobHours({
                  ...jobHours,
                  description: e.target.value,
                })
              }
            ></TextField>
          </div>
          <div
            className="button-container"
            style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
          >
            <button
              className="button-outline"
              type="button"
              onClick={(e) => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="button-primary"
              disabled={isUpdatingJobHoursLoading || !formComplete()}
            >
              {!isUpdatingJobHoursLoading && <>Save</>}
              {isUpdatingJobHoursLoading && (
                <CircularProgress color="inherit" size={12} />
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
