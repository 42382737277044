import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { Stop } from "./stops.api";

export enum ZoneStatusTypes {
  Enabled = 0,
  Disabled = 1,
  Hidden = 2,
}

export interface ZoneDeviated {
  id?: number;
  name?: string;
  status?: number;
  polygon?: string | null;
  stop_id?: number;
  stop_name?: string;
  stop_latitude?: string;
  stop_longitude?: string;
  priority?: number;
}
interface ResponseGetZoneDeviated {
  status: boolean;
  zones: ZoneDeviated[];
}
interface StopResponse {
  success: boolean;
  stops: Stop[];
}
export const getZonesDeviated =
  async function (): Promise<ResponseGetZoneDeviated> {
    const token = useAuthStore.getState().token;
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/zones-deviated`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };

export const getAZoneDeviated = async function (
  zoneId: number | undefined
): Promise<ResponseGetZoneDeviated> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/zones-deviated/${zoneId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAZoneDeviated = async function (zoneToUpdate: ZoneDeviated) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/zones-deviated`,
    zoneToUpdate,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAZoneDeviated = async function (zone: ZoneDeviated) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/zones-deviated`,
    zone,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getZoneDeviatedKml = async function (zoneId: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/kml/deviated/${zoneId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
};

export const getAllZonesDeviatedKml = async function () {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/kml/all/deviated`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
};
export const getStopsWithoutDeviatedZone =
  async function (): Promise<StopResponse> {
    const token = useAuthStore.getState().token;
    const res = await axios.get<StopResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/zones-deviated/stops-without-deviated-zone`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  };
