import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

interface CustomReportNumberSelectorInputProps {
  label?: string;
  variable: string;
  type: string;
  defaultValue?: number;
  numberSelected: (value: number, variable: string, type: string) => void;
}

export default function CustomReportNumberSelectorInput({
  label,
  numberSelected,
  variable,
  type,
  defaultValue,
}: CustomReportNumberSelectorInputProps) {
  const initialValue = 0;

  const [value, setValue] = useState<number>(defaultValue || initialValue);
  useEffect(() => {
    numberSelected(value, variable, type);
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(newValue);
    numberSelected(newValue, variable, type);
  };

  return (
    <TextField
      type="number"
      size="small"
      style={{ margin: "20px 0px" }}
      fullWidth
      label={label}
      value={value}
      onChange={handleChange}
    />
  );
}
