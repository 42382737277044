import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getUsersRidersSegments } from "../api/usersRidersSegments.api";
import StatusOptions from "./utility/StatusOptions";

export default function UsersRidersSegmentsOverview() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 50 },

    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <StatusOptions status={params.row.status}></StatusOptions>
          </>
        );
      },
    },
    { field: "priority", headerName: "Priority", flex: 1, minWidth: 200 },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/users-riders-segments/${params.row.id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const {
    data: usersRidersSegmentsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getUsersRidersSegments", getUsersRidersSegments, {
    refetchOnMount: true,
  });

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Rider Segments</h2>
        <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/users-riders-segments/create");
          }}
        >
          Create New Rider Segment
        </button>
      </div>
      {usersRidersSegmentsData && (
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={usersRidersSegmentsData?.users_riders_segments ?? []}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
          />
        </div>
      )}
    </div>
  );
}
