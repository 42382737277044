import { MenuItem, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { Stop, createAStop } from "../api/stops.api";
import BackButton from "./BackButton";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function StopCreate() {
  const navigate = useNavigate();
  const {
    mutateAsync: createStop,
    isLoading: isStopSaving,
    isSuccess,
  } = useMutation(createAStop, {
    onSuccess: () => {
      queryClient.invalidateQueries("getStops");
      enqueueSnackbar("Stop was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/stops");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this stop. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const [createStopPayload, setCreateStopPayload] = useState<Stop>({
    name: "",
    code: "",
    description: "",
    latitude: 0,
    longitude: 0,
  });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await createStop(createStopPayload);
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Stop</h2>
      <div className="create-asset-form">
        <form onSubmit={submitHandler}>
          <TextField
            required
            inputProps={{ autoComplete: "off" }}
            sx={inputBlock}
            size="small"
            label="Name"
            onChange={(e) => {
              setCreateStopPayload({
                ...createStopPayload,
                name: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ autoComplete: "off" }}
            sx={inputBlock}
            size="small"
            label="Description"
            onChange={(e) => {
              setCreateStopPayload({
                ...createStopPayload,
                description: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            required
            inputProps={{ autoComplete: "off" }}
            sx={inputBlock}
            size="small"
            label="Latitude"
            type="number"
            value={createStopPayload.latitude}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              let val;
              if (isNaN(value)) {
                val = undefined;
              }
              if (value > -90 && value < 90) {
                val = value;
              }
              if (value > 90) {
                val = 90;
              }
              if (value < -90) {
                val = -90;
              }
              setCreateStopPayload({
                ...createStopPayload,
                latitude: val,
              });
            }}
          ></TextField>
          <TextField
            required
            inputProps={{ autoComplete: "off" }}
            sx={inputBlock}
            size="small"
            label="Longitude"
            type="number"
            value={createStopPayload.longitude}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              let val;
              if (isNaN(value)) {
                val = undefined;
              }
              if (value > -180 && value < 180) {
                val = value;
              }
              if (value > 180) {
                val = 180;
              }
              if (value < -180) {
                val = -180;
              }
              setCreateStopPayload({
                ...createStopPayload,
                longitude: val,
              });
            }}
          ></TextField>
          <button
            className="button-primary"
            type="submit"
            disabled={
              !createStopPayload.name ||
              !createStopPayload.latitude ||
              !createStopPayload.longitude
            }
          >
            Create Stop
          </button>
        </form>
      </div>
    </div>
  );
}
