import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface Shift {
  id: number;
  driver_id: number;
  vehicle_id: number;
  job_id: number;
  scheduled_shift_id: number | null;
  start_timestamp: number;
  end_timestamp: number;
  started_timestamp: number;
  ended_timestamp: number;
  start_mileage: number;
  end_mileage: number;
  start_latitude: number;
  start_longitude: number;
  start_lead_time: number | null;
  job_name: string;
  vehicle_nickname: string;
  vehicle_type_name: string;
}
interface GetShiftsResponse {
  success: boolean;
  shifts: Shift[];
}

export const getAShift = async function (
  shiftId: number | undefined
): Promise<GetShiftsResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/shifts/${shiftId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
