import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Marker, StaticGoogleMap } from "react-static-google-map";

export interface StaticMapPreviewModalPointProps {
  coordinate: Coordinate;
}
export interface Coordinate {
  lat: number;
  lng: number;
}

export default function StaticMapPreviewModalPoint(
  props: StaticMapPreviewModalPointProps
) {
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    if (!props.coordinate) {
      return;
    }
  }, [props.coordinate]);
  if (props.coordinate == null) {
    return <SearchOffIcon></SearchOffIcon>;
  }
  return (
    <div
      style={{ marginLeft: "20px" }}
      onMouseDown={(e) => {
        setShowModal(true);
      }}
    >
      <SearchIcon></SearchIcon>
      {showModal && (
        <Dialog open={showModal} onClose={() => setShowModal(false)}>
          <StaticGoogleMap
            size="500x500"
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            style="&style=feature:poi|visibility:off&maptype=satellite"
          >
            <Marker
              size="small"
              location={{
                lat: props.coordinate.lat!,
                lng: props.coordinate.lng!,
              }}
            ></Marker>
          </StaticGoogleMap>
        </Dialog>
      )}
    </div>
  );
}
