import { useParams } from "react-router";
import RidesFiltered from "./utility/RidesFiltered";

export default function DriversSingleRides() {
  const { id } = useParams() as { id: string };

  return (
    <RidesFiltered
      driverId={parseInt(id)}
      dateOrderBy="desc"
      pageSize={20}
    ></RidesFiltered>
  );
}
