import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

interface RideModeOptionsProps {
  mode: number | null;
  color: string;
  textColor: string;
  jobName: string;
}

function RideModeOptions({ mode, color, textColor, jobName }: RideModeOptionsProps) {
  const buttonStyle = {
    minWidth: '27px',
    padding: '0px',
    fontSize: '0.85rem',
    cursor: 'default',
  };

  const modeText = mode === 0 ? "On Demand" : mode === 1 ? "Scheduled" : "";
  const buttonText = mode === 0 ? "OD" : "SR";

  const tooltipTitle = `${jobName ? jobName + ' - ' : ''}${modeText}`;

  let buttonToRender = null;
  if (mode === 0 || mode === 1) {
    buttonToRender = (
      <Button variant="contained" style={{ ...buttonStyle, backgroundColor: color, color: textColor }}>
        {buttonText}
      </Button>
    );
  }

  return (
    <>
      {buttonToRender && (
        <Tooltip title={tooltipTitle} enterTouchDelay={0} componentsProps={{ tooltip: { sx: { textAlign: 'center', whiteSpace: 'pre-line' } } }}>
          {buttonToRender}
        </Tooltip>
      )}
    </>
  );
}

export default RideModeOptions;
