import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../stores/auth.store.zus";

interface PermissionGuardInterface {
  allowablePermissionTypes: number[] | null;
  children: ReactNode;
}

const PermissionGuard: React.FC<PermissionGuardInterface> = ({
  allowablePermissionTypes,
  children,
}) => {
  const type = useAuthStore((state) => state.accessType);
  if (
    allowablePermissionTypes == null ||
    (type !== null && allowablePermissionTypes.includes(type))
  ) {
    return <>{children}</>;
  } else {
    return <Navigate to="/dashboard/not-authorized" />;
  }
};

export default PermissionGuard;
