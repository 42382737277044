import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { createAJobCategory } from "../api/jobs_categories.api";
import BackButton from "./BackButton";

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

interface CreateJobCategory {
  name?: string;
  priority?: number;
}

export default function JobCategoryCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync: isMutationSaving,
    isLoading: isMutationLoading,
    isSuccess,
  } = useMutation(createAJobCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("getJobs");
      queryClient.invalidateQueries("getAJob");

      queryClient.invalidateQueries("getJobCategories");
      queryClient.invalidateQueries("getAJobCategory");

      enqueueSnackbar("Job Category was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/job-categories");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this job category. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  useEffect(() => {
    setCreateJobCategoryPayload({
      ...createJobCategoryPayload,
      priority: 0,
    });
  }, []);
  const [createJobCategoryPayload, setCreateJobCategoryPayload] =
    useState<CreateJobCategory>({ priority: 0 });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (createJobCategoryPayload) {
      await isMutationSaving(createJobCategoryPayload);
    }
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Job Category</h2>
      <div className="create-asset-form">
        <form onSubmit={submitHandler}>
          <TextField
            required
            inputProps={{ maxLength: 64, required: true, autoComplete: "off" }}
            size="small"
            sx={inputBlock}
            label="Name"
            onChange={(e) => {
              setCreateJobCategoryPayload({
                ...createJobCategoryPayload,
                name: e.target.value,
              });
            }}
          ></TextField>
          <TextField
            inputProps={{ required: true, min: 0, max: 255 }}
            size="small"
            sx={inputBlock}
            label="Priority"
            type="number"
            value={createJobCategoryPayload.priority}
            onChange={(e) => {
              setCreateJobCategoryPayload({
                ...createJobCategoryPayload,
                priority: parseInt(e.target.value),
              });
            }}
          ></TextField>

          <button className="button-primary" type="submit">
            Create Job Category
          </button>
        </form>
      </div>
    </div>
  );
}
