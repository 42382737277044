import {
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { queryClient } from "../App";
import {
  AccessCodeBatchPayload,
  createAccessCodeBatch,
} from "../api/accessCodes.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";

dayjs.extend(timezone);

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
interface AccessAccountSingleCodesCreateProps {
  closeModal: Function;
}
export default function AccessAccountSingleCodesCreateBatch(
  props: AccessAccountSingleCodesCreateProps
) {
  const { id } = useParams() as { id: string };
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = { ...accessCode };
    if (payload.nickname == "") {
      payload.nickname = null;
    }
    await saveNewAccessCode(payload);
    props.closeModal();
  }
  const [accessCode, setAccessCode] = useState<AccessCodeBatchPayload>({
    amount: 0,
    access_account_id: parseInt(id),
    created_by_email: "root",
    expiration_date: null,
    max_rider_accounts: null,
    nickname: "",
    status: 0,
    type: 0,
  });
  const {
    mutateAsync: saveNewAccessCode,
    isLoading: isSaveNewAccessCodeLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(createAccessCodeBatch, {
    onSuccess: () => {
      enqueueSnackbar("Succesfully created new access codes.", {
        variant: "success",
      });
      queryClient.invalidateQueries("getAccessCodes");
      queryClient.invalidateQueries("getAnAccessCode");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this access code. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });

  const [hasMaxRiderAccountsCustom, setHasMaxRiderAccountsCustom] =
    useState<boolean>(false);
  useEffect(() => {
    if (hasMaxRiderAccountsCustom) {
      setAccessCode({
        ...accessCode,
        max_rider_accounts: 0,
      });
    }
    if (!hasMaxRiderAccountsCustom) {
      setAccessCode({
        ...accessCode,
        max_rider_accounts: null,
      });
    }
  }, [hasMaxRiderAccountsCustom]);
  const [hasExpirationDate, setHasExpirationDate] = useState<boolean>(false);
  useEffect(() => {
    if (!hasExpirationDate) {
      setAccessCode({
        ...accessCode,
        expiration_date: null,
      });
    }
    if (hasExpirationDate) {
      let d = dayjs(new Date())
        .tz(
          useSysParamsStore.getState().getParamByName("global_timezone")?.value
        )
        .hour(23)
        .minute(59)
        .second(59);
      setAccessCode({
        ...accessCode,
        expiration_date: d.unix(),
      });
    }
  }, [hasExpirationDate]);
  return (
    <div style={{ width: "400px" }}>
      <form onSubmit={submitHandler}>
        <h2>Create Multiple Access Codes</h2>
        <TextField
          inputProps={{ required: true, min: 1, max: 1000 }}
          size="small"
          type="number"
          fullWidth
          sx={inputBlock}
          label={"Number of Codes"}
          onChange={(e) =>
            setAccessCode({
              ...accessCode,
              amount: parseInt(e.target.value),
            })
          }
        ></TextField>
        <TextField
          inputProps={{ required: true, min: 0 }}
          select
          size="small"
          fullWidth
          sx={inputBlock}
          label="Type"
          defaultValue={0}
          value={accessCode?.type}
          onChange={(e) => {
            setAccessCode({
              ...accessCode,
              type: parseInt(e.target.value),
            });
          }}
        >
          <MenuItem value={0}>Normal</MenuItem>
          <MenuItem value={1}>Admin</MenuItem>
        </TextField>

        <TextField
          size="small"
          fullWidth
          required
          sx={inputBlock}
          label={"Nickname"}
          onChange={(e) =>
            setAccessCode({
              ...accessCode,
              nickname: e.target.value,
            })
          }
        ></TextField>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={hasMaxRiderAccountsCustom}
                onChange={(e) => {
                  setHasMaxRiderAccountsCustom(!hasMaxRiderAccountsCustom);
                }}
              />
            }
            label="Limit max rider accounts"
          />
        </FormGroup>
        {hasMaxRiderAccountsCustom &&
          typeof accessCode.max_rider_accounts == "number" && (
            <TextField
              size="small"
              type="number"
              fullWidth
              helperText="Number of rider accounts that can have this code at one time."
              sx={inputBlock}
              required
              inputProps={{ required: true }}
              label={"Max Rider Accounts"}
              value={accessCode.max_rider_accounts}
              onChange={(e) =>
                setAccessCode({
                  ...accessCode,
                  max_rider_accounts: parseInt(e.target.value),
                })
              }
            ></TextField>
          )}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={hasExpirationDate}
                onChange={(e) => {
                  setHasExpirationDate(!hasExpirationDate);
                }}
              />
            }
            label="Set expiration date"
          />
        </FormGroup>
        {hasExpirationDate && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <DateTimePicker
                label="Expiration Time"
                value={
                  dayjs(accessCode.expiration_date! * 1000).tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  ) as any
                }
                onChange={(e: Date | null) => {
                  if (e) {
                    let d = dayjs(e).tz(
                      useSysParamsStore
                        .getState()
                        .getParamByName("global_timezone")?.value
                    );
                    let r = d
                      .day(d.day())
                      .month(d.month())
                      .year(d.year())
                      .hour(d.hour())
                      .minute(d.minute())
                      .second(59);
                    setAccessCode({
                      ...accessCode,
                      expiration_date: r.unix(),
                    });
                  }
                }}
              ></DateTimePicker>
            </DemoContainer>
          </LocalizationProvider>
        )}
        <TextField
          inputProps={{ required: true, min: 0 }}
          select
          size="small"
          style={{ width: "200px" }}
          sx={inputBlock}
          label="Status"
          defaultValue={0}
          value={accessCode?.status}
          onChange={(e) => {
            setAccessCode({
              ...accessCode,
              status: parseInt(e.target.value),
            });
          }}
        >
          <MenuItem value={0}>Enabled</MenuItem>
          <MenuItem value={1}>Disabled</MenuItem>
          <MenuItem value={2}>Hidden</MenuItem>
        </TextField>

        <div
          className="button-container"
          style={{ margin: "20px 0px 0px 0px", justifyContent: "right" }}
        >
          <button
            className="button-outline"
            type="button"
            onClick={(e) => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button-primary"
            disabled={
              (hasExpirationDate && accessCode.expiration_date == null) ||
              (hasMaxRiderAccountsCustom &&
                accessCode.max_rider_accounts == null) ||
              isSaveNewAccessCodeLoading
            }
          >
            {!isSaveNewAccessCodeLoading && <>Save</>}
            {isSaveNewAccessCodeLoading && (
              <CircularProgress color="inherit" size={12} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
