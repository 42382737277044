import { Coordinate } from "../components/StaticMapPreview";
import { polygonStringValidation } from "./polygonStringValidation";

export function polygonStringToGoogleMapsCoordinateGetFirstCoordinate(
  polygonString: string
): Coordinate {
  if (!polygonStringValidation(polygonString)) {
    throw "Invalid input string.";
  }
  let s = polygonString.trim().split(" ");
  let splitAtComma = s[0].split(",");
  let lat = parseFloat(splitAtComma[0]);
  let lng = parseFloat(splitAtComma[1]);
  let coordinate: Coordinate = { lat: lat, lng: lng };

  return coordinate;
}
