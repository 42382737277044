export const timeStringToReadableTime = function (timeString: string) {
  // if (timeString.length > 4) {
  //   return null;
  // }
  // let t = timeString.split("");
  // let h = `${t[0]}${t[1]}`;
  // let m = `${t[2]}${t[3]}`;
  // let dayNight = "AM";
  // let hNum = parseInt(h);
  // if (hNum > 12) {
  //   hNum = hNum - 12;
  //   dayNight = "PM";
  // }
  // return `${hNum}:${m} ${dayNight}`;
  let dsSplit = timeString.split("");
  let dToday = new Date();
  let d = new Date(
    dToday.getFullYear(),
    dToday.getMonth(),
    dToday.getDay(),
    parseInt(`${dsSplit[0]}${dsSplit[1]}`),
    parseInt(`${dsSplit[2]}${dsSplit[3]}`)
  );
  let formatted = d.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formatted;
};
