interface JobTypeOptions {
  type: number;
}

export default function JobTypeOptions(props: JobTypeOptions) {
  return (
    <>
      {props.type == 0 && <>Fixed</>}
      {props.type == 1 && <>Deviated</>}
    </>
  );
}
