import Button from "@mui/material/Button";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Comment from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";

interface RatingOptions {
  rating: number;
  feedback_comment: string;
}

export default function RatingOptions(props: {
  rating: number;
  feedback_comment: string;
}) {
  const buttonStyle = {
    minWidth: "auto",
    padding: "2px 1px",
    cursor: "default",
  };
  const starStyle = {
    color: "#ffca00",
  };

  return (
    <>
      {props.rating === 5 && (
        <Tooltip title={`Rating: ${props.rating}`}>
          <Button style={{ ...buttonStyle, ...starStyle }}>
            <StarRateIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.rating === 1 && (
        <Tooltip title={`Rating: ${props.rating}`}>
          <Button style={{ ...buttonStyle, ...starStyle }}>
            <StarOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.rating > 1 && props.rating < 5 && (
        <Tooltip title={`Rating: ${props.rating}`}>
          <Button style={{ ...buttonStyle, ...starStyle }}>
            <StarHalfIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
      {props.feedback_comment && (
        <Tooltip title={`Feedback: ${props.feedback_comment}`}>
          <Button style={{ ...buttonStyle }}>
            <Comment fontSize="small" />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
